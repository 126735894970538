import { DiffViewer, Spinner, WalButton, WalTable, WalTableColumn } from '@humanitec/ui-components';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PipelineRevertModal from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelineVersions/components/PipelineRevertModal/PipelineRevertModal';
import usePipelineDetailsQuery from '@src/hooks/react-query/pipelines/queries/usePipelineDetailsQuery';
import usePipelinesVersions from '@src/hooks/react-query/pipelines/queries/usePipelineVersionsQuery';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import { PipelineVersion } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import makeRequest from '@src/utilities/make-request';

const PipelineVersions = () => {
  // i18n
  const { t } = useTranslation('viewApplication');
  const translations = t('PIPELINES');

  // Router
  const { orgId, appId, pipelineId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: pipelineVersions = [] } = usePipelinesVersions();
  const { data: pipeline } = usePipelineDetailsQuery({ id: pipelineId, appId });
  const { data: users } = useOrgRolesQuery();

  // State
  const [selectedPipelineVersion, setSelectedPipelineVersion] = useState<PipelineVersion>();
  const [selectedPipelineVersionSchema, setSelectedPipelineVersionSchema] = useState<string>();
  const [previousPipelineVersionSchema, setPreviousPipelineVersionSchema] = useState<string>();
  const [pipelineVersionSchemaIsLoading, setPipelineVersionSchemaIsLoading] =
    useState<boolean>(false);
  const [revertModalOpen, setRevertModalOpen] = useState<boolean>(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number>();

  const columns: WalTableColumn<PipelineVersion>[] = [
    {
      label: translations.EDIT_DATE,
      prop: 'created_at',
      template: (version) =>
        formatDate(version.data.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE),
    },
    {
      label: translations.EDIT_BY,
      prop: 'created_by',
      template: (version) => users?.find((user) => version.data.created_by === user.id)?.name,
    },
    {
      label: '',
      prop: 'revert-button',
      template: (version) =>
        pipeline?.version === version.data.id ? (
          <WalButton size={'small'} variant={'secondary'} disabled>
            {translations.CURRENT}
          </WalButton>
        ) : (
          <WalButton
            size={'small'}
            variant={'secondary'}
            onClick={(e) => onRevertButtonClick(e, version.data)}>
            {translations.REVERT}
          </WalButton>
        ),
    },
  ];

  const onRevertButtonClick = (e: MouseEvent, version: PipelineVersion) => {
    e.stopPropagation();
    setExpandedRowIndex(undefined);
    setSelectedPipelineVersion(version);
    setRevertModalOpen(true);
  };

  const onPipelineVersionRowClick = async (versionId: string, previousVersionId?: string) => {
    setPipelineVersionSchemaIsLoading(true);
    const selectedSchemaResponse = await makeRequest<string>(
      'GET',
      `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/schema?version=${versionId}`
    );
    if (previousVersionId) {
      const previousSchemaResponse = await makeRequest<string>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/schema?version=${previousVersionId}`
      );
      setPreviousPipelineVersionSchema(previousSchemaResponse.data);
    } else {
      setPreviousPipelineVersionSchema(undefined);
    }
    setSelectedPipelineVersionSchema(selectedSchemaResponse.data);
    setPipelineVersionSchemaIsLoading(false);
  };

  return (
    <>
      <WalTable
        rows={pipelineVersions?.map((version, index) => ({
          data: version,
          onRowClick: (e, row) =>
            onPipelineVersionRowClick(
              row.data.id,
              pipelineVersions[index + 1] ? pipelineVersions[index + 1].id : undefined
            ),
          expandableContent: pipelineVersionSchemaIsLoading ? (
            <Spinner size={'small'} />
          ) : (
            <DiffViewer
              oldValue={previousPipelineVersionSchema || ''}
              newValue={selectedPipelineVersionSchema}
            />
          ),
        }))}
        expandedRowState={[expandedRowIndex, setExpandedRowIndex]}
        columns={columns}
        tableStyle={'expandable'}
        caption={translations.PIPELINE_VERSIONS}
      />
      {revertModalOpen && selectedPipelineVersion && pipeline && (
        <PipelineRevertModal
          openState={[revertModalOpen, setRevertModalOpen]}
          selectedPipelineVersion={selectedPipelineVersion}
          pipeline={pipeline}
        />
      )}
    </>
  );
};

export default PipelineVersions;
