import { TabGroupRouting } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';

import DeployedValues from './components/DeployedValues/DeployedValues';
import EnvironmentWorkloads from './components/EnvironmentWorkloads/EnvironmentWorkloads';
import SharedResources from './components/SharedResources/SharedResources';

const ViewDeploymentOrDeltaTabs = () => {
  // i18n
  const { t } = useTranslation('viewDeployment');
  const tabsTranslations = t('TABS');

  // Context
  const { onRunningDeployment, draftModeActive } = useDeploymentOrDeltaContext();

  return (
    <TabGroupRouting
      items={[
        {
          path: 'workloads',
          label: tabsTranslations.WORKLOADS,
          element: <EnvironmentWorkloads />,
        },
        {
          path: 'shared-resources',
          label: tabsTranslations.SHARED_RESOURCES,
          element: <SharedResources />,
        },
        {
          path: 'deployed-values',
          label: tabsTranslations.DEPLOYED_VALUES,
          element: <DeployedValues />,
          hide: draftModeActive || onRunningDeployment,
        },
      ]}
    />
  );
};

export default ViewDeploymentOrDeltaTabs;
