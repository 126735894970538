import { useInfiniteQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getPipelineRunsFilterString } from '@src/containers/Orgs/PipelineRuns/utils';
import { PipelineRun } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelineRunsQueryKeys } from './pipelineRunsQueryKeys';

interface usePipelineRunsQueryProps {
  statuses?: string[];
  createBeforeDate?: string;
  createAfterDate?: string;
}

const usePipelineRunsQuery = ({
  statuses,
  createBeforeDate,
  createAfterDate,
}: usePipelineRunsQueryProps) => {
  const { orgId, appId, pipelineId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useInfiniteQuery({
    queryKey: pipelineRunsQueryKeys.pipelineRuns(orgId, appId, pipelineId, {
      statuses,
      createBeforeDate,
      createAfterDate,
    }),
    queryFn: ({ pageParam }) => {
      return makeRequest<PipelineRun[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/runs${getPipelineRunsFilterString(
          statuses,
          createBeforeDate,
          createAfterDate,
          pageParam
        )}`
      );
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage.headers.link?.match(/page=([^&]+)/)?.[1] || null;
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.pages.flatMap((page) => page.data) };
};

export default usePipelineRunsQuery;
