import { rem } from 'polished';
import { css } from 'styled-components';

import { units } from '@src/styles/variables';

import { breakpoints } from './breakpoints';

export const containerStyle = (disableOverflow?: boolean) => {
  return css`
    display: flex;
    flex: 1;
    max-width: ${units.appMaxWidth};
    width: 100%;
    padding: 0 ${units.containerHorizontalPadding};
    ${!disableOverflow && `overflow: hidden;`};

    margin: 0 auto;

    @media ${breakpoints.mobile} {
      padding-right: ${units.containerHorizontalPaddingMobile};
      padding-left: ${units.containerHorizontalPaddingMobile};
    }
  `;
};

export const flexColumn = () => {
  return css`
    display: flex;
    flex-direction: column;
  `;
};

export const flexCentered = () => {
  return css`
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    align-items: center;
  `;
};

const backgroundGradient = (width = '80vh', baseColor: string) => {
  return `
    background-image: linear-gradient(90deg, transparent 0, ${baseColor} 100%);
    background-size: ${width};
  `;
};
const animateGhostLine = (baseColor: string, animationDuration = '3000ms', width = '80vh') => {
  return `
  ${backgroundGradient(width, baseColor)};
  @keyframes ghost-lines {
    0%   { background-position: 200%; }
    100% { background-position: -100%; }
  }
  animation: ghost-lines ${animationDuration} infinite linear;
  `;
};

export const loaderAnimation = (
  baseColor: string,
  animationDuration = '3000ms',
  width = '80vh'
) => {
  return css`
    ${animateGhostLine(baseColor, animationDuration, width)};
    border-radius: ${rem(4)};
  `;
};

export const ellipsisStyle = (maxWidth?: number, invertEllipsis?: boolean) => css`
  display: block !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${maxWidth &&
  css`
    max-width: ${rem(maxWidth)};
  `}
  ${invertEllipsis &&
  css`
    direction: rtl;
    text-align: left;
  `}
`;
