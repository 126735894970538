import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { WorkloadContext } from '@src/context/workloadContext';
import useDeploymentSetQuery from '@src/hooks/react-query/deployment-set/useDeploymentSetQuery';
import { MatchParams } from '@src/models/routing';

const WorkloadContextWrapper = ({ children }: { children: ReactNode }) => {
  // Router hooks
  const { moduleId } = useParams<keyof MatchParams>() as MatchParams;

  // Selectors
  const { data: deploymentSet } = useDeploymentSetQuery();

  const deploymentSetWorkload = deploymentSet?.modules[moduleId];

  return (
    <WorkloadContext.Provider
      value={{
        dsWorkload: deploymentSetWorkload,
      }}>
      {children}
    </WorkloadContext.Provider>
  );
};

export default WorkloadContextWrapper;
