import {
  EmptyStateCard,
  FilterByOption,
  FilterByOptions,
  FilterConditions,
  Icon,
  IconNames,
  iconNamesArray,
  MultiFilterTable,
  Tag,
  Text,
  WalButton,
  WalLabel,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { AxiosResponse } from 'axios';
import { compact, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import ConfirmDeleteMatchingCriteriaModal from '@src/components/shared/MatchingCriteria/ConfirmDeleteMatchingCriteriaModal/ConfirmDeleteMatchingCriteriaModal';
import { filterResourceDefinitions } from '@src/containers/Orgs/Resources/components/ResourcesTable/resource-filter-utils';
import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useResourceDefinitionDeleteMutation from '@src/hooks/react-query/resources/mutations/useResourceDefinitionDeleteMutation';
import { useResourcesStore } from '@src/hooks/zustand/useResourcesStore';
import { ResourceConflictChange, ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import makeRequest from '@src/utilities/make-request';
import { generateAppURL } from '@src/utilities/navigation';

const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units.margin.md};
`;

const AppWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const NameWrapper = styled.div<{ alignIconAndText?: boolean }>`
  display: grid;
  grid-template-columns: auto minmax(auto, 100%);
  column-gap: ${units.padding.sm};
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  ${({ alignIconAndText }) =>
    alignIconAndText &&
    css`
      justify-content: space-between;
    `};
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
`;

const PendingDeletionTag = styled(Tag)`
  margin-right: ${units.margin.md};
`;

const ExpandableContentHeader = styled.div`
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
`;

interface ResourcesTableProps {
  resourceDefinitions: ResourceDefinition[];
}

const ResourcesTable = ({ resourceDefinitions }: ResourcesTableProps) => {
  // i18n
  const { t } = useTranslation();
  const conflictModalTranslations =
    t('ACCOUNT_SETTINGS').RESOURCES.CONFIRM_DELETE_RES_DEF_MATCHING_CRITERIA;
  const tableTranslations = t('ACCOUNT_SETTINGS').RESOURCES.TABLE;
  const filterTranslations = t('ACCOUNT_SETTINGS').RESOURCES.FILTER;
  const translations = t('RESOURCE_MATCHING');

  // React Query
  const { data: applications = [] } = useApplicationsQuery();

  // Zustand
  const { lastCreatedResourceDefinitions } = useResourcesStore();

  // Component state
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [resourceDefinitionToDelete, setResourceDefinitionToDelete] =
    useState<ResourceDefinition>();
  const [resourceDefinitionDeleteConflicts, setResourceDefinitionDeleteConflicts] = useState<
    ResourceConflictChange[] | undefined
  >();
  const [filteredResourceDefinitions, setFilteredResourceDefinitions] =
    useState<ResourceDefinition[]>(resourceDefinitions);
  const [filterConditions, setFilterConditions] = useState<FilterConditions>();

  // React query
  const {
    mutate: deleteResourceDefinition,
    error: deleteError,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useResourceDefinitionDeleteMutation();
  const { data: envTypes = [] } = useEnvironmentTypesQuery();

  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;
  const navigate = useNavigate();

  useEffect(() => {
    if (resourceDefinitions) {
      setFilteredResourceDefinitions(
        filterResourceDefinitions(resourceDefinitions, filterConditions)
      );
    }
  }, [resourceDefinitions, filterConditions]);

  useEffect(() => {
    if (isDeletedSuccessfully) {
      setConfirmDeleteModalOpen(false);
      resetDeleteMutation();
    }
  }, [isDeletedSuccessfully, resetDeleteMutation]);

  const columns: WalTableColumn<ResourceDefinition>[] = [
    {
      label: tableTranslations.ID,
      prop: 'name',
      alignIconAndText: true,
      ellipsisTooltip: {
        maxWidth: 400,
        maxCharacters: 60,
        text: (row) => row.data.name.replace(/(.{1,80})/g, '$1\u00ad'),
      },
      infoPopupText: (row) => (row.data.is_default ? translations.DEFAULT_INFO : undefined),
      template: (row) => (
        <NameWrapper alignIconAndText={row.data.is_default}>
          <Icon
            name={
              row.data.is_default
                ? 'humanitec'
                : row.data.type.includes('aws') || row.data.type === 'sqs'
                  ? 'amazon'
                  : iconNamesArray.includes(row.data.type as IconNames)
                    ? (row.data.type as IconNames)
                    : 'generic-resource'
            }
          />
          <span>{row.data.name}</span>
        </NameWrapper>
      ),
    },
    {
      label: tableTranslations.DRIVER,
      prop: 'driver_type',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 30,
        text: (row) => row.data.driver_type,
      },
      template: (row) => <p>{row.data.driver_type || '-'}</p>,
    },
    {
      label: tableTranslations.TYPE,
      prop: 'type',
      justifyContent: 'flex-start',
      ellipsisTooltip: {
        maxWidth: 150,
        maxCharacters: 30,
        text: (row) => row.data.type,
      },
      template: (row) => <p>{row.data.type || '-'}</p>,
    },
    {
      prop: 'actions',
      tabIndex: -1,
      template: (row) => {
        return (
          <ActionsWrapper>
            {row.data.is_deleted && (
              <PendingDeletionTag text={translations.PENDING_DELETION} variant={'warning'} />
            )}
            {!row.data?.is_default && (
              <WalButton
                iconLeft={'delete'}
                size={'small'}
                variant={'secondary'}
                ariaLabel={`${translations.DELETE_RESOURCE_DEFINITION} ${row.data.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickOnDeleteButton(row.data);
                }}
              />
            )}
          </ActionsWrapper>
        );
      },
    },
  ];

  const resourceTypes = uniq(resourceDefinitions?.map((def) => def.type));
  const resourceDefinitionIds = uniq(resourceDefinitions.map((def) => def.id));
  const driverTypes = uniq(resourceDefinitions?.map((def) => def.driver_type));
  const envIds = uniq(applications?.flatMap((app) => app.envs).map((env) => env.id));
  const appIds = applications?.map((app) => app.id);
  const resIds = uniq(
    compact(
      resourceDefinitions.flatMap(
        (resDef) => resDef.criteria?.flatMap((criteria) => criteria.res_id)
      )
    )
  );
  const envTypeIds = envTypes?.map((envType) => envType.id);

  const detailsFilterByOptions: FilterByOption[] = [
    {
      label: filterTranslations.RESOURCE_TYPE,
      id: 'type',
      value: 'type',
      comboSelectOptions: resourceTypes.map((resourceType) => ({
        id: resourceType,
        label: resourceType,
        searchString: resourceType,
        value: resourceType,
      })),
    },
    {
      label: filterTranslations.DRIVER_TYPE,
      id: 'driver_type',
      value: 'driver_type',
      comboSelectOptions: driverTypes.map((driverType) => ({
        id: driverType,
        label: driverType,
        searchString: driverType,
        value: driverType,
      })),
    },
    {
      label: filterTranslations.DEFINITION_ID,
      id: 'id',
      value: 'id',
      comboSelectOptions: resourceDefinitionIds.map((id) => ({
        id,
        label: id,
        searchString: id,
        value: id,
      })),
    },
  ];

  const matchingCriteriaFilterByOptions: FilterByOption[] = [
    {
      label: filterTranslations.ENVIRONMENT_TYPE,
      id: 'env_type',
      value: 'env_type',
      comboSelectOptions: envTypeIds.map((environmentType) => ({
        id: environmentType,
        label: environmentType,
        searchString: environmentType,
        value: environmentType,
      })),
    },
    {
      label: filterTranslations.ENVIRONMENT_ID,
      id: 'env_id',
      value: 'env_id',
      comboSelectOptions: envIds.map((id) => ({ id, label: id, searchString: id, value: id })),
    },
    {
      label: filterTranslations.APPLICATION_ID,
      id: 'app_id',
      value: 'app_id',
      comboSelectOptions: appIds.map((id) => ({ id, label: id, searchString: id, value: id })),
    },
    {
      label: filterTranslations.RESOURCE_ID,
      id: 'res_id',
      value: 'res_id',
      comboSelectOptions: resIds.map((id) => ({ id, label: id, searchString: id, value: id })),
    },
  ];

  const handleClickOnDeleteButton = (resourceDefinition: ResourceDefinition) => {
    setResourceDefinitionToDelete(resourceDefinition);
    setResourceDefinitionDeleteConflicts(undefined);
    // check if the resource definition has active resources
    makeRequest('GET', `/orgs/${orgId}/resources/defs/${resourceDefinition.id}/resources`).then(
      (res: AxiosResponse) => {
        setResourceDefinitionDeleteConflicts(res.data);
        if (res.data?.length > 0) {
          // delete the resource definition which will open the conflict modal with the list of the associated resources
          deleteResourceDefinition({ defId: resourceDefinition.id });
        }
        setConfirmDeleteModalOpen(true);
      }
    );
  };

  const handleDeleteResourceDef = (force?: boolean) => {
    if (resourceDefinitionToDelete) {
      deleteResourceDefinition({ defId: resourceDefinitionToDelete.id, force });
    }
  };

  const navigateToResourceDefinition = (
    e: React.MouseEvent,
    row: WalTableRow<ResourceDefinition>
  ) => {
    navigate(`${row.data.id}/usage`);
  };

  const filterByOptions: FilterByOptions = {
    details: {
      title: filterTranslations.BY_DEFINITION_DETAILS,
      options: detailsFilterByOptions,
    },
    criteria: {
      title: filterTranslations.BY_MATCHING_CRITERIA,
      options: matchingCriteriaFilterByOptions,
    },
  };

  const objectName = 'resource definition';

  const deleteConflicts =
    resourceDefinitionDeleteConflicts && deleteError?.response?.status === 409
      ? resourceDefinitionDeleteConflicts
      : [];

  const tableCols: WalTableColumn<ResourceConflictChange>[] = [
    {
      label: conflictModalTranslations.ID,
      prop: 'id',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 20,
        text: (row) => row.data.res_id,
      },
      template: (row) => <span>{row.data.res_id || '-'}</span>,
    },
    {
      label: conflictModalTranslations.REPLACEMENT_DEFINITION,
      justifyContent: 'flex-start',
      prop: 'replacement_definition',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 20,
        text: (row) => row.data.to_def,
      },
      template: (row) => <span>{row.data.to_def || '-'}</span>,
    },
  ];

  return (
    <>
      <MultiFilterTable
        filterByOptions={filterByOptions}
        onFiltersChange={(conditions?: FilterConditions) => {
          setFilterConditions(conditions);
        }}
        caption={tableTranslations.CAPTION}
        columns={columns}
        disableScrolling
        noItemsText={translations.NO_MATCHING_RESOURCES}
        tableStyle={'clickable'}
        initialRowsCount={resourceDefinitions.length}
        rows={
          filteredResourceDefinitions
            ? filteredResourceDefinitions?.map(
                (r): WalTableRow => ({
                  data: r,
                  isNew: lastCreatedResourceDefinitions.includes(r.id),
                  onRowClick: navigateToResourceDefinition,
                  expandableContent: !r.is_default ? (
                    <div>
                      <ExpandableContentHeader>
                        {tableTranslations.MATCHING_CRITERIA}
                      </ExpandableContentHeader>
                    </div>
                  ) : undefined,
                })
              )
            : []
        }
      />
      {resourceDefinitionToDelete && confirmDeleteModalOpen && (
        <ConfirmDeleteMatchingCriteriaModal
          openState={[confirmDeleteModalOpen, setConfirmDeleteModalOpen]}
          onDeleteConfirmed={() => handleDeleteResourceDef(true)}
          title={t('ACCOUNT_SETTINGS.RESOURCES.CONFIRM_DELETE_RES_DEF_MATCHING_CRITERIA_TITLE', {
            returnObjects: true,
            replace: {
              name: objectName,
              resId: ` ${resourceDefinitionToDelete.id}`,
            },
          })}
          inputConfirmation={
            deleteConflicts?.length > 0
              ? { correctValue: resourceDefinitionToDelete.id, objectName, propertyToType: 'ID' }
              : undefined
          }
          content={
            <>
              <Text>
                <Trans
                  defaults={
                    conflictModalTranslations.DELETE_RESOURCE_DEFINITION_OR_MATCHING_CRITERIA_TEXT_1
                  }
                  values={{ objectName }}
                />
              </Text>
              <Text>
                <Trans
                  defaults={conflictModalTranslations.DELETE_RESOURCE_DEFINITION_TEXT_2}
                  values={{ objectName }}
                />
              </Text>
              {objectName === 'resource definition' && (
                <Text color={'textTranslucent'} className={'mb-md'}>
                  {conflictModalTranslations.DELETE_RESOURCE_DEFINITION_TEXT_3}
                </Text>
              )}

              {deleteConflicts &&
                (deleteConflicts?.length > 0 ? (
                  <>
                    <Text color={'textTranslucent'} className={'mb-md'}>
                      {conflictModalTranslations.RESOURCES_AFFECTED}
                    </Text>
                    <WalTable
                      caption={conflictModalTranslations.RESOURCES_AFFECTED}
                      columns={tableCols}
                      tableStyle={'expandable'}
                      tableRowStyle={'base'}
                      rows={(deleteConflicts || [])?.map(
                        (deleteChange): WalTableRow => ({
                          data: deleteChange,
                          expandableContent: (
                            <>
                              <Field>
                                <WalLabel>{conflictModalTranslations.RESOURCE_ID}</WalLabel>
                                <span>{deleteChange.res_id || '_'}</span>
                              </Field>
                              <Field>
                                <WalLabel>{conflictModalTranslations.APPLICATION_ID}</WalLabel>
                                <AppWrapper>
                                  {deleteChange.app_id || '_'}
                                  {deleteChange.app_id && (
                                    <Link
                                      to={generateAppURL(orgId, deleteChange.app_id)}
                                      target={'_blank'}
                                      rel={'noopener noreferrer'}>
                                      <Icon
                                        name={'link'}
                                        overrideColor={'main-brighter'}
                                        marginLeft={'sm'}
                                        pointer
                                      />{' '}
                                    </Link>
                                  )}
                                </AppWrapper>
                              </Field>
                              <Field>
                                <WalLabel>{conflictModalTranslations.ENVIRONMENT_ID}</WalLabel>
                                <span>{deleteChange.env_id || '_'}</span>
                              </Field>
                              <Field>
                                <WalLabel>
                                  {conflictModalTranslations.REPLACEMENT_DEFINITION}
                                </WalLabel>
                                <span>{deleteChange.to_def || '_'}</span>
                              </Field>
                            </>
                          ),
                        })
                      )}
                      disableScrolling
                    />
                  </>
                ) : (
                  <EmptyStateCard>{conflictModalTranslations.NO_RESOURCES_AFFECTED}</EmptyStateCard>
                ))}
            </>
          }
        />
      )}
    </>
  );
};

export default ResourcesTable;
