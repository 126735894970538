import { get } from 'lodash';

import i18n from '@src/i18n/i18n';
import {
  HumanitecWorkloadProfileFeatureKeys,
  WorkloadFeatureDefinition,
} from '@src/models/workload-profile-v2';
import { convertPathToArraySegments } from '@src/utilities/deployment-delta-utils';

import { SectionTileProps } from '../../SectionTile';

interface WorkloadPathCollection {
  paths: Record<string, string[]>;
  featureGroups: Record<
    string,
    {
      runtimeProperties: WorkloadFeatureDefinition[];
      deltaProperties: Record<string, WorkloadFeatureDefinition>;
    }
  >;
}

/**
 * Function which iterates over features and generates paths.
 *
 * @param features The 'features' property from the workload profile
 * @param details The data in the deploymentset/delta.
 * @param path The path including any named object ids e.g. `/spec/containers/{containerId}/variables`.
 * @param pathWithoutCollectionObjects The path excluding any named object ids e.g. `/spec/containers/variables`. This is used to create a reference for grouping paths which have the same features.
 * @param collectionPaths A reference object which holds all the groupings of features & paths
 * @returns
 */
export const iterateFeatures = (
  features: Record<string, WorkloadFeatureDefinition>,
  details: any,
  path: string,
  pathWithoutCollectionObjects: string,
  collectionPaths: WorkloadPathCollection
): string[] => {
  return Object.entries(features).flatMap(([featureKey, feature]) => {
    const newPath = path ? `${path}/${featureKey}` : `${featureKey}`;
    const newPathWithoutCollectionObjects = pathWithoutCollectionObjects
      ? `${pathWithoutCollectionObjects}/${featureKey}`
      : featureKey;

    if (feature.type === 'collection' && feature.properties) {
      const convertedToArray = convertPathToArraySegments(newPath);

      const collectionData = get(details, ['spec', ...convertedToArray]);

      const nestedFeatures = feature.properties;
      if (!collectionPaths.featureGroups[newPathWithoutCollectionObjects]) {
        collectionPaths.featureGroups[newPathWithoutCollectionObjects] = {
          runtimeProperties: [],
          deltaProperties: {},
        };

        collectionPaths.featureGroups[newPathWithoutCollectionObjects].runtimeProperties =
          feature.runtime_properties || [];
        collectionPaths.featureGroups[newPathWithoutCollectionObjects].deltaProperties =
          feature.properties;
      }

      if (collectionData && nestedFeatures) {
        return Object.keys(collectionData).flatMap((collectionEntryId) => {
          const childPath = `${newPath}/${collectionEntryId}`;

          if (!collectionPaths.paths[newPathWithoutCollectionObjects]) {
            collectionPaths.paths[newPathWithoutCollectionObjects] = [];
          }

          collectionPaths.paths[newPathWithoutCollectionObjects].push(childPath);

          return iterateFeatures(
            nestedFeatures,
            details,
            childPath,
            newPathWithoutCollectionObjects,
            collectionPaths
          );
        });
      }

      return iterateFeatures(
        feature.properties,
        details,
        newPath,
        newPathWithoutCollectionObjects,
        collectionPaths
      );
    }

    return newPath;
  });
};

/**
 * Function to generate mapping between routing paths & workload features.
 *
 * @param features The 'features' property from the workload profile
 * @param details The data in the deploymentset/delta.
 * @returns collectionPaths
 */
export const findSchemaRoutingPaths = (
  features: { properties: WorkloadFeatureDefinition['properties'] } | undefined,
  details: any
) => {
  const collectionPaths: WorkloadPathCollection = { paths: {}, featureGroups: {} };

  iterateFeatures(features?.properties || {}, details, '', '', collectionPaths);

  return collectionPaths;
};

/**
 * Replacing placeholder text ( format ${abc.def.ghi}) to a constant 'PLACEHOLDER'
 * This way it is easy to validate value input so that other than placeholder rest of the text matches specifics
 */
export const replacePlaceholdersWithConstant = (original: string): string =>
  original.replace(/\$\{[a-zA-Z0-9_.\-"[\]]*\}/g, 'PLACEHOLDER');

/**
 * Reusable function to get translations. This is required to maintain old & new versions of the workload profile screen.
 */
export const getSectionFeatureInfo = (
  feature: HumanitecWorkloadProfileFeatureKeys | 'hardcoded/resource-dependencies',
  draftModeActive: boolean,
  isReplicaNewDesign: boolean
): Pick<SectionTileProps, 'title' | 'infoPopup'> | undefined => {
  const sectionsTranslations = i18n.t('VIEW_MODULE').SECTIONS;
  const labelsTranslations = i18n.t('FEATURES', { ns: 'workloadProfile' }).LABELS;
  const annotationsTranslations = i18n.t('FEATURES', { ns: 'workloadProfile' }).ANNOTATIONS;
  const probeTranslations = i18n.t('FEATURES', { ns: 'workloadProfile' }).PROBE;
  const overridesTranslations = i18n.t('FEATURES', { ns: 'workloadProfile' }).OVERRIDES;

  switch (feature) {
    case 'humanitec/image':
      return {
        title: sectionsTranslations.IMAGE,
        infoPopup: draftModeActive
          ? {
              text: sectionsTranslations.IMAGE_INFO_TEXT,
              link: sectionsTranslations.IMAGE_INFO_LINK,
              width: 450,
            }
          : {
              text: sectionsTranslations.IMAGE_INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
              link: sectionsTranslations.IMAGE_INFO_LINK_RUNNING_OR_PAST_DEPLOYMENT,
              width: 500,
            },
      };
    case 'humanitec/logs':
      return {
        title: sectionsTranslations.CONTAINER_LOGS,
        infoPopup: {
          text: sectionsTranslations.CONTAINER_LOGS_INFO_TEXT,
          link: sectionsTranslations.CONTAINER_LOGS_INFO_LINK,
          position: 'bottom',
        },
      };

    case 'humanitec/variables':
      return {
        title: sectionsTranslations.VARIABLES,
        infoPopup: draftModeActive
          ? {
              text: sectionsTranslations.VARIABLES_INFO_TEXT,
              link: sectionsTranslations.VARIABLES_INFO_LINK,
            }
          : {
              text: sectionsTranslations.VARIABLES_INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
              link: sectionsTranslations.VARIABLES_INFO_LINK_RUNNING_OR_PAST_DEPLOYMENT,
            },
      };
    case 'humanitec/files':
      return {
        title: sectionsTranslations.FILES,
        infoPopup: draftModeActive
          ? {
              text: sectionsTranslations.FILES_INFO_TEXT,
              link: sectionsTranslations.FILES_INFO_LINK,
            }
          : {
              text: sectionsTranslations.FILES_INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
              link: sectionsTranslations.FILES_INFO_LINK_RUNNING_OR_PAST_DEPLOYMENT,
            },
      };

    case 'humanitec/volumes':
    case 'humanitec/volume-mounts':
      return {
        title: sectionsTranslations.VOLUME_MOUNTS,
        infoPopup: draftModeActive
          ? {
              text: sectionsTranslations.VOLUME_MOUNTS_INFO_TEXT,
              link: sectionsTranslations.VOLUME_MOUNTS_INFO_LINK,
              width: 310,
            }
          : {
              text: sectionsTranslations.VOLUME_MOUNTS_INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
              link: sectionsTranslations.VOLUME_MOUNTS_INFO_LINK_RUNNING_OR_PAST_DEPLOYMENT,
            },
      };

    case 'humanitec/service-account':
      return {
        title: sectionsTranslations.SERVICE_ACCOUNT_TITLE,
        infoPopup: draftModeActive
          ? {
              text: sectionsTranslations.SERVICE_ACCOUNT_INFO_TEXT,
              link: sectionsTranslations.SERVICE_ACCOUNT_INFO_LINK,
            }
          : {
              text: sectionsTranslations.SERVICE_ACCOUNT_INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
              link: sectionsTranslations.SERVICE_ACCOUNT_INFO_LINK,
            },
      };
    case 'humanitec/service':
      return {
        title: sectionsTranslations.SERVICE_PORTS_TITLE,
        infoPopup: {
          text: sectionsTranslations.SERVICE_PORTS_INFO_TEXT,
          link: 'https://developer.humanitec.com/integration-and-extensions/workload-profiles/features/#service',
          width: 300,
        },
      };
    case 'humanitec/resources':
      return {
        title: sectionsTranslations.ASSIGN_RESOURCES,
        infoPopup: draftModeActive
          ? {
              text: sectionsTranslations.ASSIGN_RESOURCES_INFO_TEXT,
              link: sectionsTranslations.CONTAINER_CONFIG_INFO_LINK,
              width: 450,
            }
          : {
              text: sectionsTranslations.ASSIGN_RESOURCES_INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
              link: sectionsTranslations.CONTAINER_CONFIG_INFO_LINK_RUNNING_OR_PAST_DEPLOYMENT,
              width: 500,
            },
      };
    case 'humanitec/labels':
      return {
        title: labelsTranslations.LABELS,
        infoPopup: {
          text: labelsTranslations.INFO_TEXT,
          link: labelsTranslations.INFO_LINK,
        },
      };
    case 'humanitec/annotations':
      return {
        title: annotationsTranslations.ANNOTATIONS,
        infoPopup: {
          text: annotationsTranslations.INFO_TEXT,
          link: annotationsTranslations.INFO_LINK,
        },
      };

    case 'humanitec/ingress':
      return {
        title: sectionsTranslations.ROUTING_TITLE,
        infoPopup: {
          text: draftModeActive
            ? sectionsTranslations.ROUTING_INFO_TEXT
            : sectionsTranslations.ROUTING_INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
          link: sectionsTranslations.ROUTING_INFO_LINK,
          width: 400,
        },
      };

    case 'humanitec/probe':
      return {
        title: probeTranslations.PROBE,
        infoPopup: {
          text: draftModeActive
            ? probeTranslations.INFO_TEXT
            : probeTranslations.INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
          link: probeTranslations.INFO_LINK,
          width: 400,
        },
      };

    case 'humanitec/overrides':
      return {
        title: overridesTranslations.OVERRIDES,
        infoPopup: draftModeActive
          ? {
              text: overridesTranslations.INFO_TEXT,
              link: overridesTranslations.INFO_LINK,
              width: 500,
            }
          : {
              text: overridesTranslations.INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
              link: overridesTranslations.INFO_LINK_RUNNING_OR_PAST_DEPLOYMENT,
              width: 600,
            },
      };
    case 'humanitec/replicas':
      return {
        title: isReplicaNewDesign
          ? sectionsTranslations.PODS_STATUS
          : sectionsTranslations.REPLICAS,
        infoPopup: {
          text: isReplicaNewDesign
            ? sectionsTranslations.PODS_STATUS_INFO_TEXT
            : sectionsTranslations.REPLICAS_INFO_TEXT,
        },
      };

    case 'hardcoded/resource-dependencies':
      return {
        title: sectionsTranslations.EXTERNAL_RESOURCES_TITLE,
        infoPopup: draftModeActive
          ? {
              text: sectionsTranslations.EXTERNAL_RESOURCES_INFO_TEXT,
              link: sectionsTranslations.EXTERNAL_RESOURCES_INFO_LINK,
              position: 'top',
              width: 450,
            }
          : {
              text: sectionsTranslations.EXTERNAL_RESOURCES_INFO_TEXT_RUNNING_OR_PAST_DEPLOYMENT,
              link: sectionsTranslations.EXTERNAL_RESOURCES_INFO_LINK_RUNNING_OR_PAST_DEPLOYMENT,
              position: 'top',
              width: 450,
            },
      };

    case 'humanitec/schedules':
      return {
        title: sectionsTranslations.SCHEDULES_TITLE,
        infoPopup: {
          link: 'https://developer.humanitec.com/platform-orchestrator/reference/workload-profiles/features',
          text: sectionsTranslations.CRONJOBS_INFO_TEXT,
          width: 300,
        },
      };
    default:
      return undefined;
  }
};

/**
 * This function returns the first feature based on the name you provide. Since workload profiles can have multiple feature of the same name.
 *
 * @param properties The properties form `spec_definition`
 * @param featureName The feature name you want to find
 * @param path The path to the feature
 */
export const findSpecificFeatureByName = (
  properties: WorkloadFeatureDefinition['properties'],
  featureName: string,
  path = ''
): { path: string; feature: WorkloadFeatureDefinition } | undefined => {
  for (const [propertyPath, feature] of Object.entries(properties || {})) {
    if (feature.feature_name === featureName) {
      return { path: `${path ? `${path}/` : ''}${propertyPath}`, feature };
    }

    const nestedProperties = feature.properties;

    if (nestedProperties) {
      const foundFeature = findSpecificFeatureByName(
        nestedProperties,
        featureName,
        `${path ? `${path}/` : ''}${propertyPath}`
      );

      if (foundFeature) {
        return foundFeature;
      }
    }
  }
};
