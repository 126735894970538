import { Icon, WalButton } from '@humanitec/ui-components';
import { rem } from 'polished';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

export const AuthButton = styled(WalButton)`
  background-color: white;
  color: black;
  width: ${rem(300)};
  margin-bottom: ${units.margin.lg};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  &:not(:disabled):hover {
    background-color: white;
    color: black;
  }

  .inner-button,
  .inner-button-text {
    flex: 1;
  }
`;

export const ButtonText = styled.span`
  margin-left: ${units.margin.lg};
`;

export const AuthLogo = styled(Icon)`
  margin: 0 ${rem(7)};
`;
