import { Icon, Toggle, WalInput, WalLabel, WalModal } from '@humanitec/ui-components';
import { rem } from 'polished';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import AWSFields from '@src/containers/Orgs/Accounts/components/AddAccountModal/components/AWSFields';
import GCPFields from '@src/containers/Orgs/Accounts/components/AddAccountModal/components/GCPFields';
import {
  getRegistryIcon,
  getRegistryTitle,
} from '@src/containers/Orgs/Registries/registries.utilities';
import useRegistryCreateMutation from '@src/hooks/react-query/registries/mutations/useRegistryCreateMutation';
import useRegistryUpdateMutation from '@src/hooks/react-query/registries/mutations/useRegistryUpdateMutation';
import { Registry, RegistryType } from '@src/models/registry';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

interface AddRegistryModalProps {
  /** The open state of the modal */
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  registry?: Registry;
  type: RegistryType;
  exisitingIds: string[];
  dataTestId?: string;
}
const CredentialsTitle = styled.h5`
  margin-top: ${units.margin.lg};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${units.margin.md};
`;

const ToggleWrapper = styled.div`
  display: flex;
  margin-top: ${units.margin.lg};
  margin-bottom: ${units.margin.md};
`;

const IdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(12)};
`;

const Error = styled.small`
  color: ${({ theme }) => theme.color.alertBrighter};
  font-size: ${units.fontSize.base};
`;

const AddRegistryModal = ({
  openState,
  registry,
  type,
  exisitingIds,
  dataTestId,
}: AddRegistryModalProps) => {
  // Form
  const methods = useWalhallForm();
  const { handleSubmit, setValue, getValues, formState, clearErrors } = methods;

  // Component state
  /** Generic error message */
  const [errorMessage, setErrorMessage] = useState('');

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const registriesTranslations = t('ACCOUNT_SETTINGS').REGISTRIES;

  // React Query
  const { mutate: createRegistry, isError: createRegistryError } = useRegistryCreateMutation();
  const { mutate: updateRegistry } = useRegistryUpdateMutation();

  useEffect(() => {
    if (createRegistryError) {
      setErrorMessage('Create registry failed');
    }
  }, [createRegistryError]);

  /**
   * Clear form on close.
   */
  useEffect(() => {
    if (!openState[0]) {
      setValue('id', null);
      setValue('registry', null);
      setValue('username', null);
      setValue('password', null);
      setValue('token', null);
      setValue('enable_ci', false);
      setErrorMessage('');
    }
  }, [openState, setValue]);

  /**
   * If in editing mode, set the data of the registry
   */
  useEffect(() => {
    if (registry) {
      setValue('id', registry.id);
      setValue('registry', registry.registry);
      setValue('enable_ci', registry.enable_ci);
    }
  }, [getValues, setValue, registry]);

  /**
   * Dispatch actions for Create or Update
   */
  const handleRegistryCreateOrUpdate = async (formValue: any) => {
    const registryPayload: Partial<Registry> = {
      id: registry ? registry.id : formValue.id,
      registry: formValue.registry,
      type,
      enable_ci: formValue.enable_ci,
      creds: buildCredentials(formValue),
    };
    if (registry) {
      updateRegistry(registryPayload);
    } else if (!registry) {
      createRegistry(registryPayload);
    }
    closeModal();
  };

  const closeModal = () => {
    openState[1](false);
    clearErrors();
  };

  /**
   * Build up the credentials. It will differ depending on the registry type.
   *
   * @param formValue The value from the form.
   */
  const buildCredentials = (formValue: any): Record<string, unknown> | undefined | null => {
    let credentials;
    if (type === 'google_gcr') {
      credentials = {
        username: formValue.id ? formValue.id : undefined,
        password: formValue.token ? formValue.token : undefined,
      };
    } else if (type === 'amazon_ecr') {
      credentials = {
        username: formValue.accessKeyId ? formValue.accessKeyId : undefined,
        password: formValue.secretAccessKey ? formValue.secretAccessKey : undefined,
      };
    } else {
      credentials = {
        username: formValue.username ? formValue.username : undefined,
        password: formValue.password ? formValue.password : undefined,
      };
    }
    return credentials;
  };

  const registryFields = () => {
    switch (type) {
      case 'google_gcr':
        return <GCPFields isNew={!registry} />;
      case 'amazon_ecr':
        return <AWSFields isNew={!registry} />;
      default:
        return (
          <>
            <WalInput
              name={'username'}
              labelAbove={registry ? true : false}
              label={registriesTranslations.USERNAME}
              required={registry ? false : true}
              placeholder={registry ? '••••••••••' : undefined}
            />
            <WalInput
              name={'password'}
              labelAbove={registry ? true : false}
              type={'password'}
              label={registriesTranslations.PASSWORD}
              required={registry ? false : true}
              placeholder={registry ? '••••••••••' : undefined}
            />
          </>
        );
    }
  };

  return (
    <WalModal
      dataTestId={dataTestId}
      openState={openState}
      size={'medium'}
      title={getRegistryTitle(type)}
      disableClickOutside={formState.isDirty}
      content={
        <FormProvider {...methods}>
          <IconWrapper>
            <Icon name={getRegistryIcon(type)} size={40} />
          </IconWrapper>
          {!registry && (
            <WalInput
              name={'id'}
              label={'ID'}
              required
              readonly={Boolean(registry)}
              maxLength={20}
              standardValidation={[{ type: 'id' }, { type: 'existingId', ids: exisitingIds }]}
            />
          )}
          {registry && (
            <IdWrapper>
              <WalLabel>{'ID'}</WalLabel>
              <span>{registry.id}</span>
            </IdWrapper>
          )}
          <WalInput name={'registry'} label={'URL'} required minLength={3} />
          <CredentialsTitle>{registriesTranslations.CREDENTIALS}</CredentialsTitle>
          {registryFields()}
          <ToggleWrapper>
            <Toggle label={registriesTranslations.MAKE_CREDENTIALS_AVAILABLE} name={'enable_ci'} />
          </ToggleWrapper>
          {errorMessage && <Error>{errorMessage}</Error>}
        </FormProvider>
      }
      actions={{
        main: {
          props: {
            className: 'qa-gcp-authorize',
            onClick: handleSubmit(handleRegistryCreateOrUpdate),
          },
          text: registry ? uiTranslations.SAVE : uiTranslations.CREATE,
        },
        cancel: {
          props: {
            className: 'qa-gcp-cancel',
          },
        },
      }}
    />
  );
};

export default AddRegistryModal;
