import { Tag, WalButton, WalTable, WalTableColumn } from '@humanitec/ui-components';
import { isAfter } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useApiTokensByUserDeleteMutations from '@src/hooks/react-query/api-tokens/mutations/useApiTokensByUserDeleteMutations';
import useApiTokensByUserIdQuery from '@src/hooks/react-query/api-tokens/queries/useApiTokensByUserIdQuery';
import { useRolesStore } from '@src/hooks/zustand/useRolesStore';
import { NewApiToken } from '@src/models/api-token';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

import ConfirmDeleteApiTokenModal from '../ConfirmDeleteApiTokenModal';

// The expires_at value returned by backend to represent that when a token has no expiration date. i.e they never expire.
export const NO_EXPIRATION_VALUE = '0001-01-01T00:00:00Z';
// Number of tokens to initially display
const DEFAULT_DISPLAYED_TOKENS = 3;
// Number of tokens to increment by when the "Show more" button is pressed
const DISPLAYED_TOKENS_INCREMENTS = 10;

interface ApiTokenTableProps {
  userId: string;
  readOnly?: boolean;
}

const ExpandedContentTitle = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  word-break: break-all;
  font-size: ${units.fontSize.sm};
`;

const ExpandedContentDetail = styled.div`
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
`;

const ShowMoreButton = styled(WalButton)`
  width: max-content;
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
`;

const ApiTokenTable = ({ userId, readOnly }: ApiTokenTableProps) => {
  // i18n
  const { t } = useTranslation();
  const apiTokensTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS.API_TOKENS;
  const expirationDateTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS.API_TOKENS_EXPIRATION_DATE;
  // Component state
  const [expandedRow, setExpandedRow] = useState<number | undefined>();
  const [displayedApiTokens, setDisplayedApiTokens] = useState<number>(DEFAULT_DISPLAYED_TOKENS);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [apiTokensToBeDeleted, setApiTokensToBeDeleted] = useState<NewApiToken | undefined>();

  // Hooks
  const { lastCreatedApiTokensIds } = useRolesStore();
  // React Query
  const { data: apiTokens = [] } = useApiTokensByUserIdQuery(userId);
  const {
    mutate: deleteApiToken,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useApiTokensByUserDeleteMutations(userId);
  // Variables
  const isFullyDisplayed = displayedApiTokens >= apiTokens?.length; // this would be set to true if the full length of apiTokens list has been displayed.

  useEffect(() => {
    if (isDeletedSuccessfully) {
      setConfirmDeleteModalOpen(false);
      resetDeleteMutation();
    }
  }, [isDeletedSuccessfully, resetDeleteMutation]);

  const formatExpiresAtText = (expiryDate: string) =>
    t('ACCOUNT_SETTINGS.SERVICE_USERS.API_TOKENS.EXPIRES_AT', {
      // returns "Expires at ${expiryDate}" text
      replace: {
        expiryDate: formatDate(expiryDate, DATE_FORMATS_TYPES.DOTTED_DAY_MONTH_YEAR),
      },
    });

  const hasExpired = (expires_at: string) => isAfter(new Date(), new Date(expires_at));

  const renderExpiryDateStatus = (expires_at: string) => {
    if (expires_at === NO_EXPIRATION_VALUE) return expirationDateTranslations.NO_EXPIRATION;
    if (hasExpired(expires_at)) return apiTokensTranslations.EXPIRED;
    return formatExpiresAtText(expires_at);
  };

  const columns: WalTableColumn<NewApiToken>[] = [
    {
      label: apiTokensTranslations.ID,
      prop: 'id',
      alignIconAndText: true,
      ellipsisTooltip: {
        maxWidth: 300,
        maxCharacters: 35,
        text: (row) => row.data.id,
      },
    },
    {
      label: apiTokensTranslations.DESCRIPTION,
      prop: 'description',
      ellipsisTooltip: {
        maxWidth: 500,
        maxCharacters: 40,
        text: (row) => row.data.description,
      },
      template: (row) => (
        <p>{row.data.description || <span className={'txt-translucent'}>-</span>}</p>
      ),
    },
    {
      prop: 'actions',
      tabIndex: -1,
      template: (row) => (
        <ActionsWrapper>
          {/* Only render Tag for tokens that have expired or have specified expiry dates */}
          {row.data.expires_at !== NO_EXPIRATION_VALUE && (
            <Tag
              text={
                hasExpired(row.data.expires_at)
                  ? apiTokensTranslations.EXPIRED
                  : formatExpiresAtText(row.data.expires_at)
              }
              variant={hasExpired(row.data.expires_at) ? 'transparent' : 'warning'}
              className={'mr-sm'}
            />
          )}
          {!readOnly && (
            <WalButton
              className={'mr-sm'}
              size={'small'}
              variant={'secondary'}
              onClick={(e) => {
                e.stopPropagation();
                setApiTokensToBeDeleted(row.data);
                setConfirmDeleteModalOpen(true);
              }}>
              {apiTokensTranslations.REVOKE_TOKEN}
            </WalButton>
          )}
        </ActionsWrapper>
      ),
    },
  ];

  const handleDeleteConfirmation = () => {
    if (apiTokensToBeDeleted !== undefined) deleteApiToken({ tokenId: apiTokensToBeDeleted.id });
  };

  const renderDeleteModal = () =>
    confirmDeleteModalOpen && (
      <ConfirmDeleteApiTokenModal
        openState={[confirmDeleteModalOpen, setConfirmDeleteModalOpen]}
        onDeleteConfirmed={handleDeleteConfirmation}
        onCancel={() => setApiTokensToBeDeleted(undefined)}
      />
    );

  const renderExpandableContent = (token: NewApiToken) => {
    return (
      <>
        <ExpandedContentTitle>{apiTokensTranslations.ID}</ExpandedContentTitle>
        <ExpandedContentDetail>{token.id}</ExpandedContentDetail>
        <ExpandedContentTitle>{apiTokensTranslations.DESCRIPTION}</ExpandedContentTitle>
        <ExpandedContentDetail>{token.description || '-'}</ExpandedContentDetail>
        <ExpandedContentTitle>{apiTokensTranslations.CREATED_ON}</ExpandedContentTitle>
        <ExpandedContentDetail>
          {formatDate(token.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR)}
        </ExpandedContentDetail>
        <ExpandedContentTitle>{apiTokensTranslations.EXPIRY_DATE}</ExpandedContentTitle>
        <ExpandedContentDetail>{renderExpiryDateStatus(token.expires_at)}</ExpandedContentDetail>
      </>
    );
  };

  const handleShowMore = () => {
    if (isFullyDisplayed) {
      setDisplayedApiTokens(DEFAULT_DISPLAYED_TOKENS);
    } else {
      setDisplayedApiTokens((prevState) => prevState + DISPLAYED_TOKENS_INCREMENTS);
    }
  };

  return (
    <>
      <WalTable
        caption={apiTokensTranslations.API_TOKENS}
        columns={columns}
        expandedRowState={[expandedRow, setExpandedRow]}
        disableScrolling
        tableStyle={'expandable'}
        rows={
          apiTokens
            ? apiTokens.slice(0, displayedApiTokens)?.map((token) => ({
                data: token,
                isNew: lastCreatedApiTokensIds.includes(token.id),
                expandableContent: renderExpandableContent(token),
              }))
            : []
        }
      />
      <div className={'justify-end flex mt-md'}>
        {' '}
        {apiTokens?.length > DEFAULT_DISPLAYED_TOKENS && ( // only display 'show more' or 'show less' button if apiTokens?.length is greater than DEFAULT_DISPLAYED_TOKENS
          <ShowMoreButton variant={'secondary'} onClick={handleShowMore}>
            {isFullyDisplayed ? apiTokensTranslations.SHOW_LESS : apiTokensTranslations.SHOW_MORE}
          </ShowMoreButton>
        )}{' '}
      </div>
      {renderDeleteModal()}
    </>
  );
};

export default ApiTokenTable;
