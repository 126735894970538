import {
  FormTable,
  InfoPopup,
  QuickDelete,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import DeleteEnvironmentTypeModal from '@src/components/shared/modals/DeleteEnvironmentTypeModal';
import SectionsSettingWrapper from '@src/containers/Orgs/components/styles';
import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import { useEnvironmentTypesCreateMutation } from '@src/hooks/react-query/environment-types/mutations/useEnvironmentTypesCreateMutation';
import { useEnvironmentTypesDeleteMutation } from '@src/hooks/react-query/environment-types/mutations/useEnvironmentTypesDeleteMutation';
import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useEnvTypeRolesQueries from '@src/hooks/react-query/roles/queries/useEnvTypeRolesQueries';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { ApplicationEnvironment } from '@src/models/application';
import { EnvironmentType } from '@src/models/environment-type';
import { OrgRoles } from '@src/models/role';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import DeployersButton from './components/DeployersButton';

const formGridTemplate = css`
  grid-template-columns: 1fr 2fr auto;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${units.margin.md};
`;

const EnvironmentTypes = () => {
  // Component state
  const [editable, setEditable] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);
  const [envTypeToDelete, setEnvTypeToDelete] = useState<EnvironmentType>();
  const [associatedEnvsForTypeDeletion, setAssociatedEnvsForTypeDeletion] = useState<
    (ApplicationEnvironment & {
      appName: string;
    })[]
  >([]);

  // i18n
  const { t } = useTranslation();
  const translations = t('MANAGE_ENV_TYPES');
  const uiTranslations = t('UI');
  const navigationTranslations = t('NAVIGATION');

  // Form
  const formMethods = useWalhallForm();
  const { setError, reset } = formMethods;

  // React query
  const { mutate: deleteEnvType } = useEnvironmentTypesDeleteMutation();
  const {
    mutate: createEnvType,
    isSuccess: envTypeCreated,
    error: envTypeError,
  } = useEnvironmentTypesCreateMutation();

  // React Query
  const { data: envTypes = [] } = useEnvironmentTypesQuery();
  const { data: applications = [] } = useApplicationsQuery();
  const envTypeRoles = useEnvTypeRolesQueries(envTypes);
  const { orgRole } = useGetUserRoles();

  useEffect(() => {
    if (orgRole && (['administrator'] as OrgRoles[]).includes(orgRole)) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  }, [orgRole]);

  useEffect(() => {
    if (envTypeError?.response?.status === 409) {
      setError('id', { type: 'manual', message: 'ID already exists' });
    }
  }, [envTypeError, setError]);

  useEffect(() => {
    if (envTypeCreated) {
      reset();
    }
  }, [envTypeCreated, reset]);

  useEffect(() => {
    if (!openConfirmDeleteModal) {
      setAssociatedEnvsForTypeDeletion([]);
    }
  }, [openConfirmDeleteModal]);

  const addType = (formValues: any) => {
    createEnvType({ envType: { id: formValues.id, description: formValues.description } });
  };

  const deleteType = (envType: EnvironmentType) => {
    const envs = applications
      .flatMap((app) => app.envs.map((env) => ({ ...env, appName: app.name })))
      .filter((env) => env.type === envType.id);
    setAssociatedEnvsForTypeDeletion(envs);
    if (!envs.length) {
      deleteEnvType({ envTypeId: envType.id });
    } else {
      setEnvTypeToDelete(envType);
      setOpenConfirmDeleteModal(true);
    }
  };

  const columns: WalTableColumn<EnvironmentType>[] = [
    {
      prop: 'id',
      label: translations.TYPE_ID,
    },
    {
      prop: 'description',
      label: translations.SHORT_DESCRIPTION,
      justifyContent: 'flex-start',
    },
    {
      prop: 'deployers',
      label: translations.DEPLOYERS,
      justifyContent: 'flex-start',
      template: (row) => (
        <DeployersButton roles={envTypeRoles[row.data.id] || []} envType={row.data} />
      ),
    },
    {
      prop: 'actions',
      template: (row) =>
        editable && <QuickDelete onConfirm={() => deleteType(row.data)} size={'small'} />,
    },
  ];

  return (
    <SectionsSettingWrapper>
      <HeadingWrapper>
        <h4>{translations.TITLE}</h4>
        <InfoPopup
          text={translations.MANAGE_TYPES_INFO_TEXT}
          moreInformationLink={translations.MANAGE_TYPES_INFO_LINK}
          position={'bottom'}
        />
      </HeadingWrapper>
      <FormProvider {...formMethods}>
        <FormTable
          table={{
            caption: navigationTranslations.ENVIRONMENT_TYPES,
            columns,
            rows: envTypes.map(
              (type): WalTableRow => ({
                data: type,
              })
            ),
          }}
          form={{
            fields: [
              {
                type: 'input',
                props: {
                  name: 'id',
                  id: 'env_type_id',
                  label: translations.TYPE_ID,
                  standardValidation: [{ type: 'id' }],
                  required: true,
                },
              },
              {
                type: 'input',
                props: {
                  required: true,
                  name: 'description',
                  id: 'env_type_description',
                  label: translations.SHORT_DESCRIPTION,
                },
              },
            ],
            submitButton: { children: uiTranslations.CREATE },
            onSubmit: addType,
          }}
          hideForm={!editable}
          formGridTemplate={formGridTemplate}
          formMaxWidth={950}
        />
      </FormProvider>
      {envTypeToDelete && (
        <DeleteEnvironmentTypeModal
          openState={[openConfirmDeleteModal, setOpenConfirmDeleteModal]}
          associatedEnvs={associatedEnvsForTypeDeletion}
          envType={envTypeToDelete}
        />
      )}
    </SectionsSettingWrapper>
  );
};

export default EnvironmentTypes;
