import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QueryResponse } from '@src/hooks/react-query/types';
import { ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

const useResourceDefinitionsQuery = (): QueryResponse<ResourceDefinition[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: resourcesQueryKeys.listDefinitions(orgId),
    queryFn: () => makeRequest<ResourceDefinition[]>('GET', `/orgs/${orgId}/resources/defs`),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useResourceDefinitionsQuery;
