import { Accordion, IconNames, iconNamesArray } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { ResourceDependency } from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import ResourceCardDetails from '@src/components/shared/ResourceDependencyList/components/ResourceCardDetails/ResourceCardDetails';
import ResourceCardHeaderContent from '@src/components/shared/ResourceDependencyList/components/ResourceCardHeaderContent/ResourceCardHeaderContent';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { ActiveResource, ResourceTypes } from '@src/models/resources';

export interface ResourceCardItem {
  resourceDependency?: ResourceDependency & { path: string };
  activeResource?: ActiveResource;
}

interface ResourceDependencyListProps {
  resourceCardItems: ResourceCardItem[];
  scope: 'externals' | 'shared';
  /* search filter input value */
  filterValue?: string;
}
const ResourceDependencyList = ({
  resourceCardItems,
  scope,
  filterValue,
}: ResourceDependencyListProps) => {
  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  const { draftModeActive } = useDeploymentOrDeltaContext();

  const getIconName = (type?: ResourceTypes, defId?: string): IconNames => {
    if (defId?.includes('default')) return 'humanitec';
    if (type === 'k8s-cluster') return 'kubernetes';
    if (type?.includes('aws') || type === 'sqs') return 'amazon';
    return iconNamesArray.includes(type as IconNames) ? (type as IconNames) : 'generic-resource';
  };

  return (
    <div className={'flex-column'}>
      {resourceCardItems?.length === 0 ? (
        <span className={'txt-md'}>{sectionsTranslations.NO_RESOURCE_DEPENDENCIES_DEFINED}</span>
      ) : (
        <Accordion
          cardTestId={'resource-dependency-card'}
          items={resourceCardItems
            .filter((res) =>
              filterValue ? res.resourceDependency?.path?.includes(filterValue) : true
            )
            // eslint-disable-next-line react/no-array-index-key
            .map((res) => ({
              id: res.activeResource?.def_id || res.resourceDependency?.id || '',
              headerContent: (
                <ResourceCardHeaderContent
                  scope={scope}
                  resourceId={
                    scope === 'externals'
                      ? res.resourceDependency?.id
                      : res.activeResource
                        ? res.activeResource?.res_id || res.activeResource?.def_id
                        : res.resourceDependency?.id
                  }
                  resourceType={
                    scope === 'externals'
                      ? res.resourceDependency?.type
                      : res.activeResource?.type || res.resourceDependency?.type
                  }
                  resourceClass={
                    scope === 'externals'
                      ? res.resourceDependency?.class
                      : res.activeResource?.class || res.resourceDependency?.class
                  }
                  iconName={getIconName(
                    scope === 'externals'
                      ? res.resourceDependency?.type
                      : res.activeResource?.type ?? res.resourceDependency?.type,
                    scope === 'shared' ? res.activeResource?.def_id : undefined
                  )}
                  draftModeActive={draftModeActive}
                  resourceDependency={res.resourceDependency}
                />
              ),
              content: res.activeResource && (
                <ResourceCardDetails activeResource={res.activeResource} />
              ),
              cardStyle: scope === 'shared' ? 'default' : 'base',
              disableExpansion: draftModeActive || !res.activeResource,
            }))}
        />
      )}
    </div>
  );
};

export default ResourceDependencyList;
