import { WalButton, WalButtonProps } from '@humanitec/ui-components';
import { rem } from 'polished';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';

const SnippetWrapper = styled.div<{ $maxWidth?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: ${({ theme }) => theme.color.baseDarker};
  border-radius: ${rem(4)};
  padding: 0 ${units.margin.md};
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${rem($maxWidth)};
    `}
`;

const Snippet = styled.div`
  overflow: auto;
`;

const Code = styled.code`
  // To accomodate copy button
  padding-right: ${rem(45)};
`;

const CopyButton = styled(WalButton)<WalButtonProps & { oneLiner?: boolean }>`
  ${({ oneLiner }) =>
    !oneLiner
      ? css`
          position: absolute;
          right: ${rem(8)};
          top: ${rem(8)};
        `
      : css`
          margin-left: ${units.margin.sm};
        `}
`;
// Position fake textarea for clipboard copy.
const FakeTextArea = styled.textarea`
  position: absolute;
  left: -1000vh;
  top: -100vw;
`;

interface SnippetCopyProps {
  /** Pass a custom value to override the instruction snippets */
  customValue: string;
  oneLiner?: boolean;
  /** max width of the snippet wrapper */
  maxWidth?: number;
}

const SnippetCopy = ({ customValue, oneLiner, maxWidth }: SnippetCopyProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const textAreaRef = useRef(null);

  const copySnippet = () => {
    if (textAreaRef !== null) {
      (textAreaRef.current as any).select();
      document.execCommand('copy');
    }
  };

  return (
    <SnippetWrapper $maxWidth={maxWidth}>
      <Snippet>
        <pre>
          <Code>{customValue}</Code>
        </pre>
      </Snippet>
      <CopyButton size={'small'} variant={'secondary'} onClick={copySnippet} oneLiner={oneLiner}>
        {uiTranslations.COPY}
      </CopyButton>
      <FakeTextArea readOnly ref={textAreaRef} value={customValue} />
    </SnippetWrapper>
  );
};

export default SnippetCopy;
