import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { PipelineCriteria } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelinesQueryKeys } from '../pipelinesQueryKeys';

interface UsePipelineCriteriaQueryProps {
  trigger?: string;
  pipeline_id?: string;
  env_id?: string;
}

const usePipelineCriteriaQuery = (filters: UsePipelineCriteriaQueryProps = {}) => {
  const { orgId, envId, appId } = useParams<keyof MatchParams>() as MatchParams;
  const filtersToUse = {
    trigger: 'deployment_request',
    env_id: envId,
    ...filters,
  };
  const { data, ...queryResult } = useQuery({
    queryKey: pipelinesQueryKeys.criteria(orgId, appId, envId, filtersToUse),
    queryFn: () => {
      return makeRequest<PipelineCriteria[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipeline-criteria?match=${JSON.stringify(filtersToUse)}${
          filters.pipeline_id ? `&pipeline=${filters.pipeline_id}` : ''
        }`
      );
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(orgId && appId),
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelineCriteriaQuery;
