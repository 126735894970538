import { Placement } from '@floating-ui/react';
import { TabGroup, TabGroupOptionProps, WalButton, WalPanel } from '@humanitec/ui-components';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useUserPreferencesStore } from '@src/hooks/zustand/useUserPreferencesStore';
import { units } from '@src/styles/variables';

import DocumentationLinks from './components/DocumentationLinks';
import SupportForm from './components/SupportForm';

const SupportPanelContainer = styled(WalPanel)`
  padding: ${units.padding.xl};
  margin-right: calc(${units.margin.xl} + ${units.padding.xxxl});
`;

const Switcher = styled(TabGroup)`
  margin-bottom: ${units.margin.lg};
`;

interface SupportPanelProps {
  openContact?: boolean;
  customToggle?: ReactElement;
  placement?: Placement;
}

const SupportPanel = ({ openContact, customToggle, placement }: SupportPanelProps) => {
  const { t } = useTranslation();
  const supportTranslations = t('SUPPORT');
  const options: TabGroupOptionProps<string>[] = [
    {
      label: supportTranslations.SWITCHER.DOCUMENTATION,
      value: 'documentation',
      element: <DocumentationLinks />,
    },
    {
      label: supportTranslations.SWITCHER.GET_SUPPORT,
      value: 'get-support',
      element: <SupportForm />,
    },
  ];
  const [open, setOpen] = useState(false);

  // Zustand
  const { setOpenContactForm } = useUserPreferencesStore();

  useEffect(() => {
    if (openContact) {
      setOpen(true);
    }
  }, [openContact]);

  useEffect(() => {
    if (!open) {
      setOpenContactForm(false);
    }
  }, [open, setOpenContactForm]);

  return (
    <SupportPanelContainer
      borderRadius
      panelSize={'medium'}
      relativeTo={'element'}
      placement={placement || 'bottom'}
      openState={[open, setOpen]}
      toggle={
        customToggle || (
          <WalButton variant={'secondary'} size={'medium'}>
            {supportTranslations.HELP}
          </WalButton>
        )
      }>
      <Switcher stretchWidth size={'large'} options={options} />
    </SupportPanelContainer>
  );
};

export default SupportPanel;
