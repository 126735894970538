import { windowEnv } from '@src/environment';

/**
 * Initialise the GTM scripts. The script will only be added if 'GTM_TOKEN' exists in the environment variables.
 * Environment variables are provided using helm. As of now, GTM will only be on production.
 */
export const initGtm = () => {
  // GTM_TOKEN is set to 'null' in the local `.env` file, because we only want to show in production.
  if (windowEnv.GTM_TOKEN && windowEnv.GTM_TOKEN !== 'null') {
    generateGtmNodes(windowEnv.GTM_TOKEN);
  }
};

/**
 * Generates GTM nodes and adds them to the body & head of the application.
 * https://developers.google.com/tag-manager/quickstart.
 */
const generateGtmNodes = (gtmToken: string) => {
  // Main script.
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmToken}');`;

  // Noscript
  const noScript = document.createElement('noscript');
  const noScriptIFrame = document.createElement('iframe');

  noScriptIFrame.src = `https://www.googletagmanager.com/ns.html?id=${gtmToken}`;
  noScriptIFrame.height = '0';
  noScriptIFrame.width = '0';
  noScriptIFrame.style.display = 'none';
  noScriptIFrame.style.visibility = 'hidden';
  noScript.append(noScriptIFrame);

  // Add then to the head and body
  document.head.prepend(script);
  document.body.prepend(noScript);
};
