import { Radio, WalButton, WalCard } from '@humanitec/ui-components';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import WorkloadOrResourceCardTitle from '@src/components/shared/CloneModal/components/ReviewCloneDetails/components/WorkloadOrResourceCardTitle';
import { TitleRow } from '@src/components/shared/CloneModal/components/ReviewCloneDetails/ReviewCloneDetails';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentObject } from '@src/models/deployment-object';
import { DeploymentSet } from '@src/models/deployment-set';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';

interface UpdateWorkloadsAccordionContentProps {
  defaultCloneDraftContent: Partial<DeploymentDelta>;
  cloneDraftContentState: [
    Partial<DeploymentDelta>,
    Dispatch<SetStateAction<Partial<DeploymentDelta>>>,
  ];
  targetEnv?: Environment;
  fromDeployment?: DeploymentObject;
  targetDeploymentSet?: DeploymentSet;
}
const UpdateWorkloadsAccordionContent = ({
  targetEnv,
  defaultCloneDraftContent,
  cloneDraftContentState,
  fromDeployment,
  targetDeploymentSet,
}: UpdateWorkloadsAccordionContentProps) => {
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const modalTranslations = tViewEnvironment('DEPLOYS').DEPLOYMENT_CARD.CLONE_MODAL;
  const { appId, orgId } = useParams<keyof MatchParams>() as MatchParams;
  const [cloneDraftContent, setCloneDraftContent] = cloneDraftContentState;

  const updateWorkloadInCloneDraft = (id: string) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent.modules?.update && defaultCloneDraftContent?.modules?.update) {
      newCloneDraftContent.modules.update[id] = defaultCloneDraftContent?.modules?.update[id];
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const dontUpdateWorkloadInCloneDraft = (id: string) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent.modules?.update) {
      delete newCloneDraftContent.modules?.update[id];
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const setAllUpdateWorkloads = (updateAll?: boolean) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent.modules) {
      newCloneDraftContent.modules.update = updateAll
        ? cloneDeep(defaultCloneDraftContent.modules?.update)
        : {};
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const renderUpdateWorkloadCard = (id: string, subtitle?: string) => (
    <WalCard className={'mb-sm'} cardStyle={'base'} key={id}>
      <TitleRow>
        {fromDeployment?.env_id && (
          <WorkloadOrResourceCardTitle
            orgId={orgId}
            appId={appId}
            envId={fromDeployment?.env_id}
            deploymentId={fromDeployment?.id}
            id={id}
            subtitle={subtitle}
          />
        )}
        <div className={'flex-centered'}>
          <Radio
            name={`update-${id}`}
            label={`${modalTranslations.UPDATE_IN} ${targetEnv?.id}`}
            value={`update-${id}`}
            className={'mr-md'}
            defaultChecked={Boolean(
              cloneDraftContent.modules?.update &&
                Object.keys(cloneDraftContent.modules?.update).includes(id)
            )}
            onClick={() => updateWorkloadInCloneDraft(id)}
          />
          <Radio
            name={`update-${id}`}
            label={`${modalTranslations.DONT_UPDATE_IN} ${targetEnv?.id}`}
            value={`dont-update-${id}`}
            defaultChecked={Boolean(
              cloneDraftContent.modules?.update &&
                !Object.keys(cloneDraftContent.modules?.update).includes(id)
            )}
            onClick={() => dontUpdateWorkloadInCloneDraft(id)}
          />
        </div>
      </TitleRow>
    </WalCard>
  );

  return (
    <div>
      <p className={'txt-sm txt-translucent mb-md'}>
        {modalTranslations.UPDATE_WORKLOADS_SUBHEADING1}
      </p>
      <p className={'txt-sm txt-translucent mb-md'}>
        <Trans
          defaults={modalTranslations.UPDATE_WORKLOADS_SUBHEADING2}
          values={{ targetEnv: targetEnv?.id, sourceEnv: fromDeployment?.env_id }}>
          <span />
        </Trans>
      </p>
      <p className={'txt-sm txt-translucent mb-md'}>
        <span className={'mr-md'}>{modalTranslations.SET_ALL_TO}</span>
        <WalButton variant={'borderless-blue'} onClick={() => setAllUpdateWorkloads(true)}>
          {modalTranslations.UPDATE}
        </WalButton>
        <WalButton variant={'borderless-blue'} onClick={() => setAllUpdateWorkloads()}>
          {modalTranslations.DONT_UPDATE}
        </WalButton>
      </p>
      {defaultCloneDraftContent.modules?.update &&
        Object.keys(defaultCloneDraftContent.modules?.update)?.map((workloadId) =>
          renderUpdateWorkloadCard(workloadId, targetDeploymentSet?.modules[workloadId]?.profile)
        )}
    </div>
  );
};

export default UpdateWorkloadsAccordionContent;
