import { Spinner, Text, WalInput } from '@humanitec/ui-components';
import { rem } from 'polished';
import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import NewDiffContent from '@src/components/NewDiffContent/NewDiffContent';
import useDeploymentSetQuery from '@src/hooks/react-query/deployment-set/useDeploymentSetQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import usePipelineCriteriaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineCriteriaQuery';
import { useFeature } from '@src/hooks/useFeature';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { units } from '@src/styles/variables';
import { generatePipelinesURL } from '@src/utilities/navigation';

const Wrapper = styled.div`
  display: flex;
  padding: ${units.padding.lg} 0;
  flex-direction: column;
  min-width: ${rem(500)};
  min-height: ${rem(260)};
`;

interface ModalContentProps {
  displayedDelta?: DeploymentDelta;
  deltaCreationError: boolean;
  hideCommentField?: boolean;
  handleKeyDown?: (evt: KeyboardEvent) => void;
}

export const ModalContent = ({
  displayedDelta,
  deltaCreationError,
  hideCommentField = false,
  handleKeyDown,
}: ModalContentProps) => {
  const [isPipelineRunsEnabled] = useFeature('deployments-pipelines');

  const { appId, orgId } = useParams();
  // i18n
  const { t } = useTranslation();
  const deploymentModalTranslations = t('DEPLOYMENT_MODAL');

  // react-query
  const { data: environment } = useEnvironmentQuery();
  const { data: lastDeployedSet } = useDeploymentSetQuery({
    setId: environment?.last_deploy?.set_id,
  });

  const { data: pipelineCriteria } = usePipelineCriteriaQuery();

  const matchedPipeline = pipelineCriteria?.[0] || {
    id: 'default',
    pipeline_name: 'default',
  };

  return (
    <Wrapper>
      {!hideCommentField && (
        <WalInput
          name={'comment'}
          label={'Comment'}
          onKeyDown={handleKeyDown}
          labelAbove
          className={'mb-sm'}
        />
      )}

      {isPipelineRunsEnabled && (
        <>
          <Text color={'textTranslucent'} className={'mt-md'}>
            {deploymentModalTranslations.DEPLOYMENT_PIPELINE}
          </Text>
          <Link
            to={generatePipelinesURL(orgId, appId, matchedPipeline.id)}
            className={'mb-lg mt-sm'}>
            {matchedPipeline.pipeline_name}
          </Link>
        </>
      )}

      {displayedDelta ? (
        <NewDiffContent delta={displayedDelta} rightDeploymentSet={lastDeployedSet} />
      ) : (
        <div className={'flex mt-md gap-sm'}>
          <Spinner size={'small'} /> <span> {deploymentModalTranslations.LOADING_CHANGES} </span>
        </div>
      )}

      <div className={'flex-column'}>
        {deltaCreationError && (
          <Text color={'alert'} size={'sm'} className={'mb-md'}>
            {deploymentModalTranslations.INVALID_DELTA}
          </Text>
        )}
      </div>
    </Wrapper>
  );
};
