import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { NotificationsSocketContext } from '@src/components/socket/contexts';
import { WebSocketEventTypes } from '@src/components/socket/event-types';
import { useHandleSocketMessage } from '@src/components/socket/useHandleSocketMessage';
import { PipelineRunDetails } from '@src/containers/Orgs/PipelineRuns/components/PipelineRunDetails/PipelineRunDetails';
import DeploymentOrDeltaContextWrapper from '@src/context/DeploymentOrDeltaContextWrapper';
import SocketWrapper from '@src/context/SocketWrapper';
import { applicationsQueryKeys } from '@src/hooks/react-query/applications/applicationsQueryKeys';
import useApplicationQuery from '@src/hooks/react-query/applications/queries/useApplicationQuery';
import { environmentQueryKeys } from '@src/hooks/react-query/environments/environmentQueryKeys';
import useParamsFromParent from '@src/hooks/react-router/useParamsFromParent';
import { useFeature } from '@src/hooks/useFeature';
import { useRBAC } from '@src/hooks/useRBAC';
import { containerStyle } from '@src/styles/mixins';
import { setLastVisitedApp } from '@src/utilities/local-storage';
import { generateAppURL } from '@src/utilities/navigation';

import DeploymentAndDeltaCommon from './containers/DeploymentAndDeltaCommon/DeploymentAndDeltaCommon';
import PipelineDetails from './containers/ViewApplication/components/Pipelines/components/PipelineDetails/PipelineDetails';
import ViewApplication from './containers/ViewApplication/ViewApplication';
import ViewDraftDelta from './containers/ViewDraftDelta/ViewDraftDelta';
import CompareDeployments from './containers/ViewEnvironment/components/CompareDeployments/CompareDeployments';
import ViewEnvironment from './containers/ViewEnvironment/ViewEnvironment';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Container = styled.div`
  ${containerStyle()};
  flex-direction: column;
  flex: 1;
`;

const App = () => {
  const canAccessDraftURL = useRBAC('accessDraftURL');
  const [isPipelineRunsEnabled] = useFeature('deployments-pipelines');

  // React Query
  const queryClient = useQueryClient();

  const { isError: applicationLoadingError } = useApplicationQuery();

  // Router hooks
  const { orgId, appId } = useParamsFromParent();
  const navigate = useNavigate();

  useHandleSocketMessage(appId, NotificationsSocketContext, (message) => {
    // someone deleted the application you are viewing
    if (message.type === WebSocketEventTypes.APP_DELETED) {
      navigate(generateAppURL(orgId));
    }
    if (
      message.type === WebSocketEventTypes.ENV_CREATED ||
      message.type === WebSocketEventTypes.ENV_UPDATED ||
      message.type === WebSocketEventTypes.WORKLOAD_REMOVED
    ) {
      queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.detail(orgId, appId),
      });
      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.applicationEnvironments(orgId, message.data.application_id),
      });
    }
    if (message.type === WebSocketEventTypes.ENV_PAUSE_STATUS_UPDATED) {
      // load info about whether environments on the App are paused
      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.environmentPauseStatus(orgId, appId),
      });
    }
  });

  useEffect(() => {
    if (applicationLoadingError) {
      navigate(`/orgs/${orgId}/app-not-found`);
    }
  }, [applicationLoadingError, navigate, orgId]);

  useEffect(() => {
    setLastVisitedApp(appId);
  }, [appId]);

  useEffect(() => {
    // load info about whether environments on the App are paused
    queryClient.invalidateQueries({
      queryKey: environmentQueryKeys.environmentPauseStatus(orgId, appId),
    });
  }, [orgId, appId, queryClient]);

  return (
    <Wrapper>
      <Routes>
        {isPipelineRunsEnabled && (
          <>
            <Route
              path={`pipelines/:pipelineId/runs/:pipelineRunId`}
              element={<PipelineRunDetails />}
            />
            <Route path={'pipelines/create'} element={<PipelineDetails isCreation />} />
            <Route
              path={'pipelines/:pipelineId/versions/:pipelineVersionId/view'}
              element={<PipelineDetails isView />}
            />
            <Route path={'pipelines/:pipelineId/*'} element={<PipelineDetails />} />
          </>
        )}
        <Route
          path={'/*'}
          element={
            <Container>
              <ViewApplication />
            </Container>
          }
        />

        <Route
          path={'envs/:envId/*'}
          element={
            <SocketWrapper>
              <Routes>
                <Route
                  path={'/*'}
                  element={
                    <Container>
                      <ViewEnvironment />
                    </Container>
                  }
                />
                <Route
                  path={`compare-deployments/:leftDeploymentId/:rightDeploymentId`}
                  element={<CompareDeployments />}
                />
                <Route
                  path={'deploys/:deploymentId/*'}
                  element={
                    <DeploymentOrDeltaContextWrapper>
                      <DeploymentAndDeltaCommon />
                    </DeploymentOrDeltaContextWrapper>
                  }
                />
                {canAccessDraftURL && (
                  <Route path={'draft/:deltaId/*'} element={<ViewDraftDelta />} />
                )}
              </Routes>
            </SocketWrapper>
          }
        />
      </Routes>
    </Wrapper>
  );
};

export default App;
