import { WalInput } from '@humanitec/ui-components';
import { rem } from 'polished';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import SkeletonGroup from '@src/components/shared/Skeleton/SkeletonGroup';
import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import useFilterApplications from '@src/hooks/useFilterApplications';
import { useRBAC } from '@src/hooks/useRBAC';
import { useShouldShowWelcomeBox } from '@src/hooks/useShouldShowWelcomeBox';
import { MatchParams } from '@src/models/routing';
import { containerStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import ApplicationCard from '../components/ApplicationCard/ApplicationCard';
import CreateApplicationModal from '../components/CreateApplicationModal';
import WelcomeBox from '../components/WelcomeBox';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${containerStyle()};
`;

const FilterAppsInput = styled(WalInput)`
  width: ${rem(300)};
  align-self: flex-end;
`;

const AppsWrapper = styled.div`
  display: grid;
  flex: 1;
  overflow: auto;
  margin-bottom: ${units.margin.md};
`;

const AppsGrid = styled.div`
  display: grid;
  grid-row-gap: ${units.margin.lg};
  margin-bottom: auto;
`;

const AppsList = () => {
  // i18n
  const { t } = useTranslation();
  const appsTranslations = t('APPS_OVERVIEW');

  // Form
  const formMethods = useWalhallForm();
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // Component state
  const [filterValue, setFilterValue] = useState<string>();
  const [orgIdFromState, setOrgIdFromState] = useState<string>(orgId);

  // RBAC
  const canCreateApplication = useRBAC('createApplication');

  // React Query
  const { isLoading: applicationsLoading } = useApplicationsQuery();
  const filteredApplications = useFilterApplications(filterValue);

  const showWelcomeBox = useShouldShowWelcomeBox();

  /*
   *  We use the orgIdFromState and applicationsFromState state variables for performance reasons.
   *  It makes sure the useEffect that dispatches the pausing calls only tracks neccessary updates for orgId and applications variables.
   *  Thereby, reducing the amount of times the dispatch calls are made.
   */
  useEffect(() => {
    if (orgId !== orgIdFromState) {
      setOrgIdFromState(orgId);
    }
  }, [orgId, orgIdFromState]);

  const renderFilterApplications = () => (
    <FormProvider {...formMethods}>
      <FilterAppsInput
        readonly={applicationsLoading}
        name={'deployments-filter'}
        placeholder={appsTranslations.FILTER_APPLICATIONS}
        hideLabel
        onChange={setFilterValue}
      />
    </FormProvider>
  );

  return (
    <Wrapper>
      <PageHeader
        customHeading={appsTranslations.APPLICATIONS_TITLE}
        rightContent={renderFilterApplications()}
      />

      {canCreateApplication && (
        <CreateApplicationModal buttonVariant={showWelcomeBox ? 'secondary' : 'primary'} />
      )}

      <AppsWrapper>
        <AppsGrid>
          {showWelcomeBox && <WelcomeBox />}
          {applicationsLoading && <SkeletonGroup count={3} itemProps={{ height: 132 }} />}
          {!applicationsLoading &&
            filteredApplications.map((app) => <ApplicationCard application={app} key={app.id} />)}
        </AppsGrid>
      </AppsWrapper>
    </Wrapper>
  );
};

export default AppsList;
