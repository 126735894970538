import useApplicationsQuery from './react-query/applications/queries/useApplicationsListQuery';

const useFilterApplications = (filterValue: string | undefined) => {
  // React Query
  const { data: applications = [] } = useApplicationsQuery();

  return filterValue
    ? applications.filter(
        (application) => application.name.toLowerCase()?.includes(filterValue.toLowerCase())
      )
    : applications;
};

export default useFilterApplications;
