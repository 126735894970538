import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useWalhallContext } from '@src/context/walhallContext';
import { units } from '@src/styles/variables';
import { darkPalette } from '@src/theme/color-palette';

import EmptyStateCard from '../../molecules/EmptyStateCard/EmptyStateCard';
import { WalLabel } from '../Label';

const DiffWrapper = styled.div`
  margin-bottom: ${units.margin.sm};
  font-size: ${units.fontSize.sm};
`;

interface DiffViewerProps {
  newValue?: string;
  oldValue?: string;
  label?: string;
}

const DiffViewer = ({ newValue, oldValue, label, ...props }: DiffViewerProps) => {
  // Context
  const { theme } = useWalhallContext();

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const reactDiffViewerStyles = {
    variables: {
      dark: {
        diffViewerBackground: darkPalette.baseDarker,
        gutterColor: darkPalette.text,
        emptyLineBackground: darkPalette.baseDarker,
      },
    },
    diffContainer:
      'word-break: break-all; pre { line-height: 15px !important; } a {text-decoration:none !important}',
  };

  return (
    <>
      <DiffWrapper>
        <WalLabel>{label}</WalLabel>
        {oldValue === newValue ? (
          <EmptyStateCard>{uiTranslations.NO_CHANGES}</EmptyStateCard>
        ) : (
          <ReactDiffViewer
            data-testid={'diff-viewer'}
            splitView={false}
            newValue={newValue}
            oldValue={oldValue}
            compareMethod={DiffMethod.JSON}
            hideLineNumbers
            useDarkTheme={theme === 'dark'}
            styles={reactDiffViewerStyles}
            {...props}
          />
        )}
      </DiffWrapper>
    </>
  );
};

export default DiffViewer;
