import { WalInput } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

const AWSFields = ({ isNew }: { isNew?: boolean }) => {
  const { t } = useTranslation();
  const formTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS.ACCOUNT_MODAL.AWS;

  return (
    <>
      <WalInput
        name={'accessKeyId'}
        labelAbove={!isNew ? true : false}
        label={formTranslations.ACCESS_KEY_ID_LABEL}
        required={isNew ? true : false}
        placeholder={!isNew ? '••••••••••' : undefined}
      />
      <WalInput
        name={'secretAccessKey'}
        labelAbove={!isNew ? true : false}
        label={formTranslations.SECRET_ACCESS_KEY_LABEL}
        required={isNew ? true : false}
        placeholder={!isNew ? '••••••••••' : undefined}
      />
    </>
  );
};

export default AWSFields;
