import { FlowDiagram } from '@humanitec/ui-components';
import yaml from 'js-yaml';

import { convertToNodesAndEdges } from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/pipelines-utils';
import usePipelineSchemaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineSchemaQuery';
import { PipelineYamlSchema } from '@src/models/pipeline';

export const PipelinePreview = () => {
  const { data: schema = '' } = usePipelineSchemaQuery();

  const schemaObject = yaml.load(schema) as PipelineYamlSchema;

  const { nodes, edges } = convertToNodesAndEdges(schemaObject);

  return <FlowDiagram nodes={nodes} edges={edges} />;
};
