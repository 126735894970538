import { CardStyles } from '@humanitec/ui-components';

import { RenderWorkloadProps } from '@src/hooks/useGetWorkloadData';

import WorkloadCard from '../WorkloadCard/WorkloadCard';

interface WorkloadsListProps {
  workloadDefinitions: RenderWorkloadProps[];
  filterValue?: string;
  /** Only pass this prop if displaying this card in the active deployment card  */
  deploymentId?: string;
  cardStyle?: CardStyles;
}

const WorkloadsList = ({
  workloadDefinitions,
  filterValue,
  deploymentId,
  cardStyle,
}: WorkloadsListProps) => {
  return (
    <>
      {workloadDefinitions
        .filter((m) => (filterValue ? m.workloadId.includes(filterValue) : true))
        .map(({ workloadId, workload, deleted, edited }) => (
          <WorkloadCard
            key={workloadId}
            workloadId={workloadId}
            workload={workload}
            deleted={deleted}
            edited={edited}
            deploymentId={deploymentId}
            cardStyle={cardStyle}
          />
        ))}
    </>
  );
};

export default WorkloadsList;
