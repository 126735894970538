import { MenuItem, WalButton, WalCard, WalMenu } from '@humanitec/ui-components';
import cronstrue from 'cronstrue';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import CronJobModal from '@src/components/shared/ViewWorkloadProfile/components/RenderFeature/components/HumanitecFeature/HumanitecDeltaFeature/features/CronJobs/components/CronJobModal/CronJobModal';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { Schedule } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';

const CronJobsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CronJobCard = styled(WalCard)<{ first: boolean; last: boolean }>`
  position: relative;

  ${({ first }) =>
    first &&
    css`
      margin-top: ${units.margin.md};
    `}

  ${({ last }) =>
    !last &&
    css`
      margin-bottom: ${units.margin.md};
    `}
`;

const CronJobInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const cardButtonStyle = css`
  position: absolute;
  right: ${units.margin.md};
`;

const CardButton = styled(WalButton)`
  ${cardButtonStyle};
`;

const CardMenuWrapper = styled.div`
  ${cardButtonStyle};
`;

const CronJobTitle = styled.span`
  font-size: ${units.fontSize.base};
  font-weight: 700;
`;
const CronJobSchedule = styled.span`
  font-size: ${units.fontSize.sm};
`;

const CronJobScheduleTranslation = styled.span`
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const AddButton = styled(WalButton)`
  align-self: flex-start;
`;

export interface CronJobContainer {
  args?: string[];
  command?: string[];
}

export interface CronJob {
  name: string;
  schedule?: string;
  containers: {
    [key: string]: CronJobContainer;
  };
}

interface CronJobsProps {
  deltaPath: string;
}

const CronJobs = ({ deltaPath }: CronJobsProps) => {
  // state
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState<boolean>(false);
  const [cronJobToEdit, setCronJobToEdit] = useState<CronJob>();
  const [cronJobToDelete, setCronJobToDelete] = useState<CronJob>();
  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;
  const uiTranslations = t('UI');

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const { data, updateWorkload } = useDeltaUtils<Record<string, Schedule>>(deltaPath, () => {
    setOpenDeleteConfirmationModal(false);
  });

  const cronJobs = Object.entries(data ?? {}).map(([scheduleKey, scheduleValue]) => ({
    name: scheduleKey,
    schedule: scheduleValue.schedule,
    containers: scheduleValue.containers,
  }));

  const handleMenuItemClick = (item: MenuItem<any>, cronjob: CronJob) => {
    if (item.value === 'edit') {
      setOpenModal(true);
      setCronJobToEdit(cronjob);
    } else if (item.value === 'delete') {
      setOpenDeleteConfirmationModal(true);
      setCronJobToDelete(cronjob);
    }
  };

  const handleAddButtonClick = () => {
    setCronJobToEdit(undefined);
    setOpenModal(true);
  };

  const handleDetailsButtonClick = (cronjob: CronJob) => {
    setCronJobToEdit(cronjob);
    setOpenModal(true);
  };

  const deleteSchedule = () => {
    updateWorkload([
      {
        key: cronJobToDelete?.name,
        op: 'remove',
      },
    ]);
  };

  return (
    <>
      <CronJobsWrapper>
        {draftModeActive ? (
          <AddButton variant={'secondary'} iconLeft={'plus'} onClick={handleAddButtonClick}>
            {sectionsTranslations.ADD_CRONJOB_SCHEDULE}
          </AddButton>
        ) : (
          cronJobs.length === 0 && (
            <span className={'txt-md'}>{sectionsTranslations.CRONJOBS_NO_SCHEDULES}</span>
          )
        )}

        {cronJobs.map((cronJob, index) => (
          <CronJobCard
            first={index === 0}
            last={cronJobs.length === index + 1}
            key={cronJob.name}
            cardStyle={'default'}
            dataTestId={'cronjob-card'}>
            <CronJobInfoWrapper>
              <CronJobTitle>{cronJob.name}</CronJobTitle>
              <CronJobSchedule>{cronJob.schedule}</CronJobSchedule>
              <CronJobScheduleTranslation>
                {cronJob.schedule
                  ? cronstrue.toString(cronJob.schedule, { use24HourTimeFormat: true })
                  : ''}
              </CronJobScheduleTranslation>
            </CronJobInfoWrapper>
            {draftModeActive ? (
              <CardMenuWrapper>
                <WalMenu
                  items={[
                    { label: uiTranslations.EDIT, value: 'edit' },
                    { label: uiTranslations.DELETE, value: 'delete' },
                  ]}
                  standardToggle={{
                    type: 'dots',
                    objectType: 'cronjob',
                    objectName: cronJob.name,
                  }}
                  onItemClick={(item) => handleMenuItemClick(item, cronJob)}
                />
              </CardMenuWrapper>
            ) : (
              <CardButton
                variant={'secondary'}
                size={'small'}
                onClick={() => handleDetailsButtonClick(cronJob)}>
                {uiTranslations.DETAILS}
              </CardButton>
            )}
          </CronJobCard>
        ))}
      </CronJobsWrapper>
      {openModal && (
        <CronJobModal
          state={[openModal, setOpenModal]}
          cronJob={cronJobToEdit}
          cronJobs={cronJobs}
          deltaPath={deltaPath}
        />
      )}
      {openDeleteConfirmationModal && (
        <ConfirmDeleteModal
          state={[openDeleteConfirmationModal, setOpenDeleteConfirmationModal]}
          deleteConfirmedCallback={deleteSchedule}
          confirmMessage={sectionsTranslations.CRONJOBS_DELETE_CONFIRMATION}
          title={`${sectionsTranslations.DELETE_CRONJOB_TITLE} ${cronJobToDelete?.name}`}
        />
      )}
    </>
  );
};

export default CronJobs;
