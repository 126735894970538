import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { AppRoles, OrgRoles } from '@src/models/role';

interface PrivateRouteForOrgRoleProps {
  allOrAny: 'all' | 'any';
  children: ReactNode;
  orgRoles?: OrgRoles[];
  appRoles?: AppRoles[];
  redirectUrl?: string;
}

export const PrivateRouteForOrgRole = ({
  allOrAny,
  children,
  orgRoles,
  appRoles,
  redirectUrl,
}: PrivateRouteForOrgRoleProps) => {
  const [permitted, setPermitted] = useState<boolean>();
  const { orgRole, appRole } = useGetUserRoles();

  const orgPermissionSatisfied = useMemo(
    () => (orgRoles ? (orgRole ? orgRoles.includes(orgRole) : undefined) : true),
    [orgRole, orgRoles]
  );

  const appPermissionSatisfied = useMemo(
    () => (appRoles ? (appRole ? appRoles.includes(appRole) : undefined) : true),
    [appRole, appRoles]
  );

  useEffect(() => {
    if (allOrAny === 'all' && orgPermissionSatisfied && appPermissionSatisfied) {
      setPermitted(true);
    } else if (allOrAny === 'any' && (orgPermissionSatisfied || appPermissionSatisfied)) {
      setPermitted(true);
    } else if (orgPermissionSatisfied !== undefined && appPermissionSatisfied !== undefined) {
      setPermitted(false);
    }
  }, [orgPermissionSatisfied, appPermissionSatisfied, allOrAny]);

  return permitted ? (
    <>{children}</>
  ) : redirectUrl && permitted !== undefined ? (
    <Navigate to={redirectUrl} />
  ) : (
    <></>
  );
};
