import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { ApiToken } from '@src/models/api-token';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { apiTokensQueryKeys } from '../apiTokensQueryKeys';

/*
 * Query for api tokens that is scoped to an organization
 * The endpoint used here is deprecated and this functionality would be removed when the new server users epic is released.
 */
const useApiTokensQuery = (): QueryResponse<ApiToken[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: apiTokensQueryKeys.listApiTokensByOrg(orgId),
    queryFn: () => makeRequest<ApiToken[]>('GET', `/tokens?org_id=${orgId}`),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useApiTokensQuery;
