import {
  ActionButtons,
  AddButtonForm,
  SecretValue,
  VariablesViewAddEdit,
  VariablesViewAddEditProps,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { MouseEventHandler, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import SharedAppValuesHistory from '@src/components/shared/SharedAppValuesHistory/SharedAppValuesHistory';
import useAppValuesCreateMutation from '@src/hooks/react-query/shared-values/mutations/useAppValuesCreateMutation';
import useAppValuesDeleteMutation from '@src/hooks/react-query/shared-values/mutations/useAppValuesDeleteMutation';
import useAppValuesUpdateMutation from '@src/hooks/react-query/shared-values/mutations/useAppValuesUpdateMutation';
import useAppValuesQuery from '@src/hooks/react-query/shared-values/queries/useAppValuesQuery';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { useRBAC } from '@src/hooks/useRBAC';
import { AppValue } from '@src/models/app-value';
import { KeyValue } from '@src/models/general';
import { placeholderStyle } from '@src/styles/global-styles';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const ReferenceText = styled.span`
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
`;

const ReferencePlaceholder = styled.span`
  ${placeholderStyle()}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AddValueWrapper = styled.div`
  margin-bottom: ${units.margin.md};
`;

const StyledDash = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
`;

const SharedAppValues = () => {
  // State
  const [expandedRow, setExpandedRow] = useState<number | undefined>();
  const [rowToOverrideColumns, setRowToOverrideColumns] = useState<number | undefined>();
  const [showAddModeFormContent, setShowAddModeFormContent] = useState<boolean | undefined>();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [historyViewKey, setHistoryViewKey] = useState<string>('');
  // Form
  const createFormMethods = useWalhallForm();

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const settingsTranslations = t('APP_SETTINGS');

  // Context
  const { orgRole, appRole } = useGetUserRoles();
  const canEditApp = useRBAC('editApplication');

  // react-query
  const { data: appValues = [] } = useAppValuesQuery();
  const { mutate: createAppValue, isPending: appValuesCreationInProgress } =
    useAppValuesCreateMutation();
  const { mutate: updateAppValue } = useAppValuesUpdateMutation();
  const { mutate: deleteAppValue } = useAppValuesDeleteMutation();

  /**
   * RBAC
   * This restricts editing/deleting permissions based on RBAC detailed at
   * https://www.notion.so/humanitec/Frontend-Engineering-f8a268f74f7e4a48a3f5f8eea4d94040#6ef33f52f696404593bb7d54c1a2b5cc
   */
  const canEdit = () =>
    orgRole === 'administrator' || appRole === 'developer' || appRole === 'owner';

  // Methods
  const createNewValue = (formData: KeyValue) => {
    createAppValue({ appValue: formData });
  };

  const deleteValue = (deletedKeyValue: KeyValue) => {
    deleteAppValue({ appValueKey: deletedKeyValue.key });
  };

  const updateValue = (updatedValue: KeyValue) => {
    updateAppValue({ appValue: updatedValue });
  };

  const handleEdit = (index: number) => {
    setExpandedRow(index);
    setRowToOverrideColumns(index);
  };

  const handleCancelEditMode = () => {
    setRowToOverrideColumns(undefined);
  };

  const handleCancelAddMode = () => {
    setShowAddModeFormContent(false);
  };

  // eslint-disable-next-line
  const placeholderReferenceExample = '${values.KEY}';

  const onShowHistory =
    (row: WalTableRow<AppValue & { path: string }>): MouseEventHandler =>
    (e) => {
      setShowHistoryModal(true);
      setHistoryViewKey(row.data.key);
      e.stopPropagation();
    };
  const columns: WalTableColumn<AppValue & { path: string }>[] = [
    {
      label: uiTranslations.KEY_LABEL,
      prop: 'key',
      ellipsisTooltip: {
        maxWidth: 280,
        maxCharacters: 25,
        text: (row) => row.data.key,
      },
      template: (row) => <p>{row.data.key}</p>,
    },
    {
      label: uiTranslations.VALUE_LABEL,
      prop: 'value',
      ellipsisTooltip: {
        maxWidth: 280,
        maxCharacters: 10,
        text: (row) =>
          row.data.is_secret && row.data.secret_version
            ? settingsTranslations.VALUE_HISTORY.SECRET_ID + ': ' + row.data.secret_version
            : row.data.value,
      },
      template: (row) =>
        row.data.is_secret ? (
          <SecretValue secretVersionId={row.data.secret_version} />
        ) : (
          <p>{row.data.value || <StyledDash>-</StyledDash>}</p>
        ),
    },
    {
      label: uiTranslations.DESCRIPTION,
      prop: 'description',
      ellipsisTooltip: {
        maxWidth: 280,
        maxCharacters: 10,
        text: (row) => row.data.description,
      },
      template: (row) => <p>{row.data.description || <StyledDash>-</StyledDash>}</p>,
    },
    {
      prop: 'actions',
      tabIndex: -1,
      template: (row) => {
        return (
          <ActionsWrapper>
            <ActionButtons
              buttons={canEdit() ? ['edit', 'history', 'delete'] : ['history']}
              onEdit={() => handleEdit(appValues.indexOf(row.data))}
              onDelete={() => deleteValue(row.data)}
              onShowHistory={onShowHistory(row)}
            />
          </ActionsWrapper>
        );
      },
    },
  ];

  const renderVariablesViewAddEdit = ({
    id,
    keyValue,
    onSave,
    onCancel,
    mode,
    isSaving,
    disableKeyField,
    existingKeys = undefined,
  }: VariablesViewAddEditProps) => (
    <VariablesViewAddEdit
      key={keyValue?.key}
      id={id}
      keyValue={keyValue}
      onSave={onSave}
      onCancel={onCancel}
      mode={mode}
      isSaving={isSaving}
      disableKeyField={disableKeyField}
      showDescription
      showSecret
      existingKeys={existingKeys}
    />
  );

  const renderAddVariablesSection = (
    <AddButtonForm
      showFormContentState={[showAddModeFormContent, setShowAddModeFormContent]}
      buttonText={uiTranslations.ADD_VARIABLES}
      formContent={
        <>
          {renderVariablesViewAddEdit({
            mode: 'add',
            onSave: createNewValue,
            onCancel: handleCancelAddMode,
            isSaving: appValuesCreationInProgress,
            existingKeys: appValues.map((av) => av.key),
          })}
        </>
      }
    />
  );

  return (
    <>
      <Wrapper>
        {canEditApp && (
          <>
            <ReferenceText>
              {settingsTranslations.REFERENCE_USING}
              <ReferencePlaceholder>{placeholderReferenceExample}</ReferencePlaceholder>
              {settingsTranslations.WITHIN_CONTAINER_VARS}
            </ReferenceText>
            <FormProvider {...createFormMethods}>
              <AddValueWrapper>{renderAddVariablesSection}</AddValueWrapper>
            </FormProvider>
          </>
        )}
        <WalTable
          caption={settingsTranslations.APP_VALUES_SECRETS_HEADING}
          columns={columns}
          expandedRowState={[expandedRow, setExpandedRow]}
          disableScrolling
          tableStyle={'expandable'}
          rows={
            appValues
              ? appValues?.map((appValue, rowIndex) => ({
                  data: appValue,
                  expandableContent: renderVariablesViewAddEdit({
                    keyValue: appValue,
                    mode: 'view',
                  }),
                  overrideColumns: rowToOverrideColumns === rowIndex,
                  columnOverrideTemplate: renderVariablesViewAddEdit({
                    keyValue: appValue,
                    mode: 'edit',
                    onSave: updateValue,
                    onCancel: handleCancelEditMode,
                    disableKeyField: true,
                  }),
                }))
              : []
          }
        />
      </Wrapper>
      {showHistoryModal && (
        <SharedAppValuesHistory
          keyValue={historyViewKey}
          onClose={() => setShowHistoryModal(false)}
        />
      )}
    </>
  );
};

export default SharedAppValues;
