import {
  FormTable,
  InfoPopup,
  QuickDelete,
  WalDropdownMenu,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import SectionsSettingWrapper from '@src/containers/Orgs/components/styles';
import useRoleDeleteMutation from '@src/hooks/react-query/roles/mutations/useRoleDeleteMutation';
import useRoleUpdateMutation from '@src/hooks/react-query/roles/mutations/useRoleUpdateMutation';
import useSendInvitations from '@src/hooks/react-query/roles/mutations/useSendInvitations';
import useGetInvitations from '@src/hooks/react-query/roles/queries/useGetInvitations';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';
import { useFeature } from '@src/hooks/useFeature';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { useRoleDropdownOptions } from '@src/hooks/useRoleDropdownOptions';
import { OrgRoles, Role } from '@src/models/role';
import { Invitation } from '@src/models/user';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { useWalhallForm } from '@src/utilities/form';
import { EMAIL_PATTERN } from '@src/utilities/string-utility';

const Name = styled.div`
  font-size: ${units.fontSize.sm};
`;

const Email = styled.div`
  font-size: ${units.fontSize.sm};
  display: flex;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface FormProps {
  'email-wrapper': string[];
  email: string;
  role: OrgRoles;
  errorInputPlaceholder: string;
}

const OrgMembersList = () => {
  // Component state
  const [resetArgs, setResetArgs] = useState(false);

  const [openConfirmDowngradeModal, setOpenConfirmDowngradeModal] = useState<boolean>(false);
  const [updateRoleOfMember, setUpdateRoleOfMember] = useState<{
    memberId: string;
    role: OrgRoles;
  }>();

  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');
  const orgSettingsTranslations = t('ORG_SETTINGS');
  const navigationTranslations = t('NAVIGATION');

  // Context
  const { orgRole } = useGetUserRoles();

  // Optimizely
  const [isReworkOrgMembersReadonlyEnabled] = useFeature('rework-org-members-readonly');

  // React Query
  const { data: user } = useGetCurrentUserQuery();
  const { data: usersData } = useOrgRolesQuery();
  const {
    mutate: updateRole,
    isSuccess: isUpdatedSuccessfully,
    reset: resetUpdateMutation,
  } = useRoleUpdateMutation();
  const { mutate: sendInvite, isPending: sendingInvites } = useSendInvitations(() =>
    setResetArgs(true)
  );
  const { mutate: deleteRole } = useRoleDeleteMutation();

  useEffect(() => {
    if (isUpdatedSuccessfully) {
      setOpenConfirmDowngradeModal(false);
      resetUpdateMutation();
    }
  }, [isUpdatedSuccessfully, resetUpdateMutation]);

  const users = useMemo(() => {
    return (
      usersData?.filter(
        (userData) => userData.type !== 'service' // filter service users by search term if one is inputted
      ) || []
    );
  }, [usersData]);

  const userRows: WalTableRow<Role<OrgRoles>>[] = useMemo(
    () =>
      users?.map(
        (u): WalTableRow => ({
          data: u,
        })
      ) ?? [],
    [users]
  );

  // Custom hook
  const regularUsersRoleOptions = useRoleDropdownOptions();
  const serviceUsersRoleOptions = useRoleDropdownOptions(true);

  // Selectors
  const { data: invitations } = useGetInvitations();

  const placeholderInput = 'errorInputPlaceholder';

  // Form
  const methods = useWalhallForm<FormProps>({
    defaultValues: { 'email-wrapper': [], role: 'member', email: '', errorInputPlaceholder: '' },
  });

  const {
    watch,
    setError,
    setValue,
    clearErrors,
    formState: { isSubmitted, isSubmitSuccessful },
  } = methods;

  const emailInput = watch('email');
  const emails = watch('email-wrapper');

  useEffect(() => {
    const duplicateEmails = users.map((u) => u.email).filter((e) => emails.includes(e));
    if (isSubmitted && !isSubmitSuccessful) {
      if (duplicateEmails.length) {
        setError(placeholderInput, { message: `${duplicateEmails[0]} already exists` });
      } else if (Boolean(emails.length) || (emailInput && EMAIL_PATTERN.test(emailInput))) {
        clearErrors(placeholderInput);
      } else {
        setError(placeholderInput, { message: authTranslations.ENTER_VALID_EMAIL });
        return;
      }
    }
  }, [
    isSubmitted,
    emails,
    clearErrors,
    setError,
    emailInput,
    authTranslations.ENTER_VALID_EMAIL,
    isSubmitSuccessful,
    users,
  ]);

  const removeUser = (id: string) => {
    deleteRole(id);
  };

  const modifyRole = (userId: string, role: OrgRoles) => {
    // if the user is downgrading their role
    if (user?.id === userId) {
      if (
        (orgRole === 'administrator' && role !== 'administrator') ||
        (orgRole === 'manager' && role === 'member')
      ) {
        setUpdateRoleOfMember({ memberId: userId, role });
        setOpenConfirmDowngradeModal(true);
      }
    } else {
      updateRole({ roleId: userId, newRole: role });
    }
  };

  const formattedInvitations = useMemo(() => {
    // Parses data from the invitations into a hasmap with a key of user_id and value of expiry date timestamp
    const invitationsMap: { [key: string]: string } = {};
    invitations?.forEach(
      (invitation: Invitation) => (invitationsMap[invitation.user_id] = invitation.expires_at)
    );
    return invitationsMap;
  }, [invitations]);

  const getExpiryDate = (id: string) =>
    formattedInvitations[id]
      ? formatDate(formattedInvitations[id], DATE_FORMATS_TYPES.DATE_MONTH_YEAR)
      : ' _ ';

  const tableCols: WalTableColumn<Role<OrgRoles>>[] = [
    {
      label: 'Name',
      prop: 'name',
      template: (row) => {
        if (isReworkOrgMembersReadonlyEnabled) {
          return (
            <Name>
              <Link to={row.data.id}>{row.data.name || '...'}</Link>
            </Name>
          );
        }
        return <Name>{row.data.name || '...'}</Name>;
      },
    },
    {
      label: 'Email',
      prop: 'email',
      justifyContent: 'flex-start',
      template: (row) => <Email>{row.data.email}</Email>,
    },
    {
      label: authTranslations.STATUS,
      prop: 'active',
      justifyContent: 'flex-start',
      template: (row) => (
        <Email>
          {row.data.type === 'invitation' ? (
            <>
              {authTranslations.INVITE_PENDING}
              <InfoPopup
                text={`${authTranslations.INVITE_EXPIRES_AT} ${getExpiryDate(row.data.id)}`}
                position={'bottom'}
                width={150}
              />
            </>
          ) : (
            authTranslations.ACTIVE
          )}
        </Email>
      ),
    },
    {
      label: 'Role',
      prop: 'role',
      tabIndex: -1,
      template: (row) => (
        <WalDropdownMenu
          buttonSize={'small'}
          defaultValue={row.data.role}
          items={row.data.type !== 'service' ? regularUsersRoleOptions : serviceUsersRoleOptions} // only display artefact contributor role option for service users
          onItemClick={(roleId) => modifyRole(row.data.id, roleId as OrgRoles)}
          disabled={
            (users.filter((u) => u.role === 'administrator').length <= 1 &&
              row.data.role === 'administrator') ||
            (row.data.role === 'administrator' && orgRole !== 'administrator')
          }
        />
      ),
    },
    {
      prop: 'actions',
      tabIndex: -1,
      template: (row) => {
        return (
          orgRole === 'administrator' &&
          // show delete if role belongs to non-admin OR there are more than one admin
          (row.data.role !== 'administrator' ||
            users.filter((u) => u.role === 'administrator').length > 1) && (
            <QuickDelete
              onConfirm={() => removeUser(row.data.id)}
              confirmationButtonText={orgSettingsTranslations.REMOVE_USER}
            />
          )
        );
      },
    },
  ];

  const submit = (props: FormProps) => {
    const { email, role, 'email-wrapper': formEmails } = props;

    const duplicateEmails = users.map((u) => u.email).filter((e) => formEmails.includes(e));

    if (duplicateEmails.length) {
      setError(placeholderInput, { message: `${duplicateEmails[0]} already exists` });
    } else if (Boolean(formEmails.length) || (emailInput && EMAIL_PATTERN.test(emailInput))) {
      clearErrors(placeholderInput);
    } else {
      setError(placeholderInput, { message: authTranslations.ENTER_VALID_EMAIL });
      return;
    }
    const existingEmails = users?.map((u) => u.email) ?? [];

    const filteredExistingAndEmpty = formEmails
      .filter((e) => !existingEmails.includes(e))
      .filter((e) => e);

    const emailsToSend = filteredExistingAndEmpty.map((e) => ({
      email: e,
      role,
    }));

    // If an email is entered without pressing a separator key(',', ';', ' ')
    if (email && EMAIL_PATTERN.test(email)) {
      emailsToSend.push({
        email,
        role,
      });
    }

    if (emailsToSend.length) {
      emailsToSend.map((e) => sendInvite(e));
    }

    setValue('email-wrapper', []);
    setValue('email', '');
  };

  return (
    <SectionsSettingWrapper>
      <HeadingWrapper>
        <h4>{authTranslations.INVITE_TO_ORG}</h4>
        <InfoPopup
          text={authTranslations.INVITE_TO_ORG_INFO_TEXT}
          moreInformationLink={authTranslations.INVITE_TO_ORG_INFO_LINK}
          position={'bottom'}
        />
      </HeadingWrapper>
      <FormProvider {...methods}>
        <FormTable
          disableOverflow
          table={{
            caption: navigationTranslations.ORG_MEMBERS,
            columns: tableCols,
            rows: userRows,
          }}
          formGridTemplate={css`
            grid-template-columns: 2fr 1fr auto;
          `}
          form={{
            extraErrors: [{ name: placeholderInput, label: authTranslations.EMAIL }],
            fields: [
              {
                type: 'args-input',
                props: {
                  name: 'email',
                  label: authTranslations.EMAIL,
                  editMode: 'outside-bubble',
                  separators: [';', ',', ' '],
                  matchPattern: EMAIL_PATTERN,
                  resetArgsState: [resetArgs, setResetArgs],
                  hideError: true,
                },
              },
              {
                type: 'dropdown',
                props: {
                  items: regularUsersRoleOptions,
                  name: 'role',
                  label: 'Role',
                  allowUpdatesToDefaultValue: false,
                },
              },
            ],
            submitButton: {
              children:
                emails.length > 1 ? authTranslations.SEND_INVITES : authTranslations.SEND_INVITE,
            },
            onSubmit: submit,
            formSubmitting: sendingInvites,
          }}
          formMaxWidth={1000}
        />
      </FormProvider>
      {updateRoleOfMember && (
        <ConfirmDeleteModal
          title={orgSettingsTranslations.CHANGE_ROLE_CONFIRMATION_TITLE}
          confirmMessage={orgSettingsTranslations.CHANGE_ROLE_CONFIRMATION_TEXT}
          state={[openConfirmDowngradeModal, setOpenConfirmDowngradeModal]}
          deleteButtonText={orgSettingsTranslations.CHANGE_ROLE}
          deleteConfirmedCallback={() => {
            updateRole({ roleId: updateRoleOfMember.memberId, newRole: updateRoleOfMember.role });
          }}
        />
      )}
    </SectionsSettingWrapper>
  );
};

export default OrgMembersList;
