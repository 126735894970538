import { windowEnv } from '@src/environment';

export const initMaze = () => {
  // Only show on dev
  if (windowEnv.ENVIRONMENT_NAME === 'dev') {
    // Main script.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `(function (m, a, z, e) {
    var s, t;
    try {
      t = m.sessionStorage.getItem('maze-us');
    } catch (err) {}
  
    if (!t) {
      t = new Date().getTime();
      try {
        m.sessionStorage.setItem('maze-us', t);
      } catch (err) {}
    }
  
    s = a.createElement('script');
    s.src = z + '?t=' + t + '&apiKey=' + e;
    s.async = true;
    a.getElementsByTagName('head')[0].appendChild(s);
    m.mazeUniversalSnippetApiKey = e;
  })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', 'f31f2547-ad94-4eb8-879e-8593ae7fbc50');`;
    document.head.prepend(script);
  }
};
