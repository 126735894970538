import { createContext, useContext } from 'react';

import { Workload } from '../models/deployment-set';

interface WorkloadContextProps {
  /** The workload as defined in deployment set (with no changes applied) */
  dsWorkload?: Workload;
}

export const WorkloadContext = createContext<WorkloadContextProps>({});

export const useWorkloadContext = () => useContext(WorkloadContext);
