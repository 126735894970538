import { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { WalButton } from '../../base/Button/Button';
import { QuickDelete } from '../QuickDelete/QuickDelete';

const Wrapper = styled.div<{ columnsSize: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columnsSize }) => columnsSize}, max-content);
  column-gap: ${units.margin.md};
  align-items: center;
`;
interface ActionsButtonsProps {
  /**
   * Pass an array of buttons to be displayed in that order
   */
  buttons: ('edit' | 'history' | 'delete')[];
  onEdit?: () => void;
  onShowHistory?: (e: MouseEvent) => void;
  onDelete?: () => void;
}
const ActionButtons = ({ buttons, onEdit, onDelete, onShowHistory }: ActionsButtonsProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const components: Record<(typeof buttons)[number], ReactNode> = {
    edit: (
      <WalButton
        key={'edit'}
        iconLeft={'edit'}
        variant={'secondary'}
        size={'small'}
        ariaLabel={uiTranslations.EDIT}
        onClick={onEdit}
      />
    ),
    history: (
      <WalButton
        key={'history'}
        iconLeft={'history'}
        variant={'secondary'}
        size={'small'}
        ariaLabel={uiTranslations.SHOW_HISTORY}
        onClick={onShowHistory}
      />
    ),
    delete: <QuickDelete key={'delete'} onConfirm={() => onDelete && onDelete()} />,
  };
  return (
    <Wrapper columnsSize={buttons.length}>{buttons.map((button) => components[button])}</Wrapper>
  );
};

export default ActionButtons;
