import { KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { KeyBindings } from '@src/types/key-bindings';

import { ButtonSize, ButtonVariant, WalButton } from '../../base/Button/Button';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

const QuickDeleteWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

interface QuickDeleteProps {
  // button variant
  onConfirm: () => void;
  variant?: ButtonVariant;
  size?: ButtonSize;
  confirmationButtonText?: string;
}
export const QuickDelete = ({
  variant,
  onConfirm,
  size,
  confirmationButtonText,
}: QuickDeleteProps) => {
  const [hidden, setHidden] = useState<boolean>(true);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KeyBindings.ENTER) {
      e.preventDefault();
      setHidden(!hidden);
    }
  };

  return (
    <QuickDeleteWrapper>
      <WalButton
        iconLeft={'delete'}
        variant={variant || 'secondary'}
        ariaLabel={uiTranslations.DELETE}
        size={size || 'small'}
        onClick={(e) => {
          e.stopPropagation();
          setHidden(!hidden);
        }}
        onKeyDown={handleKeyDown}
      />
      {!hidden && (
        <ConfirmationDialog
          onConfirm={onConfirm}
          hiddenState={[hidden, setHidden]}
          confirmationButtonText={confirmationButtonText}
        />
      )}
    </QuickDeleteWrapper>
  );
};
