import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import { Org } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';
import { generateAppURL } from '@src/utilities/navigation';
import { convertToNameAndId } from '@src/utilities/string-utility';

import { userQueryKeys } from '../../user/userQueryKeys';
import { orgQueryKeys } from '../orgQueryKeys';

interface UseOrgCreateMutationProps {
  onError?: () => void;
}

const useOrgCreateMutation = ({ onError }: UseOrgCreateMutationProps | undefined = {}) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data, ...results } = useMutation<
    AxiosResponse<Org>,
    AxiosError<{ message: string; code: number }>,
    string
  >({
    mutationFn: (orgName: string) => {
      const { name, id } = convertToNameAndId(orgName);
      return makeRequest<Org>('POST', '/orgs', { name, id });
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({
        queryKey: orgQueryKeys.list(),
      });
      await queryClient.invalidateQueries({
        queryKey: userQueryKeys.currentUser(),
      });
      navigate(generateAppURL(response.data.id));
    },
    onError: (error) => {
      datadogRum.addError(error);
      onError?.();
    },
  });

  return { ...results, data: data?.data };
};

export default useOrgCreateMutation;
