import { User } from '@src/models/user';

export const generateFrontChatNodes = (onloadCallback: () => void) => {
  const script = document.createElement('script');
  script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';

  script.onload = () => {
    onloadCallback();
    initFrontChat();
  };

  document.body.append(script);
};

export const initFrontChat = () => {
  (window as any)?.FrontChat?.('init', {
    chatId: '11e0d2769ddaaa9cbf3c4c9962852ef9',
    useDefaultLauncher: true,
  });
};

export const identifyFrontChat = (user: User) => {
  (window as any)?.FrontChat('identity', {
    email: user.email,
    name: user.name,
    userHash: user.properties.frontapp_secret,
  });
};

export const shutdownFrontChat = () => {
  (window as any)?.FrontChat?.('shutdown');
};
