export const pipelinesQueryKeys = {
  allByOrg: (orgId: string | undefined, fetchAll?: boolean) => [
    'orgs',
    orgId,
    'pipelines',
    fetchAll ? 'all' : '',
  ],
  // e.g. ['orgs', 'my-org', 'apps', 'sample-app', 'pipelines' ]
  all: (orgId: string | undefined, appId: string | undefined) => [
    'orgs',
    orgId,
    'apps',
    appId,
    'pipelines',
  ],
  list: (
    orgId: string | undefined,
    appId: string | undefined,
    filters?: { 'metadata[humanitec.io/ui-action]': 'deploy' | 're-deploy' }
  ) => {
    const baseQuery: (
      | string
      | undefined
      | { 'metadata[humanitec.io/ui-action]': 'deploy' | 're-deploy' }
    )[] = [...pipelinesQueryKeys.all(orgId, appId), 'list'];

    if (filters) {
      baseQuery.push(filters);
    }

    return baseQuery;
  },
  details: (
    orgId: string | undefined,
    appId: string | undefined,
    pipelineId: string | undefined,
    versionId?: string | undefined
  ) => [...pipelinesQueryKeys.all(orgId, appId), pipelineId, 'versions', versionId],
  versions: (
    orgId: string | undefined,
    appId: string | undefined,
    pipelineId: string | undefined
  ) => [...pipelinesQueryKeys.details(orgId, appId, pipelineId), 'versions'],
  schema: (
    orgId: string | undefined,
    appId: string | undefined,
    pipelineId: string | undefined,
    versionId?: string | undefined
  ) => [...pipelinesQueryKeys.details(orgId, appId, pipelineId), 'schema', versionId],
  validationSchema: (orgId: string | undefined) => ['orgs', orgId, ['validation-schema']],
  criteria: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined,
    filters?: { trigger?: string; env_id?: string; pipeline_id?: string }
  ) => {
    const baseQuery: (
      | string
      | undefined
      | { trigger?: string; env_id?: string; pipeline_id?: string }
    )[] = [...pipelinesQueryKeys.all(orgId, appId), 'envs', envId, 'criteria'];

    if (filters) {
      baseQuery.push(filters);
    }

    return baseQuery;
  },
};
