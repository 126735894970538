import {
  Icon,
  OptionGrid,
  OptionGridOption,
  WalButton,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { rem } from 'polished';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AddAccountModal from '@src/containers/Orgs/Accounts/components/AddAccountModal/AddAccountModal';
import {
  SectionTopBarSearchField,
  SectionTopBarTitle,
  SectionTopBarWrapper,
} from '@src/containers/Orgs/components/SectionTopBarWrapper';
import SectionsSettingWrapper from '@src/containers/Orgs/components/styles';
import useResourceAccountsQuery from '@src/hooks/react-query/resource-accounts/queries/useResourceAccountsQuery';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { useResourcesStore } from '@src/hooks/zustand/useResourcesStore';
import { ResourceAccount, ResourceAccountType } from '@src/models/resource-account';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

const AddAccountsTitle = styled(SectionTopBarTitle)`
  min-width: ${rem(120)};
`;

const AccountsTable = styled(WalTable)`
  margin-bottom: ${units.margin.lg};
`;

export const getResourceAccountTypeText = (
  type: ResourceAccountType,
  translations: any
): string => {
  switch (type) {
    case 'aws':
      return translations.AWS;
    case 'gcp':
      return translations.GCP;
    case 'azure':
      return translations.AZURE;
    case 'aiven':
      return translations.AIVEN;
    case 'cloudflare':
      return translations.CLOUDFLARE;
    default:
      return '';
  }
};

const Accounts = () => {
  // React Query
  const { data: resourceAccounts = [] } = useResourceAccountsQuery();

  // Component state
  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);
  const [accountTypeToAdd, setAccountTypeToAdd] = useState<ResourceAccountType>('gcp');
  const [editingAccount, setEditingAccount] = useState<ResourceAccount | undefined>();
  const [filteredAccounts, setFilteredAccounts] = useState<ResourceAccount[]>([]);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const accountsTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS;

  // Zustand
  const { lastCreatedResourceAccountIds } = useResourcesStore();

  // context
  const { orgRole } = useGetUserRoles();

  useEffect(() => {
    setFilteredAccounts(resourceAccounts);
  }, [resourceAccounts]);

  const editAccount = (account: ResourceAccount) => {
    setEditingAccount(account);
    setAccountModalOpen(true);
  };

  const accountColumns: WalTableColumn<ResourceAccount>[] = [
    {
      label: accountsTranslations.ACCOUNT_NAME,
      prop: 'name',
      template: (row) => (
        <span className={'flex-centered'}>
          <Icon size={20} name={row.data.type} marginRight={'md'} />
          {row.data.name}
        </span>
      ),
    },
    {
      label: accountsTranslations.ACCOUNT_TYPE,
      prop: 'type',
      template: (row) => (
        <span>{getResourceAccountTypeText(row.data.type, accountsTranslations)}</span>
      ),
    },
    {
      label: accountsTranslations.IN_USE,
      prop: 'is_used',
      justifyContent: 'center',
      template: (row) => (row.data.is_used ? <Icon name={'checkmark'} /> : <span>-</span>),
    },
    {
      label: accountsTranslations.CREATED_AT,
      prop: 'created_at',
      template: (row) => (
        <span>
          {row.data.is_default
            ? '...'
            : formatDate(row.data.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}
        </span>
      ),
    },
    {
      prop: 'actions',
      hide: orgRole !== 'administrator',
      template: (row) =>
        !row.data.is_default && (
          <WalButton
            iconLeft={'edit'}
            onClick={() => editAccount(row.data)}
            size={'small'}
            variant={'secondary'}
            ariaLabel={uiTranslations.EDIT}
          />
        ),
    },
  ];

  const accountOptions: OptionGridOption<any, ResourceAccountType>[] = [
    {
      name: accountsTranslations.GCP,
      id: 'gcp',
      icon: 'gcp',
      testingId: 'qa-gcp-button',
    },
    {
      name: accountsTranslations.AWS,
      id: 'aws',
      icon: 'aws',
    },
    {
      name: accountsTranslations.AIVEN,
      id: 'aiven',
      icon: 'aiven',
    },
    {
      name: accountsTranslations.AZURE,
      id: 'azure',
      icon: 'azure',
    },
    {
      name: accountsTranslations.CLOUDFLARE,
      id: 'cloudflare',
      icon: 'cloudflare',
    },
  ];

  const handleAccountOptionClick = (id: ResourceAccountType) => {
    setAccountTypeToAdd(id);
    setAccountModalOpen(true);
  };

  const filterAccounts = (value: string) => {
    setFilteredAccounts(
      [...resourceAccounts].filter((resourceAccount) =>
        resourceAccount.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <SectionsSettingWrapper>
      <SectionTopBarWrapper>
        <AddAccountsTitle>{accountsTranslations.ADD_ACCOUNTS}</AddAccountsTitle>
        <OptionGrid<any, ResourceAccountType>
          options={accountOptions}
          onOptionClick={handleAccountOptionClick}
          itemMinWidth={150}
        />
      </SectionTopBarWrapper>

      {resourceAccounts.length > 1 && (
        <SectionTopBarSearchField
          name={'filter-accounts'}
          placeholder={accountsTranslations.ACCOUNTS_FILTER_PLACEHOLDER}
          onChange={filterAccounts}
        />
      )}

      <AccountsTable
        caption={accountsTranslations.TABLE_CAPTION}
        columns={accountColumns}
        disableScrolling
        rows={
          filteredAccounts
            ? filteredAccounts?.map(
                (resourceAccount): WalTableRow => ({
                  data: resourceAccount,
                  isNew: lastCreatedResourceAccountIds.includes(resourceAccount.id),
                })
              )
            : []
        }
      />
      <AddAccountModal
        className={'qa-provider-modal'}
        key={editingAccount?.id}
        editingAccountState={[editingAccount, setEditingAccount]}
        openState={[accountModalOpen, setAccountModalOpen]}
        accountType={editingAccount ? editingAccount.type : accountTypeToAdd}
      />
    </SectionsSettingWrapper>
  );
};

export default Accounts;
