import { datadogRum } from '@datadog/browser-rum';
import { Spinner, WalButton, WalCard, WalModal } from '@humanitec/ui-components';
import { rem } from 'polished';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { DeploymentObject } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import makeRequest from '@src/utilities/make-request';
import { capitalize } from '@src/utilities/string-utility';

const GenerateManifestContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${units.margin.lg};
`;

const DeploymentItem = styled(WalCard)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.color.baseDarker};
  margin: ${units.margin.lg} 0;
`;

const DeploymentComment = styled.div`
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.sm};
`;

const DeploymentId = styled.span`
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-left: ${units.margin.sm};
`;

const DeploymentDate = styled.span`
  display: inline-flex;
  font-size: ${units.fontSize.sm};
  align-items: center;
  margin-top: ${units.margin.sm};
`;

const GeneratingManifestRow = styled.div`
  font-size: ${units.fontSize.sm};
  display: flex;
  justify-content: space-between;
  padding: ${units.padding.md};
  border: ${rem(1)} solid ${({ theme }) => theme.color.baseOutline};
  border-radius: ${rem(3)};
  align-items: center;
  width: 100%;
`;

const GeneratingSpinner = styled(Spinner)`
  margin-right: ${units.margin.md};
`;

interface ExportManifestModalProps {
  state: [boolean, Dispatch<SetStateAction<boolean>>];
  deployment: DeploymentObject;
}

const ExportManifestModal = ({ state, deployment }: ExportManifestModalProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const viewApplicationTranslations = t('VIEW_APPLICATION');

  // Conponent state
  const [modalOpen, setModalOpen] = state;
  const [fileToDownload, setFileToDownload] = useState<any>(null);
  const [preparingFile, setPreparingFile] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  useEffect(() => {
    makeRequest(`get`, `/orgs/${orgId}/apps/${appId}/envs/${envId}/deploys/${deployment.id}/export`)
      .then((res) => {
        setFileToDownload(res.data);
      })
      .catch((error) => {
        datadogRum.addError(error); // report error to datadog
        setErrorMessage(error?.response?.data?.message);
      })
      .finally(() => setPreparingFile(false));
  }, [deployment, envId, appId, orgId]);

  const close = () => {
    setModalOpen(false);
  };

  return (
    <WalModal
      title={viewApplicationTranslations.EXPORT_DEPLOYMENT_MANIFEST}
      subTitle={`${envId} environment`}
      openState={[modalOpen, setModalOpen]}
      size={'large'}
      content={
        <>
          <DeploymentItem padding={'small'}>
            <div>
              <DeploymentDate>
                {formatDate(
                  deployment.status_changed_at,
                  DATE_FORMATS_TYPES.DATE_MONTH_HOUR_MINUTE
                )}
              </DeploymentDate>
              <DeploymentId>{deployment.id}</DeploymentId>
            </div>
            <DeploymentComment>{deployment.comment || uiTranslations.NO_COMMENT}</DeploymentComment>
          </DeploymentItem>
          <GenerateManifestContainer>
            {preparingFile && (
              <GeneratingManifestRow>
                <div className={'flex-centered'}>
                  <GeneratingSpinner size={'small'} />
                  <span>{viewApplicationTranslations.PREPARING_FILE}</span>
                </div>
              </GeneratingManifestRow>
            )}
            {errorMessage && !preparingFile && (
              <GeneratingManifestRow>
                <div className={'flex-centered'}>
                  <span>{capitalize(errorMessage)}</span>
                </div>
              </GeneratingManifestRow>
            )}
            {fileToDownload && !preparingFile && (
              <GeneratingManifestRow>
                {fileToDownload && (
                  <React.Fragment>
                    <span>{fileToDownload?.name}</span>
                    <span>{fileToDownload?.size}</span>
                    <span>
                      {formatDate(
                        fileToDownload.created,
                        DATE_FORMATS_TYPES.DATE_MONTH_HOUR_MINUTE,
                        true
                      )}
                    </span>
                  </React.Fragment>
                )}
                <WalButton
                  variant={'secondary'}
                  size={'small'}
                  disabled={!fileToDownload?.url}
                  link={{
                    href: fileToDownload?.url,
                  }}
                  download={fileToDownload?.name}>
                  {uiTranslations.DOWNLOAD}
                </WalButton>
              </GeneratingManifestRow>
            )}
          </GenerateManifestContainer>
        </>
      }
      actions={{
        main: {
          text: uiTranslations.CLOSE,
          props: {
            onClick: close,
            type: 'submit',
          },
        },
      }}
    />
  );
};

export default ExportManifestModal;
