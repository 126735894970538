import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import useParamsFromParent from '@src/hooks/react-router/useParamsFromParent';
import { PipelineRunJob } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelineRunsQueryKeys } from './pipelineRunsQueryKeys';

interface usePipelineRunDetailsQueryProps {
  id?: string;
}

const usePipelineRunJobsQuery = ({
  id,
}: usePipelineRunDetailsQueryProps): QueryResponse<PipelineRunJob[]> => {
  const { orgId, ...params } = useParams<keyof MatchParams>() as MatchParams;
  const { appId: parentAppId, pipelineId: parentPipelineId } = useParamsFromParent();

  const appId = params.appId || parentAppId;
  const pipelineId = params.pipelineId || parentPipelineId;

  const { data, ...queryResult } = useQuery({
    queryKey: pipelineRunsQueryKeys.pipelineRunJobs(orgId, appId, pipelineId, id),
    queryFn: () => {
      return makeRequest<PipelineRunJob[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/runs/${id}/jobs`
      );
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(id && orgId && appId && pipelineId),
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelineRunJobsQuery;
