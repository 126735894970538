import { Accordion, Text } from '@humanitec/ui-components';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SkeletonItem } from '@src/components/shared/Skeleton/SkeletonItem';
import {
  getDuration,
  getIsWaitingForApproval,
  getStatus,
  getStatusIcons,
} from '@src/containers/Orgs/PipelineRuns/utils';
import usePipelineRunJobDetailsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunJobDetailsQuery';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

import { StepDetails } from '../StepDetails/StepDetails';

interface JobDetailsProps {
  jobId: string;
}
const translations = i18next.t('PIPELINE_RUNS');

const Title = styled(Text)`
  text-transform: capitalize;
`;

const Wrapper = styled.div`
  justify-content: space-between;
`;

const CustomText = styled(Text)`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const JobDetails = ({ jobId }: JobDetailsProps) => {
  const { t } = useTranslation();
  const errorTranslations = t('ERROR');
  const { data: job, isLoading: isJobLoading } = usePipelineRunJobDetailsQuery({ jobId });

  if (isJobLoading) {
    return <SkeletonItem height={390} />;
  }

  if (!job) {
    return <CustomText color={'textTranslucent'}>{errorTranslations.JOB_NOT_FOUND}</CustomText>;
  }

  const steps = job.steps || [];

  return (
    <div className={'flex-column full-height'}>
      <Title size={'lg'}>{job.id}</Title>

      <div className={'flex-centered mt-xl'}>
        <div className={'flex-column mr-xl'}>
          <Text size={'sm'} color={'textTranslucent'} className={'mt-sm'}>
            {translations.TABLE.STATUS}
          </Text>
          <Text size={'base'} className={'mt-sm flex-centered'}>
            {getStatus(getIsWaitingForApproval(job) ? 'waiting' : job.status)}
          </Text>
        </div>
        <div className={'flex-column mr-xl'}>
          <Text size={'sm'} color={'textTranslucent'} className={'mt-sm'}>
            {translations.TABLE.STARTED}
          </Text>
          <Text size={'base'} className={'mt-sm flex-centered'}>
            {job.created_at &&
              formatDate(job.created_at, DATE_FORMATS_TYPES.HOUR_MINUTE_DATE_MONTH_YEAR)}
          </Text>
        </div>
        <div className={'flex-column mr-xl'}>
          <Text size={'sm'} color={'textTranslucent'} className={'mt-sm'}>
            {translations.STATUS.COMPLETED}
          </Text>
          <Text size={'base'} className={'mt-sm flex-centered'}>
            {job.completed_at
              ? formatDate(job.completed_at, DATE_FORMATS_TYPES.HOUR_MINUTE_DATE_MONTH_YEAR)
              : '-'}
          </Text>
        </div>
        <div className={'flex-column'}>
          <Text size={'sm'} color={'textTranslucent'} className={'mt-sm'}>
            {translations.JOBS.DURATION}
          </Text>
          <Text size={'base'} className={'mt-sm flex-centered'}>
            {getDuration(job.completed_at, job.created_at)}
          </Text>
        </div>
      </div>

      <Text size={'sm'} color={'textTranslucent'} className={'mt-xl mb-sm'}>
        {translations.STEPS}
      </Text>
      <Accordion
        items={steps.map(({ index, status, completed_at, created_at, name }) => ({
          cardStyle: 'base',
          id: `job-step-${index}`,
          headerContent: (
            <Wrapper className={'flex-centered'}>
              <span className={'flex-centered'}>
                <Text color={'text'} size={'base'} className={'flex-centered'}>
                  {getStatusIcons(16)[status]} {name || `${translations.STEP} ${index + 1}`}
                </Text>
              </span>
              <Text size={'sm'} color={'textTranslucent'}>
                {getDuration(completed_at, created_at)}
              </Text>
            </Wrapper>
          ),
          content: <StepDetails jobId={job.id} stepIndex={index} />,
        }))}
      />
    </div>
  );
};
