import { ComboSelectMenuItem, Text } from '@humanitec/ui-components';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  ListItem,
  ListItemText,
} from '@src/components/shared/MatchingCriteria/AddNewCriteria/components/NewCriteriaRowEntry';
import MatchingCriteria from '@src/components/shared/MatchingCriteria/MatchingCriteria';
import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useApplicationEnvironmentsQuery from '@src/hooks/react-query/environments/queries/useApplicationEnvironmentsQuery';
import useCreatePipelineCriteriaMutation from '@src/hooks/react-query/pipelines/mutation/useCreatePipelineCriteriaMutation';
import useDeletePipelineCriteriaMutation from '@src/hooks/react-query/pipelines/mutation/useDeletePipelineCriteriaMutation';
import usePipelineCriteriaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineCriteriaQuery';
import usePipelineDetailsQuery from '@src/hooks/react-query/pipelines/queries/usePipelineDetailsQuery';
import { EnvironmentType } from '@src/models/environment-type';
import { PipelineCriteria } from '@src/models/pipeline';
import { units } from '@src/styles/variables';

import { defaultDropdownOptions } from './utils';

const CustomText = styled(Text)`
  line-height: ${rem(20)};

  a {
    font-size: ${units.fontSize.base};
  }
`;

export const PipelineMatchingCriteria = () => {
  const { pipelineId, pipelineVersionId, appId } = useParams();

  const [deleteConflictsModalOpen, setDeleteConflictsModalOpen] = useState(false);
  const [criteriaToDelete, setCriteriaToDelete] = useState<PipelineCriteria | null>(null);

  const { t } = useTranslation();
  const translations = t('MATCHING_CRITERIA');
  const pipelineTranslations = t('PIPELINES');

  // React query
  const { data: envTypes = [] } = useEnvironmentTypesQuery();
  const { data: envs = [] } = useApplicationEnvironmentsQuery();
  const {
    mutate: createMatchingCriteria,
    isPending: isCriteriaCreating,
    isSuccess: isCriteriaCreated,
    error,
    reset,
  } = useCreatePipelineCriteriaMutation();

  const { data: pipelineCriteria = [] } = usePipelineCriteriaQuery({
    pipeline_id: pipelineId,
    env_id: undefined,
  });
  const { data: pipeline } = usePipelineDetailsQuery({
    id: pipelineId,
    versionId: pipelineVersionId,
    appId,
  });

  const {
    mutateAsync: deleteCriteria,
    isPending: isDeletingCriteria,
    error: deleteCriteriaError,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useDeletePipelineCriteriaMutation();

  useEffect(() => {
    if (isDeletedSuccessfully) {
      setDeleteConflictsModalOpen(false);
      resetDeleteMutation();
    }
  }, [isDeletedSuccessfully, resetDeleteMutation]);

  useEffect(() => {
    if (isCriteriaCreated) {
      reset();
    }
  }, [isCriteriaCreated, reset]);

  const EnvTypeItems = envTypes.map((e: EnvironmentType) => ({
    label: e.id,
    value: e.id,
    id: e.id,
    searchString: e.id,
    overflowHidden: true,
    component: (
      <ListItem>
        <ListItemText>{e.id}</ListItemText>
      </ListItem>
    ),
  }));

  const environmentItems = envs.map((e) => ({
    label: e.id,
    value: e.id,
    id: e.id,
    searchString: e.id,
    overflowHidden: true,
    component: (
      <ListItem>
        <ListItemText>{e.id}</ListItemText>
      </ListItem>
    ),
  }));

  const itemsMap: Partial<Record<keyof PipelineCriteria, ComboSelectMenuItem<string>[]>> = {
    env_type: EnvTypeItems,
    env_id: environmentItems,
    deployment_type: [
      {
        label: 'deploy',
        value: 'deploy',
        id: 'deploy',
        searchString: 'deploy',
        overflowHidden: true,
        component: (
          <ListItem>
            <ListItemText>{translations.DEPLOY}</ListItemText>
          </ListItem>
        ),
      },
      {
        label: 're-deploy',
        value: 're-deploy',
        id: 're-deploy',
        searchString: 're-deploy',
        overflowHidden: true,
        component: (
          <ListItem>
            <ListItemText>{translations.REDEPLOY}</ListItemText>
          </ListItem>
        ),
      },
    ],
  };

  const handleDeleteCriteria = () => {
    if (criteriaToDelete) {
      deleteCriteria({ criteriaId: criteriaToDelete.id });
    }
  };

  const handleDeleteButtonClick = (criteria: PipelineCriteria) => {
    setDeleteConflictsModalOpen(true);
    setCriteriaToDelete(criteria);
  };

  const submitCriteria = (criteria: Partial<PipelineCriteria>) => {
    const { env_type, deployment_type, env_id } = criteria;
    const payload: Partial<PipelineCriteria> = {
      env_type: env_type === '*' ? '' : env_type,
      env_id: env_id === '*' ? '' : env_id,
      deployment_type: deployment_type === '*' ? '' : deployment_type,
    };
    createMatchingCriteria({
      criteria: payload,
    });
  };

  return (
    <MatchingCriteria<PipelineCriteria>
      description={
        <CustomText size={'base'}>
          <Trans defaults={pipelineTranslations.MATCHING_CRITERIA.DESCRIPTION} />
        </CustomText>
      }
      initialCriteria={[{ label: 'Environment type', key: 'env_type', value: 'env_type' }]}
      onSubmitCriteria={submitCriteria}
      isCriteriaCreating={isCriteriaCreating}
      isCriteriaCreated={isCriteriaCreated}
      addCriteriaDescription={pipelineTranslations.MATCHING_CRITERIA.AVAILABLE_WHERE_TEXT}
      submitCriteriaError={error}
      comboSelectItemsMap={itemsMap}
      defaultDropdownOptions={defaultDropdownOptions}
      criteriaList={pipelineCriteria}
      onDeleteConfirmed={handleDeleteCriteria}
      deleteCriteriaError={deleteCriteriaError}
      isDeletingCriteria={isDeletingCriteria}
      confirmDeleteModalState={[deleteConflictsModalOpen, setDeleteConflictsModalOpen]}
      onDeleteButtonClicked={handleDeleteButtonClick}
      criteriaToDelete={criteriaToDelete}
      section={'pipelines'}
      confirmDeleteModalContent={
        <Trans
          defaults={pipelineTranslations.MATCHING_CRITERIA.DELETE_WARNING}
          values={{ pipelineName: pipeline?.name }}>
          <i />
        </Trans>
      }
    />
  );
};
