import { IconNames } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@src/hooks/useFeature';
import { OrgRoles } from '@src/models/role';
import {
  generateAppsListURL,
  generateResourcesUrl,
  generateSettingsURL,
} from '@src/utilities/navigation';

import { useGetUserRoles } from './useGetUserRoles';

interface LinkProps {
  to: string;
  label: string;
  icon: IconNames;
  endOfGroup?: boolean;
  roles: OrgRoles[];
}

/**
 * Hook which returns the navigation list for navbar. This is used for 2 purposes:
 * 1. Rendering the items on the NavigationBar component.
 * 2. Rendering the items on the "Org level settings" pages e.g. Resource definitions, Registries etc.
 *
 * This hook takes care of handling RBAC for each item.
 */
export const useGetNavigationPermissions = (orgId: string) => {
  // i18n
  const { t } = useTranslation();
  const translations = t('NAVIGATION');

  // Optimizely
  const [isNewServiceUsersEnabled] = useFeature('service_users');

  // Selectors
  const { orgRole } = useGetUserRoles({ orgId });

  const items: LinkProps[] = [
    {
      to: generateAppsListURL(orgId),
      label: translations.APPLICATIONS,
      icon: 'applications',
      roles: ['administrator', 'manager', 'member'],
      endOfGroup: true,
    },
    {
      to: generateResourcesUrl(orgId),
      label: translations.RESOURCES,
      icon: 'resources',
      roles: ['administrator'],
    },
    {
      to: generateSettingsURL(orgId, 'registries'),
      label: translations.REGISTRIES,
      icon: 'registries',
      roles: ['administrator'],
    },
    {
      to: generateSettingsURL(orgId, 'environment-types'),
      label: translations.ENVIRONMENT_TYPES,
      icon: 'environment-types',
      roles: ['administrator', 'manager'],
    },
    {
      to: generateSettingsURL(orgId, 'accounts'),
      label: translations.CLOUD_ACCOUNTS,
      icon: 'cloud-accounts',
      roles: ['administrator', 'manager'],
    },
    {
      to: generateSettingsURL(orgId, 'images'),
      label: translations.CONTAINER_IMAGES,
      icon: 'container-images',
      roles: ['administrator', 'manager'],
    },
    {
      to: generateSettingsURL(orgId, isNewServiceUsersEnabled ? 'service-users' : 'api-tokens'),
      label: isNewServiceUsersEnabled ? translations.SERVICE_USERS : translations.API_TOKENS,
      icon: 'api-tokens',
      roles: isNewServiceUsersEnabled ? ['administrator', 'manager'] : ['administrator'],
    },
    {
      to: generateSettingsURL(orgId, 'org-members'),
      label: translations.ORG_MEMBERS,
      icon: 'org-members',
      roles: ['administrator', 'manager'],
      endOfGroup: true,
    },
  ];

  return items.filter((item) => item.roles.includes(orgRole));
};
