import { WarningSection } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useUserDetailQuery from '@src/hooks/react-query/user/useUserDetailQuery';

interface AdministratorWarningProps {
  type: 'env-type' | 'application';
}

const AdministratorWarning = ({ type }: AdministratorWarningProps) => {
  const { data: userDetail } = useUserDetailQuery();
  const { t } = useTranslation('orgMembers');
  const translations = t('ADMINISTRATOR_WARNING');

  return (
    userDetail?.role === 'administrator' && (
      <WarningSection showIcon mode={'info'}>
        <span className={'mb-md txt-md'}>
          {type === 'application'
            ? translations.APP_OWNER_PERMISSION
            : translations.ENV_TYPE_DEPLOYER_PERMISSION}
        </span>
        <span>
          <span className={'txt-sm txt-translucent'}>{translations.READ_MORE_RBAC}</span>{' '}
          <Link
            target={'_blank'}
            to={'https://developer.humanitec.com/platform-orchestrator/security/rbac/'}>
            {translations.IN_OUR_DOCUMENTATION}
          </Link>
        </span>
      </WarningSection>
    )
  );
};

export default AdministratorWarning;
