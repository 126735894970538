import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './i18n/i18n';

import AppRoot from './AppRoot';
import * as serviceWorker from './serviceWorker';
import { initGtm } from './utilities/tracking/gtm/gtm';
import { initMaze } from './utilities/tracking/maze';

initGtm();
initMaze();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <AppRoot />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
