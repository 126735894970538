import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { apiTokensQueryKeys } from '../apiTokensQueryKeys';

interface ApiTokensDeleteMutationVariables {
  tokenId: string;
}

/*
 * Mutation to delete api tokens that is scoped to an organization
 * The endpoint used here is deprecated and this functionality would be removed when the new server users epic is released.
 */
const useApiTokensDeleteMutations = () => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse,
    AxiosError,
    ApiTokensDeleteMutationVariables
  >({
    mutationFn: ({ tokenId }) => makeRequest('DELETE', `/tokens/${tokenId}?org_id=${orgId}`),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: apiTokensQueryKeys.listApiTokensByOrg(orgId),
      });
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useApiTokensDeleteMutations;
