import { Spinner, WalButton } from '@humanitec/ui-components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import ErrorPage from '@src/components/shared/ErrorPage/ErrorPage';
import useAcceptInviteMutation from '@src/hooks/react-query/user/useAcceptInviteMutation';
import useLoadInviteQuery from '@src/hooks/react-query/user/useLoadInviteQuery';
import { cl } from '@src/styles/global-styles';
import { setInviteToken as setInviteTokenLocalStorage } from '@src/utilities/local-storage';
import { generateAppURL } from '@src/utilities/navigation';
import { getUrlParam } from '@src/utilities/url-params';

import SignUpForm from '../../components/SignUpForm/SignUpForm';

const AcceptInvite = () => {
  // Component state
  const [inviteToken, setInviteToken] = useState<string | undefined>();

  // Router hooks
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: invite,
    isLoading: isLoadingInvite,
    isSuccess: isLoadedInvite,
  } = useLoadInviteQuery(inviteToken);

  const {
    mutate: acceptInvite,
    isSuccess,
    isPending: isAcceptingInvite,
  } = useAcceptInviteMutation();

  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');
  const title = t('AUTHENTICATE.SIGN_UP_TO_ORG', { organizationName: invite?.org?.name });
  const joiningOrgTitle = t('AUTHENTICATE.JOINING_ORG', {
    organizationName: invite?.org?.name,
  });

  useEffect(() => {
    const token = getUrlParam(location.search, 'inviteToken');
    if (token) {
      setInviteToken(token);
      // Set in localstorage
      setInviteTokenLocalStorage(token);
    }
  }, [location.search]);

  useEffect(() => {
    if (isSuccess && invite?.org.id) {
      navigate(generateAppURL(invite?.org.id));
    }
  }, [isSuccess, navigate, invite]);

  const googleAuthSuccess = useCallback(
    (gToken: string): void => {
      if (invite) {
        acceptInvite({
          invite,
          payload: {
            provider: 'google',
            token: gToken,
            // This can be hardcoded to true as we don't allow the this callback to run if the checkbox is not accepted. See `GoogleButton` component.
            privacy_policy_accepted: true,
          },
        });
      }
    },
    [acceptInvite, invite]
  );

  const navigateToLogin = () => {
    navigate('/auth/login');
  };

  return (
    <>
      {isLoadingInvite ? (
        <Spinner />
      ) : invite && isAcceptingInvite ? (
        <div {...cl('flex', 'align-center')}>
          <Spinner />
          {joiningOrgTitle}
        </div>
      ) : isLoadedInvite && invite?.status === 'pending' ? (
        <>
          <h1 {...cl('mb-xl', 'txt-center', 'm-0')}>{authTranslations.ACCEPT_INVITE}</h1>
          <div {...cl('my-lg', 'mx-0', 'txt-sm')}>{title}</div>
          <SignUpForm isInvite googleAuthSuccess={googleAuthSuccess} />
        </>
      ) : invite?.status === 'expired' ? (
        <ErrorPage
          title={authTranslations.INVITE_EXPIRED}
          descriptionTexts={[
            authTranslations.INVITE_EXPIRED_DESC_1,
            authTranslations.INVITE_EXPIRED_DESC_2,
          ]}
          buttons={
            <WalButton onClick={navigateToLogin}>{authTranslations.BACK_TO_LOGIN}</WalButton>
          }
        />
      ) : (
        (!invite || invite?.status === 'done') && (
          <ErrorPage
            title={authTranslations.INVITE_NOT_FOUND}
            descriptionTexts={[
              authTranslations.INVITE_NOT_FOUND_DESC_1,
              authTranslations.INVITE_NOT_FOUND_DESC_2,
            ]}
            buttons={
              <WalButton onClick={navigateToLogin}>{authTranslations.BACK_TO_LOGIN}</WalButton>
            }
          />
        )
      )}
    </>
  );
};

export default AcceptInvite;
