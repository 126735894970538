import { useQueryClient } from '@tanstack/react-query';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import AppHeader from '@src/components/shared/AppHeader/AppHeader';
import ErrorBoundary from '@src/components/shared/ErrorBoundary/ErrorBoundary';
import ErrorPage from '@src/components/shared/ErrorPage/ErrorPage';
import LoadingBar from '@src/components/shared/LoadingBar';
import NavigationBar from '@src/components/shared/NavigationBar/NavigationBar';
import { PrivateRouteForOrgRole } from '@src/components/shared/PrivateRouteForOrgRole';
import { NotificationsSocketContext } from '@src/components/socket/contexts';
import { WebSocketEventTypes } from '@src/components/socket/event-types';
import SocketProvider from '@src/components/socket/socketProvider';
import { WebSocketMessageEvent } from '@src/components/socket/web-socket-message';
import Accounts from '@src/containers/Orgs/Accounts/Accounts';
import ApiTokens from '@src/containers/Orgs/ApiTokens/ApiTokens';
import EnvironmentTypes from '@src/containers/Orgs/EnvironmentTypes/EnvironmentTypes';
import ManageImages from '@src/containers/Orgs/ManageImages/ManageImages';
import Registries from '@src/containers/Orgs/Registries/containers/Registries/Registries';
import ServiceUsers from '@src/containers/Orgs/ServiceUsers/ServiceUsers';
import { windowEnv } from '@src/environment';
import { applicationsQueryKeys } from '@src/hooks/react-query/applications/applicationsQueryKeys';
import useArtefactsQuery from '@src/hooks/react-query/artefacts/queries/useArtefactsQuery';
import useArtefactVersionsQuery from '@src/hooks/react-query/artefacts/queries/useArtefactVersionsQuery';
import { environmentQueryKeys } from '@src/hooks/react-query/environments/environmentQueryKeys';
import useGetOrgsQuery from '@src/hooks/react-query/organisations/queries/useGetOrgsQuery';
import { pipelineRunsQueryKeys } from '@src/hooks/react-query/pipeline-runs/queries/pipelineRunsQueryKeys';
import { pipelinesQueryKeys } from '@src/hooks/react-query/pipelines/pipelinesQueryKeys';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';
import { useDatadogReplay } from '@src/hooks/useDatadogReplay';
import { useFeature } from '@src/hooks/useFeature';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { MatchParams } from '@src/models/routing';
import { setSelectedOrganization } from '@src/utilities/local-storage';
import { generateAppURL, generateSettingsURL } from '@src/utilities/navigation';

import Apps from './Apps/Apps';
import OrgMembers from './OrgMembers/OrgMembers';
import OrgsRoot from './OrgsRoot';
import ResourcesRoot from './Resources/ResourcesRoot';

const Wrapper = styled.div`
  display: flex;
  grid-template-columns: ${rem(300)} 1fr;
  flex: 1;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LoadingStateContainer = styled.div`
  margin: auto auto;
  text-align: center;
`;

const RedirectToSectionBasedOnRole = () => {
  // Walhall context
  const { orgRole } = useGetUserRoles();

  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  return orgRole && orgRole === 'member' ? (
    <Navigate to={generateSettingsURL(orgId, 'environment-types')} />
  ) : orgRole ? (
    <Navigate to={generateSettingsURL(orgId, 'images')} />
  ) : (
    <></>
  );
};

const Orgs = () => {
  // router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const navigate = useNavigate();

  // i18n
  const { t } = useTranslation();
  const errorTranslations = t('ERROR');
  const UITranslations = t('UI');

  // Optimizely
  const [isNewServiceUsersEnabled] = useFeature('service_users');

  // Context
  const { data: orgs = [] } = useGetOrgsQuery();

  // State
  const [artefactIdFromWebSocket, setArtefactIdFromWebSocket] = useState<string>('');

  // React query
  const queryClient = useQueryClient();
  const { refetch: refetchOrgRoles } = useOrgRolesQuery();
  const {
    data: user,
    refetch: refetchCurrentUser,
    isLoading: userLoading,
  } = useGetCurrentUserQuery();
  useArtefactsQuery();

  // @TODO: Look into this implementaion more. If artefactIdFromWebSocket stays the same, the query might not re-run unless archived pararmater changes.
  useArtefactVersionsQuery(artefactIdFromWebSocket, false, !!artefactIdFromWebSocket); // this query would only be enabled when artefactIdFromWebSocket has a value

  useDatadogReplay();

  useEffect(() => {
    const findOrgIdFromUrl = orgs.find((org) => org.id === orgId);
    // redirect to appRoot if the org doesn't exist
    if (!findOrgIdFromUrl && orgs?.[0]?.id) {
      navigate(generateAppURL(orgs[0].id));
    }
  }, [navigate, orgId, orgs]);

  useEffect(() => {
    setSelectedOrganization(orgId);
  }, [orgId]);

  const handleWebSocketMessage = (message: WebSocketMessageEvent) => {
    const parsedMessage = JSON.parse(message.data);
    const data = parsedMessage.data;
    if (data.organization_id === orgId) {
      // loads the artefacts versions if we get an event that an artefact version has been added or updated
      if (
        parsedMessage.type === WebSocketEventTypes.ARTEFACT_VERSION_ADDED ||
        parsedMessage.type === WebSocketEventTypes.ARTEFACT_VERSION_UPDATED ||
        parsedMessage.type === WebSocketEventTypes.BUILD_ADDED
      ) {
        setArtefactIdFromWebSocket(data.artefact_id);
      }
      // load applications if an application is created or deleted
      if (
        parsedMessage.type === WebSocketEventTypes.APP_CREATED ||
        parsedMessage.type === WebSocketEventTypes.APP_DELETED
      ) {
        queryClient.invalidateQueries({
          queryKey: applicationsQueryKeys.list(data.organization_id),
        });
      }
      if (parsedMessage.type === WebSocketEventTypes.PIPELINE_UPDATED) {
        if (parsedMessage.routing_key === `organization.${data.organization_id}.pipeline.updated`) {
          queryClient.invalidateQueries({
            queryKey: pipelinesQueryKeys.details(
              data.organization_id,
              data.application_id,
              data.pipeline_id
            ),
          });
        }
        queryClient.invalidateQueries({
          queryKey: pipelinesQueryKeys.list(data.organization_id, data.application_id),
        });
      }
      if (parsedMessage.type === WebSocketEventTypes.PIPELINE_RUN_UPDATED) {
        if (
          parsedMessage.routing_key === `organization.${data.organization_id}.pipeline.run.created`
        ) {
          queryClient.invalidateQueries({
            queryKey: environmentQueryKeys.listEnvironmentDeployments(
              data.organization_id,
              data.application_id,
              data.environment_id
            ),
          });
        }
        if (
          parsedMessage.routing_key ===
          `organization.${data.organization_id}.pipeline.run.completed`
        ) {
          queryClient.invalidateQueries({
            queryKey: environmentQueryKeys.applicationEnvironments(
              data.organization_id,
              data.application_id
            ),
          });
        }
        if (
          parsedMessage.routing_key === `organization.${data.organization_id}.pipeline.run.updated`
        ) {
          queryClient.invalidateQueries({
            queryKey: pipelineRunsQueryKeys.pipelineRunDetails(
              data.organization_id,
              data.application_id,
              data.pipeline_id,
              data.run_id
            ),
          });
        }

        queryClient.invalidateQueries({
          queryKey: pipelineRunsQueryKeys.pipelineRuns(
            data.organization_id,
            data.application_id,
            data.pipeline_id
          ),
        });
      }
      if (parsedMessage.type === WebSocketEventTypes.PIPELINE_JOB_UPDATED) {
        queryClient.invalidateQueries({
          queryKey: pipelineRunsQueryKeys.pipelineRunDetails(
            data.organization_id,
            data.application_id,
            data.pipeline_id,
            data.run_id
          ),
        });

        queryClient.invalidateQueries({
          queryKey: pipelineRunsQueryKeys.pipelineRunJobs(
            data.organization_id,
            data.application_id,
            data.pipeline_id,
            data.run_id
          ),
        });

        if (data.status === 'failed' || data.status === 'success') {
          queryClient.invalidateQueries({
            queryKey: environmentQueryKeys.all(data.organization_id, data.application_id),
          });
        }
      }
      if (
        parsedMessage.type === WebSocketEventTypes.USER_UPDATED ||
        parsedMessage.type === WebSocketEventTypes.USER_DELETED ||
        parsedMessage.type === WebSocketEventTypes.USER_CREATED
      ) {
        refetchOrgRoles();
        // refetch current user to get possibly updated roles
        refetchCurrentUser();
      }
    }
  };

  return (
    <Wrapper>
      <NavigationBar />
      <ErrorBoundary>
        <SocketProvider
          url={`${windowEnv.BASE_WEBSOCKET_URL}/notifications/watch`}
          scope={orgId}
          context={NotificationsSocketContext}
          onMessage={handleWebSocketMessage}
          sendPayload={{
            event: 'organization.common',
            routing_key: `organization.${orgId}.#`,
            command: 'listen',
            data: {
              organization_id: orgId,
            },
          }}>
          {userLoading || !user?.id ? (
            <>
              <LoadingBar />
              <LoadingStateContainer>
                <div>{UITranslations.LOADING}</div>
              </LoadingStateContainer>
            </>
          ) : (
            <MainContent id={'main-content'}>
              <AppHeader />
              <Routes>
                <Route path={`/`} element={<OrgsRoot />} />
                <Route path={`apps/*`} element={<Apps />} />
                <Route
                  path={`resources/*`}
                  element={
                    <PrivateRouteForOrgRole
                      allOrAny={'all'}
                      orgRoles={['administrator']}
                      redirectUrl={generateAppURL(orgId)}>
                      <ResourcesRoot />
                    </PrivateRouteForOrgRole>
                  }
                />
                <Route element={<RedirectToSectionBasedOnRole />} />
                <Route path={`environment-types`} element={<EnvironmentTypes />} />
                <Route path={`images`} element={<ManageImages />} />
                <Route path={`accounts`} element={<Accounts />} />
                <Route
                  path={`registries`}
                  element={
                    <PrivateRouteForOrgRole
                      allOrAny={'all'}
                      orgRoles={['administrator']}
                      redirectUrl={generateSettingsURL(orgId, 'images')}>
                      <Registries />
                    </PrivateRouteForOrgRole>
                  }
                />
                {isNewServiceUsersEnabled ? (
                  <Route
                    path={`service-users`}
                    element={
                      <PrivateRouteForOrgRole
                        allOrAny={'all'}
                        orgRoles={['administrator', 'manager']}
                        redirectUrl={generateSettingsURL(orgId)}>
                        <ServiceUsers />
                      </PrivateRouteForOrgRole>
                    }
                  />
                ) : (
                  <Route
                    path={`api-tokens`}
                    element={
                      <PrivateRouteForOrgRole
                        allOrAny={'all'}
                        orgRoles={['administrator']}
                        redirectUrl={generateSettingsURL(orgId, 'images')}>
                        <ApiTokens />
                      </PrivateRouteForOrgRole>
                    }
                  />
                )}
                <Route path={`org-members/*`} element={<OrgMembers />} />
                <Route path={`*/*`} element={<RedirectToSectionBasedOnRole />} />
                <Route
                  path={`app-not-found`}
                  element={<ErrorPage title={errorTranslations.APP_NOT_FOUND} />}
                />
                <Route path={'*'} element={<ErrorPage />} />
              </Routes>
            </MainContent>
          )}
        </SocketProvider>
      </ErrorBoundary>
    </Wrapper>
  );
};

export default Orgs;
