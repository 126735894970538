import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import ErrorBoundary from '@src/components/shared/ErrorBoundary/ErrorBoundary';
import PrivateRoute from '@src/components/shared/PrivateRoute';
import PublicRoute from '@src/components/shared/PublicRoute/PublicRoute';
import { windowEnv } from '@src/environment';
import { cl } from '@src/styles/global-styles';

import AuthHeader from './components/AuthHeader';
import AcceptInvite from './containers/AcceptInvite/AcceptInvite';
import AccountDetails from './containers/AccountDetails/AccountDetails';
import CreateOrganization from './containers/CreateOrganization/CreateOrganization';
import GithubLogin from './containers/GithubLogin/GithubLogin';
import { GithubLoginInvite } from './containers/GithubLoginInvite/GithubLoginInvite';
import GithubRegister from './containers/GithubRegister/GithubRegister';
import { GithubRegisterInvite } from './containers/GithubRegisterInvite/GithubRegisterInvite';
import Login from './containers/Login/Login';
import SignUp from './containers/SignUp/SignUp';
import { SSOLogin } from './containers/SSOLogin/SSOLogin';

const AuthBody = styled.div`
  padding-top: 20vh;
  overflow-y: auto;
`;

const RedirectToLocal = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

const Auth = () => {
  // Router hooks
  const location = useLocation();

  return (
    <div {...cl('flex-column', 'full-width')}>
      <AuthHeader />
      <ErrorBoundary>
        <AuthBody {...cl('flex-column', 'flex-1', 'align-center')}>
          <Routes>
            <Route
              path={`signup`}
              element={
                <PublicRoute>
                  <SignUp />
                </PublicRoute>
              }
            />
            <Route
              path={`login`}
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path={`sso-login`}
              element={
                <PublicRoute>
                  <SSOLogin />
                </PublicRoute>
              }
            />
            <Route
              path={`github-login`}
              element={
                <PublicRoute>
                  <GithubLogin />
                </PublicRoute>
              }
            />
            <Route
              path={`github-login-invite`}
              element={
                <PublicRoute>
                  <GithubLoginInvite />
                </PublicRoute>
              }
            />
            <Route
              path={`github-register`}
              element={
                <PublicRoute>
                  <GithubRegister />
                </PublicRoute>
              }
            />
            <Route
              path={`github-register-invite`}
              element={
                <PublicRoute>
                  <GithubRegisterInvite />
                </PublicRoute>
              }
            />
            <Route path={`accept-invite`} element={<AcceptInvite />} />
            <Route
              path={`account-details`}
              element={
                <PrivateRoute>
                  <AccountDetails />
                </PrivateRoute>
              }
            />
            <Route
              path={`create-organization`}
              element={
                <PrivateRoute>
                  <CreateOrganization />
                </PrivateRoute>
              }
            />
            {/* To test locally */}
            {windowEnv.BASE_URL === 'https://dev-api.humanitec.io' && (
              <>
                <Route
                  path={`github-register-local`}
                  element={
                    <RedirectToLocal
                      url={`http://localhost:4200/auth/github-register${location.search}`}
                    />
                  }
                />
                <Route
                  path={`github-register-invite-local`}
                  element={
                    <RedirectToLocal
                      url={`http://localhost:4200/auth/github-register-invite${location.search}`}
                    />
                  }
                />
                <Route
                  path={`github-login-local`}
                  element={
                    <RedirectToLocal
                      url={`http://localhost:4200/auth/github-login${location.search}`}
                    />
                  }
                />
                <Route
                  path={`github-login-invite-local`}
                  element={
                    <RedirectToLocal
                      url={`http://localhost:4200/auth/github-login-invite${location.search}`}
                    />
                  }
                />
              </>
            )}
          </Routes>
        </AuthBody>
      </ErrorBoundary>
    </div>
  );
};

export default Auth;
