import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { NavigateFunction, useParams } from 'react-router-dom';

import { useEnvironmentCloneStore } from '@src/hooks/zustand/useEnvironmentCloneStore';
import { DeploymentObject } from '@src/models/deployment-object';
import { DeploymentSet } from '@src/models/deployment-set';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { environmentQueryKeys } from '../../environments/environmentQueryKeys';
import { deploymentDeltaQueryKeys } from '../deploymentDeltaQueryKeys';
import useDeploymentDeltaMetadataMutation from './useDeploymentDeltaMetadataMutation';

const useDeploymentDeltaDeployMutation = () => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  // zustand
  const { deleteEnvironmentClone } = useEnvironmentCloneStore();

  // react-query
  const queryClient = useQueryClient();
  const { mutate: updateDeploymentDeltaMetadata } = useDeploymentDeltaMetadataMutation();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<DeploymentSet>,
    AxiosError<string>,
    {
      deltaId: string;
      comment?: string;
      navigate?: {
        navigateFn: NavigateFunction;
        url: string;
      };
    }
  >({
    mutationFn: ({ deltaId, comment }) => {
      const deploymentPayload: Partial<DeploymentObject> = {
        delta_id: deltaId,
        comment,
      };
      return makeRequest(
        'POST',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/deploys`,
        deploymentPayload
      );
    },
    onSuccess: (_, { deltaId, navigate }) => {
      navigate?.navigateFn(navigate.url);

      // Invalidate the delta that was deployed
      updateDeploymentDeltaMetadata({
        deltaId,
        metadata: 'archived',
        value: true,
      });

      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.environmentDetail(orgId, appId, envId),
      });

      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.applicationEnvironments(orgId, appId),
      });

      deleteEnvironmentClone(appId, envId);

      // Invalidate deployments list
      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.listEnvironmentDeployments(orgId, appId, envId),
      });
      // Invalidate individual delta
      queryClient.invalidateQueries({
        queryKey: deploymentDeltaQueryKeys.detail(orgId, appId, deltaId),
      });
      // Invalidate all deltas
      queryClient.invalidateQueries({
        queryKey: deploymentDeltaQueryKeys.list(orgId, appId, { env: envId }),
      });
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useDeploymentDeltaDeployMutation;
