import { ErrorWarning, Spinner, WalModal } from '@humanitec/ui-components';
import yaml from 'js-yaml';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import DynamicForm from '@src/components/shared/DynamicForm/DynamicForm';
import { convertPipelineJSONSchemaToDynamicFormJsonSchema } from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/pipelines-utils';
import usePipelineRunCreateMutation from '@src/hooks/react-query/pipeline-runs/mutation/usePipelineRunCreateMutation';
import usePipelineDetailsQuery from '@src/hooks/react-query/pipelines/queries/usePipelineDetailsQuery';
import usePipelineSchemaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineSchemaQuery';
import { PipelineRun, PipelineYamlSchema } from '@src/models/pipeline';
import { CodePlaceholder } from '@src/styles/global-styles';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const FormWrapper = styled.div`
  margin-bottom: ${units.margin.lg};
`;

interface TriggerPipelineRunModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  pipelineId: string;
  pipelineRun?: PipelineRun;
  pipelineName?: string;
}
const TriggerPipelineRunModal = ({
  openState,
  pipelineId,
  pipelineRun,
  pipelineName,
}: TriggerPipelineRunModalProps) => {
  // React Query
  const { appId } = useParams();
  const { data: schema = '', isLoading: schemaIsLoading } = usePipelineSchemaQuery(undefined, {
    appId: pipelineRun?.app_id || appId,
    pipelineId,
  });

  const { data: pipeline } = usePipelineDetailsQuery({
    id: pipelineId,
    appId: pipelineRun?.app_id || appId,
  });

  const {
    mutate: createPipelineRun,
    isSuccess: createPipelineRunSuccess,
    isPending: isCreatingPipelineRun,
    error: createPipelineRunError,
  } = usePipelineRunCreateMutation(pipelineId, { appId: pipelineRun?.app_id });

  // i18n
  const { t } = useTranslation('viewApplication');
  const translations = t('PIPELINES');

  // State
  const [_, setOpen] = openState;

  const { delta_id, delta, ...rest } = pipelineRun?.inputs || {};
  const methods = useWalhallForm({
    defaultValues: {
      inputs: {
        inputs: { ...rest, delta: delta_id || delta },
      },
    },
  });

  useEffect(() => {
    if (createPipelineRunSuccess) {
      setOpen(false);
    }
  }, [createPipelineRunSuccess, setOpen]);

  const yamlSchemaJSON: any = yaml.load(schema);
  const formSchema = convertPipelineJSONSchemaToDynamicFormJsonSchema(
    yamlSchemaJSON?.on?.pipeline_call
  );

  const onFormSubmit = (formData: PipelineYamlSchema['on']['pipeline_call']) => {
    setOpen(false);
    createPipelineRun({ inputs: formData?.inputs });
  };

  useEffect(() => {
    if (formSchema && isEmpty(formSchema.properties?.inputs) && !isCreatingPipelineRun) {
      setOpen(false);
      createPipelineRun({ inputs: { inputs: {} } });
    }
  }, [createPipelineRun, formSchema, isCreatingPipelineRun, setOpen]);

  return (
    <FormProvider {...methods}>
      <WalModal
        title={`${translations.RUN_PIPELINE}: ${pipelineName || pipeline?.name || ''}`}
        openState={openState}
        handleFormSubmit={onFormSubmit}
        content={
          schemaIsLoading && !formSchema ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : formSchema ? (
            <FormWrapper>
              <DynamicForm formSchema={formSchema} prefix={'inputs'} />
              {createPipelineRunError && (
                <ErrorWarning
                  mode={'alert'}
                  code={createPipelineRunError.response?.data?.error}
                  message={createPipelineRunError.response?.data.message}
                />
              )}
            </FormWrapper>
          ) : (
            <div className={'mt-md mb-md'}>
              <Trans defaults={translations.CANNOT_TRIGGER_PIPELINE}>
                <CodePlaceholder />
              </Trans>
            </div>
          )
        }
        actions={{
          cancel: {},
          main: { text: translations.RUN, props: { type: 'submit', disabled: !formSchema } },
        }}
      />
    </FormProvider>
  );
};

export default TriggerPipelineRunModal;
