import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { isStatusMessage } from '@src/containers/Orgs/PipelineRuns/utils';
import usePipelineRunJobStepDetailsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunJobStepDetailsQuery';
import usePipelineRunsApprovalRequestsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunsApprovalRequestsQuery';
import useGetUserByIdQuery from '@src/hooks/react-query/user/useGetUserByIdQuery';
import { MatchParams } from '@src/models/routing';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

interface StepDetailsProps {
  jobId: string;
  stepIndex: number;
}

const Log = styled.div`
  white-space: pre-wrap;
  font-family: monospace;
  align-items: start;

  color: ${({ theme }) => theme.color.text};

  &.ERROR {
    color: ${({ theme }) => theme.color.alertBrighter};
  }
  &.WARN {
    color: ${({ theme }) => theme.color.yellow};
  }
  &.DEBUG {
    color: ${({ theme }) => theme.color.textTranslucent};
  }

  & > span:last-child {
    flex: 1;
  }
`;

export const StepDetails = ({ jobId, stepIndex }: StepDetailsProps) => {
  const { appId, pipelineRunId } = useParams<keyof MatchParams>() as MatchParams;
  const { t } = useTranslation();
  const translations = t('UI');

  const { data: logs = [] } = usePipelineRunJobStepDetailsQuery({
    jobId,
    index: stepIndex,
  });

  const { data: items = [] } = usePipelineRunsApprovalRequestsQuery(appId, {
    run: pipelineRunId,
  });

  const jobApproval = items.find(({ job_id }) => job_id === jobId);

  const { data: approver } = useGetUserByIdQuery(jobApproval?.approved_by || '');

  return (
    <div className={'full-height py-md'}>
      {logs.map(({ level, message, at }) => (
        <Log
          key={`${at} ${message}`}
          className={`${level} mb-md flex-row`}
          data-testid={'step-log'}>
          <span>{formatDate(at, DATE_FORMATS_TYPES.LOGS)} </span>
          <span>{level ? `[${level}]: ` : ' '}</span>
          <span>
            {message}{' '}
            {isStatusMessage(message) && approver && `${translations.BY} ${approver?.name}`}
          </span>
        </Log>
      ))}
    </div>
  );
};
