import { rem } from 'polished';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useAuthenticateUserMutation from '@src/hooks/react-query/user/useAuthenticateUserMutation';
import { useCurrentUserStore } from '@src/hooks/zustand/useCurrentUserStore';
import { cl } from '@src/styles/global-styles';

import SignUpForm from '../../components/SignUpForm/SignUpForm';

const Wrapper = styled.div`
  max-width: ${rem(1000)};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 18%;
`;

const List = styled.ul`
  padding-left: ${rem(20)};
`;

const SignUp = () => {
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  const { authErrorMessage: authenticateFailedReason } = useCurrentUserStore();

  const { mutate: authenticateUser } = useAuthenticateUserMutation({
    authenticationType: 'register',
    provider: 'google',
  });

  const googleAuthSuccess = useCallback(
    (googleToken: string): void => {
      authenticateUser(googleToken);
    },
    [authenticateUser]
  );

  return (
    <Wrapper>
      <h1 {...cl('mb-xl', 'txt-center')}>{authTranslations.SIGN_UP_FOR_FREE}</h1>
      <Grid>
        <div>
          <h2 {...cl('mb-lg')}>{authTranslations.WITH_HUMANITEC}</h2>
          <span>{authTranslations.EVERYONE_CAN}</span>
          <List>
            <li>{authTranslations.SPIN_UP_ENVS}</li>
            <li>{authTranslations.DEPLOY_AND_ROLLBACK}</li>
            <li>{authTranslations.WHATS_RUNNING}</li>
            <li>{authTranslations.HOOK_UP_TECH}</li>
          </List>
          {authTranslations.WITHOUT_BREAKING_ANYTHING}
        </div>
        <SignUpForm
          isInvite={false}
          googleAuthSuccess={googleAuthSuccess}
          authenticateFailedReason={authenticateFailedReason}
        />
      </Grid>
    </Wrapper>
  );
};

export default SignUp;
