import { WalDropdownMenu as DropdownMenu } from '@humanitec/ui-components';

interface MembersRoleDropdownProps {
  defaultValue: string;
  label?: string;
  fullWidth?: boolean;
}
const MembersRoleDropdown = ({ defaultValue, label, fullWidth }: MembersRoleDropdownProps) => {
  return (
    <DropdownMenu
      name={'role'}
      label={label}
      defaultValue={defaultValue}
      buttonVariant={'input'}
      fullWidth={fullWidth}
      items={[
        {
          id: 'owner',
          label: 'Owner',
          value: 'owner',
        },
        {
          id: 'developer',
          label: 'Developer',
          value: 'developer',
        },
        {
          id: 'viewer',
          label: 'Viewer',
          value: 'viewer',
        },
      ]}
      allowUpdatesToDefaultValue={false}
    />
  );
};

export default MembersRoleDropdown;
