import { Route, Routes, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import DisplayDeploymentErrors from '@src/components/shared/DisplayDeploymentErrors';
import RenderViewWorkload from '@src/components/shared/ViewWorkloadProfile/RenderViewWorkload';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import WorkloadContextWrapper from '@src/context/WorkloadContextWrapper';
import useDeploymentErrorsQuery from '@src/hooks/react-query/environments/queries/useDeploymentErrorsQuery';
import { MatchParams } from '@src/models/routing';
import { containerStyle } from '@src/styles/mixins';

import ViewDeploymentOrDeltaTabs from './components/ViewDeploymentOrDeltaTabs/ViewDeploymentOrDeltaTabs';
import ViewDeploymentPageHeader from './components/ViewDeploymentPageHeader';

const Container = styled.div`
  ${containerStyle()};
  flex-direction: column;
  flex: 1;
`;

const Wrapper = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base};
`;

const ViewDeploymentAndDeltaCommon = () => {
  // Router hooks
  const { deploymentId } = useParams<keyof MatchParams>() as MatchParams;
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // React Query
  const { data: deploymentErrors } = useDeploymentErrorsQuery(deploymentId);

  return (
    <Container>
      <ViewDeploymentPageHeader />
      {deploymentErrors && !draftModeActive && deploymentId && (
        <DisplayDeploymentErrors errors={deploymentErrors} scope={'environment'} />
      )}
      <ViewDeploymentOrDeltaTabs />
    </Container>
  );
};

const WorkloadWrapper = () => {
  return (
    <WorkloadContextWrapper>
      <Wrapper>
        <Container>
          <RenderViewWorkload />
        </Container>
      </Wrapper>
    </WorkloadContextWrapper>
  );
};

const DeploymentAndDeltaCommon = () => {
  return (
    <Routes>
      <Route path={'/*'} element={<ViewDeploymentAndDeltaCommon />} />
      <Route path={'workloads/:moduleId/*'} element={<WorkloadWrapper />} />
    </Routes>
  );
};

export default DeploymentAndDeltaCommon;
