import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import useParamsFromParent from '@src/hooks/react-router/useParamsFromParent';
import { PipelineRun } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelineRunsQueryKeys } from './pipelineRunsQueryKeys';

interface usePipelineRunDetailsQueryProps {
  id?: string;
  customPipelineId?: string;
}

const usePipelineRunDetailsQuery = ({
  id,
  customPipelineId,
}: usePipelineRunDetailsQueryProps): QueryResponse<PipelineRun> => {
  const { orgId, ...params } = useParams<keyof MatchParams>() as MatchParams;
  const { appId: parentAppId, pipelineId: parentPipelineId } = useParamsFromParent();

  const pipelineId = customPipelineId || params.pipelineId || parentPipelineId;
  const appId = params.appId || parentAppId;

  const { data, ...queryResult } = useQuery({
    queryKey: pipelineRunsQueryKeys.pipelineRunDetails(orgId, appId, pipelineId, id),
    queryFn: () => {
      return makeRequest<PipelineRun>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/runs/${id}`
      );
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(id && orgId && pipelineId && appId),
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelineRunDetailsQuery;
