/* eslint-disable no-console */
import { WalModal } from '@humanitec/ui-components';
import { Dispatch, KeyboardEvent, SetStateAction, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useDeploymentDeltaDeployMutation from '@src/hooks/react-query/deployment-delta/mutations/useDeploymentDeltaDeployMutation';
import useDeploymentDeltaQuery from '@src/hooks/react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import usePipelineDeployMutation from '@src/hooks/react-query/pipeline-runs/mutation/usePipelineDeployMutation';
import { useFeature } from '@src/hooks/useFeature';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentObject } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import { KeyBindings } from '@src/types/key-bindings';
import { diffDeploymentSets } from '@src/utilities/deployment-delta-utils';
import { useWalhallForm } from '@src/utilities/form';
import makeRequest from '@src/utilities/make-request';
import { generateAppURL } from '@src/utilities/navigation';

import { ModalContent } from './ModalContent/ModalContent';

interface DeploymentOverviewModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  deploymentType:
    | { type: 'deploy'; data: DeploymentDelta }
    | { type: 'redeploy'; data: DeploymentObject };
}

const DeploymentOverviewModal = ({ openState, deploymentType }: DeploymentOverviewModalProps) => {
  const [isPipelineRunsEnabled] = useFeature('deployments-pipelines');

  // i18n
  const { t } = useTranslation();
  const deploymentModalTranslations = t('DEPLOYMENT_MODAL');

  // Form
  const methods = useWalhallForm<{ comment: string }>();
  const { setValue, handleSubmit } = methods;

  // Component state
  const [modalOpen, setModalOpen] = openState;
  const [deltaCreationError, setDeltaCreationError] = useState<boolean>(false);
  const [generateDelta, setGenerateDelta] = useState(false);

  const [displayedDelta, setDisplayedDelta] = useState<DeploymentDelta>();

  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;
  const navigate = useNavigate();

  // React Query
  const { data: environment } = useEnvironmentQuery();
  const { data: activeDelta } = useDeploymentDeltaQuery();

  const { mutate: deployDeploymentDelta } = useDeploymentDeltaDeployMutation();
  const { mutate: deployThroughMatchedPipeline } = usePipelineDeployMutation();

  // set the delta to display the diff from
  useEffect(() => {
    if (!modalOpen) return;
    if (deploymentType.type === 'deploy') {
      // if there is an active delta(draft) display it
      setDisplayedDelta(deploymentType.data);
    } else if (activeDelta) {
      setDisplayedDelta(activeDelta);
    } else if (modalOpen) {
      // get the diff between the displayed set and the last deployed set
      if (
        !generateDelta &&
        deploymentType.type === 'redeploy' &&
        deploymentType.data?.set_id &&
        environment?.last_deploy
      ) {
        setGenerateDelta(true);
        diffDeploymentSets(
          deploymentType.data?.set_id,
          environment?.last_deploy?.set_id,
          appId,
          orgId
        ).then((diffBaseDelta) => {
          makeRequest<string>(
            // make request is used here because the delta is temporary and should not be added to the store
            'post',
            `/orgs/${orgId}/apps/${appId}/deltas`,
            diffBaseDelta
          )
            .then((createdDeltaRes) => {
              if (createdDeltaRes) {
                const delta = {
                  ...diffBaseDelta,
                  id: createdDeltaRes.data,
                };
                setDisplayedDelta(delta);
              }
            })
            .catch(() => {
              setDeltaCreationError(true);
            });
        });
      }
    }
  }, [
    activeDelta,
    environment,
    appId,
    orgId,
    generateDelta,
    setGenerateDelta,
    deploymentType.type,
    deploymentType.data,
    modalOpen,
  ]);

  const deploy = (formState: { comment: string }) => {
    if (displayedDelta && environment) {
      setValue('comment', '');

      if (isPipelineRunsEnabled) {
        deployThroughMatchedPipeline({
          deltaId: displayedDelta.id,
          comment: formState.comment,
        });
      } else {
        deployDeploymentDelta({
          deltaId: displayedDelta.id,
          comment: formState.comment,
          navigate: {
            navigateFn: navigate,
            url: generateAppURL(orgId, appId, envId),
          },
        });
      }
    }
    setModalOpen(false);
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.keyCode === KeyBindings.ENTER) {
      evt.preventDefault();
      handleSubmit(deploy)();
    }
  };

  return (
    <WalModal
      title={`${
        deploymentType.type === 'deploy'
          ? deploymentModalTranslations.DEPLOY_CHANGES_TO
          : deploymentModalTranslations.REDEPLOY_CHANGES_TO
      } ${envId}`}
      openState={[modalOpen, setModalOpen]}
      content={
        <FormProvider {...methods}>
          <ModalContent
            displayedDelta={displayedDelta}
            deltaCreationError={deltaCreationError}
            handleKeyDown={handleKeyDown}
          />
        </FormProvider>
      }
      actions={{
        main: {
          text:
            deploymentType.type === 'deploy'
              ? deploymentModalTranslations.DEPLOY
              : deploymentModalTranslations.REDEPLOY,
          props: {
            disabled: deltaCreationError || !displayedDelta,
            onClick: handleSubmit(deploy),
          },
        },
        cancel: {},
      }}
      size={'large'}
    />
  );
};

export default DeploymentOverviewModal;
