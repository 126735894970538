export const REGISTRATION_CODE = 'registration_code';

// Comma separated values. List of scopes here https://developer.github.com/apps/building-oauth-apps/understanding-scopes-for-oauth-apps/
export const GITHUB_SCOPES = 'repo';
export const GITHUB_ORGANIZATIONS = 'organizations';
export const SELECTED_GITHUB_ORGANIZATION = 'selected_org';

export const TOKEN = 'token';
export const LS_ACCESS_TOKEN = 'accessToken';
export const LS_THEME_PREFERENCES = 'preferredTheme';
export const LS_USER_PREFERENCES = 'userPreferences';
export const LS_SELECTED_ORGANIZATION = 'SELECTED_ORGANIZATION';
export const LS_NAVIGATION_MINIMIZED = 'navigationMinimized';
export const LS_INVITE_TOKEN = 'inviteToken';
export const LS_LAST_VISITED_APP = 'lastVisitedApp';

export const TRANSITION_TIMEOUT: number = 150;
