import { WalDropdownMenu, WalInput } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useRoleDropdownOptions } from '@src/hooks/useRoleDropdownOptions';
import { units } from '@src/styles/variables';

export interface VariablesViewAddEditProps {
  /* list of existing keys to validate against because you can"t add duplicate variable keys */
  existingServiceUsers?: string[];
}

const FieldInput = styled(WalInput)`
  label {
    color: ${({ theme }) => theme.color.text};
  }
  margin-bottom: ${units.margin.md};
`;

const FieldDropdown = styled(WalDropdownMenu)`
  label {
    color: ${({ theme }) => theme.color.text};
  }
`;

const ServiceUsersAddForm = ({ existingServiceUsers }: VariablesViewAddEditProps) => {
  // i18n
  const { t } = useTranslation();
  const serviceUsersTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS;
  // Custom hook
  const roleItems = useRoleDropdownOptions(true);

  return (
    <>
      <FieldInput
        labelAbove
        name={'name'}
        label={serviceUsersTranslations.NAME}
        placeholder={serviceUsersTranslations.NAME}
        required
        standardValidation={[
          { type: 'key' },
          { type: 'existingId', ids: existingServiceUsers || [] },
        ]}
      />

      <FieldDropdown
        label={serviceUsersTranslations.SELECT_A_ROLE}
        items={roleItems}
        defaultValue={'member'}
        fullWidth
        name={'role'}
      />
    </>
  );
};

export default ServiceUsersAddForm;
