import { Route, Routes } from 'react-router-dom';

import AppsList from '@src/containers/Orgs/Apps/containers/AppsList/containers/AppsList';

import App from './containers/App/App';

const Apps = () => {
  return (
    <Routes>
      <Route path={'/'} element={<AppsList />} />
      <Route path={':appId/*'} element={<App />} />
    </Routes>
  );
};

export default Apps;
