import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import {
  AuthenticationProviders,
  AuthenticationTypes,
  useCurrentUserStore,
} from '@src/hooks/zustand/useCurrentUserStore';
import { isAxiosError } from '@src/types/type-guards';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from './userQueryKeys';

interface AuthenticateUserProps {
  token?: string | null;
  provider?: AuthenticationProviders;
  authenticationType?: AuthenticationTypes;
  privacyPolicyAccepted?: boolean;
}

const useAuthenticateUserMutation = (props?: AuthenticateUserProps) => {
  const queryClient = useQueryClient();
  const { provider, authenticationType, privacyPolicyAccepted } = props || {};
  const { setAuthErrorMessage, setAuthType } = useCurrentUserStore();
  const payload = {
    provider,
    privacy_policy_accepted:
      privacyPolicyAccepted || authenticationType === 'register' ? true : undefined,
  };

  return useMutation<AxiosResponse, AxiosError, string>({
    mutationFn: (token: string) =>
      makeRequest('POST', `/auth/${authenticationType}`, { ...payload, token }),
    onSuccess: () => {
      queryClient.cancelQueries({ queryKey: userQueryKeys.currentUser() });
      queryClient.invalidateQueries({ queryKey: userQueryKeys.currentUser() });

      if (authenticationType) {
        setAuthType(authenticationType);
      }
    },
    onError: (error: AxiosError<{ message: string }> | Error) => {
      datadogRum.addError(error);

      const isFromAxios = isAxiosError(error);
      const responseCode = isFromAxios ? error.response?.status : undefined;

      if (responseCode === 400) {
        setAuthErrorMessage('bad-request');
      } else if (responseCode === 403 && provider) {
        setAuthErrorMessage(`${provider}-not-exist`);
      } else {
        setAuthErrorMessage(isFromAxios ? error.response?.data?.message : error.message);
      }
    },
  });
};

export default useAuthenticateUserMutation;
