import { MultistepModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ResourceDefinitionForm from '@src/containers/Orgs/Resources/components/ResourceDefinitionForm/ResourceDefinitionForm';
import { ResourceDriver, ResourceForm, ResourceType } from '@src/models/resources';
import { useWalhallForm } from '@src/utilities/form';

import SelectResourceDriverStep from './components/SelectResourceDriverStep';
import SelectResourceTypeStep from './components/SelectResourceTypeStep';

interface AddResourceDefinitionModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  resourceDrivers: ResourceDriver[];
  resourceTypes: ResourceType[];
}

const AddResourceDefinitionModal = ({
  resourceDrivers,
  resourceTypes,
  openState,
}: AddResourceDefinitionModalProps) => {
  // state
  const [open, setOpen] = openState;
  const [addResourceModalCurrentStep, setAddResourceModalCurrentStep] = useState<number>(0);
  const [selectedResourceType, setSelectedResourceType] = useState<ResourceType>();
  const [selectedDriver, setSelectedDriver] = useState<ResourceDriver>();
  const [resourceDefinitionCreatedOrUpdated, setResourceDefinitionCreatedOrUpdated] =
    useState<boolean>(false);
  const [resourceDefinitionCreating, setResourceDefinitionCreating] = useState<boolean>(false);

  const createButtonRef = useRef<HTMLButtonElement | null>(null);

  // Form
  const formMethods = useWalhallForm<ResourceForm>();

  // i18n
  const { t } = useTranslation();

  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;
  const uiTranslations = t('UI');

  useEffect(() => {
    if (resourceDefinitionCreatedOrUpdated) {
      setOpen(false);
    }
  }, [resourceDefinitionCreatedOrUpdated, setOpen]);

  const submitResourcesForm = async () => {
    createButtonRef.current?.click();
  };

  return (
    <MultistepModal
      dataTestId={'resource-definition-modal'}
      disableClickOutside
      steps={[
        {
          title: resourcesTranslations.ADD_RESOURCE_DEFINITION,
          actions: [
            {
              label: uiTranslations.CLOSE,
            },
          ],
          content: (
            <SelectResourceTypeStep
              resourceTypes={resourceTypes}
              setCurrentStep={setAddResourceModalCurrentStep}
              setSelectedResourceType={setSelectedResourceType}
            />
          ),
        },
        {
          title: selectedResourceType?.name || '',
          actions: [
            {
              label: uiTranslations.BACK,
            },
          ],
          content: (
            <SelectResourceDriverStep
              setCurrentStep={setAddResourceModalCurrentStep}
              selectedResourceType={selectedResourceType}
              resourceDrivers={resourceDrivers}
              setSelectedDriver={setSelectedDriver}
            />
          ),
        },
        {
          title: resourcesTranslations.CONFIGURATION,
          actions: [
            {
              label: uiTranslations.BACK,
            },
            {
              label: `${uiTranslations.ADD} ${selectedResourceType?.name}`,
              variant: 'primary',
              loading: resourceDefinitionCreating,
              callback: submitResourcesForm,
            },
          ],
          content: selectedResourceType && (
            <FormProvider {...formMethods}>
              <ResourceDefinitionForm
                resourceType={selectedResourceType}
                selectedDriver={selectedDriver}
                createButtonRef={createButtonRef}
                onResourceDefinitionCreatedOrUpdated={() =>
                  setResourceDefinitionCreatedOrUpdated(true)
                }
                onResourceDefinitionCreating={(creating) => setResourceDefinitionCreating(creating)}
              />
            </FormProvider>
          ),
        },
      ]}
      openState={[open, setOpen]}
      currentStepState={[addResourceModalCurrentStep, setAddResourceModalCurrentStep]}
    />
  );
};

export default AddResourceDefinitionModal;
