import { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import TabGroup from '../../base/TabGroup/TabGroup';

export interface RoutingItem {
  label: string;
  path: string;
  element: ReactNode;
  hide?: boolean;
  confirmOnLeave?: boolean;
  disabled?: boolean;
  hoverText?: string;
}

interface TabGroupRoutingProps {
  items: RoutingItem[];
  optionClicked?: (clickedValue: string, selectedByKeyboard: boolean) => void;
  confirmMessage?: string;
  onLeaveTabConfirm?: (value: string) => void;
}

const TabPanel = styled.div`
  height: 100%;
`;
/**
 * Renders a Tab Group with routing underneath. The tab item & route will have the same value (path).
 * The component will detect which tab is active by checking the route.
 */
const TabGroupRouting = ({
  items,
  optionClicked,
  confirmMessage,
  onLeaveTabConfirm,
}: TabGroupRoutingProps) => {
  return (
    <>
      <TabGroup
        onLeaveTabConfirm={onLeaveTabConfirm}
        confirmMessage={confirmMessage}
        optionClicked={optionClicked}
        size={'large'}
        options={items.map((item) => ({
          label: item.label,
          value: item.path,
          link: { to: item.path },
          hide: item.hide,
          disabled: item.disabled,
          hoverText: item.hoverText,
          confirmOnLeave: item.confirmOnLeave,
        }))}
      />
      <Routes>
        <Route
          path={'/'}
          element={<Navigate to={items.filter((item) => !item.hide)[0].path} replace />}
        />
        {items
          .filter((item) => !item.hide && !item.disabled)
          .map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={
                <TabPanel role={'tabpanel'} aria-labelledby={item.path}>
                  {item.element}
                </TabPanel>
              }
            />
          ))}
        <Route
          path={'*'}
          element={<Navigate to={items.filter((item) => !item.hide)[0].path} replace />}
        />
      </Routes>
    </>
  );
};

export default TabGroupRouting;
