import { ComboSelect, ComboSelectMenuItem, InputSize, MenuItem } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import UserDropdownItem from '@src/components/shared/UserDropdownItem';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import { OrgRoles, Role } from '@src/models/role';
import { useWalhallForm } from '@src/utilities/form';

export interface FilterOrgMembersProps {
  onItemClick: (item: MenuItem<Role<OrgRoles>>) => void;
  existingIds: string[];
  inputSize?: InputSize;
  className?: string;
}

const FilterOrgMembers = ({
  onItemClick,
  existingIds,
  className,
  inputSize,
}: FilterOrgMembersProps) => {
  // Component state
  const [options, setOptions] = useState<ComboSelectMenuItem<Role<OrgRoles>>[]>([]);

  // Form
  const formMethods = useWalhallForm();

  // i18n
  const { t } = useTranslation();
  const translations = t('COMPONENTS').FILTER_ORG_MEMBERS;

  // React Query
  const { data: users = [] } = useOrgRolesQuery();

  useEffect(() => {
    if (users?.length > 0) {
      setOptions(
        users
          ?.filter((user) => !existingIds.includes(user.id))
          .map((user) => ({
            label: user.name,
            component: <UserDropdownItem user={user} />,
            value: user,
            searchString: `${user.email?.toLowerCase()} ${user.name}`,
            id: user.email?.toLowerCase(),
          }))
      );
    }
  }, [users, existingIds]);

  return (
    <FormProvider {...formMethods}>
      <ComboSelect
        name={'users'}
        onItemClick={onItemClick}
        label={translations.FILTER_INPUT_LABEL}
        items={options}
        className={className}
        inputSize={inputSize}
        mode={'close-on-click'}
      />
    </FormProvider>
  );
};

export default FilterOrgMembers;
