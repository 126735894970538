import { TabGroupRouting } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ResumeEnvText from '@src/components/shared/PauseEnvironment/components/ResumeEnvText';
import EnvStatus from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/EnvStatus/EnvStatus';
import useApplicationEnvironmentsQuery from '@src/hooks/react-query/environments/queries/useApplicationEnvironmentsQuery';
import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import { useFeature } from '@src/hooks/useFeature';
import { useRBAC } from '@src/hooks/useRBAC';
import { MatchParams } from '@src/models/routing';

import DeleteEnvironment from './components/DeleteEnvironment';
import Deploys from './components/Deploys/Deploys';
import Drafts from './components/Drafts/Drafts';
import EnvAutomation from './components/EnvAutomation/EnvAutomation';
import EnvValues from './components/EnvValues/EnvValues';
import ViewEnvironmentHeader from './ViewEnvironmentHeader';

const ViewEnvironment = () => {
  // i18n
  const { t } = useTranslation('viewEnvironment');
  const tabsTranslations = t('TABS');

  // React Query
  const { data: applicationEnvironments = [] } = useApplicationEnvironmentsQuery();

  // Router hooks
  const { envId } = useParams<keyof MatchParams>() as MatchParams;
  const { data: envPauseStatusMap } = useEnvironmentPauseStatusQuery();
  const envPauseStatus = envPauseStatusMap?.[envId];

  // RBAC
  const canAccessDraftURL = useRBAC('accessDraftURL');
  const canDeleteEnvironment = useRBAC('deleteEnvironment');

  // Optimizely
  const [isEnvStatusTabEnabled] = useFeature('env_status_tab');

  return (
    <>
      <ViewEnvironmentHeader />
      {envPauseStatus && <ResumeEnvText />}
      <TabGroupRouting
        items={[
          {
            label: tabsTranslations.STATUS,
            path: 'status',
            element: <EnvStatus />,
            hide: !isEnvStatusTabEnabled,
          },
          {
            label: tabsTranslations.DEPLOYMENTS,
            path: 'deploys',
            element: <Deploys />,
          },
          {
            label: tabsTranslations.DRAFT_DELTAS,
            path: 'draft',
            hide: !canAccessDraftURL,
            element: <Drafts />,
          },
          {
            label: tabsTranslations.AUTOMATION,
            path: 'automation',
            element: <EnvAutomation />,
          },
          {
            label: tabsTranslations.SECRET_AND_VALUE_OVERRIDES,
            path: 'secret-and-value-overrides',
            element: <EnvValues />,
          },
          {
            label: tabsTranslations.DELETE_ENVIRONMENT,
            path: 'delete',
            element: <DeleteEnvironment />,
            hide: !canDeleteEnvironment || applicationEnvironments.length <= 1,
          },
        ]}
      />
    </>
  );
};

export default ViewEnvironment;
