import { Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import ResourceDefinitionConfiguration from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionConfiguration/ResourceDefinitionConfiguration';
import ResourceDefinitionUsage from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionUsage/ResourceDefinitionUsage';
import ResourceDefinition from '@src/containers/Orgs/Resources/components/ResourceDefinition/ResourceDefinition';
import { ResourceManagement } from '@src/containers/Orgs/Resources/containers/ResourceManagement/ResourceManagement';

import ResourceDefinitionMatchingCriteria from './components/ResourceDefinition/components/ResourceDefinitionMatchingCriteria/ResourceDefinitionMatchingCriteria/ResourceDefinitionMatchingCriteria';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  flex-direction: column;
`;

const ResourcesRoot = () => {
  // Context
  const location = useLocation();

  return (
    <Wrapper>
      <Routes location={location}>
        <Route path={`:defId`} element={<ResourceDefinition />}>
          <Route path={`usage`} element={<ResourceDefinitionUsage />} />
          <Route path={`matching-criteria`} element={<ResourceDefinitionMatchingCriteria />} />
          <Route path={'configuration'} element={<ResourceDefinitionConfiguration />} />
        </Route>
        <Route path={'/'} element={<ResourceManagement />} />
      </Routes>
    </Wrapper>
  );
};

export default ResourcesRoot;
