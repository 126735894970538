import { cloneDeep } from 'lodash';
import { useMemo } from 'react';

import { addActiveDeltaUpdatesToDeploymentSet } from '@src/utilities/deployment-delta-utils';

import useDeploymentDeltaQuery from './react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import useDeploymentSetQuery from './react-query/deployment-set/useDeploymentSetQuery';
import useParamsFromParent from './react-router/useParamsFromParent';

export const useGetDeploymentSetWithDeltaUpdates = () => {
  // Router hooks
  const { orgId, appId, deployOrDeltaSegment, deployOrDeltaId } = useParamsFromParent();

  const draftModeActive = deployOrDeltaSegment === 'draft';

  // React Query
  const { data: deploymentSet } = useDeploymentSetQuery({ orgId, appId });
  const { data: activeDelta } = useDeploymentDeltaQuery(
    draftModeActive ? { orgId, appId, deltaId: deployOrDeltaId } : undefined
  );

  return useMemo(
    () =>
      addActiveDeltaUpdatesToDeploymentSet(
        cloneDeep(deploymentSet) || {},
        draftModeActive,
        cloneDeep(activeDelta)
      ),
    [activeDelta, deploymentSet, draftModeActive]
  );
};
