import { createFilterConditionsDictionary, FilterConditions } from '@humanitec/ui-components';

import { ResourceDefinition } from '@src/models/resources';

/**
 * filters the resource definition according to the given filter conditions
 *
 * @param resourceDefinitions
 * @param filterConditions
 */
export const filterResourceDefinitions = (
  resourceDefinitions: ResourceDefinition[],
  filterConditions?: FilterConditions
): ResourceDefinition[] => {
  const detailsFilter = createFilterConditionsDictionary(filterConditions?.details);
  const matchingCriteriaFilter = createFilterConditionsDictionary(filterConditions?.criteria);

  return resourceDefinitions
    .filter((def) => (detailsFilter?.id ? detailsFilter.id.includes(def.id) : true))
    .filter((def) => (detailsFilter?.type ? detailsFilter?.type.includes(def.type) : true))
    .filter((def) =>
      detailsFilter?.driver_type ? detailsFilter?.driver_type?.includes(def.driver_type) : true
    )
    .filter((def) =>
      matchingCriteriaFilter?.app_id
        ? def.criteria?.some(
            (criteria) =>
              criteria.app_id && matchingCriteriaFilter?.app_id?.includes(criteria.app_id)
          )
        : true
    )
    .filter((def) =>
      matchingCriteriaFilter?.env_id
        ? def.criteria?.some(
            (criteria) =>
              criteria.env_id && matchingCriteriaFilter?.env_id?.includes(criteria.env_id)
          )
        : true
    )
    .filter((def) =>
      matchingCriteriaFilter?.env_type
        ? def.criteria?.some(
            (criteria) =>
              criteria.env_type && matchingCriteriaFilter?.env_type?.includes(criteria.env_type)
          )
        : true
    )
    .filter((def) =>
      matchingCriteriaFilter?.res_id
        ? def.criteria?.some(
            (criteria) =>
              criteria.res_id && matchingCriteriaFilter?.res_id?.includes(criteria.res_id)
          )
        : true
    );
};
