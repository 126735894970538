import { Icon, IconSizes, Spinner } from '@humanitec/ui-components';
import { differenceInSeconds } from 'date-fns';
import { t } from 'i18next';
import { ReactNode } from 'react';

import { PipelineRun, PipelineRunJob } from '@src/models/pipeline';
import {
  convertSecondsToTimeUnitsDHMS,
  DATE_FORMATS_TYPES,
  formatDate,
} from '@src/utilities/datetime/datetime';

const translations = t('PIPELINE_RUNS');

export const statuses: Record<string, string> = {
  succeeded: translations.STATUS.SUCCEEDED,
  executing: translations.STATUS.RUNNING,
  queued: translations.STATUS.QUEUED,
  failed: translations.STATUS.FAILED,
  cancelled: translations.STATUS.CANCELLED,
  waiting: translations.STATUS.WAITING,
};

/**
 * Gets the icons for the statuses
 *
 * @param size
 * @returns Icon
 */
export const getStatusIcons = (
  size?: IconSizes,
  isSelected?: boolean
): Record<string, ReactNode> => ({
  skipped: (
    <Icon
      size={size || 14}
      name={'double-right-arrow'}
      overrideColor={'text-translucent'}
      marginRight={'sm'}
    />
  ),
  created: <Icon size={size || 14} name={'checkmark'} overrideColor={'green'} marginRight={'sm'} />,
  succeeded: (
    <Icon size={size || 14} name={'checkmark'} overrideColor={'green'} marginRight={'sm'} />
  ),
  executing: <Spinner size={'small'} className={'mr-sm'} color={isSelected ? 'white' : 'text'} />,
  queued: <Icon size={size || 14} name={'queued'} overrideColor={'yellow'} marginRight={'sm'} />,
  failed: <Icon size={size || 14} name={'warning'} overrideColor={'alert'} marginRight={'sm'} />,
  cancelled: <Icon size={size || 14} name={'cross'} overrideColor={'alert'} marginRight={'sm'} />,
  waiting: <Icon size={size || 14} name={'warning'} overrideColor={'yellow'} marginRight={'sm'} />,
});

/**
 * Gets the status based on the given status string.
 *
 * @param {string} status - The status string to retrieve the status for.
 * @param {boolean} [withIcon=false] - Optional parameter to include an icon with the status.
 * @param {IconSizes} [iconSize] - Optional parameter to specify the size of the icon.
 * @returns {string | JSX.Element} The status string or JSX element representing the status, with or without an icon.
 */
export const getStatus = (status: string, withIcon = false, iconSize?: IconSizes) => {
  return withIcon ? (
    <>
      {getStatusIcons(iconSize)[status]} <span>{statuses[status]}</span>
    </>
  ) : (
    statuses[status]
  );
};

/**
 * Determines if a given PipelineRun or PipelineRunJob is waiting for approval.
 *
 * @param {PipelineRun | PipelineRunJob} runOrJob - The PipelineRun or PipelineRunJob to check.
 * @returns {boolean} Returns true if the run or job is waiting for approval; otherwise, false.
 */
export const getIsWaitingForApproval = (runOrJob: PipelineRun | PipelineRunJob) => {
  const waitingFor = JSON.stringify(runOrJob.waiting_for);
  return waitingFor.includes('approval') && runOrJob.status === 'executing';
};

/**
 * Checks if a message is a status message indicating approval or denial.
 *
 * @param {string} message - The message to check.
 * @returns {boolean} - True if the message indicates approval or denial, false otherwise.
 */
export const isStatusMessage = (message: string) => {
  return message.includes('denied') || message.includes('approved');
};

/**
 * Gets duration of pipeline run
 *
 * @param date1
 * @param date2
 * @returns difference between two dates
 */
export const getDuration = (date1: string, date2: string) => {
  const d = differenceInSeconds(new Date(date1), new Date(date2));

  return isNaN(d) ? '-' : convertSecondsToTimeUnitsDHMS(d);
};

/**
 *
 * @param applications
 * @returns array of filters
 */

/**
 * Get's search string to be used in pipeline runs request
 *
 * @param app
 * @param pipeline
 * @param status
 * @returns
 */
export const getPipelineRunsFilterString = (
  statusFilterValues?: string[],
  createBeforeDate?: string,
  createAfterDate?: string,
  page?: number,
  appId?: string,
  envId?: string
) => {
  let query = '';

  if (statusFilterValues && statusFilterValues.length > 0) {
    query += `${query ? '&' : `?`}status=${statusFilterValues.join('&status=')}`;
  }

  if (createBeforeDate) {
    query += `${query ? '&' : `?`}created_before=${formatDate(
      createBeforeDate,
      DATE_FORMATS_TYPES.RFC,
      undefined
    )}`;
  }

  if (createAfterDate) {
    query += `${query ? '&' : `?`}created_after=${formatDate(
      createAfterDate,
      DATE_FORMATS_TYPES.RFC,
      undefined
    )}`;
  }

  if (page) {
    query += `${query ? '&' : `?`}page=${page}`;
  }

  if (appId) {
    query += `${query ? '&' : `?`}app=${appId}`;
  }
  if (envId) {
    query += `${query ? '&' : `?`}env=${envId}`;
  }

  return query;
};

/**
 * Generates a query string based on the provided filter object.
 *
 * @param {Record<string, string>?} filter - An object representing the filter parameters.
 * @returns {string} The generated query string.
 *
 * @example
 * // Usage with a filter object
 * const filter = { category: 'electronics', price: '100' };
 * const queryString = getFilterString(filter);
 * // Output: "?category=electronics&price=100"
 *
 * @example
 * // Usage without a filter object
 * const queryString = getFilterString();
 * // Output: ""
 */
export const getFilterString = (filter?: Record<string, string>) => {
  let filterString = '';
  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        filterString += !filterString ? '?' : '&';
        filterString += `${key}=${value}`;
      }
    });
  }
  return filterString;
};
