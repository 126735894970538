import React, { Dispatch, ReactElement, ReactNode, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components/macro';

import RightArrowSVG from '@src/components/shared/svg/RightArrowSVG';
import { units } from '@src/styles/variables';

import { WalButton, WalButtonProps } from '../../base/Button/Button';
import WalModal, { ModalProps } from '../../base/Modal/Modal';

const TitleBreadCrumbs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${units.margin.md};
`;

const ArrowWrapper = styled.div`
  margin: 0 ${units.margin.md};
`;

const Title = styled.h4<{ currentStep?: boolean }>`
  color: ${({ theme }) => theme.color.textTranslucent};
  ${({ currentStep }) =>
    currentStep &&
    css`
      color: ${({ theme }) => theme.color.text};
    `}
`;

interface ActionButton extends Omit<WalButtonProps, 'onClick' | 'key'> {
  label: string;
  callback?: () => void;
  /* If set to true, action button won't automatically decide next step to move to, so the user has to manually increment or decrement the current step */
  disableDefaultAction?: boolean;
}

export interface Step {
  title?: string | ReactElement;
  subTitle?: string | ReactNode;
  actions: ActionButton[];
  content: ReactNode;
  hideStep?: boolean;
}

export interface MultistepModalProps extends ModalProps {
  steps: Step[];
  /** the current step state, this allows us to change the step from outside the component */
  currentStepState?: [number, Dispatch<SetStateAction<number>>];
  disableClickOutside?: boolean;
}

const MultistepModal = (props: MultistepModalProps) => {
  const {
    title,
    currentStepState,
    openState,
    disableClickOutside,
    showClose = true,
    ...rest
  } = props;
  const [, setOpen] = openState;
  const steps = props.steps.filter((step) => !step.hideStep);

  const internalCurrentStepState = useState<number>(0);
  const [currentStep, setCurrentStep] = currentStepState || internalCurrentStepState;

  const handleActionButtonClick = (
    action: ActionButton,
    actionIndex: number,
    disableDefaultAction?: boolean
  ) => {
    const currentStepActions = steps[currentStep].actions;
    if (action.callback) {
      action.callback();
    }

    /* DEFAULT STEP ACTION BLOCK.
     * This block only runs if `disableDefaultAction`is not set to true.
     */
    if (disableDefaultAction !== true) {
      // if the button is the first action button (i.e a left button. Typically these can either be a "Close" or "Back" depending on what the current step is )
      if (actionIndex === 0) {
        if (currentStep !== 0) {
          // should go back if it is not the first step( This means the button likely a "Back" button).
          setCurrentStep(currentStep - 1);
        } else {
          // should go close the modal if it is  the first step(This means the button likely a "Close" button).
          setOpen(false);
        }
      } else if (actionIndex === currentStepActions.length - 1) {
        if (!(currentStep === steps.length - 1)) {
          // should go to the next step if the button is the last action button and it is not the last step (This means the button likely a "Next" button).
          setCurrentStep(currentStep + 1);
        }
      }
    }
  };

  return (
    <WalModal
      disableClickOutside={disableClickOutside}
      title={
        <TitleBreadCrumbs className={'hyphens'}>
          {title && (
            <>
              <Title>{title}</Title>
              <ArrowWrapper>
                <RightArrowSVG size={'small'} />
              </ArrowWrapper>
            </>
          )}
          {steps.map(
            (step, index) =>
              !step.hideStep &&
              currentStep >= index && (
                <React.Fragment key={typeof step.title === 'string' ? step.title : index}>
                  <Title currentStep={index === currentStep}>{step.title} </Title>
                  {currentStep !== index && (
                    <ArrowWrapper>
                      <RightArrowSVG size={'small'} />
                    </ArrowWrapper>
                  )}
                </React.Fragment>
              )
          )}
        </TitleBreadCrumbs>
      }
      subTitle={steps[currentStep].subTitle}
      content={steps[currentStep].content}
      actions={{
        justifyContentSpaceBetween: true,
        customComponent: (
          <>
            {steps[currentStep].actions.map((action, index) => (
              <WalButton
                {...action}
                type={action.type || 'button'}
                size={action.size || 'medium'}
                variant={action.variant || 'secondary'}
                onClick={() => handleActionButtonClick(action, index, action.disableDefaultAction)}
                key={action.label}
                disabled={action.disabled}>
                {action.label}
              </WalButton>
            ))}
          </>
        ),
      }}
      openState={openState}
      showClose={showClose}
      {...rest}
    />
  );
};

export default MultistepModal;
