import {
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { rem } from 'polished';
import React, {
  cloneElement,
  Dispatch,
  MouseEvent,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import styled, { css } from 'styled-components/macro';

import { viewAppBreakpoints } from '@src/styles/breakpoints';
import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

export type PanelSize = 'small' | 'medium' | 'large' | 'fitContent' | 'parent';
export type PanelRelativeTo = 'element' | 'window';

const PANEL_WIDTH = {
  large: rem(600),
  medium: rem(390),
  small: rem(140),
  fitContent: 'unset',
  parent: '100%',
};

export interface WalPanelProps {
  /** useState for open state */
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  /** The content of the panel */
  children?: ReactNode;
  /** The element that toggles the panel */
  toggle?: ReactElement;
  /** The width of the panel */
  panelSize?: PanelSize;
  /** The max width of the panel */
  maxSize?: PanelSize;
  /** The panel placement */
  placement?: Placement;
  /** What the panel is positioned relative to */
  relativeTo?: PanelRelativeTo;
  /** If the toggle is disabled */
  disabled?: boolean;
  /** Toggles 100% wiidth */
  fullWidth?: boolean;
  /** Custom class */
  className?: string;
  /** if the panel should be toggled on hover */
  toggleOnHover?: boolean;
  /** function that it is triggered when the panel is closed */
  onClose?: any;
  ariaControls?: string;
  ariaLabel?: string;
  onToggleKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onToggleClick?: (event: MouseEvent) => void;
  borderRadius?: boolean;
  marginTop?: boolean;
  /** Disables toggling panel on click. e.g. when you want to toggle it on focus of an element */
  disableToggleOnClick?: boolean;
  /**
   * Enable this to avoid cut off when the panel has an parent element with overflow.
   * 'position: relative' must not be present on any parent element of the panel within the 'overflow: hidden' parent.
   */
  popOutOfOverflow?: boolean;
  /* Adds a tab index to the parent of the toggle element.*/
  toggleElementWrapperTabIndex?: number;
  /* Adds a tab index to the toggle element. If not specified tabIndex of -1 is added and toggleElementWrapperTabIndex is applied */
  toggleElementTabIndex?: number;
  /* If true, it disables attempting to add a tab index to the toggle element(i.e neither toggleElementTabIndex or the default of -1 is added). toggleElementWrapperTabIndex is still applied */
  disableToggleElementTabIndexAndDefaults?: boolean;
  /** Set to true to hide the panel content */
  hideContent?: boolean;
  /** If content should remain when items === 0 */
  alwaysMaintainPanelContent?: boolean;
  customFocusState?: [boolean, Dispatch<SetStateAction<boolean>>];
  subMenuActive?: boolean;
  /** spacing between floating element and trigger in px **/
  floatingElementOffset?: number;
  /* disables floating ui auto resizing of the floating element */
  disableFloatingUiResize?: boolean;
  /* disables floating ui auto shifting of the floating element, this was added because our modal styling was conflicting with floating-ui styles, after migrating our modal component (WAL-5115) to floating ui, this property will not be needed anymore */
  disableFloatingUiShift?: boolean;
  /** white-space: nowrap will be enabled unless this prop is passed */
  disableNoWrap?: boolean;
  /** initialFocus prop of FloatingFocusManager */
  floatingFocusManagerInitialFocus?: number;
  role?: 'listbox' | 'combobox';
}

interface PanelContentProps {
  /** If the panel is open */
  open?: boolean;
  /** The width of the panel */
  size: PanelSize;
  /** What the panel is positioned relative to */
  relativeTo: PanelRelativeTo;
  /** If the toggle is disabled */
  disabled: boolean;
  /** if the panel should be toggled on hover */
  toggleOnHover?: boolean;
  borderRadius?: boolean;
  marginTop?: boolean;
  disableNoWrap: boolean;
}

const DEFAULT_PROPS: PanelContentProps = {
  size: 'medium',
  relativeTo: 'element',
  disabled: false,
  marginTop: false,
  disableNoWrap: false,
};

const panelContentCss = (props: PanelContentProps & { fullWidth?: boolean }) => css`
  display: flex;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
    skew(0deg, 0deg);
  opacity: 1;
  flex-direction: column;
  z-index: 100;
  margin: 0;
  padding: ${units.padding.lg} 0;
  border: 1px solid ${({ theme }) => theme.color.baseOutline};
  background-color: ${({ theme }) => theme.color.baseLayer};
  box-shadow:
    ${rem(2)} ${rem(2)} ${rem(8)} 0 ${({ theme }) => theme.color.baseShadow},
    0 ${rem(1)} ${rem(3)} 0 ${({ theme }) => theme.color.baseShadow};
  ${!props.open &&
  css`
    display: none;
    transform: translate3d(0, (${rem(-30)}), 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
      rotateZ(0deg) skew(0deg, 0deg);
    opacity: 0;
    transition: all 0.1s linear;
  `}

  width: ${PANEL_WIDTH[props.size]};
  ${props.borderRadius &&
  css`
    border-radius: ${rem(4)};
  `}

  ${() => {
    switch (props.relativeTo) {
      case 'window':
        return css`
          position: fixed;
          @media ${viewAppBreakpoints.smallerThanIncluding.sm} {
            left: 0;
            right: 0;
            width: 100%;
          }
        `;
      case 'element':
      default:
        return css`
          position: absolute;
          @media ${viewAppBreakpoints.smallerThanIncluding.sm} {
            position: fixed;
            left: 0;
            right: 0;
            top: unset;
            width: 100%;
          }
        `;
    }
  }};

  ${props.fullWidth &&
  css`
    width: 100%;
  `}

  ${!props.disableNoWrap &&
  css`
    white-space: nowrap;
  `}
`;

export const PanelContent = styled.div<
  PanelContentProps & { fullWidth?: boolean; toggleWidth?: number }
>`
  ${(props) => panelContentCss(props)}
`;

export const ToggleElementWrapper = styled.div<{
  disabled: boolean;
  fullWidth: boolean;
}>`
  display: flex;
  cursor: pointer;
  &:focus {
    outline: -webkit-focus-ring-color auto;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

/**
 * Returns a panel component.
 */
export const WalPanel = ({
  openState,
  children,
  toggle,
  panelSize = DEFAULT_PROPS.size,
  relativeTo = DEFAULT_PROPS.relativeTo,
  placement,
  disabled = DEFAULT_PROPS.disabled,
  className,
  fullWidth,
  toggleOnHover,
  ariaControls,
  onToggleKeyDown,
  onToggleClick,
  borderRadius,
  disableToggleOnClick,
  toggleElementWrapperTabIndex = 0,
  toggleElementTabIndex,
  disableToggleElementTabIndexAndDefaults,
  hideContent,
  alwaysMaintainPanelContent,
  floatingElementOffset,
  disableFloatingUiResize,
  disableFloatingUiShift,
  ariaLabel,
  maxSize,
  disableNoWrap,
  floatingFocusManagerInitialFocus,
  role,
}: WalPanelProps) => {
  // Component state
  const [open, setOpen] = openState;
  const { x, y, refs, strategy, context } = useFloating({
    open,
    placement: placement || 'bottom-start',
    onOpenChange: setOpen,
    // shift is needed to shift the item in the preferred axe in case it can't be shown
    // flip will flip the tooltip example from top to bottom if there is no place on top
    // offset is the spacing between the trigger and the floating element
    middleware: [
      shift({ mainAxis: disableFloatingUiShift ? false : true }),
      flip(),
      offset(floatingElementOffset ?? 5),
      size({
        apply: ({ availableHeight, elements, rects }) => {
          if (!disableFloatingUiResize) {
            Object.assign(elements.floating.style, {
              maxWidth: maxSize ? PANEL_WIDTH[maxSize] : undefined,
              width: panelSize === 'parent' ? `${rects.reference.width}px` : PANEL_WIDTH[panelSize],
              maxHeight: `${availableHeight}px`,
              overflow: 'auto',
            });
          }
        },
      }),
    ],
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      enabled: Boolean(toggleOnHover),
      // safe polygon will make sure that the hover area between the trigger and the floating element
      // so the floating element does not close unexpectedly
      handleClose: safePolygon({
        buffer: 3,
      }),
    }),
    useClick(context, {
      enabled: !disableToggleOnClick,
    }),
    useRole(context, { role: 'listbox' }),
    useDismiss(context, {
      referencePress: true,
    }),
  ]);

  /**
   * Toggles the open state of the panel.
   */
  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onToggleClick) {
      onToggleClick(e);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (
      !disabled &&
      (event.keyCode === KeyBindings.ENTER ||
        event.keyCode === KeyBindings.SPACE ||
        event.keyCode === KeyBindings.ARROWDOWN)
    ) {
      event.preventDefault();
      setOpen(!open);
    }
    if (onToggleKeyDown) {
      onToggleKeyDown(event);
    }
  };

  return (
    <>
      {toggle && (
        <ToggleElementWrapper
          aria-expanded={open}
          aria-controls={ariaControls}
          aria-label={ariaLabel}
          disabled={disabled}
          onKeyDown={onKeyDown}
          fullWidth={Boolean(fullWidth)}
          data-testid={'panel-toggle'}
          {...getReferenceProps({
            ref: refs.setReference,
            className: 'js-tooltip-trigger',
            role,
            tabIndex:
              typeof toggleElementTabIndex === 'number' && !disableToggleElementTabIndexAndDefaults
                ? undefined
                : toggleElementWrapperTabIndex,
            onKeyDown,
            onClick,
          })}>
          {disableToggleElementTabIndexAndDefaults
            ? toggle
            : cloneElement(
                toggle,
                typeof toggleElementTabIndex === 'number'
                  ? { tabIndex: toggleElementTabIndex }
                  : { tabIndex: -1 }
              )}
        </ToggleElementWrapper>
      )}
      {open && (!hideContent || alwaysMaintainPanelContent) && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            modal={false}
            initialFocus={floatingFocusManagerInitialFocus}>
            <PanelContent
              data-testid={'panel-content'}
              disableNoWrap={Boolean(disableNoWrap)}
              className={className}
              open={open}
              size={panelSize}
              relativeTo={relativeTo}
              fullWidth={fullWidth}
              disabled={disabled}
              borderRadius={borderRadius}
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                },
              })}>
              {children}
            </PanelContent>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};
