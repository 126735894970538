import { rem } from 'polished';
import styled from 'styled-components';

const SpacerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const SpacerLine = styled.div`
  background-color: ${({ theme }) => theme.color.baseOutline};
  height: ${rem(1)};
  width: 100%;
`;

interface SpacerProps {
  dataTestId?: string;
  /** Classname to be passed by styled components */
  className?: string;
}

/**
 * Returns a simple line spacer.
 */
export const Spacer = ({ dataTestId, className }: SpacerProps) => (
  <SpacerWrapper className={className} data-testid={dataTestId}>
    <SpacerLine />
  </SpacerWrapper>
);
