import { Icon, SearchInput, WalButton, WalCard } from '@humanitec/ui-components';
import { rem } from 'polished';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import useDeploymentSetQuery from '@src/hooks/react-query/deployment-set/useDeploymentSetQuery';
import { DeploymentObject } from '@src/models/deployment-object';
import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  column-gap: ${units.margin.lg};
  cursor: pointer;
  margin-bottom: ${units.margin.md};
`;

const WorkloadCard = styled(WalCard)`
  margin-bottom: ${units.margin.md};
  display: grid;
  position: relative;
  grid-template-columns: ${rem(40)} 1fr max-content;
  align-items: center;
  justify-content: space-between;
  column-gap: ${units.margin.md};
`;

const WorkloadCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: ${units.margin.md};
`;

const SelectButton = styled(WalButton)`
  position: absolute;
  right: ${units.margin.md};
  top: ${units.margin.md};
`;

const WorkloadImageWrapper = styled.div`
  width: ${rem(40)};
  height: ${rem(40)};
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const WorkloadCardTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WorkloadTitle = styled.p`
  font-weight: 700;
  font-size: ${units.fontSize.base};
  margin-bottom: ${units.margin.sm};
`;

const WorkloadProfile = styled.p`
  font-size: ${units.fontSize.sm};
`;

const SelectText = styled.span`
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.main};
`;

interface SelectWorkloadsProps {
  selectedWorkloadsState: [string[], Dispatch<SetStateAction<string[]>>];
  fromDeployment?: DeploymentObject;
}

const SelectWorkloads = ({ fromDeployment, selectedWorkloadsState }: SelectWorkloadsProps) => {
  // React Query
  const { data: deploymentSet } = useDeploymentSetQuery({ setId: fromDeployment?.set_id });

  // Components state
  const [selectedWorkloads, setSelectedWorkloads] = selectedWorkloadsState;
  const [filterWorkloadsValue, setFilterWorkloadsValue] = useState<string>('');

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const partialCloneTranslations = t('VIEW_APPLICATION', {
    returnObjects: true,
  }).PARTIAL_CLONE_MODAL;

  useEffect(() => {
    setSelectedWorkloads((prevState) => {
      if (!prevState.length) {
        return Object.keys(deploymentSet?.modules ?? {});
      }
      return prevState;
    });
  }, [deploymentSet?.modules, setSelectedWorkloads]);

  const selectAllWorkloads = () => {
    const newSelectedWorkloads = [...selectedWorkloads];
    Object.keys(deploymentSet?.modules ?? {}).forEach((workloadId: string) => {
      if (!newSelectedWorkloads.includes(workloadId)) {
        newSelectedWorkloads.push(workloadId);
      }
    });
    setSelectedWorkloads(newSelectedWorkloads);
  };

  const deselectAllWorkloads = () => {
    setSelectedWorkloads([]);
  };

  const selectWorkload = (workloadId: string) => {
    const newSelectedWorkloads = [...selectedWorkloads];
    newSelectedWorkloads.push(workloadId);
    setSelectedWorkloads(newSelectedWorkloads);
  };

  const deselectWorkload = (workloadId: string) => {
    const newSelectedWorkloads = [...selectedWorkloads];
    const workloadIndex = newSelectedWorkloads.indexOf(workloadId);
    newSelectedWorkloads.splice(workloadIndex, 1);
    setSelectedWorkloads(newSelectedWorkloads);
  };

  return (
    <>
      <FilterWrapper>
        <SearchInput
          name={'filter-workloads'}
          placeholder={partialCloneTranslations.SEARCH_WORKLOADS}
          onChange={setFilterWorkloadsValue}
        />
        <SelectText
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.keyCode === KeyBindings.ENTER) {
              selectAllWorkloads();
            }
          }}
          onClick={selectAllWorkloads}>
          {partialCloneTranslations.SELECT_ALL}
        </SelectText>
        <SelectText
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.keyCode === KeyBindings.ENTER) {
              deselectAllWorkloads();
            }
          }}
          onClick={deselectAllWorkloads}>
          {partialCloneTranslations.DESELECT_ALL}
        </SelectText>
      </FilterWrapper>
      <WorkloadCardsWrapper>
        {Object.entries(deploymentSet?.modules ?? {})
          .filter(([workloadId]) => workloadId?.includes(filterWorkloadsValue))
          .map(([workloadId, workload]) => {
            return (
              <WorkloadCard key={workloadId} dataTestId={'workload-card'}>
                <WorkloadImageWrapper>
                  {workload.profile.includes('rabbitmq') && <Icon name={'rabbitmq'} size={40} />}
                  {workload.profile.includes('redis') && <Icon name={'redis'} size={40} />}
                  {workload.profile.includes('mariadb') && <Icon name={'mariadb'} size={40} />}
                  {workload.profile.includes('elasticsearch') && (
                    <Icon name={'elasticsearch'} size={40} />
                  )}
                  {workload.profile.includes('default') && (
                    <Icon name={'default-workload'} size={40} disableInvert />
                  )}
                </WorkloadImageWrapper>
                <WorkloadCardTitleWrapper>
                  <WorkloadTitle>{workloadId}</WorkloadTitle>
                  <WorkloadProfile>
                    {workload.profile.substring(workload.profile.indexOf('/') + 1)}
                  </WorkloadProfile>
                </WorkloadCardTitleWrapper>
                <SelectButton
                  dataTestid={workloadId + '-selectButton'}
                  variant={selectedWorkloads.includes(workloadId) ? 'border' : 'secondary'}
                  size={'small'}
                  onClick={
                    selectedWorkloads.includes(workloadId)
                      ? () => deselectWorkload(workloadId)
                      : () => selectWorkload(workloadId)
                  }>
                  {selectedWorkloads.includes(workloadId)
                    ? uiTranslations.SELECTED
                    : uiTranslations.SELECT}
                </SelectButton>
              </WorkloadCard>
            );
          })}
      </WorkloadCardsWrapper>
    </>
  );
};

export default SelectWorkloads;
