import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import AddResourceDependency from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import ResourceDependencyList, {
  ResourceCardItem,
} from '@src/components/shared/ResourceDependencyList/ResourceDependencyList';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useEnvironmentResourcesQuery from '@src/hooks/react-query/environments/queries/useEnvironmentResourcesQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { Workload } from '@src/models/deployment-set';
import { MatchParams } from '@src/models/routing';

const AvailableExternalResourcesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExternalResources = () => {
  // Context
  const { draftModeActive, onRunningDeployment } = useDeploymentOrDeltaContext();

  const { workloadResourceDependencies } = useDeltaUtils<Workload>();

  // Router hooks
  const { moduleId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: environmentResources = [] } = useEnvironmentResourcesQuery();

  const resourceCardItems: ResourceCardItem[] = useMemo(() => {
    return draftModeActive
      ? workloadResourceDependencies.map((resDep) => ({
          resourceDependency: resDep,
        }))
      : onRunningDeployment
        ? workloadResourceDependencies.map((resDep) => ({
            resourceDependency: resDep,
            activeResource: environmentResources.find(
              (activeResource) =>
                activeResource.res_id === `modules.${moduleId}.externals.${resDep.id}` &&
                activeResource.type === resDep.type &&
                activeResource.class === (resDep.class || 'default')
            ),
          }))
        : [];
  }, [
    draftModeActive,
    workloadResourceDependencies,
    onRunningDeployment,
    environmentResources,
    moduleId,
  ]);

  return (
    <AvailableExternalResourcesWrapper>
      {draftModeActive && <AddResourceDependency scope={'externals'} />}
      <div className={'mt-md'}>
        <ResourceDependencyList resourceCardItems={resourceCardItems} scope={'externals'} />
      </div>
    </AvailableExternalResourcesWrapper>
  );
};

export default ExternalResources;
