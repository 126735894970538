import { Card, Tooltip, WalButton } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import SkeletonGroup from '@src/components/shared/Skeleton/SkeletonGroup';
import TriggerPipelineRunModal from '@src/components/shared/TriggerPipelineRunModal/TriggerPipelineRunModal';
import usePipelinesQuery from '@src/hooks/react-query/pipelines/queries/usePipelinesQuery';
import { generatePipelinesURL } from '@src/utilities/navigation';

const CustomCard = styled(Card)`
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  justify-content: space-between;
`;

const CustomLink = styled(Link)`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomTooltip = styled(Tooltip)`
  max-width: 80%;
`;

const PipelinesList = () => {
  const { t } = useTranslation('viewApplication');
  const translations = t('PIPELINES');

  const { orgId, appId } = useParams();
  const { data: pipelines = [], isLoading: arePipelinesLoading } = usePipelinesQuery();
  const [openTriggerPipelineForm, setOpenTriggerPipelineForm] = useState(false);
  const [pipelineToTrigger, setPipelineToTrigger] = useState<{ id: string; name: string }>();

  const handleClickOnTrigger = (pipelineId: string, pipelineName: string) => {
    setOpenTriggerPipelineForm(true);
    setPipelineToTrigger({ id: pipelineId, name: pipelineName });
  };
  return (
    <>
      <Wrapper className={'flex-centered'}>
        <WalButton
          disabled={arePipelinesLoading}
          link={{ to: `${generatePipelinesURL(orgId, appId, 'create')}` }}
          variant={'primary'}
          iconLeft={'plus'}>
          {translations.ADD_NEW}
        </WalButton>
      </Wrapper>

      {arePipelinesLoading ? (
        <SkeletonGroup count={3} itemProps={{ className: 'mt-lg' }} />
      ) : (
        pipelines.map(({ id, name }) => (
          <CustomCard key={id} className={'flex-centered mt-md'}>
            <CustomTooltip
              text={name}
              triggerComponent={
                <CustomLink
                  to={`${generatePipelinesURL(orgId, appId, id)}/runs`}
                  className={'title-link txt-md'}>
                  {name}
                </CustomLink>
              }
            />
            <WalButton
              variant={'secondary'}
              size={'small'}
              onClick={() => handleClickOnTrigger(id, name)}>
              {translations.RUN}
            </WalButton>
          </CustomCard>
        ))
      )}
      {openTriggerPipelineForm && pipelineToTrigger && (
        <TriggerPipelineRunModal
          openState={[openTriggerPipelineForm, setOpenTriggerPipelineForm]}
          pipelineId={pipelineToTrigger.id}
          pipelineName={pipelineToTrigger.name}
        />
      )}
    </>
  );
};
export default PipelinesList;
