import { Accordion } from '@humanitec/ui-components';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import AddWorkloadsAccordionContent from '@src/components/shared/CloneModal/components/ReviewCloneDetails/components/AddWorkloadsAccordionContent';
import RemoveWorkloadsAccordionContent from '@src/components/shared/CloneModal/components/ReviewCloneDetails/components/RemoveWorkloadsAccordionContent';
import UpdateWorkloadsAccordionContent from '@src/components/shared/CloneModal/components/ReviewCloneDetails/components/UpdateWorkloadsAccordionContent';
import useDeploymentSetQuery from '@src/hooks/react-query/deployment-set/useDeploymentSetQuery';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentObject } from '@src/models/deployment-object';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';
import { diffDeploymentSets } from '@src/utilities/deployment-delta-utils';

const AccordionHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface ReviewCloneDetailsProps {
  cloneDraftContentState: [
    Partial<DeploymentDelta>,
    Dispatch<SetStateAction<Partial<DeploymentDelta>>>,
  ];
  targetEnv?: Environment;
  fromDeployment?: DeploymentObject;
}
const ReviewCloneDetails = ({
  targetEnv,
  fromDeployment,
  cloneDraftContentState,
}: ReviewCloneDetailsProps) => {
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const modalTranslations = tViewEnvironment('DEPLOYS').DEPLOYMENT_CARD.CLONE_MODAL;
  const { appId, orgId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: targetDeploymentSet } = useDeploymentSetQuery({
    setId: targetEnv?.last_deploy?.set_id,
  });

  // State
  const [cloneDraftContent, setCloneDraftContent] = cloneDraftContentState;
  const [defaultCloneDraftContent, setDefaultCloneDraftContent] = useState<
    Partial<DeploymentDelta>
  >({});

  useEffect(() => {
    if (fromDeployment?.set_id) {
      diffDeploymentSets(
        fromDeployment?.set_id,
        targetEnv?.last_deploy?.set_id || '0',
        appId,
        orgId
      ).then((draft) => {
        setCloneDraftContent(cloneDeep(draft));
        setDefaultCloneDraftContent(cloneDeep(draft));
      });
    }
  }, [fromDeployment, targetEnv?.last_deploy?.set_id, appId, orgId, setCloneDraftContent]);

  const addResourceChangeInCloneDraft = (path: string) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent) {
      const defaultResourceChange = defaultCloneDraftContent.shared?.find(
        (change) => change.path === path
      );
      if (defaultResourceChange) {
        newCloneDraftContent.shared?.push(defaultResourceChange);
      }
      setCloneDraftContent(newCloneDraftContent);
    }
  };

  const removeResourceChangeFromCloneDraft = (path: string) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    const resourceChangeIndex = cloneDraftContent.shared?.findIndex(
      (change) => change.path === path
    );
    if (resourceChangeIndex !== undefined) {
      cloneDraftContent.shared?.splice(resourceChangeIndex, 1);
      setCloneDraftContent(newCloneDraftContent);
    }
  };

  const removedResourcesCount =
    defaultCloneDraftContent.shared?.filter((change) => change.op === 'remove').length || 0;
  const removedWorkloadsCount = defaultCloneDraftContent.modules?.remove?.length || 0;
  const addedWorkloadsCount = defaultCloneDraftContent.modules?.add
    ? Object.keys(defaultCloneDraftContent.modules?.add).length
    : 0;
  const addedResourcesCount =
    defaultCloneDraftContent.shared?.filter((change) => change.op === 'add').length || 0;
  return (
    <Accordion
      items={[
        {
          id: 'remove-workloads',
          expandedByDefault: true,
          headerContent: (
            <AccordionHeaderContent>
              {modalTranslations.REMOVE_WORKLOADS_AND_SHARED_RESOURCES}
              <span className={'txt-sm txt-translucent'}>
                <span className={'mr-sm'}>
                  {removedWorkloadsCount + removedResourcesCount}{' '}
                  {modalTranslations.AVAILABLE_TO_REMOVE}
                </span>
              </span>
            </AccordionHeaderContent>
          ),
          content: (
            <RemoveWorkloadsAccordionContent
              targetEnv={targetEnv}
              fromDeployment={fromDeployment}
              cloneDraftContentState={[cloneDraftContent, setCloneDraftContent]}
              defaultCloneDraftContent={defaultCloneDraftContent}
              removeResourceChangeFromCloneDraft={removeResourceChangeFromCloneDraft}
              addResourceChangeInCloneDraft={addResourceChangeInCloneDraft}
              targetDeploymentSet={targetDeploymentSet}
            />
          ),
          sortIndex: defaultCloneDraftContent.modules?.remove?.length ? 1 : -1,
          disableExpansion: Boolean(
            defaultCloneDraftContent.modules?.remove?.length === 0 &&
              (defaultCloneDraftContent.shared
                ? defaultCloneDraftContent.shared?.filter((change) => change.op === 'remove')
                    .length === 0
                : true)
          ),
        },
        {
          id: 'add-workloads',
          expandedByDefault: true,
          headerContent: (
            <AccordionHeaderContent>
              {modalTranslations.ADD_WORKLOADS_AND_SHARED_RESOURCES}
              <span className={'txt-sm txt-translucent'}>
                <span className={'mr-sm'}>
                  {addedWorkloadsCount + addedResourcesCount} {modalTranslations.AVAILABLE_TO_ADD}
                </span>
              </span>
            </AccordionHeaderContent>
          ),
          content: (
            <AddWorkloadsAccordionContent
              targetEnv={targetEnv}
              fromDeployment={fromDeployment}
              cloneDraftContentState={[cloneDraftContent, setCloneDraftContent]}
              defaultCloneDraftContent={defaultCloneDraftContent}
              addResourceChangeInCloneDraft={addResourceChangeInCloneDraft}
              removeResourceChangeFromCloneDraft={removeResourceChangeFromCloneDraft}
            />
          ),
          sortIndex:
            defaultCloneDraftContent.modules?.add &&
            Object.keys(defaultCloneDraftContent.modules?.add).length
              ? 0
              : -1,
          disableExpansion: Boolean(
            (defaultCloneDraftContent.modules?.add
              ? Object.keys(defaultCloneDraftContent.modules?.add).length === 0
              : true) &&
              (defaultCloneDraftContent.shared
                ? defaultCloneDraftContent.shared?.filter(
                    (resourceChange) => resourceChange.op === 'add'
                  ).length === 0
                : true)
          ),
        },
        {
          id: 'update-workloads',
          expandedByDefault: true,
          headerContent: (
            <AccordionHeaderContent>
              {modalTranslations.UPDATE_WORKLOADS_AND_SHARED_RESOURCES}
              <span className={'txt-sm txt-translucent'}>
                <span className={'mr-sm'}>
                  {defaultCloneDraftContent.modules?.update
                    ? Object.keys(defaultCloneDraftContent.modules?.update).length
                    : 0}{' '}
                  {modalTranslations.AVAILABLE_TO_UPDATE}
                </span>
              </span>
            </AccordionHeaderContent>
          ),
          content: (
            <UpdateWorkloadsAccordionContent
              targetEnv={targetEnv}
              fromDeployment={fromDeployment}
              cloneDraftContentState={[cloneDraftContent, setCloneDraftContent]}
              defaultCloneDraftContent={defaultCloneDraftContent}
              targetDeploymentSet={targetDeploymentSet}
            />
          ),
          sortIndex: Number(
            defaultCloneDraftContent.modules?.update &&
              Object.keys(defaultCloneDraftContent.modules?.update).length
          )
            ? 0
            : -1,
          disableExpansion: defaultCloneDraftContent.modules?.update
            ? Object.keys(defaultCloneDraftContent.modules?.update).length === 0
            : true,
        },
      ]}
    />
  );
};

export default ReviewCloneDetails;
