import { SearchInput, WalButton } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import WelcomeBox from '@src/containers/Orgs/Apps/containers/AppsList/components/WelcomeBox';
import AddResourceDefinitionModal from '@src/containers/Orgs/Resources/components/AddResourceDefinitionModal/AddResourceDefinitionModal';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import useResourceDriversQuery from '@src/hooks/react-query/resources/queries/useResourceDriversQuery';
import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import { useShouldShowWelcomeBox } from '@src/hooks/useShouldShowWelcomeBox';
import { ResourceDefinition } from '@src/models/resources';
import { containerStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';

import ResourcesTable from '../../components/ResourcesTable/ResourcesTable';

const Container = styled.div`
  ${containerStyle()};
  flex-direction: column;
  overflow: auto;
  margin-bottom: ${units.margin.lg};
`;

const AddResourcesWrapper = styled.div`
  display: flex;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${units.padding.xxl};
`;

export const ResourceManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // Component state
  const [filteredResourceDefinitions, setFilteredResourceDefinitions] = useState<
    ResourceDefinition[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('query')?.toLowerCase() || ''
  );
  const [openAddResourceDefinitionModal, setOpenAddResourceDefinitionModal] = useState(false);

  // react query
  const { data: resourceDefinitions } = useResourceDefinitionsQuery();
  const { data: resourceTypes } = useResourceTypesQuery();
  const { data: resourceDrivers } = useResourceDriversQuery();

  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  const showWelcomeBox = useShouldShowWelcomeBox();

  useEffect(() => {
    const filterBasedOnSearchValue = searchValue
      ? resourceDefinitions?.filter(
          (r) =>
            r.name.toLowerCase()?.includes(searchValue) ||
            r.driver_type.toLowerCase().includes(searchValue) ||
            r.driver_account?.toLowerCase().includes(searchValue)
        )
      : resourceDefinitions;

    setFilteredResourceDefinitions(
      filterBasedOnSearchValue?.sort((a, b) =>
        a.is_default && b.is_default ? 0 : b.is_default ? -1 : 1
      ) || []
    );
  }, [searchValue, resourceDefinitions]);

  const filterResources = (value: string) => {
    searchParams.set('query', value);
    setSearchParams(searchParams);
    setSearchValue(value.toLowerCase());
  };

  return (
    <Container>
      <PageHeader />
      <AddResourcesWrapper>
        <WalButton
          variant={showWelcomeBox ? 'secondary' : 'primary'}
          iconLeft={'plus'}
          onClick={() => setOpenAddResourceDefinitionModal(true)}>
          {resourcesTranslations.ADD_RESOURCE_DEFINITION}
        </WalButton>

        <SearchInput
          defaultValue={searchValue}
          name={'filter-resources'}
          placeholder={resourcesTranslations.RESOURCES_SEARCH_PLACEHOLDER}
          onChange={filterResources}
        />
      </AddResourcesWrapper>

      {showWelcomeBox && <WelcomeBox className={'mb-xl'} />}

      <ResourcesTable resourceDefinitions={filteredResourceDefinitions} />
      {openAddResourceDefinitionModal && resourceDrivers && resourceTypes && (
        <AddResourceDefinitionModal
          openState={[openAddResourceDefinitionModal, setOpenAddResourceDefinitionModal]}
          resourceDrivers={resourceDrivers}
          resourceTypes={resourceTypes}
        />
      )}
    </Container>
  );
};
