import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { DeploymentObject } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { QueryResponse } from '../../types';
import { environmentQueryKeys } from '../environmentQueryKeys';

const useDeploymentQuery = (customParams?: {
  orgId?: string;
  appId?: string;
  envId?: string;
  deploymentId?: string;
}): QueryResponse<DeploymentObject> => {
  const {
    orgId: routerOrgId,
    appId: routerAppId,
    envId: routerEnvId,
    deploymentId: routerDeploymentId,
  } = useParams<keyof MatchParams>() as MatchParams;

  const orgId = customParams?.orgId || routerOrgId;
  const appId = customParams?.appId || routerAppId;
  const envId = customParams?.envId || routerEnvId;
  const deploymentId = customParams?.deploymentId || routerDeploymentId;

  const { data, ...queryResult } = useQuery({
    queryKey: environmentQueryKeys.environmentDeploymentDetail(orgId, appId, envId, deploymentId),
    queryFn: () =>
      makeRequest<DeploymentObject>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/deploys/${deploymentId}`
      ),
    enabled: Boolean(orgId && appId && envId && deploymentId),
  });
  return { ...queryResult, data: data?.data };
};

export default useDeploymentQuery;
