import { Text, WalButton } from '@humanitec/ui-components';

import { ResourceTypeItem } from '@src/components/shared/AddResourceDependency/AddResourceDependency';

interface ResourceListProps {
  items: ResourceTypeItem[];
  onClick: (item: ResourceTypeItem) => void;
}

export const ResourceTypeList = ({ items, onClick }: ResourceListProps) => (
  <div className={'flex-column'}>
    {items.map((item, index) => {
      if (item.hideFromList) return null;

      return !item.separatorItem ? (
        <WalButton
          className={'mb-md'}
          variant={'secondary'}
          iconRight={'arrow-right'}
          key={item.id}
          onClick={() => onClick?.(item)}>
          <Text className={'pt-lg pb-lg'} size={'base'}>
            {item.component || item.label}
          </Text>
        </WalButton>
      ) : (
        <Text size={'base'} key={item.id} className={`mb-md ${index > 0 ? 'mt-lg' : ''}`}>
          {item.label}
        </Text>
      );
    })}
  </div>
);
