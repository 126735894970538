import { ErrorWarning, Spinner, Text, WalButton, WalInput } from '@humanitec/ui-components';
import { JSONSchema7 } from 'json-schema';
import { rem } from 'polished';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useWalhallContext } from '@src/context/walhallContext';
import usePipelineCreateMutation from '@src/hooks/react-query/pipelines/mutation/usePipelineCreateMutation';
import usePipelineUpdateMutation from '@src/hooks/react-query/pipelines/mutation/usePipelineUpdateMutation';
import usePipelineDetailsQuery from '@src/hooks/react-query/pipelines/queries/usePipelineDetailsQuery';
import usePipelineSchemaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineSchemaQuery';
import usePipelineYamlValidationSchemaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineYamlValidationSchemaQuery';
import { useWalhallForm } from '@src/utilities/form';
import { generatePipelinesURL } from '@src/utilities/navigation';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${rem(300)};

  form {
    width: 100%;
  }
`;
interface PipelinesYamlProps {
  isCreation?: boolean;
  isView?: boolean;
}
export const PipelinesYaml = ({ isCreation, isView }: PipelinesYamlProps) => {
  const { shouldConfirmOnNavigateState, nextRouteState, navigateDialogState } = useWalhallContext();
  const { orgId, appId, pipelineId } = useParams();
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const versionId = search.get('version') || undefined;

  // i18n
  const { t } = useTranslation('viewApplication');
  const translations = t('PIPELINES');

  // state
  const [hasError, setHasError] = useState(false);
  const [_, setShouldConfirmOnNavigate] = shouldConfirmOnNavigateState;
  const [, setShowNavigateDialog] = navigateDialogState;
  const [, setNextRoute] = nextRouteState;

  // react-query
  const { data: yamlSchemaForPipeline } = usePipelineYamlValidationSchemaQuery();
  const { data: schema = '', isLoading: isLoadingPipelineSchema } =
    usePipelineSchemaQuery(versionId);
  const { data: pipeline, isLoading: isLoadingPipelineDetails } = usePipelineDetailsQuery({
    id: pipelineId,
    appId,
    versionId,
  });
  const {
    mutate: createPipeline,
    isError: isPipelineCreateError,
    isSuccess: isPipelineCreateSuccess,
    error: pipelineCreateError,
    data: createdPipeline,
    reset: resetCreatePipelineState,
  } = usePipelineCreateMutation();
  const {
    mutate: updatePipeline,
    isError: isPipelineUpdateError,
    isSuccess: isPipelineUpdateSuccess,
    error: pipelineUpdateError,
    reset: resetUpdatePipelineState,
  } = usePipelineUpdateMutation();

  // form
  const formMethods = useWalhallForm({ defaultValues: { 'pipeline-yaml': schema } });
  const {
    formState: { isDirty },
    reset,
    handleSubmit,
    watch,
  } = formMethods;
  const watchPipelineYaml = watch('pipeline-yaml');

  const resetFormState = useCallback(() => {
    if (isPipelineUpdateError) {
      resetUpdatePipelineState();
    }
    if (isPipelineCreateError) {
      resetCreatePipelineState();
    }
  }, [
    isPipelineCreateError,
    isPipelineUpdateError,
    resetCreatePipelineState,
    resetUpdatePipelineState,
  ]);

  useEffect(() => {
    // set default value and set dirty to false
    reset({ 'pipeline-yaml': schema });
  }, [schema, reset]);

  useEffect(() => {
    if (isDirty) {
      setShouldConfirmOnNavigate(true);
    } else {
      setShouldConfirmOnNavigate(false);
    }
  }, [isDirty, setShouldConfirmOnNavigate]);

  useEffect(() => {
    if (isPipelineCreateSuccess && createdPipeline) {
      navigate(`${generatePipelinesURL(orgId, appId, createdPipeline.id)}/definition`);
    }
  }, [appId, isPipelineCreateSuccess, navigate, orgId, createdPipeline]);

  useEffect(() => {
    if (isPipelineUpdateSuccess) {
      resetFormState();
      reset({ 'pipeline-yaml': isCreation ? '' : schema });
    }
  }, [isCreation, isPipelineUpdateSuccess, reset, resetFormState, schema]);

  const handleCancel = () => {
    setShouldConfirmOnNavigate(false);
    if (isCreation) {
      if (isDirty) {
        setShowNavigateDialog(true);
        setNextRoute(`${generatePipelinesURL(orgId, appId)}/pipelines`);
      } else {
        navigate(`${generatePipelinesURL(orgId, appId)}/pipelines`);
      }
    } else {
      resetFormState();
      reset({ 'pipeline-yaml': isCreation ? '' : schema });
      if (hasError) {
        setHasError(false);
      }
    }
  };

  const onSubmit = (value: Record<string, string>) => {
    if (hasError) {
      setHasError(false);
    }

    if (isCreation) {
      createPipeline({
        yamlData: `${value['pipeline-yaml']}`,
      });
    } else {
      updatePipeline({
        yamlData: `${value['pipeline-yaml']}`,
        etag: pipeline?.etag || '',
      });
    }
  };

  const generateErrorMessage = (title?: string, errors?: string[]) => {
    return (
      errors?.reduce((message, error) => {
        return `${message}\n${error}`;
      }, title) || translations.INVALID_SCHEMA
    );
  };

  const isDefaultPipeline = pipeline?.id === 'default';

  if (!isCreation && (isLoadingPipelineDetails || isLoadingPipelineSchema)) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <WalInput
            labelAbove
            monacoEditorOnValidate={(errs) => {
              setHasError(Boolean(errs.length));
              resetFormState();
            }}
            onChange={() => {
              resetFormState();
            }}
            noMargin
            monacoOptions={{ contextmenu: true, autoIndent: 'none' }}
            monacoEditorFileExtension={'yaml'}
            yamlSchema={{
              uri: 'http://json-schema.org/draft-07/schema#',
              schema: yamlSchemaForPipeline as JSONSchema7,
            }}
            isMonacoEditor
            name={'pipeline-yaml'}
            rows={14}
            size={'large'}
          />
          {isPipelineCreateError && (
            <ErrorWarning
              code={pipelineCreateError?.response?.data?.error}
              message={generateErrorMessage(
                pipelineCreateError?.response?.data?.message,
                pipelineCreateError?.response?.data?.details?.errors
              )}
              mode={'alert'}
              className={'mt-md'}
            />
          )}
          {isPipelineUpdateError && (
            <ErrorWarning
              code={pipelineUpdateError?.response?.data?.error}
              message={generateErrorMessage(
                pipelineUpdateError?.response?.data?.message,
                pipelineUpdateError?.response?.data?.details?.errors
              )}
              mode={'alert'}
              className={'mt-md'}
            />
          )}
          {!isDefaultPipeline && !isView && (
            <div className={'flex-centered justify-end mt-lg'}>
              <WalButton
                type={'submit'}
                className={'mr-sm'}
                disabled={hasError || !isDirty || !watchPipelineYaml}>
                <Text size={'base'}>{translations.SAVE}</Text>
              </WalButton>
              <WalButton
                onClick={handleCancel}
                variant={'secondary'}
                disabled={!isCreation && (!isDirty || !watchPipelineYaml)}>
                <Text size={'base'}>{translations.CANCEL}</Text>
              </WalButton>
            </div>
          )}
        </form>
      </FormProvider>
    </Wrapper>
  );
};

export default PipelinesYaml;
