import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { PipelineCriteria } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelinesQueryKeys } from '../pipelinesQueryKeys';

const useCreatePipelineCriteriaMutation = (errorCallback?: (error: AxiosError) => void) => {
  const queryClient = useQueryClient();
  const { orgId, appId, pipelineId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<PipelineCriteria>,
    AxiosError<ApiErrorResponse>,
    {
      criteria: Partial<PipelineCriteria>;
    }
  >({
    mutationFn: ({ criteria }) =>
      makeRequest('POST', `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/criteria`, {
        ...criteria,
        trigger: 'deployment_request',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: pipelinesQueryKeys.criteria(orgId, appId, envId) });
    },
    onError: errorCallback,
  });
  return { ...queryResult, data: data?.data };
};

export default useCreatePipelineCriteriaMutation;
