import { Dispatch, KeyboardEvent, ReactNode, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

import { WalButton } from '../../base/Button/Button';
import { WalCard } from '../../base/Card/Card';

const EntryEditorWrapper = styled.div`
  display: flex;
  margin-bottom: ${units.margin.xs};
  align-items: center;
`;

const ActionButton = styled(WalButton)<{ hideLabel?: boolean }>`
  margin-left: ${units.margin.md};
  ${({ hideLabel }) =>
    hideLabel &&
    css`
      margin-top: ${units.margin.lg};
    `}
`;

const WrapperCard = styled(WalCard)<{ readonly?: boolean }>`
  flex: 1;
  cursor: pointer;
  ${({ readonly }) =>
    !readonly &&
    css`
      max-width: 94%;
    `}
`;

export type EntryEditorState = 'view' | 'edit';

export interface EntryEditorProps {
  entryState: [EntryEditorState, Dispatch<SetStateAction<EntryEditorState>>];
  id: string;
  formComponent: ReactNode;
  viewComponent: ReactNode;
  /** wether the entry is readonly */
  readonly?: boolean;
  /** callback function when the user deletes an entry */
  onDeleteEntry: (key: string) => void;
  onSubmitEntry: (key: string) => void;
  onClickOutside?: (key: string) => void;
  submitOnClickOutside?: boolean;
  disableSubmit?: boolean;
  cardStyle?: 'transparent' | 'default';
  onStartEdit?: () => void;
}

const EntryEditor = ({
  id,
  readonly,
  onDeleteEntry,
  onSubmitEntry,
  onClickOutside,
  disableSubmit,
  submitOnClickOutside,
  formComponent,
  viewComponent,
  cardStyle,
  entryState,
  onStartEdit,
}: EntryEditorProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const [state, setState] = entryState;
  const fieldsWrapperRef: any = useRef(null);

  useEffect(() => {
    /**
     * Handles the user's click depending on whether it is inside or outside of the panel.
     *
     * @param event the click event
     */
    const handleClickOutside = (event: any) => {
      if (fieldsWrapperRef.current?.contains(event.target)) {
        // user clicked inside fieldsWrapper
        return;
      }
      if (state === 'edit' && submitOnClickOutside) {
        // user clicked out the fieldsWrapper
        setState('view');
      }
      if (state === 'edit' && onClickOutside) {
        onClickOutside(id);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [state, onClickOutside, id, submitOnClickOutside, setState]);

  /**
   * delete field handler
   */
  const handleDeleteEntry = (): void => {
    onDeleteEntry(id);
  };

  const handleSubmitEntry = (): void => {
    onSubmitEntry(id);
  };

  const editField = (): void => {
    if (!readonly && state !== 'edit') {
      setState('edit');
      if (onStartEdit) {
        onStartEdit();
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === KeyBindings.ENTER) {
      editField();
    }
  };

  /**
   * State helpers.
   */
  const isEditing = state === 'edit';

  return (
    <EntryEditorWrapper ref={fieldsWrapperRef}>
      <WrapperCard
        tabIndex={0}
        padding={'small'}
        readonly={readonly}
        cardStyle={cardStyle ? cardStyle : 'default'}
        onKeyDown={handleKeyDown}
        onClick={editField}>
        {isEditing ? formComponent : viewComponent}
      </WrapperCard>
      {isEditing && !submitOnClickOutside ? (
        <ActionButton
          variant={'primary'}
          type={'submit'}
          onClick={handleSubmitEntry}
          disabled={disableSubmit}
          iconLeft={'checkmark'}
          ariaLabel={uiTranslations.SUBMIT}
        />
      ) : (
        !isEditing &&
        !readonly && (
          <ActionButton variant={'secondary'} onClick={handleDeleteEntry} iconLeft={'remove'} />
        )
      )}
    </EntryEditorWrapper>
  );
};

export default EntryEditor;
