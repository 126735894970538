import {
  CopyText,
  Spinner,
  Text,
  WalButton,
  WalCard,
  WarningSection,
} from '@humanitec/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ErrorPage from '@src/components/shared/ErrorPage/ErrorPage';
import TriggerPipelineRunModal from '@src/components/shared/TriggerPipelineRunModal/TriggerPipelineRunModal';
import useCancelPipelineRunsMutation from '@src/hooks/react-query/pipeline-runs/mutation/useCancelPipelineRunsMutation';
import { pipelineRunsQueryKeys } from '@src/hooks/react-query/pipeline-runs/queries/pipelineRunsQueryKeys';
import usePipelineRunDetailsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunDetailsQuery';
import usePipelineRunJobDetailsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunJobDetailsQuery';
import usePipelineRunJobsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunJobsQuery';
import usePipelineRunsApprovalRequestsQuery, {
  getPipelineRunsApprovalRequestsQuery,
} from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunsApprovalRequestsQuery';
import usePipelineDetailsQuery from '@src/hooks/react-query/pipelines/queries/usePipelineDetailsQuery';
import useGetUserByIdQuery from '@src/hooks/react-query/user/useGetUserByIdQuery';
import { MatchParams } from '@src/models/routing';
import { containerStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import {
  DATE_FORMATS_TYPES,
  DateTillNowDifference,
  formatDate,
} from '@src/utilities/datetime/datetime';
import { generatePipelinesURL } from '@src/utilities/navigation';

import { getDuration, getIsWaitingForApproval, getStatus, isStatusMessage } from '../../utils';
import { ReviewsApproval } from '../ReviewsApproval/ReviewsApproval';
import { PipelineJobs } from './components/PipelineJobs/PipelineJobs';

const Container = styled.div`
  ${containerStyle()};
  flex-direction: column;
  overflow: auto;
  margin-bottom: ${units.margin.lg};
`;

const ErrorCard = styled(WalCard)`
  flex-direction: row;
`;

const ErrorWrapper = styled.div`
  & > * {
    display: block;
  }

  margin-left: ${units.margin.lg};

  & {
    flex: 1;
    word-break: break-word;
  }

  &:first-child {
    flex: 4;
    margin-left: 0px;
  }

  &:last-child {
    white-space: nowrap;
  }
`;

const SpinnerWrapper = styled.div`
  height: 100%;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomWalButton = styled(WalButton)`
  align-self: flex-start;
`;

const LeftPart = styled.span`
  flex: 5;
`;
const RightPart = styled.span`
  flex: 1;
`;

const WrapText = styled(Text)`
  display: flex;
  flex-wrap: wrap;
`;

const errorTranslations = i18next.t('ERROR');
const translations = i18next.t('PIPELINE_RUNS');
const uiTranslations = i18next.t('UI');

export const PipelineRunDetails = () => {
  const queryClient = useQueryClient();
  const { orgId, appId, pipelineRunId, pipelineId } = useParams<keyof MatchParams>() as MatchParams;

  // state
  const [openTriggerPipelineForm, setOpenTriggerPipelineForm] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
  const [reviewProps, setReviewProps] = useState<{
    runId: string;
    appId: string;
    pipelineId: string;
  }>({
    runId: '',
    appId: '',
    pipelineId: '',
  });

  // react-query
  const { data: pipelineRun, isLoading: isPipelineRunLoading } = usePipelineRunDetailsQuery({
    id: pipelineRunId,
  });
  const { data: pipeline } = usePipelineDetailsQuery({
    id: pipelineRun?.pipeline_id,
    appId: pipelineRun?.app_id,
  });
  const { data: jobs = [] } = usePipelineRunJobsQuery({ id: pipelineRunId });
  const {
    mutate: cancelPipeline,
    isPending: isCancelling,
    isSuccess: isCancelled,
  } = useCancelPipelineRunsMutation();
  const lastRunJobId = jobs[jobs.length - 1]?.id;

  const { data: lastRunJob, isLoading: isLastRunJobLoading } = usePipelineRunJobDetailsQuery({
    jobId: lastRunJobId,
  });

  const { data: items = [] } = usePipelineRunsApprovalRequestsQuery(appId, {
    run: pipelineRunId,
  });

  const jobApproval = items.find(({ job_id }) => job_id === lastRunJobId);

  const { data: approver } = useGetUserByIdQuery(jobApproval?.approved_by || '');

  useEffect(() => {
    if (isCancelled) {
      queryClient.invalidateQueries({
        queryKey: pipelineRunsQueryKeys.pipelineRunDetails(orgId, appId, pipelineId, pipelineRunId),
      });
    }
  }, [appId, isCancelled, orgId, pipelineId, pipelineRunId, queryClient]);

  if (isPipelineRunLoading || isLastRunJobLoading) {
    return (
      <SpinnerWrapper className={'flex-centered'}>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (!(pipelineRun && lastRunJob)) {
    return <ErrorPage descriptionTexts={[errorTranslations.RUN_NOT_FOUND]} />;
  }

  const handleReviewPipelineRun = (runId: string, applicationId: string, pipelineIden: string) => {
    setReviewProps({ runId, appId: applicationId, pipelineId: pipelineIden });
    setShowReviewModal(true);
  };

  const handlePrefetchPipelineRunApprovalRequests = async (app: string, runId: string) => {
    await queryClient.prefetchQuery(
      getPipelineRunsApprovalRequestsQuery(orgId, app, { run: runId, status: 'waiting' })
    );
  };

  const handleCancelPipelineRun = () => {
    cancelPipeline({
      pipelineId: pipelineRun.pipeline_id,
      runId: pipelineRun.id,
      appId: pipelineRun.app_id,
    });
  };

  return (
    <Container>
      <div className={'flex-column mb-md pt-xl mt-lg full-height'}>
        <Wrapper>
          <LeftPart className={'flex-column'}>
            <Text size={'lg'}>
              {pipelineRun.created_at &&
                formatDate(
                  pipelineRun.created_at,
                  DATE_FORMATS_TYPES.HOUR_MINUTE_DATE_MONTH_YEAR,
                  undefined
                )}
            </Text>
            <WrapText size={'sm'} color={'textTranslucent'} className={'mt-sm'}>
              <Link
                to={`${generatePipelinesURL(orgId, appId, pipelineRun.pipeline_id)}/versions/${
                  pipelineRun.pipeline_version
                }/view?run=${pipelineRunId}`}>
                {pipeline?.name}
              </Link>
            </WrapText>
            <div className={'flex-centered mt-xl'}>
              <div className={'flex-column mr-xl'}>
                <Text size={'sm'} color={'textTranslucent'} className={'mt-sm'}>
                  {translations.TABLE.STATUS}
                </Text>
                <Text size={'base'} className={'mt-sm flex-centered'}>
                  {getStatus(
                    getIsWaitingForApproval(pipelineRun) ? 'waiting' : pipelineRun.status,
                    true,
                    16
                  )}
                </Text>
              </div>
              <div className={'flex-column mr-xl'}>
                <Text size={'sm'} color={'textTranslucent'} className={'mt-sm'}>
                  {translations.TABLE.STARTED}
                </Text>
                <Text size={'base'} className={'mt-sm flex-centered'}>
                  {pipelineRun.executing_at &&
                    DateTillNowDifference(new Date(pipelineRun.executing_at))}{' '}
                  {translations.TABLE.AGO}
                </Text>
              </div>
              <div className={'flex-column mr-xl'}>
                <Text size={'sm'} color={'textTranslucent'} className={'mt-sm'}>
                  {translations.TABLE.TOTAL_RUN_TIME}
                </Text>
                <Text size={'base'} className={'mt-sm flex-centered'}>
                  {pipelineRun.completed_at && pipelineRun.executing_at
                    ? getDuration(pipelineRun.completed_at, pipelineRun.executing_at)
                    : '-'}
                </Text>
              </div>
              {pipeline?.version && (
                <div className={'flex-column'}>
                  <Text size={'sm'} color={'textTranslucent'} className={'mt-sm'}>
                    {translations.TABLE.PIPELINE_VERSION}
                  </Text>
                  <Text size={'base'} className={'mt-sm flex-centered'}>
                    <CopyText text={pipeline.version} showText />
                  </Text>
                </div>
              )}
            </div>
          </LeftPart>
          <RightPart className={'flex justify-end'}>
            {getIsWaitingForApproval(pipelineRun) && (
              <CustomWalButton
                disabled={isCancelling}
                className={'mr-md'}
                variant={'primary'}
                size={'medium'}
                onMouseEnter={() =>
                  handlePrefetchPipelineRunApprovalRequests(pipelineRun.app_id, pipelineRun.id)
                }
                onClick={() => {
                  handleReviewPipelineRun(
                    pipelineRun.id,
                    pipelineRun.app_id,
                    pipelineRun.pipeline_id
                  );
                }}>
                {translations.REVIEW_APPROVALS}
              </CustomWalButton>
            )}
            {pipelineRun.status === 'executing' ? (
              <CustomWalButton
                variant={'secondary'}
                size={'medium'}
                onClick={handleCancelPipelineRun}
                loading={isCancelling}
                disabled={isCancelling}>
                {isCancelling ? translations.CANCELLING : translations.CANCEL_RUN}
              </CustomWalButton>
            ) : (
              <CustomWalButton
                variant={'primary'}
                size={'medium'}
                onClick={() => setOpenTriggerPipelineForm(true)}
                loading={isCancelling}
                disabled={isCancelling}>
                {isCancelling ? translations.CANCELLING : translations.RERUN}
              </CustomWalButton>
            )}
          </RightPart>
        </Wrapper>
        {pipelineRun.status === 'failed' && (
          <WarningSection mode={'alert'} className={'mt-xl'}>
            <ErrorCard>
              <ErrorWrapper>
                <Text color={'textTranslucent'} size={'sm'}>
                  {translations.ERROR.LABEL}
                </Text>
                <Text>
                  {lastRunJob.status_message}{' '}
                  {isStatusMessage(lastRunJob.status_message) &&
                    approver &&
                    `${uiTranslations.BY} ${approver?.name}`}
                </Text>
              </ErrorWrapper>
              <ErrorWrapper>
                <Text color={'textTranslucent'} size={'sm'}>
                  {translations.ERROR.JOB}
                </Text>
                <Text>{lastRunJobId}</Text>
              </ErrorWrapper>
              <ErrorWrapper>
                <Text color={'textTranslucent'} size={'sm'}>
                  {translations.ERROR.STEP}
                </Text>
                <Text>{`Step ${lastRunJob.steps?.length}` || '-'}</Text>
              </ErrorWrapper>
              <ErrorWrapper>
                <Text color={'textTranslucent'} size={'sm'}>
                  {translations.ERROR.TIME}
                </Text>
                <Text>
                  {lastRunJob?.steps?.length
                    ? formatDate(
                        lastRunJob?.steps[lastRunJob.steps?.length - 1].completed_at,
                        DATE_FORMATS_TYPES.HOUR_MINUTE_DATE_MONTH_YEAR
                      )
                    : '-'}
                </Text>
              </ErrorWrapper>
            </ErrorCard>
          </WarningSection>
        )}
        <PipelineJobs />
      </div>

      {openTriggerPipelineForm && (
        <TriggerPipelineRunModal
          openState={[openTriggerPipelineForm, setOpenTriggerPipelineForm]}
          pipelineId={pipelineRun.pipeline_id}
          pipelineRun={pipelineRun}
        />
      )}
      {showReviewModal && (
        <ReviewsApproval {...reviewProps} openState={[showReviewModal, setShowReviewModal]} />
      )}
    </Container>
  );
};
