import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import { cl } from '@src/styles/global-styles';
import { generateAppURL } from '@src/utilities/navigation';

const AppValuesLink = () => {
  // i18n
  const { t } = useTranslation();
  const configsTranslations = t('VIEW_MODULE').CONFIGS;

  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  return (
    <span {...cl('txt-sm')}>
      {configsTranslations.EDIT_APP_DEFAULTS}
      <Link
        {...cl('ml-sm')}
        to={{
          pathname: `${generateAppURL(orgId, appId)}/${'values-and-secrets'}`,
        }}>
        {configsTranslations.SHARED_APP_VALUES}
      </Link>
    </span>
  );
};

export default AppValuesLink;
