import { isEqual } from 'lodash';
import { rem } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import SkeletonGroup from '@src/components/shared/Skeleton/SkeletonGroup';

import { WalButton } from '../../base/Button/Button';
import { Text } from '../../base/Text/Text';
import MultiFilter, { FilterByOptions, FilterConditions } from '../MultiFilter/MultiFilter';
import { WalTable, WalTableProps, WalTableRow } from '../Table/Table';

const NoItemsMessage = styled.p`
  text-align: center;
  justify-content: center;
  margin-top: 30%;
  width: 100%;
  flex-direction: column;
`;

const SkeletonWrapper = styled.span`
  margin-top: ${rem(50)};
  display: flex;
  width: 100%;
  flex-direction: column;
`;

interface MultiFilterTableProps extends WalTableProps {
  filterByOptions: FilterByOptions;
  onFiltersChange: (conditions?: FilterConditions) => void;
  filteredRows?: WalTableRow[];
  /* text that will be shown when no items corespoonds to the applied filters */
  noItemsText?: string;
  /* the initial number of rows before filtering */
  initialRowsCount: number;
  /* custom message to be displayed when filter is applied */
  hideFilterMessage?: boolean;
  defaultConditions?: FilterConditions;
  /* the loading state */
  isLoading?: boolean;
  infiniteScroll?: {
    isLoadingNextPage: boolean;
    hasNextPage: boolean;
    fetchNextPage: () => void;
    hasError: boolean;
  };
}

const MultiFilterTable = ({
  filterByOptions,
  onFiltersChange,
  initialRowsCount,
  noItemsText,
  hideFilterMessage = false,
  defaultConditions = {},
  isLoading,
  ...tableProps
}: MultiFilterTableProps) => {
  const { rows } = tableProps;
  const [filterConditions, setFilterConditions] = useState<FilterConditions | undefined>(
    defaultConditions
  );
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const [_, setSearchParams] = useSearchParams();

  const handleFilterChange = (conditions: FilterConditions) => {
    setFilterConditions(conditions);
    onFiltersChange(conditions);
  };

  const handleClearFilter = () => {
    setFilterConditions(defaultConditions);
    onFiltersChange(defaultConditions);
    setSearchParams('');
  };

  const generateFilterMessage = (
    currentRows: any[] | undefined,
    initialRowsLength: number,
    filtersHiddenText: string
  ) => {
    if (currentRows && currentRows.length !== initialRowsLength) {
      const hiddenCount = initialRowsLength - (rows.length || 0);
      return `${hiddenCount} ${filtersHiddenText}`;
    }
    return '';
  };

  const areFiltersApplied = !isEqual(filterConditions, defaultConditions);
  const isNoItems = rows?.length === 0;

  return (
    <div className={'flex-centered mb-md'}>
      <MultiFilter
        disabled={isLoading}
        filterByOptions={filterByOptions}
        onFiltersChange={handleFilterChange}
        defaultConditions={filterConditions}
      />

      {areFiltersApplied && (
        <span>
          {!hideFilterMessage && (
            <Text size={'sm'} className={'mr-sm'}>
              {generateFilterMessage(rows, initialRowsCount, uiTranslations.FILTERS_HIDDEN)}
            </Text>
          )}
          <WalButton variant={'borderless-blue'} onClick={handleClearFilter}>
            {uiTranslations.CLEAR_FILTERS}
          </WalButton>
        </span>
      )}

      {isLoading && (
        <SkeletonWrapper>
          <SkeletonGroup count={10} itemProps={{ height: 47 }} />
        </SkeletonWrapper>
      )}

      {isNoItems && !isLoading && (
        <NoItemsMessage className={'flex-centered mb-md'}>
          {noItemsText || uiTranslations.FILTER_NO_MATCHING_ITEMS}
        </NoItemsMessage>
      )}

      {!isLoading && <WalTable {...tableProps} rows={rows} />}
    </div>
  );
};

export default MultiFilterTable;
