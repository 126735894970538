import {
  ComboSelect,
  ConfirmationDialog,
  DropdownItem,
  MenuItem,
  Toggle,
  WalButton,
  WalCard,
  WalDropdownMenu,
  WalInput,
  WalMenu,
} from '@humanitec/ui-components';
import { rem } from 'polished';
import { KeyboardEvent, MouseEvent, ReactNode, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { css } from 'styled-components/macro';

import { useWalhallContext } from '@src/context/walhallContext';
import useArtefactsQuery from '@src/hooks/react-query/artefacts/queries/useArtefactsQuery';
import useEnvironmentRuleCreateMutation from '@src/hooks/react-query/rules/mutations/useEnvironmentRuleCreateMutation';
import useEnvironmentRuleDeleteMutation from '@src/hooks/react-query/rules/mutations/useEnvironmentRuleDeleteMutation';
import useEnvironmentRuleUpdateMutation from '@src/hooks/react-query/rules/mutations/useEnvironmentRuleUpdateMutation';
import { useRBAC } from '@src/hooks/useRBAC';
import { Rule } from '@src/models/rule';
import { cl, st } from '@src/styles/global-styles';
import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';
import { useWalhallForm } from '@src/utilities/form';

const ColumnText = styled.span`
  margin-top: ${rem(4)};
  ${st('txt-sm')}
`;

const ActiveToggle = styled(Toggle)`
  align-self: baseline;
  margin-top: ${rem(3)};
`;

const gridTemplate = css`
  grid-template-columns:
    ${rem(110)} ${rem(130)} 1fr minmax(${rem(100)}, 1fr)
    ${rem(50)}
    ${rem(100)};
`;

const RuleRow = styled.div<{ mode: 'edit' | 'view' }>`
  position: relative;
  display: grid;
  ${gridTemplate};
  grid-column-gap: ${units.margin.sm};
  margin: ${units.margin.sm} 0;
`;

const HeadersRow = styled.div<{ mode: 'edit' | 'view' }>`
  display: grid;
  ${gridTemplate};
  grid-column-gap: ${units.margin.sm};
`;

const Header = ({ children }: { children: ReactNode }) => (
  <div {...cl('txt-sm', 'txt-translucent')}>{children}</div>
);

const RuleButtons = styled.div`
  display: flex;
  align-items: center;
  align-self: baseline;
  justify-self: flex-end;
  justify-content: flex-end;
`;

const RuleStatus = styled.div<{ $active: boolean; $isLightTheme: boolean }>`
  background-color: ${({ theme }) => theme.color.baseDarker};
  font-size: ${rem(11)};
  line-height: ${rem(16)};
  padding: ${rem(3)} ${rem(7)};
  border-radius: ${rem(4)};
  margin-right: ${units.margin.md};
  ${({ theme, $isLightTheme }) =>
    $isLightTheme &&
    css`
      color: ${theme.color.white};
    `}
  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.color.green};
    `}
`;

interface EnvRuleEntryProps {
  /* Env Rule object */
  rule: Rule;
  /* If the component is used for creating new env rule*/
  newlyCreated?: boolean;
  /* when user clicks discard , remove add or edit form */
  onDiscard?: () => void;
}

type RuleForType = 'all-images' | 'selected-images' | 'all-images-except';

const getRuleForDisplayedText = (value: RuleForType) => {
  switch (value) {
    case 'selected-images':
      return 'Selected images';
    case 'all-images-except':
      return 'All images except';
    default:
      return 'All Images';
  }
};

const EnvRuleEntry = ({ rule, newlyCreated, onDiscard }: EnvRuleEntryProps) => {
  // Component state
  const [ruleForType, setRuleForType] = useState<RuleForType>('all-images');
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [editedRule, setEditedRule] = useState<Rule>(rule);
  const [confirmDeleteBoxHidden, setConfirmDeleteBoxHidden] = useState<boolean>(true);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const envAutomationModalTranslations = t('ENVIRONMENT_AUTOMATION_MODAL', {
    returnObjects: true,
  });

  // react query
  const { data: artefacts = [] } = useArtefactsQuery();
  const { mutate: createRule } = useEnvironmentRuleCreateMutation();
  const { mutate: updateRule } = useEnvironmentRuleUpdateMutation();
  const { mutate: deleteRule } = useEnvironmentRuleDeleteMutation();

  // Context
  const { theme } = useWalhallContext();

  const canDeployEnv = useRBAC('deployEnvironment');

  const activeName = `active-${rule.id}`;

  // Form
  const formMethods = useWalhallForm({
    defaultValues: {
      images: '',
      matches: rule.match_ref,
      [activeName]: rule.active,
    },
  });

  const { setValue } = formMethods;

  const ruleForOption: DropdownItem<RuleForType>[] = [
    { label: envAutomationModalTranslations.ALL_IMAGES, value: 'all-images', id: 'all-images' },
    {
      label: envAutomationModalTranslations.SELECTED_IMAGES,
      value: 'selected-images',
      id: 'selected-images',
    },
    {
      label: envAutomationModalTranslations.ALL_IMAGES_EXCEPT,
      value: 'all-images-except',
      id: 'all-images-except',
    },
  ];

  /**
   * Rule active/inactive can be set from outside the form(through menu). So set the form value when it changes to keep it up to date.
   */
  useEffect(() => {
    setValue(activeName, rule.active);
  }, [setValue, rule.active, activeName]);

  useEffect(() => {
    if (newlyCreated) {
      setMode('edit');
    } else {
      setMode('view');
    }
  }, [newlyCreated]);

  /**
   * Find rule for type.
   */
  useEffect(() => {
    setRuleForType(
      rule.artefacts_filter?.length === 0
        ? 'all-images'
        : rule.exclude_artefacts_filter
          ? 'all-images-except'
          : 'selected-images'
    );
  }, [rule]);

  const updateRuleInUi = (
    ruleKey: 'active' | 'forImage' | 'artefacts_filter' | 'match_ref',
    value: string | string[] | boolean
  ) => {
    let newRule = { ...editedRule };

    if (ruleKey === 'forImage') {
      if (value !== 'all-images') {
        newRule = {
          ...editedRule,
          exclude_artefacts_filter: Boolean(value === 'all-images-except'),
        };
      } else {
        setRuleForType(value);
        newRule.artefacts_filter = [];
        newRule.exclude_artefacts_filter = false;
      }
    } else {
      newRule = {
        ...editedRule,
        [ruleKey]: value,
      };
    }

    if (
      ruleKey === 'forImage' &&
      (value === 'selected-images' || value === 'all-images-except') &&
      !newRule.artefacts_filter.length
    ) {
      setRuleForType(value);
    }
    setEditedRule(newRule);
  };

  useEffect(() => {
    setEditedRule(rule);
  }, [rule]);

  const onSubmitChanges = (e: MouseEvent) => {
    e.stopPropagation();
    if (newlyCreated) {
      createRule({ newRule: editedRule });
    } else {
      updateRule({ rule: editedRule });
    }
    if (onDiscard) onDiscard();
    setMode('view');
  };

  const discardChanges = (e: MouseEvent) => {
    if (newlyCreated) {
      if (onDiscard) onDiscard();
    } else {
      setEditedRule(rule);
      setMode('view');
    }
    e.stopPropagation();
  };

  const onMenuItemClick = (item: MenuItem<string>) => {
    if (item.value === 'edit') {
      setMode('edit');
    }
    if (item.value === 'delete') {
      setConfirmDeleteBoxHidden(false);
    }
    if (item.value === 'toggle') {
      const newRule = {
        ...rule,
        active: !rule.active,
      };
      updateRule({ rule: newRule });
    }
  };

  const handleDeleteRule = () => {
    deleteRule({ ruleId: rule.id });
  };

  const ruleMenuItems: MenuItem<string>[] = [
    { label: uiTranslations.EDIT, value: 'edit' },
    { label: rule.active ? uiTranslations.DEACTIVATE : uiTranslations.ACTIVATE, value: 'toggle' },
    { label: uiTranslations.DELETE, value: 'delete' },
  ];

  const handleRuleClick = () => {
    setMode('edit');
  };

  const handleRuleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KeyBindings.ENTER) {
      setMode('edit');
    }
  };

  return (
    <WalCard
      {...cl('mb-md', 'full-width')}
      tabIndex={0}
      onClick={handleRuleClick}
      onKeyDown={handleRuleKeyDown}
      highlightOnHover={mode === 'view'}>
      <HeadersRow mode={mode}>
        <Header>{envAutomationModalTranslations.RUN}</Header>
        <Header>{envAutomationModalTranslations.FOR}</Header>
        <Header>{envAutomationModalTranslations.IMAGES}</Header>
        <Header>{envAutomationModalTranslations.THAT_MATCHES}</Header>
        {mode === 'edit' && <Header>{envAutomationModalTranslations.ACTIVE}</Header>}
      </HeadersRow>
      <FormProvider {...formMethods}>
        <RuleRow mode={mode}>
          <ColumnText>{envAutomationModalTranslations.ON_IMAGE_UPDATE}</ColumnText>
          {mode === 'edit' ? (
            <WalDropdownMenu
              items={ruleForOption}
              defaultValue={ruleForType}
              buttonVariant={'input'}
              onItemClick={(id, item) => updateRuleInUi('forImage', item.id)}
              fullWidth
              disabled={!canDeployEnv}
              buttonSize={'small'}
            />
          ) : (
            <ColumnText>{getRuleForDisplayedText(ruleForType)}</ColumnText>
          )}
          <div>
            {mode === 'edit' ? (
              ruleForType !== 'all-images' ? (
                <ComboSelect
                  defaultValues={editedRule.artefacts_filter}
                  items={
                    artefacts?.map((artefact) => ({
                      label: artefact.name,
                      value: artefact,
                      searchString: artefact.name,
                      id: artefact.name,
                    })) ?? []
                  }
                  maxHeight={200}
                  maxWidth={300}
                  inputPlaceholder={'image-name'}
                  onChange={(value) => updateRuleInUi('artefacts_filter', value)}
                  name={'images'}
                  inputSize={'small'}
                  hideLabel
                  invertEllipsis
                  noTooltipChips
                  readonly={!canDeployEnv}
                />
              ) : (
                <ColumnText>{'-'}</ColumnText>
              )
            ) : (
              <ColumnText>
                {rule.artefacts_filter.length > 0 ? rule.artefacts_filter.join(', ') : '-'}
              </ColumnText>
            )}
          </div>
          {mode === 'edit' ? (
            <WalInput
              name={'matches'}
              size={'small'}
              hideLabel
              placeholder={'refs/*'}
              readonly={!canDeployEnv}
              onBlur={(inputValue: string) => updateRuleInUi('match_ref', inputValue)}
            />
          ) : (
            <ColumnText>{rule.match_ref || 'refs/*'}</ColumnText>
          )}
          {mode === 'edit' ? (
            <ActiveToggle
              name={activeName}
              size={'small'}
              disabled={!canDeployEnv}
              defaultChecked={rule.active}
              onChange={(checked) => updateRuleInUi('active', checked)}
            />
          ) : (
            // Extra dive to match the amount of colums in edit mode. Otherwise grid will not match when edit & view are open side by side
            <div />
          )}
          {canDeployEnv &&
            (mode === 'edit' ? (
              <RuleButtons>
                <WalButton
                  {...cl('mr-sm')}
                  variant={'secondary'}
                  size={'small'}
                  onClick={discardChanges}>
                  {uiTranslations.DISCARD}
                </WalButton>
                <WalButton
                  variant={'primary'}
                  size={'small'}
                  type={'submit'}
                  iconLeft={'checkmark'}
                  ariaLabel={envAutomationModalTranslations.SUBMIT_RULE_CHANGES}
                  onKeyDown={(e) => e.stopPropagation()}
                  onClick={onSubmitChanges}
                />
              </RuleButtons>
            ) : (
              <RuleButtons>
                <RuleStatus $active={rule.active} $isLightTheme={theme === 'light'}>
                  {rule.active ? uiTranslations.ACTIVE : uiTranslations.INACTIVE}
                </RuleStatus>
                <WalMenu
                  standardToggle={{ type: 'dots', objectType: 'automation rule', objectName: '' }}
                  items={ruleMenuItems}
                  panel={{ placement: 'bottom-end' }}
                  onItemClick={onMenuItemClick}
                />
                {!confirmDeleteBoxHidden && (
                  <ConfirmationDialog
                    hiddenState={[confirmDeleteBoxHidden, setConfirmDeleteBoxHidden]}
                    onConfirm={handleDeleteRule}
                  />
                )}
              </RuleButtons>
            ))}
        </RuleRow>
      </FormProvider>
    </WalCard>
  );
};

export default EnvRuleEntry;
