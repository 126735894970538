import { ExpandableCard, InfoPopup } from '@humanitec/ui-components';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { SectionTileProps } from '../../SectionTile';

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const SectionCard = styled(ExpandableCard)<{ order?: number }>`
  margin-bottom: ${units.margin.lg};
  ${({ order }) =>
    order &&
    css`
      order: ${order};
    `}
`;

interface WorkloadFeatureBaseProps extends SectionTileProps {
  expandedByDefault: boolean;
  id: string;
  order?: number;
}

/**
 * Workload feature component without the type: 'delta' | 'runtime' differentiation.
 * The id & expandedByDefault props are passed directly here, whereas in the `WorkloadFeature` component, we pass featureType.
 */
const WorkloadFeatureBase = ({
  expandedByDefault,
  id,
  title,
  children,
  infoPopup,
  order,
}: WorkloadFeatureBaseProps) => {
  return (
    <SectionCard
      order={order}
      expandedByDefault={expandedByDefault}
      cardStyle={'default'}
      id={id}
      title={title}
      allowExpandedUpdateAfterFirstRender
      headerContent={
        <Title className={'txt-lg'}>
          {title}
          {infoPopup?.text && (
            <InfoPopup
              moreInformationLink={infoPopup.link}
              text={infoPopup.text}
              position={infoPopup.position}
              width={infoPopup.width}
            />
          )}
        </Title>
      }
      content={children}
    />
  );
};

export default WorkloadFeatureBase;
