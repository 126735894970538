import { Checkbox, SearchInput, WalTable, WalTableRow } from '@humanitec/ui-components';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useUserRolesQuery from '@src/hooks/react-query/user/useUserRolesQuery';
import { EnvironmentType } from '@src/models/environment-type';
import { MatchParams } from '@src/models/routing';
import { useWalhallForm } from '@src/utilities/form';

import AdministratorWarning from '../../../components/AdministratorWarning';

const EnvironmentTypesTable = styled(WalTable)`
  width: 50%;
`;

const OrgMemberEnvTypeRoles = () => {
  // Component state
  const [filterValue, setFilterValue] = useState<string>();

  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // Form
  const formMethods = useWalhallForm();

  // React Query
  const { data: environmentTypes } = useEnvironmentTypesQuery();
  const { data: userRoles } = useUserRolesQuery();

  // i18n
  const { t } = useTranslation('orgMembers');

  const translations = t('ENVIRONMENT_TYPES_TAB');

  return (
    <>
      <AdministratorWarning type={'env-type'} />
      <SearchInput
        name={'filter-env-type'}
        onChange={setFilterValue}
        placeholder={translations.FIND_ENV_TYPE}
      />
      <FormProvider {...formMethods}>
        <EnvironmentTypesTable
          caption={translations.TABLE_CAPTION}
          columns={[
            {
              prop: 'environment-type',
              label: translations.ENVIRONMENT_TYPE,
              template: ({ data }: WalTableRow<EnvironmentType>) => data.id,
            },
            {
              prop: 'can-deploy',
              label: translations.CAN_DEPLOY,
              template: ({ data }: WalTableRow<EnvironmentType>) => (
                <Checkbox
                  name={data.id}
                  defaultChecked={Boolean(userRoles?.[`/orgs/${orgId}/env-types/${data.id}`])}
                  readonly
                />
              ),
              justifyContent: 'flex-start',
            },
          ]}
          rows={
            (filterValue
              ? environmentTypes?.filter((environmentType) =>
                  environmentType.id.toLowerCase()?.includes(filterValue.toLowerCase())
                )
              : environmentTypes
            )?.map((environmentType) => ({ data: environmentType })) ?? []
          }
        />
      </FormProvider>
    </>
  );
};

export default OrgMemberEnvTypeRoles;
