import { CardStyles, Icon, WalCard } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Status from '@src/components/shared/Status';
import useDeploymentErrorsQuery from '@src/hooks/react-query/environments/queries/useDeploymentErrorsQuery';
import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import useEnvironmentRuntimeQuery from '@src/hooks/react-query/environments/queries/useEnvironmentRuntimeQuery';
import { useFeature } from '@src/hooks/useFeature';
import { DeploymentObject } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateAppURL, generateDeploymentURL } from '@src/utilities/navigation';

const EnvCard = styled(WalCard)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${units.margin.md};
`;

const PausedBadge = styled.span`
  display: flex;
  align-items: center;
`;

interface EnvironmentCardProps {
  appId: string;
  envId: string;
  environmentType: string;
  cardStyle?: CardStyles;
  lastDeploy?: DeploymentObject;
  showStatus?: boolean;
}

const EnvironmentCard = ({
  appId,
  envId,
  environmentType,
  cardStyle,
  lastDeploy,
  showStatus,
}: EnvironmentCardProps) => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // i18n
  const { t } = useTranslation();
  const { t: tViewApplicationList } = useTranslation('viewApplicationList');
  const uiTranslations = t('UI');
  const envCardTranslations = tViewApplicationList('ENVIRONMENT_CARD');

  const { data: envPauseStatusMap } = useEnvironmentPauseStatusQuery({ appId });

  // React Query
  const { data: environmentRuntimeData } = useEnvironmentRuntimeQuery(showStatus, { appId, envId });
  const { environmentRuntime } = environmentRuntimeData || {};
  const { data: lastDeploymentErrors } = useDeploymentErrorsQuery(
    lastDeploy?.id,
    showStatus && lastDeploy?.status === 'failed',
    { appId, envId }
  );

  // Optimizely
  const [isEnvStatusEnabled] = useFeature('env-card-status');

  const hasRunningPods = Boolean(Object.keys(environmentRuntime?.modules || {}).length);

  const deploymentErrorsCount = lastDeploymentErrors?.length || 0;

  // Needs clarification
  const runtimeErrorsCount = Object.values(environmentRuntime?.modules || {})?.filter(
    (workloadRuntime) => workloadRuntime?.status_class === 'Failure'
  ).length;

  const hasError = deploymentErrorsCount > 0 || runtimeErrorsCount > 0;

  const getStatusText = () => {
    let statusText = '';
    if (deploymentErrorsCount > 0) {
      statusText =
        statusText +
        `${deploymentErrorsCount} ${
          deploymentErrorsCount === 1
            ? envCardTranslations.DEPLOYMENT_ERROR
            : envCardTranslations.DEPLOYMENT_ERRORS
        }`;
    }
    if (runtimeErrorsCount > 0) {
      statusText =
        statusText +
        `${deploymentErrorsCount > 0 ? ', ' : ''}${runtimeErrorsCount} ${
          runtimeErrorsCount === 1
            ? envCardTranslations.RUNTIME_ERROR
            : envCardTranslations.RUNTIME_ERRORS
        }`;
    }
    return statusText;
  };
  return (
    <EnvCard cardStyle={cardStyle} key={envId} dataTestId={'environment-card'}>
      <div>
        <Link to={generateAppURL(orgId, appId, envId)} className={'title-link'}>
          {envId}
        </Link>
        <span className={'txt-sm txt-translucent'}>{environmentType}</span>
      </div>

      {envPauseStatusMap?.[envId] ? (
        <PausedBadge>
          <Icon name={'pause-in-circle'} overrideColor={'yellow'} marginRight={'sm'} size={14} />
          <span className={'txt-xs'}>{uiTranslations.PAUSED}</span>
        </PausedBadge>
      ) : (
        showStatus &&
        lastDeploy &&
        hasRunningPods &&
        isEnvStatusEnabled && (
          <Status
            color={hasError ? 'red' : 'green'}
            link={
              lastDeploy && generateDeploymentURL(orgId, appId, envId, lastDeploy?.id, 'workloads')
            }
            text={hasError ? getStatusText() : envCardTranslations.RUNNING}
          />
        )
      )}
    </EnvCard>
  );
};

export default EnvironmentCard;
