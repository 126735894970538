import { WalButton } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import AddContainerImageModal from '@src/components/shared/AddContainerImageModal/AddContainerImageModal';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { units } from '@src/styles/variables';

import ContainerCard from './components/ContainerCard';

const AddButton = styled(WalButton)`
  margin-bottom: ${units.margin.md};
`;

interface ContainersProps {
  deltaPath: string;
  featureKey: string;
}

const Containers = ({ deltaPath, featureKey }: ContainersProps) => {
  const [openAddContainerModal, setOpenAddContainerModal] = useState<boolean>(false);

  const { data } = useDeltaUtils(deltaPath);
  const containerIds = Object.keys(data || {});

  // i18n
  const { t } = useTranslation('workloadProfile');

  const containersTranslations = t('FEATURES').CONTAINERS;

  const { draftModeActive } = useDeploymentOrDeltaContext();

  return (
    <>
      {draftModeActive && (
        <>
          <AddButton
            variant={'secondary'}
            iconLeft={'plus'}
            onClick={() => setOpenAddContainerModal(true)}>
            {containersTranslations.ADD_CONTAINER}
          </AddButton>
          {openAddContainerModal && (
            <AddContainerImageModal
              openState={[openAddContainerModal, setOpenAddContainerModal]}
              deltaPath={deltaPath}
              currentContainerIds={containerIds}
            />
          )}
        </>
      )}
      {containerIds.length
        ? containerIds.map((containerId, index) => {
            const to = `${featureKey}/${containerId}`;

            return (
              <ContainerCard
                last={containerIds.length === index + 1}
                containerId={containerId}
                to={to}
                readonly={!draftModeActive}
                key={containerId}
                deltaPath={`${deltaPath}/${containerId}`}
              />
            );
          })
        : !draftModeActive && containersTranslations.NO_CONTAINERS_DEFINED}
    </>
  );
};

export default Containers;
