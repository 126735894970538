import { CopyText, WalButton, WalLabel } from '@humanitec/ui-components';
import { AxiosResponse } from 'axios';
import { rem } from 'polished';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import ChangeImageModal from '@src/components/shared/AddContainerImageModal/ChangeImageModal';
import ImageVersionModal from '@src/components/shared/ImageVersionsModal/ImageVersionsModal';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { ArtefactVersion } from '@src/models/artefact';
import { MatchParams } from '@src/models/routing';
import { flexCentered, flexColumn } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { getArtefactsByReference } from '@src/utilities/get-artefacts-by-reference';

const Column = styled.div`
  ${flexColumn()};
  margin-right: ${units.margin.lg};
`;

const Row = styled.div`
  ${flexCentered()};
  width: 100%;
  flex: 1;
  margin-bottom: ${units.margin.md};
  p {
    font-size: ${units.fontSize.sm};
  }
`;

const ContainerImageWrapper = styled.div`
  position: relative;
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: ${units.margin.md};
  align-items: center;
  right: 0;
  top: ${rem(-32)};
`;

interface ContainerImageProps {
  deltaPath: string;
}

export const ContainerImage = ({ deltaPath }: ContainerImageProps) => {
  // Component state
  const [imageIsExternal, setImageIsExternal] = useState(false);
  const [artefactVersions, setArtefactVersions] = useState<ArtefactVersion[]>();
  const [openChangeImageVersionModal, setOpenChangeImageVersionModal] = useState<boolean>(false);
  const [openSelectImageModal, setOpenSelectImageModal] = useState<boolean>(false);

  // i18n
  const { t } = useTranslation();
  const imageTranslations = t('VIEW_MODULE').IMAGE;

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data: imageName } = useDeltaUtils<string>(deltaPath);

  const currentArtefactVersion: ArtefactVersion | undefined = useMemo(() => {
    return artefactVersions?.find((artefactVersion) => {
      const { name, digest, version } = artefactVersion;

      const imageByDigest = digest && `${name}@${digest}`;
      const imageByVersion = version && `${name}:${version}`;

      return (imageByDigest || imageByVersion) === imageName;
    });
  }, [artefactVersions, imageName]);

  useEffect(() => {
    if (imageName) {
      getArtefactsByReference(orgId, imageName)
        .then((res: AxiosResponse) => {
          setArtefactVersions(res.data);
          if (res.data?.length === 0) {
            setImageIsExternal(true);
          } else {
            setImageIsExternal(false);
          }
        })
        .catch(() => {
          setArtefactVersions([]);
          setImageIsExternal(false);
        });
    }
  }, [imageName, orgId]);

  return (
    <ContainerImageWrapper>
      {draftModeActive && (
        <ButtonsWrapper>
          <WalButton variant={'secondary'} onClick={() => setOpenSelectImageModal(true)}>
            {imageTranslations.CHANGE_IMAGE}
          </WalButton>
          {!imageIsExternal && currentArtefactVersion && (
            <WalButton onClick={() => setOpenChangeImageVersionModal(true)} variant={'secondary'}>
              {imageTranslations.CHANGE_VERSION}
            </WalButton>
          )}
        </ButtonsWrapper>
      )}

      <>
        {!imageIsExternal && currentArtefactVersion ? (
          <>
            {currentArtefactVersion?.name && (
              <Row>
                <Column>
                  <WalLabel>{imageTranslations.IMAGE_NAME}</WalLabel>
                  <span className={'txt-sm'}>{currentArtefactVersion?.name}</span>
                </Column>
                {currentArtefactVersion?.version && (
                  <Column>
                    <WalLabel>{imageTranslations.IMAGE_TAG}</WalLabel>
                    <span className={'txt-sm'}>{currentArtefactVersion?.version}</span>
                  </Column>
                )}
              </Row>
            )}
            {currentArtefactVersion?.commit && (
              <Row>
                <Column>
                  <WalLabel>{imageTranslations.COMMIT_ID}</WalLabel>
                  <CopyText
                    text={currentArtefactVersion?.commit}
                    showText
                    textClassName={'txt-sm'}
                  />
                </Column>
              </Row>
            )}
            <Row>
              {currentArtefactVersion?.ref && (
                <Column>
                  <WalLabel>{imageTranslations.REFERENCE}</WalLabel>
                  <CopyText text={currentArtefactVersion?.ref} showText textClassName={'txt-sm'} />
                </Column>
              )}
            </Row>
            {currentArtefactVersion?.created_at && (
              <Row>
                <Column>
                  <WalLabel>{imageTranslations.ADDED_AT}</WalLabel>
                  <span className={'txt-sm'}>
                    {formatDate(
                      currentArtefactVersion?.created_at,
                      DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
                    )}
                  </span>
                </Column>
              </Row>
            )}
            {currentArtefactVersion?.digest && (
              <Row>
                <Column>
                  <WalLabel>{imageTranslations.DIGEST}</WalLabel>
                  <CopyText
                    text={currentArtefactVersion?.digest}
                    showText
                    textClassName={'txt-sm'}
                  />
                </Column>
              </Row>
            )}
          </>
        ) : (
          <Row>
            <Column>
              <WalLabel>{imageTranslations.IMAGE_NAME}</WalLabel>
              {imageName && <CopyText text={imageName} showText textClassName={'txt-sm'} />}
            </Column>
          </Row>
        )}
      </>

      {openChangeImageVersionModal && currentArtefactVersion?.artefact_id && (
        <ImageVersionModal
          openState={[openChangeImageVersionModal, setOpenChangeImageVersionModal]}
          artefactId={currentArtefactVersion.artefact_id}
          currentArtefactVersionId={currentArtefactVersion.id}
          title={imageTranslations.SELECT_IMAGE_VERSION}
          mode={'select-version'}
          deltaPath={deltaPath}
        />
      )}
      {openSelectImageModal && (
        <ChangeImageModal
          openState={[openSelectImageModal, setOpenSelectImageModal]}
          currentImageIsExternal={imageIsExternal}
          deltaPath={deltaPath}
        />
      )}
    </ContainerImageWrapper>
  );
};
