import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getFilterString } from '@src/containers/Orgs/PipelineRuns/utils';
import type { QueryResponse } from '@src/hooks/react-query/types';
import { Pipeline } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelinesQueryKeys } from '../pipelinesQueryKeys';

const usePipelinesQuery = (filters?: {
  'metadata[humanitec.io/ui-action]': 'deploy' | 're-deploy';
}): QueryResponse<Pipeline[]> => {
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: pipelinesQueryKeys.list(orgId, appId, filters),
    queryFn: () => {
      return makeRequest<Pipeline[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipelines${getFilterString(filters)}`
      );
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(orgId && appId),
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelinesQuery;
