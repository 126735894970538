import { Icon, IconNames, iconNamesArray, WalCard, WalLabel } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { ResourceCategory, ResourceType } from '@src/models/resources';
import { units } from '@src/styles/variables';
import { allLowerCaseExceptFirstWord } from '@src/utilities/string-utility';

export const getResourceSection = (resourceCategory: ResourceCategory): ResourceTypeSection =>
  resourceCategory === 'k8s'
    ? 'cluster'
    : resourceCategory === 'datastore'
      ? 'datastore'
      : resourceCategory === 'dns' || resourceCategory === 'ingress'
        ? 'routing'
        : 'other';

interface SelectResourceTypeStepProps {
  resourceTypes: ResourceType[];
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setSelectedResourceType: Dispatch<SetStateAction<ResourceType | undefined>>;
}

const ResourceTypeCard = styled(WalCard)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${units.margin.sm};
`;

const CategoryWrapper = styled.div`
  margin-bottom: ${units.margin.md};
`;

export type ResourceTypeSection = 'cluster' | 'datastore' | 'routing' | 'other';

const SelectResourceTypeStep = ({
  resourceTypes,
  setCurrentStep,
  setSelectedResourceType,
}: SelectResourceTypeStepProps) => {
  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  const getResourceCategoryTitle = (category: ResourceTypeSection) => {
    let title;
    switch (category) {
      case 'cluster':
        title = resourcesTranslations.CLUSTER_RESOURCES;
        break;
      case 'datastore':
        title = resourcesTranslations.DATA_STORAGES;
        break;
      case 'routing':
        title = resourcesTranslations.ROUTING;
        break;
      default:
        title = resourcesTranslations.OTHER_RESOURCES;
    }
    return title;
  };

  const handleResourceTypeClick = (resourceType: ResourceType) => {
    setSelectedResourceType(resourceType);
    setCurrentStep(1);
  };

  const renderResourceTypes = () => {
    const sections: ResourceTypeSection[] = ['cluster', 'datastore', 'routing', 'other'];
    return sections.map((section) => (
      <CategoryWrapper key={section}>
        <WalLabel>{getResourceCategoryTitle(section)}</WalLabel>
        {resourceTypes
          .filter((resourceType) => getResourceSection(resourceType.category) === section)
          .map((resourceType) => (
            <ResourceTypeCard
              dataTestId={`${section} resource type card`}
              cardStyle={'base'}
              key={resourceType.name}
              highlightOnHover
              onClick={() => handleResourceTypeClick(resourceType)}
              showRightArrow>
              <div className={'flex-centered'}>
                <Icon
                  marginRight={'md'}
                  name={
                    resourceType.type.includes('aws') || resourceType.type === 'sqs'
                      ? 'amazon'
                      : iconNamesArray.includes(resourceType.type as IconNames)
                        ? (resourceType.type as IconNames)
                        : 'generic-resource'
                  }
                />
                {allLowerCaseExceptFirstWord(resourceType.name)}
              </div>
            </ResourceTypeCard>
          ))}
      </CategoryWrapper>
    ));
  };
  return <>{renderResourceTypes()}</>;
};

export default SelectResourceTypeStep;
