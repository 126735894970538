import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import { Rule } from '@src/models/rule';
import makeRequest from '@src/utilities/make-request';

import { rulesQueryKeys } from '../rulesQueryKeys';

const useEnvironmentRuleCreateMutation = () => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ newRule }: { newRule: Partial<Rule> }) =>
      makeRequest<Rule[]>('POST', `/orgs/${orgId}/apps/${appId}/envs/${envId}/rules`, newRule),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: rulesQueryKeys.list(orgId, appId, envId),
      });
    },
  });
};

export default useEnvironmentRuleCreateMutation;
