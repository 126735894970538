import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import PauseButtonSection from '@src/components/shared/PauseEnvironment/PauseButtonSection/PauseButtonSection';

export const ViewEnvironmentHeader = () => {
  return (
    <PageHeader
      rightContent={<PauseButtonSection disableInfoText disableWrapperPadding />}
      showPageContext
    />
  );
};
export default ViewEnvironmentHeader;
