import { EmptyStateCard, WalButton, WalTable, WalTableColumn } from '@humanitec/ui-components';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CloneModal from '@src/components/shared/CloneModal/CloneModal';
import DeploymentOverviewModal from '@src/components/shared/DeploymentOverviewModal/DeploymentOverviewModal';
import SkeletonGroup from '@src/components/shared/Skeleton/SkeletonGroup';
import ExportManifestModal from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/Deploys/components/components/ExportManifestModal/ExportManifestModal';
import { getCommonPipelineRunsColumns } from '@src/containers/Orgs/PipelineRuns/components/PipelineRunsList/PipelineRunsList';
import useDeploymentsListQuery from '@src/hooks/react-query/environments/queries/useDeploymentsListQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import usePipelineRunsByOrgQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunsByEnvironmentQuery';
import { useFeature } from '@src/hooks/useFeature';
import { useRBAC } from '@src/hooks/useRBAC';
import { DeploymentObject } from '@src/models/deployment-object';
import { PipelineRun } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateCompareDeploymentsURL, generateDeploymentURL } from '@src/utilities/navigation';

import DeploymentCard from './components/DeploymentCard';

const ShowMoreButton = styled(WalButton)`
  align-self: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units.margin.md};
`;

const DISPLAYED_DEPLOYMENTS = 10;

const DeploymentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${units.margin.sm};
`;

const DeploymentRowsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DeploymentButtons = styled.div`
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  column-gap: ${units.margin.sm};
`;

interface PipelineRunWithDeployment extends PipelineRun {
  deployments?: DeploymentObject[];
}

const Deploys = () => {
  // Component state
  const [displayedDeployments, setDisplayedDeployments] = useState(DISPLAYED_DEPLOYMENTS);
  // i18n
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const { t } = useTranslation();
  const deploysTranslations = tViewEnvironment('DEPLOYS');
  const uiTranslations = t('UI');

  // Optimizely
  const [isNewCreateDeltaTextEnabled] = useFeature('create-delta-text');
  const [isEnvStatusTabEnabled] = useFeature('env_status_tab');
  const [isNewDeploymentListEnabled] = useFeature('new_deployments_screen');

  // Router
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  // RBAC
  const canDeployEnv = useRBAC('deployEnvironment');

  // state
  const [deploymentOverviewModalOpen, setDeploymentOverviewModalOpen] = useState(false);
  const [exportManifestModalOpen, setExportManifestModalOpen] = useState(false);
  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [selectedDeployment, setSelectedDeployment] = useState<DeploymentObject>();

  // React Query
  const { data: environmentDeployments = [], isFetching: areEnvironmentDeploymentsLoading } =
    useDeploymentsListQuery();
  const { data: environmentPipelineRuns = [] } = usePipelineRunsByOrgQuery({ envId, appId });
  const { data: environment } = useEnvironmentQuery();

  const pastDeployments = useMemo(
    () => environmentDeployments.filter((deploy) => deploy.id !== environment?.last_deploy?.id),
    [environment?.last_deploy?.id, environmentDeployments]
  );

  const runsWithDeployments: PipelineRunWithDeployment[] = useMemo(() => {
    const runs: PipelineRunWithDeployment[] = [...environmentPipelineRuns];
    return runs.map((run) => {
      run.deployments = environmentDeployments.filter(
        (deploy) => deploy.pipeline?.run_id === run.id
      );
      return run;
    });
  }, [environmentPipelineRuns, environmentDeployments]);

  const header = (text: string) => <div className={'pb-md txt-md txt-translucent'}>{text}</div>;
  const hasDeployment = Boolean(pastDeployments.length || environment?.last_deploy);

  const tableColumns: WalTableColumn<PipelineRunWithDeployment>[] = [
    ...getCommonPipelineRunsColumns(
      deploysTranslations.TABLE.PIPELINE_RUN,
      deploysTranslations.TABLE.RUN_STATUS,
      orgId
    ),
    {
      label: deploysTranslations.TABLE.DEPLOYMENT_COMMENTS,
      prop: 'deployments',
      justifyContent: 'flex-start',
      template: (row) =>
        row.data.deployments && row.data.deployments.length > 0 ? (
          <DeploymentRowsWrapper>
            {row.data.deployments.map((deployment) => (
              <DeploymentRow key={deployment.id}>
                <Link
                  to={generateDeploymentURL(orgId, appId, envId, deployment.id, 'workloads')}
                  className={'title-link'}>
                  {deployment.comment || uiTranslations.NO_COMMENT}
                </Link>
                <DeploymentButtons>
                  <WalButton
                    variant={'secondary'}
                    size={'small'}
                    onClick={() => {
                      setSelectedDeployment(deployment);
                      setCloneModalOpen(true);
                    }}>
                    {deploysTranslations.TABLE.CLONE}
                  </WalButton>
                  <WalButton
                    variant={'secondary'}
                    size={'small'}
                    onClick={() => {
                      setSelectedDeployment(deployment);
                      setExportManifestModalOpen(true);
                    }}>
                    {deploysTranslations.TABLE.MANIFEST}
                  </WalButton>
                  {environment?.last_deploy && (
                    <WalButton
                      variant={'secondary'}
                      size={'small'}
                      link={{
                        to: generateCompareDeploymentsURL(
                          orgId,
                          appId,
                          envId,
                          deployment.id,
                          environment?.last_deploy?.id === deployment.id
                            ? pastDeployments[0]?.id || '0'
                            : environment?.last_deploy?.id
                        ),
                      }}>
                      {deploysTranslations.TABLE.DIFF}
                    </WalButton>
                  )}
                  {environment?.last_deploy?.id !== deployment.id && (
                    <WalButton
                      disabled={deployment?.status === 'in progress' || !canDeployEnv}
                      size={'small'}
                      variant={'secondary'}
                      onClick={() => {
                        setSelectedDeployment(deployment);
                        setDeploymentOverviewModalOpen(true);
                      }}>
                      {deployment?.status === 'in progress'
                        ? deploysTranslations.TABLE.CURRENTLY_DEPLOYING
                        : deploysTranslations.TABLE.REDEPLOY}
                    </WalButton>
                  )}
                </DeploymentButtons>
              </DeploymentRow>
            ))}
          </DeploymentRowsWrapper>
        ) : (
          <span>{deploysTranslations.TABLE.NO_DEPLOYMENT}</span>
        ),
    },
  ];

  return (
    <Wrapper>
      {areEnvironmentDeploymentsLoading ? (
        <span className={'flex-column gap-md'}>
          <SkeletonGroup count={3} />
        </span>
      ) : (
        <>
          {!hasDeployment && (
            <EmptyStateCard>
              <p className={isNewCreateDeltaTextEnabled ? 'mb-sm' : undefined}>
                {deploysTranslations.NO_DEPLOYS_TO_THIS_ENVIRONMENT}
              </p>
              {isNewCreateDeltaTextEnabled && (
                <p>
                  <Trans defaults={deploysTranslations.CLICK_CREATE_DELTA} />
                </p>
              )}
            </EmptyStateCard>
          )}
          {isNewDeploymentListEnabled ? (
            <WalTable
              rows={runsWithDeployments.map((run) => ({
                data: run,
                verticalAlignTop: run.deployments && run.deployments.length > 1,
              }))}
              columns={tableColumns}
              caption={deploysTranslations.TABLE.CAPTION}
            />
          ) : (
            <>
              {environment?.last_deploy?.set_id && !isEnvStatusTabEnabled && (
                <>
                  {header(deploysTranslations.ACTIVE_DEPLOY)}
                  <DeploymentCard
                    deploy={environment.last_deploy}
                    lastPastDeploymentId={pastDeployments[0]?.id}
                    isActiveDeployment
                  />
                </>
              )}
              {pastDeployments.length > 0 &&
                !isEnvStatusTabEnabled &&
                header(deploysTranslations.PREVIOUS_DEPLOYS)}
              {pastDeployments.slice(0, displayedDeployments).map((deploy) => (
                <DeploymentCard key={deploy.id} deploy={deploy} />
              ))}
              {pastDeployments.length > displayedDeployments && (
                <ShowMoreButton
                  variant={'secondary'}
                  onClick={() =>
                    setDisplayedDeployments((prevState) => prevState + DISPLAYED_DEPLOYMENTS)
                  }>
                  {deploysTranslations.SHOW_MORE}
                </ShowMoreButton>
              )}
            </>
          )}
        </>
      )}
      {deploymentOverviewModalOpen && selectedDeployment && (
        <DeploymentOverviewModal
          openState={[deploymentOverviewModalOpen, setDeploymentOverviewModalOpen]}
          deploymentType={{ type: 'redeploy', data: selectedDeployment }}
        />
      )}
      {cloneModalOpen && selectedDeployment && (
        <CloneModal
          fromDeployment={selectedDeployment}
          openState={[cloneModalOpen, setCloneModalOpen]}
        />
      )}

      {exportManifestModalOpen && selectedDeployment && (
        <ExportManifestModal
          state={[exportManifestModalOpen, setExportManifestModalOpen]}
          deployment={selectedDeployment}
        />
      )}
    </Wrapper>
  );
};

export default Deploys;
