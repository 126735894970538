import { ComboSelectMenuItem, DropdownItem, WalButton, WalCard } from '@humanitec/ui-components';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import NewCriteriaRowEntry from '@src/components/shared/MatchingCriteria/AddNewCriteria/components/NewCriteriaRowEntry';
import { PipelineCriteria } from '@src/models/pipeline';
import { ResourceCriteria } from '@src/models/resources';
import { viewAppBreakpoints } from '@src/styles/breakpoints';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const CurrentCriteriaSelectionsWrapper = styled.div`
  width: 100%;
  font-size: ${units.fontSize.base};

  @media ${viewAppBreakpoints.greaterThanIncluding.sm} {
    width: 40%;
  }
`;

const SectionWrapper = styled(WalCard)`
  font-size: ${units.fontSize.sm};
`;

const DescriptionText = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-top: ${units.margin.sm};
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
`;

const AddButton = styled(WalButton)`
  margin-top: ${units.margin.md};
`;

const ButtonContainer = styled.div`
  margin-top: ${units.margin.md};
  display: flex;
`;

const LeftButton = styled(WalButton)`
  margin-right: ${units.margin.sm};
`;

export interface CriteriaFormField<T> {
  key: keyof T;
  label: string;
  value: string;
}
interface AddNewCriteriaProps<T> {
  onCancel: () => void;
  submitCriteria: (vals: { criteria: CriteriaFormField<T>[] }) => void;
  itemsMap: Partial<Record<keyof T, ComboSelectMenuItem<string>[]>>;
  defaultDropdownOptions: DropdownItem<keyof T>[];
  isCriteriaCreating: boolean;
  errorText?: ReactNode;
  description: string;
  initialSelectedCriteria: CriteriaFormField<T>[];
}

const AddNewCriteria = <T = PipelineCriteria | ResourceCriteria,>({
  onCancel,
  submitCriteria,
  itemsMap,
  defaultDropdownOptions,
  initialSelectedCriteria,
  isCriteriaCreating,
  errorText,
  description,
}: AddNewCriteriaProps<T>) => {
  // i18n
  const { t } = useTranslation();
  const translations = t('MATCHING_CRITERIA');
  const uiTranslations = t('UI');

  // State
  const [criteriaDropdownOptions, setCriteriaDropdownOptions] =
    useState<DropdownItem<keyof T>[]>(defaultDropdownOptions);

  // Mutations
  const formMethods = useWalhallForm<{ criteria: CriteriaFormField<T>[] }>({
    defaultValues: { criteria: [] },
  });
  const { handleSubmit, watch, control, clearErrors } = formMethods;
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'criteria',
  });
  const formValues = watch();
  const hasValues = formValues.criteria.some((field) => Boolean(field.value));

  useEffect(() => {
    setCriteriaDropdownOptions(
      defaultDropdownOptions.filter(
        (option) => !option.hideFromList && !fields.some((field) => field.key === option.id)
      )
    );
  }, [fields, defaultDropdownOptions, setCriteriaDropdownOptions]);

  useEffect(() => {
    if (fields.length === 0) {
      append(
        initialSelectedCriteria.map((criteria) => ({
          key: criteria.key,
          label: criteria.label,
          value: '',
        }))
      );
    }
  }, [initialSelectedCriteria, append, fields]);

  const handleSelectDropdownItem = (index: number, item: DropdownItem<keyof T>) => {
    update(index, { label: item.label, key: item.value, value: '' });
    clearErrors();
  };

  const addCriteria = () => {
    if (criteriaDropdownOptions.length > 0) {
      append({
        label: criteriaDropdownOptions[0].label,
        key: criteriaDropdownOptions[0].value,
        value: '',
      });
      criteriaDropdownOptions.shift();
    }
  };

  const deleteCriteria = (criteriaKey: keyof T, index: number) => {
    const dropdownItem = defaultDropdownOptions.find((option) => option.id === criteriaKey);
    if (dropdownItem) {
      setCriteriaDropdownOptions((list) => [...list, dropdownItem]);
      remove(index);
    }
  };

  return (
    <SectionWrapper>
      <div>{description}</div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitCriteria)}>
          <CurrentCriteriaSelectionsWrapper>
            {fields.map((criteria, index) => (
              <NewCriteriaRowEntry<T>
                key={criteria.id}
                criteriaKey={criteria.key}
                label={criteria.label}
                index={index}
                onDeleteCriteria={() => deleteCriteria(criteria.key, index)}
                onSelectDropdownItem={(_, item) => handleSelectDropdownItem(index, item)}
                showDelete={index !== 0}
                criteriaOptions={criteriaDropdownOptions}
                itemsMap={itemsMap}
                showSelectDropdown={!(criteria.key === 'class' || criteria.key === 'res_id')}
              />
            ))}
            {criteriaDropdownOptions.length > 0 && (
              <AddButton
                variant={'borderless-blue'}
                iconLeft={{ name: 'plus' }}
                solidColor
                onClick={addCriteria}>
                {translations.ADD_ANOTHER_CONDITION}
              </AddButton>
            )}
            <DescriptionText>{translations.USE_WILDCARD}</DescriptionText>
            {errorText}
          </CurrentCriteriaSelectionsWrapper>
          <ButtonContainer>
            <LeftButton
              variant={'primary'}
              type={'submit'}
              disabled={!hasValues || isCriteriaCreating}
              loading={isCriteriaCreating}>
              {isCriteriaCreating ? uiTranslations.SAVING : uiTranslations.SAVE}
            </LeftButton>

            <WalButton variant={'secondary'} onClick={onCancel}>
              {uiTranslations.CANCEL}
            </WalButton>
          </ButtonContainer>
        </form>
      </FormProvider>
    </SectionWrapper>
  );
};

export default AddNewCriteria;
