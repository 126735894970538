import { CreateDraftDeltaButton } from '@src/components/shared/PageHeader/components/CreateDraftDeltaButton';
import useDeploymentDeltaListQuery from '@src/hooks/react-query/deployment-delta/queries/useDeploymentDeltaListQuery';
import { DeploymentDelta } from '@src/models/deployment-delta';

import DraftCard from './components/DraftCard';

const Drafts = () => {
  // Context
  const { data: environmentDeltas = [] } = useDeploymentDeltaListQuery();

  return (
    <>
      <CreateDraftDeltaButton className={'mb-md'} />
      {environmentDeltas
        .sort((DeploymentDelta1: DeploymentDelta, DeploymentDelta2: DeploymentDelta) => {
          return (
            new Date(DeploymentDelta2.metadata?.last_modified_at || '').valueOf() -
            new Date(DeploymentDelta1.metadata?.last_modified_at || '').valueOf()
          );
        })
        .map((delta) => (
          <DraftCard key={delta.id} delta={delta} />
        ))}
    </>
  );
};

export default Drafts;
