import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { windowEnv } from '@src/environment';
import { units } from '@src/styles/variables';

import { AuthButtonsProps } from '../AuthButtons';

const Button = styled.div`
  margin-bottom: ${units.margin.lg};
`;

type NewGoogleButtonProps = Pick<
  AuthButtonsProps,
  'googleAuthSuccess' | 'googleOnClick' | 'checked' | 'type'
>;

const GoogleButton = ({
  googleAuthSuccess,
  googleOnClick,
  checked,
  type,
}: NewGoogleButtonProps) => {
  const [initialized, setInitialized] = useState(false);

  /**
   * We need to reinitialize the google button to make sure the checked state is up to date.
   */
  useEffect(() => {
    setInitialized(false);
  }, [checked]);

  useEffect(() => {
    const button = document.querySelector('#renderGoogleDiv') as HTMLElement;

    if (!button || initialized) return;

    setInitialized(true);

    (window as any)?.google?.accounts?.id?.initialize({
      client_id: windowEnv.GOOGLE_CLIENT_ID,
      callback: (response: any) => {
        googleOnClick?.();

        if (checked || type === 'login') {
          googleAuthSuccess(response.credential);
        }
      },
    });

    (window as any)?.google?.accounts?.id?.renderButton(button, {
      width: 300,
      text: type === 'register' ? 'signup_with' : 'signin_with',
      locale: 'en',
    });
  }, [googleAuthSuccess, googleOnClick, type, checked, initialized]);

  return <Button id={'renderGoogleDiv'} />;
};

export default GoogleButton;
