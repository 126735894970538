import { TabGroup, TabGroupOptionProps } from '@humanitec/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import { MatchParams } from '@src/models/routing';
import { containerStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';

const Container = styled.div`
  ${containerStyle()};
  flex-direction: column;
  overflow: auto;
  margin-bottom: ${units.margin.lg};
`;

type ResourceTabs = 'usage' | 'matching-criteria' | 'configuration';

const ResourceDefinition = () => {
  // Router hooks
  const { defId } = useParams<keyof MatchParams>() as MatchParams;

  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  const { data: resourceDefinitions } = useResourceDefinitionsQuery();

  const resourceDefinition = useMemo(
    () => resourceDefinitions?.find((r) => r.id === defId),
    [resourceDefinitions, defId]
  );

  const tabOptions: TabGroupOptionProps<ResourceTabs>[] = [
    {
      label: resourcesTranslations.USAGE,
      value: 'usage',
      link: {
        to: `usage`,
      },
    },
    {
      label: resourcesTranslations.MATCHING_CRITERIA,
      disabled: resourceDefinition?.is_default,
      disabledInfo: resourcesTranslations.MATCHING_CRITERIA_DISABLED,
      value: 'matching-criteria',
      link: {
        to: `matching-criteria`,
      },
    },
    {
      label: resourcesTranslations.CONFIGURATION,
      disabled: resourceDefinition?.is_default,
      disabledInfo: resourcesTranslations.CONFIGURATION_DISABLED,
      value: 'configuration',
      link: {
        to: `configuration`,
      },
    },
  ];

  return (
    <Container>
      <PageHeader />
      <TabGroup options={tabOptions} size={'large'} />
      <Outlet />
    </Container>
  );
};

export default ResourceDefinition;
