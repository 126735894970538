import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import { isAxiosError } from '@src/types/type-guards';
import makeRequest from '@src/utilities/make-request';
import { convertToId } from '@src/utilities/string-utility';

import { apiTokensQueryKeys } from '../apiTokensQueryKeys';

/*
 * Mutation to create api tokens that is scoped to an organization
 * The endpoint used here is deprecated and this functionality would be removed when the new server users epic is released.
 */
const useApiTokensCreateMutations = (
  successCallback?: () => void,
  errorCallback?: (errorStatusCode?: number) => void
) => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<AxiosResponse, AxiosError, string>({
    mutationFn: (tokenLabel) => {
      const newToken = {
        org_id: orgId,
        label: tokenLabel,
        id: convertToId(tokenLabel),
      };
      return makeRequest('POST', `/tokens`, newToken);
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: apiTokensQueryKeys.listApiTokensByOrg(orgId),
      });
      if (successCallback) {
        successCallback();
      }
    },
    onError: (error) => {
      const errorStatusCode = isAxiosError(error) ? error?.response?.status : undefined;
      errorCallback?.(errorStatusCode);
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useApiTokensCreateMutations;
