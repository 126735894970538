import { EmptyStateCard } from '@humanitec/ui-components';
import { Trans, useTranslation } from 'react-i18next';

import { useFeature } from '@src/hooks/useFeature';

const DeploymentCardEmptyState = () => {
  // i18n
  const { t } = useTranslation('viewEnvironment');
  const deploysTranslations = t('DEPLOYS');

  const [isNewCreateDeltaTextEnabled] = useFeature('create-delta-text');

  return (
    <EmptyStateCard>
      <p className={isNewCreateDeltaTextEnabled ? 'mb-sm' : undefined}>
        {deploysTranslations.NO_DEPLOYS_TO_THIS_ENVIRONMENT}
      </p>
      {isNewCreateDeltaTextEnabled && (
        <p>
          <Trans defaults={deploysTranslations.CLICK_CREATE_DELTA} />
        </p>
      )}
    </EmptyStateCard>
  );
};

export default DeploymentCardEmptyState;
