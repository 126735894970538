import { MonacoEditor, WalModal } from '@humanitec/ui-components';
import { isEqual } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRBAC } from '@src/hooks/useRBAC';

interface AddPayloadModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  onClose?: () => void;
}

const AddPayloadModal = ({ openState, onClose }: AddPayloadModalProps) => {
  // Form
  const { setValue, register, watch } = useFormContext();

  const [open, setOpen] = openState;

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // RBAC
  const canDeleteApplication = useRBAC('deleteApplication');

  const payloadData = watch('payloadData');

  const onChange = (changedValue: string | undefined) => {
    const valueIsDefined = changedValue !== undefined;

    if (!valueIsDefined || !canDeleteApplication) return;

    if (!(valueIsDefined && isEqual(JSON.parse(changedValue), payloadData))) {
      setValue('payloadData', changedValue, { shouldDirty: true });
    }
  };

  return (
    <WalModal
      disableOverflow
      openState={[open, setOpen]}
      title={`${canDeleteApplication ? uiTranslations.EDIT : uiTranslations.VIEW} payload`}
      size={'large'}
      disableClickOutside
      actions={
        !canDeleteApplication
          ? { cancel: { text: uiTranslations.CLOSE } }
          : {
              main: {
                text: uiTranslations.DONE,
                props: {
                  onClick: () => {
                    onClose?.();
                    setOpen(false);
                  },
                },
              },
            }
      }
      content={
        <>
          <MonacoEditor
            hideSyntaxDropdown
            width={'100%'}
            height={'350px'}
            fileExtension={'json'}
            readOnly={!canDeleteApplication}
            value={payloadData}
            onChange={onChange}
          />
          <input type={'hidden'} {...register('payloadData')} />
        </>
      }
    />
  );
};

export default AddPayloadModal;
