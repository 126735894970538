import { DynamicFormSchema } from '@src/types/dynamic-form';

export const testSchema: DynamicFormSchema = {
  type: 'object',
  properties: {
    name: {
      title: 'Name',
      type: 'string',
    },
    agree: {
      title: 'Agree?',
      type: 'boolean',
    },
    phonenumber: {
      title: 'Phone #',
      type: 'number',
    },
    annotations: {
      title: 'Annotations',
      default: {
        'alb.ingress.kubernetes.io/scheme': 'internet-facing',
        'alb.ingress.kubernetes.io/target-type': 'ip',
      },
      type: 'object',
    },
    labels: {
      title: 'Labels',
      default: {
        label1: 'test',
        label2: 'test',
      },
      type: ['string', 'object'],
    },
    withAdditionalProperties: {
      additionalProperties: { type: 'string' },
      title: 'Additional',
      type: 'object',
    },
  },
  required: ['name'],
};

export const patternPropertiesJSONSchema: DynamicFormSchema = {
  type: 'object',
  properties: {
    single: {
      type: 'string',
      title: 'Single input',
      pattern: '^[A-Z]*$',
      uiHints: {
        validationMessages: {
          pattern: 'Must be uppercase characters',
        },
      },
    },
    thepatternones: {
      title: 'Labels',
      type: 'object',
      additionalProperties: false,
      description: 'Labels to add to the manifest.',
      patternProperties: {
        '^[A-C]*$': {
          type: 'string',
          pattern: '^[X-Z]*$',
        },
        '^[D-F]*$': {
          type: 'string',
          pattern: '^[U-W]*$',
        },
        '^[F-H]*$': {
          type: 'string',
          pattern: '^[R-T]*$',
        },
      },
      uiHints: {
        validationMessages: {
          patternPropertiesMessage: 'Must match one of the following groups: A-C, D-F, F-H',
          patternProperties: {
            '^[A-C]*$': {
              pattern: 'Must be characters X, Y, or Z',
            },
            '^[D-F]*$': {
              pattern: 'Must be characters U, V, or W',
            },
            '^[F-H]*$': {
              pattern: 'Must be characters R, S, or T',
            },
          },
        },
      },
      default: {
        ABC: 'XYZ',
        DEF: 'UVW',
        FGH: 'RST',
      },
    },
  },
};

export const uiHintsJSONSchema: DynamicFormSchema = {
  type: 'object',
  required: ['first'],
  properties: {
    first: {
      title: 'First',
      type: 'string',
      uiHints: {
        order: 1,
        group: 'test',
      },
    },
    eighth: {
      title: 'Eighth',
      type: 'string',
      uiHints: {
        order: 10,
        inputType: 'textarea',
      },
    },
    fourth: {
      properties: {
        fifth: {
          title: 'Fifth',
          type: 'string',
          uiHints: {
            group: 'pw',
            order: 1,
          },
        },
        second: {
          title: 'Second',
          type: 'string',
          uiHints: {
            order: 2,
            group: 'host',
            width: 2,
          },
        },
      },
      type: 'object',
    },
    seventh: {
      title: 'Seventh',
      type: 'boolean',
    },
    sixth: {
      title: 'Sixth',
      type: 'string',
      uiHints: {
        group: 'pw',
      },
    },
    third: {
      title: 'Third',
      type: 'integer',
      uiHints: {
        order: 1,
        group: 'host',
      },
    },
  },
  uiGroups: {
    pw: {
      type: 'section',
      title: 'Section',
      order: 2,
    },
    host: {
      type: 'row',
      order: 3,
      section: 'pw',
    },
  },
};

export const ajvValidationSchema: DynamicFormSchema = {
  type: 'object',
  properties: {
    json: {
      type: 'object',
      properties: {
        required_param: {
          type: 'string',
        },
        second_param: {
          type: 'string',
          pattern: '^[0-9a-z][0-9a-z-]+[0-9a-z]$',
        },
        third_param: {
          type: 'string',
          pattern: '^[0-9a-z][0-9a-z-]+[0-9a-z]$',
        },
      },
      required: ['required_param'],
      uiHints: {
        inputType: 'textarea',
      },
    },
  },
};

export const simpleSchema: DynamicFormSchema = {
  type: 'object',
  properties: {
    name: {
      title: 'Name',
      type: 'string',
      default: 'Max Mustermann',
    },
    agree: {
      title: 'Agree?',
      type: 'boolean',
    },
  },
};
