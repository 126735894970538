import { ButtonVariant, WalButton, WalCard, WalLabel } from '@humanitec/ui-components';
import styled from 'styled-components/macro';

import SectionHeader from '@src/components/shared/SectionHeader';
import NoDeploymentSelectedCard from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/CompareDeployments/components/NoDeploymentSelectedCard';
import useEnvironmentRuntimeQuery from '@src/hooks/react-query/environments/queries/useEnvironmentRuntimeQuery';
import { DeploymentObject } from '@src/models/deployment-object';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

import DeploymentEntryRows from './DeploymentEntryRows/DeploymentEntryRows';

const Card = styled(WalCard)`
  margin-bottom: ${units.margin.md};
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface SelectDeploymentCardProps {
  deploy?: DeploymentObject;
  buttonText: string;
  buttonVariant: ButtonVariant;
  buttonCallback: () => void;
  title?: string;
  isTransparent?: boolean;
  isSelected?: boolean;
}

const SelectDeploymentCard = ({
  title,
  deploy,
  buttonText,
  buttonVariant,
  buttonCallback,
  isTransparent,
  isSelected,
}: SelectDeploymentCardProps) => {
  // React Query
  const { data: environmentRuntimeData } = useEnvironmentRuntimeQuery();
  const { environmentRuntime } = environmentRuntimeData || {};

  const hasRunningPods = Boolean(Object.keys(environmentRuntime?.modules || {}).length);

  return (
    <div className={'flex-column'}>
      {title && (
        <SectionHeader sticky={false} backgroundColor={isTransparent ? 'transparent' : undefined}>
          {title}
        </SectionHeader>
      )}
      {deploy ? (
        <Card cardStyle={isTransparent ? 'transparent' : 'default'}>
          <ContentWrapper>
            <DeploymentEntryRows
              date={
                formatDate(
                  deploy.status_changed_at,
                  DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE,
                  true
                ) as string
              }
              status={deploy.status}
              hash={deploy.id}
              hasRunningPods={hasRunningPods}
              comment={deploy.comment}
              createdBy={deploy.created_by}
            />
            {isSelected ? (
              <WalLabel>Selected</WalLabel>
            ) : (
              <WalButton variant={buttonVariant} onClick={buttonCallback}>
                {buttonText}
              </WalButton>
            )}
          </ContentWrapper>
        </Card>
      ) : (
        <NoDeploymentSelectedCard selectButtonClicked={buttonCallback} />
      )}
    </div>
  );
};

export default SelectDeploymentCard;
