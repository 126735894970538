import { Spinner, WalButton } from '@humanitec/ui-components';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import PauseEnvironmentModal from '@src/components/shared/modals/PauseEnvironmentModal';
import useEnvironmentPauseMutation from '@src/hooks/react-query/environments/mutations/useEnvironmentPauseMutation';
import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import useCurrentPausingEnvironments from '@src/hooks/zustand/useCurrentPausingEnvironments';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';

import ResumeEnvText from '../components/ResumeEnvText';

const PauseItemsWrapper = styled.div<{ disablePadding?: boolean }>`
  display: flex;
  ${({ disablePadding }) =>
    !disablePadding &&
    css`
      margin-bottom: ${units.margin.md};
    `}
`;
const PauseEnvModalText = styled.div`
  margin-top: ${units.margin.sm};
  margin-bottom: ${units.margin.md};
`;

const SpinnerText = styled.div`
  margin-left: ${units.margin.md};
`;

const SpinnerTextWrapper = styled.div`
  display: flex;
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

interface PauseButtonSectionProps {
  /** Pass true if you only want to display the button (hide info text) */
  disableInfoText?: boolean;
  disableWrapperPadding?: boolean;
  hidePauseButton?: boolean;
}

const PauseButtonSection = ({
  disableInfoText,
  disableWrapperPadding,
  hidePauseButton = false,
}: PauseButtonSectionProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const [openPauseEnvironmentModal, setOpenPauseEnvironmentModal] = useState<boolean>(false);

  const { mutate: pauseEnvironment } = useEnvironmentPauseMutation();

  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const pauseEnv = () => {
    // dispatch an action to pause the environment
    pauseEnvironment({ paused: true });
    setOpenPauseEnvironmentModal(false);
  };

  const resumeEnv = () => {
    // dispatch an action to resume a paused environment
    pauseEnvironment({ paused: false });
  };

  const { data: envPausedStatusMap } = useEnvironmentPauseStatusQuery({ appId, orgId });
  const envPauseStatus = envPausedStatusMap?.[envId];
  const { isEnvPausing } = useCurrentPausingEnvironments();
  const isUpdatingCurrentEnvironment = isEnvPausing(envId);

  // RBAC
  const canPauseEnvironment = useRBAC('pauseEnvironment');

  const ResumeButtonElement = () => {
    return (
      <WalButton
        size={'medium'}
        onClick={resumeEnv}
        iconLeft={{ name: 'play', size: 12, disableInvert: true }}
        loading={isUpdatingCurrentEnvironment}
        disabled={isUpdatingCurrentEnvironment}>
        {uiTranslations.RESUME_ENVIRONMENT}
      </WalButton>
    );
  };

  if (canPauseEnvironment) {
    return (
      <PauseItemsWrapper disablePadding={disableWrapperPadding}>
        {envPauseStatus === undefined && (
          <SpinnerTextWrapper>
            <Spinner size={'small'} />
            <SpinnerText>{uiTranslations.CHECKING_RUNTIME_STATUS}</SpinnerText>
          </SpinnerTextWrapper>
        )}
        {envPauseStatus === false && !hidePauseButton && (
          <>
            <WalButton
              variant={'secondary'}
              size={'medium'}
              onClick={() => setOpenPauseEnvironmentModal(true)}
              iconLeft={{ name: 'pause', size: 12 }}>
              {uiTranslations.PAUSE_ENVIRONMENT}
            </WalButton>
            <PauseEnvironmentModal
              openState={[openPauseEnvironmentModal, setOpenPauseEnvironmentModal]}
              content={
                <Trans defaults={uiTranslations.PAUSE_ENV_CONFIRMATION_TEXT_1}>
                  <PauseEnvModalText />
                </Trans>
              }
              onPause={pauseEnv}
              onCancel={() => setOpenPauseEnvironmentModal(false)}
            />
          </>
        )}{' '}
        {envPauseStatus === true &&
          (disableInfoText ? (
            <ResumeButtonElement />
          ) : (
            <ResumeEnvText>
              <ResumeButtonElement />
            </ResumeEnvText>
          ))}
      </PauseItemsWrapper>
    );
  }

  return <span />;
};

export default PauseButtonSection;
