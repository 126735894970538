export const sharedValuesQueryKeys = {
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'values']
  appValuesList: (orgId: string | undefined, appId: string | undefined) => [
    'orgs',
    orgId,
    'apps',
    appId,
    'value-set-versions',
    'list',
  ],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs', 'my-env', 'value-set-versions']
  envValuesList: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined
  ) => ['orgs', orgId, 'apps', appId, 'envs', envId, 'value-set-versions'],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs', 'my-env', 'value-set-versions', 'my-version', 'deployed-values']
  deployedEnvValuesList: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined,
    versionId: string | undefined
  ) => [...sharedValuesQueryKeys.envValuesList(orgId, appId, envId), versionId, 'deployed-values'],
};
