import {
  Icon,
  MenuItem,
  WalMenu,
  WalModal,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import ImageVersionsModal from '@src/components/shared/ImageVersionsModal/ImageVersionsModal';
import useArtefactDeleteMutation from '@src/hooks/react-query/artefacts/mutations/useArtefactDeleteMutation';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { Artefact } from '@src/models/artefact';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

const TableNameCell = styled.p`
  font-size: ${units.fontSize.sm};
  font-weight: 700;
  color: ${({ theme }) => theme.color.text};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const ImagesTable = styled(WalTable)`
  margin-bottom: ${units.margin.md};
`;

interface ManageImagesListProps {
  artefacts: Artefact[];
}

const MenuWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: end;
`;

const WarningText = styled.div`
  display: flex;
  margin-top: ${units.margin.md};
  margin-bottom: ${units.margin.lg};
`;

const ManageImagesList = ({ artefacts }: ManageImagesListProps) => {
  const { orgRole } = useGetUserRoles();
  // i18n
  const { t } = useTranslation();
  const manageImagesTranslations = t('ACCOUNT_SETTINGS').MANAGE_IMAGES;

  // Mutation
  const { mutate: deleteArtefact } = useArtefactDeleteMutation();

  // Component state
  const [selectedArtefact, setSelectedArtefact] = useState<Artefact | undefined>(undefined);
  const [openManageVersionsModal, setOpenManageVersionsModal] = useState<boolean>(false);
  const uiTranslations = t('UI');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const versionsModalTitle = t('ACCOUNT_SETTINGS.MANAGE_IMAGES.MANAGE_IMAGE_VERSIONS', {
    replace: { name: selectedArtefact?.name },
  });

  const handleImageMenuClick = (selectedItem: MenuItem<string>, artefact: Artefact) => {
    setSelectedArtefact(artefact);
    if (selectedItem.value === 'manage-versions') {
      setOpenManageVersionsModal(true);
    }
    if (selectedItem.value === 'delete') {
      setOpenDeleteModal(true);
    }
  };

  const closeModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteImage = (artefactId: string) => {
    deleteArtefact({ artefactId });
  };

  const handleDeleteButtonClick = (id: string) => {
    handleDeleteImage(id);
    closeModal();
  };

  const tableCols: WalTableColumn<Artefact>[] = [
    {
      label: manageImagesTranslations.IMAGE_NAME,
      prop: 'name',
      template: (row) => <TableNameCell>{row.data.name}</TableNameCell>,
    },
    {
      label: manageImagesTranslations.IMAGE_LAST_UPDATED,
      prop: 'last-update',
      template: (row) =>
        formatDate(row?.data?.updated_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE) || 'N/A',
    },
    {
      prop: 'actions',
      template: (row) =>
        !row.data.name.includes('humanitec.io/public/sample-app') &&
        !row.data.name.includes('humanitec.io/public/sample-service') && (
          <MenuWrapper>
            <WalMenu
              items={[
                { value: 'manage-versions', label: manageImagesTranslations.MANAGE_VERSIONS },
                {
                  value: 'delete',
                  label: manageImagesTranslations.DELETE_IMAGE,
                  hideFromList: orgRole !== 'administrator',
                },
              ]}
              panel={{ placement: 'bottom-end' }}
              onItemClick={(item) => handleImageMenuClick(item, row.data)}
              standardToggle={{
                type: 'dots',
                objectType: 'image',
                objectName: row.data.name,
              }}
            />
            {openDeleteModal && row.data.id === selectedArtefact?.id && (
              <WalModal
                key={row.data.id}
                title={manageImagesTranslations.DELETE_IMAGE}
                openState={[openDeleteModal, setOpenDeleteModal]}
                content={
                  <>
                    {manageImagesTranslations.DELETE_IMAGE_CONFIRMATION_TEXT_1}
                    <WarningText>
                      <Icon name={'warning'} overrideColor={'alert'} marginRight={'sm'} />
                      {manageImagesTranslations.DELETE_IMAGE_CONFIRMATION_TEXT_2}
                    </WarningText>
                  </>
                }
                actions={{
                  main: {
                    props: {
                      variant: 'danger',
                      onClick: () => handleDeleteButtonClick(row.data.id),
                    },
                    text: uiTranslations.DELETE,
                  },
                  cancel: {
                    text: uiTranslations.CLOSE,
                  },
                }}
              />
            )}
          </MenuWrapper>
        ),
    },
  ];

  return (
    <Wrapper>
      {artefacts.length > 0 && (
        <ImagesTable
          caption={manageImagesTranslations.TABLE_CAPTION}
          columns={tableCols}
          disableScrolling
          rows={artefacts.map(
            (artefact): WalTableRow => ({
              data: artefact,
            })
          )}
        />
      )}
      {selectedArtefact && openManageVersionsModal && (
        <ImageVersionsModal
          openState={[openManageVersionsModal, setOpenManageVersionsModal]}
          artefactId={selectedArtefact.id}
          mode={'list-versions'}
          title={versionsModalTitle}
        />
      )}
    </Wrapper>
  );
};

export default ManageImagesList;
