import { Context, ReactNode, useCallback, useEffect, useState } from 'react';

import { WebSocketMessageEvent } from '@src/components/socket/web-socket-message';

interface SocketProviderProps {
  /** the request url to open the websocket */
  url: string;
  /** the react context used */
  context: Context<WebSocket | undefined>;
  /** The scope of the socket e.g. the current URl or orgId. If the scope is changed, the socket will close and reopen */
  scope: string;
  /** json object to be sent to the server when the websocket opens */
  sendPayload?: Record<string, unknown>;
  /** callback function that will be called when a message is received */
  onMessage?: (message: WebSocketMessageEvent) => void;
  /** the websocket should be opened only if a sendPayload exists */
  onlyOpenWhenSendPayloadExists?: boolean;
  children: ReactNode;
}

enum SOCKET_CLOSE_CODES {
  NORMAL = 1000,
  ABNORMAL = 1006,
  SCOPE_CHANGED = 4001,
}

const SocketProvider = ({
  url,
  context,
  scope,
  children,
  sendPayload,
  onMessage,
  onlyOpenWhenSendPayloadExists,
}: SocketProviderProps) => {
  const [socket, setSocket] = useState<WebSocket>();
  // The scope for which the websocket is currently open.
  const [currentWebsocketScope, setCurrentWebsocketScope] = useState<string>();

  const openSocket = useCallback(() => {
    if (scope) {
      const newSocket = new WebSocket(`${url}`);

      setCurrentWebsocketScope(scope);

      newSocket.onopen = function () {
        if (sendPayload) {
          this.send(JSON.stringify(sendPayload));
        }
      };

      newSocket.onclose = (event: CloseEvent) => {
        // open a new connection if socket gets closed unexpectedly
        if (
          event.code !== SOCKET_CLOSE_CODES.NORMAL &&
          event.code !== SOCKET_CLOSE_CODES.SCOPE_CHANGED &&
          event.code !== SOCKET_CLOSE_CODES.ABNORMAL
        ) {
          openSocket();
        }
        // put code here if u want to do anything on cleanup
      };
      newSocket.onerror = () => {
        // put error handing here
      };

      if (onMessage) {
        newSocket.onmessage = onMessage;
      }
      setSocket(newSocket);
    }
  }, [url, sendPayload, scope, onMessage]);

  /**
   * If the selected scope(orgId) is not equal to the scope(orgId) for which the websocket is open, close it and open again.
   */
  useEffect(() => {
    if (scope && scope !== currentWebsocketScope) {
      // Close it if it's open
      if (socket?.OPEN) {
        socket.close(4001, 'Scope changed');
      }
      // Open the socket.
      if (!onlyOpenWhenSendPayloadExists || (onlyOpenWhenSendPayloadExists && sendPayload)) {
        openSocket();
      }
    }
  }, [
    scope,
    currentWebsocketScope,
    openSocket,
    socket,
    onlyOpenWhenSendPayloadExists,
    sendPayload,
  ]);

  /**
   * If the socket is open and the scope is null, this means the user has logged out.
   * Close the socket.
   */
  useEffect(() => {
    if (socket?.OPEN && !scope) {
      socket.close(1000, 'User logged out');
    }
  }, [socket, scope]);

  return <context.Provider value={socket}>{children}</context.Provider>;
};

export default SocketProvider;
