import { WalButton, WalCard } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import useApplicationDeleteMutation from '@src/hooks/react-query/applications/mutations/useApplicationDeleteMutation';
import useApplicationEnvironmentsQuery from '@src/hooks/react-query/environments/queries/useApplicationEnvironmentsQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { Application } from '@src/models/application';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

import ConfirmDeleteAppModal from '../../../../../../../components/shared/ConfirmDeleteAppModal/ConfirmDeleteAppModal';
import EnvironmentCard from '../../../App/components/EnvironmentCard';

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${units.margin.lg};
`;

const TopInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ApplicationCardProps {
  application: Application;
}

const ApplicationCard = ({ application }: ApplicationCardProps) => {
  // i18n
  const { t } = useTranslation();
  const { t: tViewApplicationList } = useTranslation('viewApplicationList');
  const uiTranslations = t('UI');
  const applicationCardTranslations = tViewApplicationList('APPLICATION_CARD');

  // Component state
  const [confirmDeleteAppModalOpen, setConfirmDeleteAppModalOpen] = useState<boolean>(false);

  // RBAC
  const canDeleteApplication = useRBAC('deleteApplication', { appId: application.id });

  // React Query
  const { data: applicationEnvironments = [] } = useApplicationEnvironmentsQuery({
    appId: application.id,
  });
  const {
    mutate: deleteApplication,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useApplicationDeleteMutation();

  const handleConfirmDelete = () => {
    deleteApplication({ appId: application.id });
  };

  useEffect(() => {
    if (isDeletedSuccessfully) {
      setConfirmDeleteAppModalOpen(false);
      resetDeleteMutation();
    }
  }, [isDeletedSuccessfully, resetDeleteMutation]);

  const environmentsWithAtLeastOneDeploy = applicationEnvironments.filter(
    (environment) => environment.last_deploy
  );

  const latestDeployTime =
    environmentsWithAtLeastOneDeploy.length > 0
      ? new Date(
          Math.max(
            ...environmentsWithAtLeastOneDeploy.map((e) =>
              e?.last_deploy?.created_at ? new Date(e?.last_deploy?.created_at).getTime() : 0
            )
          )
        )
      : undefined;

  const lastChangeDeployedText = tViewApplicationList('APPLICATION_CARD.LAST_CHANGE_DEPLOYED', {
    replace: {
      time: formatDate(latestDeployTime?.toString(), DATE_FORMATS_TYPES.DATE_MONTH_YEAR, true),
    },
  });

  return (
    <>
      <WalCard dataTestId={'application-card'}>
        <Top>
          <TopInfo>
            <Link to={application.id} className={'title-link mb-sm'}>
              {application.name}
            </Link>
            <span className={'txt-sm txt-translucent'}>
              {environmentsWithAtLeastOneDeploy.length
                ? lastChangeDeployedText
                : applicationCardTranslations.NO_DEPLOYS_IN_APPLICATION}
            </span>
          </TopInfo>
          {canDeleteApplication && (
            <WalButton
              iconLeft={'delete'}
              size={'small'}
              ariaLabel={uiTranslations.DELETE}
              variant={'secondary'}
              onClick={() => setConfirmDeleteAppModalOpen(true)}
            />
          )}
        </Top>
        {applicationEnvironments.map((environment) => (
          <EnvironmentCard
            key={environment.id}
            envId={environment.id}
            appId={application.id}
            lastDeploy={environment.last_deploy}
            environmentType={environment.type}
            cardStyle={'base'}
          />
        ))}
      </WalCard>
      <ConfirmDeleteAppModal
        openState={[confirmDeleteAppModalOpen, setConfirmDeleteAppModalOpen]}
        deleteConfirmedCallback={handleConfirmDelete}
        application={application}
      />
    </>
  );
};

export default ApplicationCard;
