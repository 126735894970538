import {
  AutoCompleteOptions,
  Checkbox,
  CheckboxProps,
  FormFieldGeneratorDefinition,
  FormGenerator,
  KeyValueEntries,
  WalInput,
  WalInputProps,
} from '@humanitec/ui-components';
import { get, isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { useFeature } from '@src/hooks/useFeature';
import { units } from '@src/styles/variables';
import { DynamicFormSchema } from '@src/types/dynamic-form';
import {
  capitalize,
  PLACEHOLDER_OR_NUMBER_REGEX,
  PLACEHOLDER_REGEX,
  PLACEHOLDER_REGEX_STRING,
} from '@src/utilities/string-utility';

import { isField, isJSONSchemaField, isRow, isSection } from './utils/dynamic-form-type-guards';
import { FieldProps, RowProps, SectionProps } from './utils/dynamic-form-types';
import {
  DOT_NOTATION_DELIMITER,
  isPatternProperties,
  iterateProps,
  sortDynamicFormFieldsAndSections,
} from './utils/dynamic-form-utils';

const SectionCard = styled.div<{ first: boolean }>`
  ${({ first }) =>
    !first &&
    css`
      margin-top: ${units.margin.lg};
    `}
`;

interface CheckboxDefinition {
  type: 'checkbox';
  props: CheckboxProps;
}

interface InputDefinition {
  type: 'input';
  props: WalInputProps;
}

export interface DynamicFormProps {
  /** JSON schema object */
  formSchema: DynamicFormSchema;
  /** Prefix for the dynamic form. All values will be nested under this prefix in the form response */
  prefix: string;
  /** Callback which is called during the iteration of the schema props */
  onFieldCallback?: (fieldProps: FieldProps) => void;
  /** Set to true if the form is being edited i.e. it has default values */
  editing?: boolean;
  className?: string;
  /** Renders the fields as readonly */
  readonly?: boolean;
  /** Renders the fields in view mode */
  viewMode?: boolean;
  /**
   * autocomplete options for the inputs, if this is defined then the input will use monaco editor with the possibility to add placeholders
   */
  autoCompleteOptions?: AutoCompleteOptions;
}

/**
 * Generates a Dynamic form based on JSON schema (https://json-schema.org/) values.
 */
const DynamicForm = ({
  formSchema,
  prefix,
  onFieldCallback,
  className,
  editing,
  readonly,
  viewMode,
  autoCompleteOptions,
}: DynamicFormProps) => {
  // Component state
  const [fieldArray, setFieldArray] = useState<FieldProps[]>([]);
  const [sortedFields, setSortedFields] = useState<(FieldProps | SectionProps | RowProps)[]>([]);
  const [validated, setValidated] = useState<string[]>([]);
  // Form
  const { trigger, watch } = useFormContext();
  const formValues = watch();

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // optimizely
  const [isAdditionalPropsEnabled] = useFeature('show-additional-properties');

  useEffect(() => {
    const newProps = iterateProps({
      schema: formSchema,
      path: prefix,
      required: undefined,
      secret: false,
      lastKey: '',
      isAdditionalPropsEnabled,
      callback: (props) => {
        const { schema, secret } = props;

        onFieldCallback?.(props);

        return {
          ...props,
          order: schema.uiHints?.order ?? undefined,
          secret,
        };
      },
    });

    const arrayify = isArray(newProps) ? newProps : [newProps];
    setFieldArray(arrayify.filter((p) => p) as FieldProps[]);
  }, [formSchema, prefix, onFieldCallback, isAdditionalPropsEnabled]);

  useEffect(() => {
    setSortedFields(sortDynamicFormFieldsAndSections(formSchema, fieldArray));
  }, [fieldArray, formSchema]);

  const validateExclusive = (
    value: string,
    fieldName: string,
    property: DynamicFormSchema
  ): string | undefined => {
    if (!value) return;
    const floatValue: number = parseFloat(value);

    if (property.exclusiveMinimum !== undefined && !(floatValue > property.exclusiveMinimum)) {
      return `${capitalize(fieldName)} must be greater than ${property.exclusiveMinimum}`;
    }
    if (property.exclusiveMaximum !== undefined && !(floatValue > property.exclusiveMaximum)) {
      return `${capitalize(fieldName)} must be less than ${property.exclusiveMinimum}`;
    }

    if (
      !value.toString().match(RegExp(PLACEHOLDER_REGEX, 'g')) &&
      property.type === 'object' &&
      !isJSONSchemaField(property)
    ) {
      try {
        JSON.parse(value);
      } catch {
        return 'Please provide valid JSON';
      }
    }
  };

  /**
   * Validated prefilled values. TODO: Investigate default values in react-hook-form. Maybe there's a reason why they aren't validated by default.
   */
  useEffect(() => {
    fieldArray.forEach((f) => {
      if (f.schema.default && get(formValues, f.path) && !validated.includes(f.path)) {
        trigger();
        setValidated((prevState) => [...prevState, f.path]);
      }
    });
  }, [fieldArray, formValues, trigger, validated]);

  const getInputProps = (
    propName: string,
    property: DynamicFormSchema,
    required?: boolean,
    secret?: boolean
  ): InputDefinition | CheckboxDefinition | null => {
    if (property.type === 'boolean') {
      return {
        type: 'checkbox',
        props: {
          defaultChecked: typeof property.default === 'boolean' && property.default === true,
          addInputMargin: true,
          requiredTrue: required,
          name: propName,
          label:
            property.title ||
            propName.replace(DOT_NOTATION_DELIMITER, '.').replace(`${prefix}.`, ''),
          description: property.description,
          readonly: readonly || viewMode,
        },
      };
    }

    const rows =
      property.uiHints?.inputType === 'textarea' ||
      typeof property.default === 'object' ||
      property.type === 'object'
        ? 4
        : undefined;

    const isNumberField = Boolean(property.type === 'integer' || property.type === 'number');

    return property.type
      ? {
          type: 'input',
          props: {
            name: propName,
            labelExtensionText: property.example ? ` e.g. ${property.example}` : '',
            placeholder: property.placeholder,
            defaultValue:
              typeof property.default === 'object'
                ? JSON.stringify(property.default, undefined, 2)
                : property.default?.toString(),
            // Set required to true if editing & is secret WAL-3503
            required: secret && editing ? false : required,
            type:
              property.uiHints?.inputType === 'password'
                ? 'password'
                : isNumberField
                  ? 'number'
                  : 'text',
            label:
              property.title ||
              propName.replace(DOT_NOTATION_DELIMITER, '.').replace(`${prefix}.`, ''),
            labelAbove: property.placeholder ? true : false,
            readonly,
            viewMode,
            pattern: property.pattern
              ? {
                  value: new RegExp(`${PLACEHOLDER_REGEX_STRING}|${property.pattern}`),
                  message: property.uiHints?.validationMessages?.pattern || '',
                }
              : isNumberField && autoCompleteOptions
                ? {
                    value: PLACEHOLDER_OR_NUMBER_REGEX,
                    message: uiTranslations.PLACEHOLDER_AND_NUMBER_VALIDATION_MESSAGE,
                  }
                : undefined,
            minLength: property.minLength,
            maxLength: property.maxLength,
            min: property.minimum,
            max: property.maximum,
            validate: {
              validateExclusive: (value: string) => validateExclusive(value, propName, property),
            },
            rows,
            resizable: true,
            description: property.description,
            noLabelOverflow: true,
            valueAsNumber: isNumberField && !Boolean(autoCompleteOptions),
            secret,
            isMonacoEditor: Boolean(autoCompleteOptions),
            autoCompleteOptions,
          },
        }
      : null;
  };

  /**
   * Renders an input depending on the type.
   *
   * @param propName
   * @param index
   */
  const displayPropInput = (field: InputDefinition | CheckboxDefinition, index: number) => {
    if (field.type === 'checkbox') {
      return <Checkbox {...field.props} key={index} />;
    }

    return <WalInput {...field.props} key={index} />;
  };

  const rowOrInput = (field: RowProps | FieldProps, index: number) => {
    if (isField(field)) {
      const property = field.schema;

      if (isPatternProperties(property)) {
        return (
          <KeyValueEntries
            key={index}
            title={property.title}
            entries={
              typeof property.default !== 'string' &&
              typeof property.default !== 'number' &&
              typeof property.default !== 'boolean'
                ? Object.entries(property.default || {}).map(([key, value]) => ({ key, value }))
                : []
            }
            group={{ name: field.path, label: field.schema.title }}
            editingEnabled={!(viewMode || readonly)}
            pairValidations={Object.entries(property.patternProperties || {}).map(
              ([key, value]) => {
                return {
                  key: {
                    value: key,
                    message: property?.uiHints?.validationMessages?.patternPropertiesMessage,
                  },
                  value: {
                    value: value.pattern,
                    message:
                      property?.uiHints?.validationMessages?.patternProperties?.[key]?.pattern,
                  },
                };
              }
            )}
          />
        );
      }
      const inputProps = getInputProps(field.path, field.schema, field.required, field.secret);
      return inputProps && displayPropInput(inputProps, index);
    } else if (isRow(field)) {
      const fields = field.fields.map(({ path, schema, required }) =>
        getInputProps(path, schema, required)
      );

      return (
        <FormGenerator
          key={index}
          gridTemplate={css`
            grid-template-columns: ${field.fields.map(({ schema }) =>
              typeof schema.uiHints?.width === 'number' ? `${schema.uiHints?.width}fr ` : '1fr '
            )};
          `}
          fields={fields.filter((f) => f !== null) as FormFieldGeneratorDefinition[]}
          description={
            !viewMode ? field.fields.map(({ schema }) => schema.description).join(' ') : ''
          }
        />
      );
    }
  };

  return (
    <div data-testid={`form-wrapper-${prefix}`} className={className}>
      {sortedFields.length > 0 &&
        sortedFields.map((field, index) =>
          isSection(field) && field.fields.length ? (
            // eslint-disable-next-line react/no-array-index-key
            <SectionCard first={index === 0} key={index}>
              <h3>{field.title}</h3>
              {field.fields.map((s, i) => rowOrInput(s, i))}
            </SectionCard>
          ) : (
            (isField(field) || isRow(field)) && rowOrInput(field, index)
          )
        )}
    </div>
  );
};

export default DynamicForm;
