import { Checkbox } from '@humanitec/ui-components';
import { rem } from 'polished';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { AuthenticateFailedReasons } from '@src/hooks/zustand/useCurrentUserStore';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import { getUrlParam } from '@src/utilities/url-params';

import AuthButtons from '../AuthButtons/AuthButtons';

const CheckboxText = styled.p`
  font-size: ${units.fontSize.sm};
`;

const CheckboxLink = styled.a`
  font-size: ${units.fontSize.sm};
`;

const PrivacyPolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units.margin.lg};
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: column;
`;

const Error = styled.span`
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.alert};
`;

const AlreadyHaveAnAccount = styled.div`
  text-align: center;
  margin-bottom: ${units.margin.lg};
`;

const Form = styled.form`
  max-width: ${rem(320)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface SignUpFormProps {
  googleAuthSuccess: (token: string) => void;
  isInvite: boolean;
  authenticateFailedReason?: AuthenticateFailedReasons;
}

const SignUpForm = ({ googleAuthSuccess, authenticateFailedReason, isInvite }: SignUpFormProps) => {
  const methods = useWalhallForm();
  const { watch, clearErrors, trigger } = methods;
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');
  const location = useLocation();
  const inviteToken = getUrlParam(location.search, 'inviteToken');

  const githubOnClick = () => {
    trigger();
  };

  const googleOnClick = () => {
    trigger();
  };

  const checkBoxChange = (event: any) => {
    if (event.target.checked) {
      clearErrors();
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <PrivacyPolicyWrapper>
          <CheckBoxWrapper>
            <Checkbox
              name={'privacy_disclaimer_accepted'}
              requiredTrue
              handleChange={checkBoxChange}
            />
            <CheckboxText>
              {authTranslations.I_AGREE}{' '}
              <CheckboxLink href={'https://humanitec.com/privacy-policy'} target={'_blank'}>
                {authTranslations.PRIVACY_POLICY}
              </CheckboxLink>
              <span>{' & '}</span>
              <CheckboxLink href={'https://humanitec.com/terms-and-conditions'} target={'_blank'}>
                {authTranslations.TERMS_OF_USE}
              </CheckboxLink>
            </CheckboxText>
          </CheckBoxWrapper>
          {methods.formState.errors.privacy_disclaimer_accepted && (
            <Error>{authTranslations.YOU_MUST_AGREE}</Error>
          )}
        </PrivacyPolicyWrapper>
        <AuthButtons
          checked={Boolean(watch('privacy_disclaimer_accepted'))}
          isInvite={isInvite}
          type={'register'}
          googleAuthSuccess={googleAuthSuccess}
          googleOnClick={googleOnClick}
          githubOnClick={githubOnClick}
          authenticateFailedReason={authenticateFailedReason}
        />
        <AlreadyHaveAnAccount>
          <span className={'txt-sm'}>{authTranslations.ALREADY_HAVE_AN_ACCOUNT}</span>{' '}
          <Link
            className={'txt-sm'}
            to={`/auth/login${inviteToken ? `?inviteToken=${inviteToken}` : ''}`}>
            {authTranslations.LOG_IN}
          </Link>
        </AlreadyHaveAnAccount>
        <a
          className={'txt-sm'}
          href={
            'https://docs.google.com/forms/d/e/1FAIpQLSdiK5DSWD7CqCbehw_aTuSG5X0pmUd_YeTzYdJJYgiGTApiZQ/viewform'
          }
          rel={'noreferrer noopener'}
          target={'_blank'}>
          {authTranslations.ALTERNATIVE_SIGNUP_OPTIONS}
        </a>
      </Form>
    </FormProvider>
  );
};

export default SignUpForm;
