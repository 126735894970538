import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { ActiveResource } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

const useActiveResourcesQuery = (): QueryResponse<ActiveResource[]> => {
  const { orgId, defId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: resourcesQueryKeys.activeResources(orgId, defId),
    queryFn: () =>
      makeRequest<ActiveResource[]>('GET', `/orgs/${orgId}/resources/defs/${defId}/resources`),
    enabled: Boolean(orgId && defId),
  });

  return { ...queryResult, data: data?.data };
};

export default useActiveResourcesQuery;
