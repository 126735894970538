import { WalButton, WalInput } from '@humanitec/ui-components';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { KeyValue } from '@src/models/general';
import { useWalhallForm } from '@src/utilities/form';
import { LABEL_KEY_REGEXP } from '@src/utilities/string-utility';

interface AnnotationFormProps {
  defaultValues?: KeyValue;
  onSubmit: (formData: KeyValue) => void;
  onCancel: () => void;
  existingKeys: string[];
}
/**
 * Helper component to add and edit annotations
 */
const AnnotationForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  existingKeys,
}: AnnotationFormProps) => {
  // i18n
  const { t } = useTranslation('workloadProfile');
  const annotationsTranslations = t('FEATURES').ANNOTATIONS;

  const { t: tCommon } = useTranslation();
  const uiTranslations = tCommon('UI');

  // form
  const formMethods = useWalhallForm<KeyValue>({ defaultValues, mode: 'all' });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <WalInput
          className={'mt-md'}
          label={annotationsTranslations.KEY}
          labelAbove
          name={'key'}
          required
          maxLength={63}
          pattern={{
            value: LABEL_KEY_REGEXP,
            message: annotationsTranslations.KEY_INVALID,
          }}
          validate={{
            customValidate: (keyVal) =>
              !existingKeys.includes(keyVal.toLowerCase()) || annotationsTranslations.KEY_EXISTS,
          }}
        />
        <WalInput
          className={'mt-md'}
          label={annotationsTranslations.VALUE}
          labelAbove
          name={'value'}
          rows={4}
        />
        <div className={'flex gap-sm mt-md'}>
          <WalButton
            type={'submit'}
            size={'small'}
            disabled={!formMethods.formState.isValid || !formMethods.formState.isDirty}>
            {uiTranslations.SAVE}
          </WalButton>
          <WalButton size={'small'} variant={'secondary'} onClick={onCancel}>
            {uiTranslations.CANCEL}
          </WalButton>
        </div>
      </form>
    </FormProvider>
  );
};
export default AnnotationForm;
