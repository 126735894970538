import { Spinner } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import Status from '@src/components/shared/Status';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentErrorsQuery from '@src/hooks/react-query/environments/queries/useDeploymentErrorsQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import { useDebouncedValue } from '@src/hooks/useDebouncedValue';
import { useGetReplicaSetRuntimeStatus } from '@src/hooks/useGetReplicaSetRuntimeStatus';
import { mapRuntimeStatusToAlertColor } from '@src/utilities/runtime-utils';

export interface WorkLoadStatusProps {
  deploymentId?: string;
  workloadId: string;
}
export const WorkloadStatus = ({ deploymentId, workloadId }: WorkLoadStatusProps) => {
  // React Query
  const { hasWorkloadError } = useDeploymentErrorsQuery(deploymentId);
  const runtimeStatus = useGetReplicaSetRuntimeStatus(workloadId);
  const { data: environment } = useEnvironmentQuery();

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const hasError = useDebouncedValue(
    hasWorkloadError(workloadId) || mapRuntimeStatusToAlertColor(runtimeStatus),
    300
  );

  // i18n
  const { t: tViewDeployment } = useTranslation('viewDeployment');
  const workloadTranslations = tViewDeployment('WORKLOADS');

  if (draftModeActive) {
    return <></>;
  }

  return environment?.last_deploy?.status === 'in progress' ? (
    <span className={'flex align-center gap-sm'}>
      <Spinner size={'small'} />
    </span>
  ) : hasError ? (
    <Status color={'red'} text={workloadTranslations.UNHEALTHY} />
  ) : (
    <Status color={'green'} text={workloadTranslations.HEALTHY} />
  );
};
