/*
 * @component
 * This component encapsulates the command overrides editor and all buttons and dropdown used to interact with it. Currently the only language that should be supported is YAML. See https://www.notion.so/humanitec/DES-341-Container-Overrides-Command-Arguments-2-0-15434a5acf7448dca219715eeb4943dc
 */
import jsYaml from 'js-yaml';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { units } from '@src/styles/variables';

import { WalButton } from '../../base/Button/Button';
import { CardStyles, WalCard } from '../../base/Card/Card';
import MonacoEditor from '../../base/Editor/MonacoEditor';
import { Spinner } from '../../base/Spinner/Spinner';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${units.margin.md};
`;

const WalCardWrapper = styled(WalCard)`
  text-align: center;
  padding: ${units.padding.xl} 0px;
  color: ${({ theme }) => theme.color.textTranslucent};
`;
const RightSideContent = styled.div`
  display: flex;
  align-items: center;
`;

const ParsingIndicator = styled.div`
  display: flex;
`;

const ParsingText = styled.span`
  margin-left: ${units.margin.sm};
  margin-right: ${units.margin.md};
`;

const WalButtonWrapper = styled(WalButton)`
  height: fit-content;
  width: fit-content;
`;

const ButtonContainer = styled.div`
  margin-top: ${units.margin.md};
  display: flex;
  justify-content: space-between;
`;

const LeftButton = styled(WalButton)`
  margin-right: ${units.margin.sm};
`;

export interface EditorToggleProps {
  /* default value to be passed to Editor */
  value: string[];
  /* heading for component  */
  title?: string;
  /* unique id to be used for the editor */
  name: string;
  /* method called when the save button is clicked */
  onSave: (value: string[]) => void;
  /* method cancel when the cancel button is clicked */
  onCancel?: () => void;
  /* set to true to disable editing */
  readonly?: boolean;
  /* used to set height of edit mode contents. Defaults to 200px if not defined */
  height?: string;
  /* text to display when no value has been entered */
  emptyStateText: string;
  cardStyle?: CardStyles;
}

// Converts any valid YAML(this includes valid JSON) to pretty-print YAML. e.g ["bash"] becomes "- bash"
const prettifyYAML = (args: string[] | undefined) =>
  args && args.length > 0 ? jsYaml.dump(args) : '';

const EditorToggle = ({
  name,
  title,
  value = [],
  readonly = false,
  onSave,
  onCancel,
  height,
  emptyStateText,
  cardStyle,
}: EditorToggleProps) => {
  // Component State
  const [defaultEditorValueSet, setDefaultEditorValueSet] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editorValue, setEditorValue] = useState<string>('');
  const [hasErrors, setHasErrors] = useState(false);
  const [showParsingIndicator, setShowParsingIndicator] = useState<boolean>(false);
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // load value given to the editor
  useEffect(() => {
    if (!editorValue && !defaultEditorValueSet && value.length) {
      setDefaultEditorValueSet(true);
      setEditorValue(prettifyYAML(value));
    }
  }, [value, setEditorValue, editorValue, defaultEditorValueSet]);

  const loadJSONFromYAML = (code: string) => {
    const output = jsYaml.load(code);
    return output === undefined ? [] : output;
  };

  /** Returns true if the value that the user entered in the editor is a JSON array as opposed to a YAML list. */
  const shouldShowParsingIndicator = (valueString: string) => valueString.trim().startsWith('[');

  const handleSave = () => {
    const editorValueAsJSON = loadJSONFromYAML(editorValue);
    // Validate end result before saving
    if (Array.isArray(editorValueAsJSON)) {
      onSave(editorValueAsJSON);
      if (shouldShowParsingIndicator(editorValue)) setShowParsingIndicator(true);
      // Converts the value prop to pretty-print YAML before passing it to the editor.
      setEditorValue(prettifyYAML(editorValueAsJSON));
      setTimeout(() => setShowParsingIndicator(false), 200);
    }
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    setEditorValue(prettifyYAML(value));
    onCancel?.();
  };

  const handleClear = () => setEditorValue('');
  const showEditButton = () => !readonly && !editMode;
  const showEmptyState = () => value.length === 0 && !editorValue && !editMode;
  const onChange = (editorText?: string) => editorText !== undefined && setEditorValue(editorText);

  return (
    <div data-testid={name}>
      <Header>
        <span className={'txt-sm'}>{title}</span>
        <RightSideContent>
          {showParsingIndicator && (
            <ParsingIndicator>
              <Spinner size={'small'} />
              <ParsingText className={'txt-sm'}>Parsing </ParsingText>
            </ParsingIndicator>
          )}
          {showEditButton() && (
            <WalButtonWrapper
              iconLeft={'edit'}
              variant={'secondary'}
              onClick={() => setEditMode(true)}>
              {uiTranslations.EDIT_YAML}
            </WalButtonWrapper>
          )}
        </RightSideContent>
      </Header>
      {showEmptyState() ? (
        <WalCardWrapper cardStyle={cardStyle}>{emptyStateText}</WalCardWrapper>
      ) : (
        <MonacoEditor
          autofocus={editMode}
          width={'100%'}
          height={height || '200px'}
          readOnly={!editMode}
          value={editorValue}
          onChange={onChange}
          fileExtension={'yaml'}
          hideSyntaxDropdown
          yamlSchema={{
            uri: 'https://example.com/json-schema/array.json',
            schema: {
              type: 'array',
              items: {
                type: 'string',
              },
            },
          }}
          onValidate={(markers) => setHasErrors(markers.length > 0)}
        />
      )}
      {editMode && (
        <ButtonContainer>
          <div>
            <LeftButton
              variant={'primary'}
              onClick={handleSave}
              // if value changed or no errors , TODO: See if there is a way to allow empty documents in json-schema
              disabled={
                prettifyYAML(value) === editorValue || (editorValue.trim() !== '' && hasErrors)
              }>
              {uiTranslations.SAVE}
            </LeftButton>
            <WalButton variant={'secondary'} onClick={handleCancel}>
              {uiTranslations.CANCEL}
            </WalButton>
          </div>
          <WalButton
            variant={'secondary'}
            onClick={handleClear}
            disabled={!editorValue}
            iconLeft={'delete'}>
            {uiTranslations.CLEAR}
          </WalButton>
        </ButtonContainer>
      )}
    </div>
  );
};

export default EditorToggle;
