import ReactFlow, { Controls, Edge, Node } from 'reactflow';
import styled from 'styled-components';

// eslint-disable-next-line
import 'reactflow/dist/style.css';

import { getLayoutedElements } from './utils';

const CustomReactFlow = styled(ReactFlow)`
  height: calc(100% - 30px) !important;
  border: 1px solid ${({ theme }) => theme.color.baseOutline};
  overflow: auto;

  & .react-flow__edge-path {
    stroke: ${({ theme }) => theme.color.baseOutline};
  }

  & .react-flow__node {
    background: ${({ theme }) => theme.color.baseBrighter};
    color: ${({ theme }) => theme.color.text};
  }

  .react-flow__handle {
    background: transparent;
    border: none;
  }
`;

const CustomControls = styled(Controls)`
  .react-flow__controls-button {
    background: ${({ theme }) => theme.color.baseLayer};
    border-bottom: 1px solid ${({ theme }) => theme.color.baseOutline};
    fill: ${({ theme }) => theme.color.text};
  }
`;

export interface FlowDiagramProps {
  nodes: Node[];
  edges: Edge[];
  showControls?: boolean;
}
export const FlowDiagram = ({ nodes, edges, showControls = true }: FlowDiagramProps) => {
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges);

  return (
    <CustomReactFlow nodes={layoutedNodes} edges={layoutedEdges} className={'nodrag'} fitView>
      {showControls && <CustomControls showInteractive={false} position={'bottom-right'} />}
    </CustomReactFlow>
  );
};
