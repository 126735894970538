import {
  FilterByOptions,
  FilterConditions,
  MultiFilterTable,
  WalButton,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { formatDistanceToNowStrict } from 'date-fns';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import TriggerPipelineRunModal from '@src/components/shared/TriggerPipelineRunModal/TriggerPipelineRunModal';
import useCancelPipelineRunsMutation from '@src/hooks/react-query/pipeline-runs/mutation/useCancelPipelineRunsMutation';
import { pipelineRunsQueryKeys } from '@src/hooks/react-query/pipeline-runs/queries/pipelineRunsQueryKeys';
import { getPipelineRunsApprovalRequestsQuery } from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunsApprovalRequestsQuery';
import usePipelineRunsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunsQuery';
import { PipelineRun } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import { containerStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { generatePipelinesURL } from '@src/utilities/navigation';

import { getDuration, getIsWaitingForApproval, getStatus, statuses } from '../../utils';
import { ReviewsApproval } from '../ReviewsApproval/ReviewsApproval';

const Container = styled.div`
  ${containerStyle()};
  flex-direction: column;
  overflow: auto;
  padding: 0;
  margin-bottom: ${units.margin.lg};
`;

const CustomLink = styled(Link)`
  display: inline-block;
  white-space: nowrap;
  max-width: ${rem(200)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusWrapper = styled.div`
  flex-wrap: nowrap;
  display: flex;
`;

export const getCommonPipelineRunsColumns = (
  runColumnLabel: string,
  statusColumnLabel: string,
  orgId?: string
) => {
  return [
    {
      label: runColumnLabel,
      prop: 'run',
      fixedWidth: 170,
      template: (row: WalTableRow<PipelineRun>) => (
        <CustomLink
          to={generatePipelinesURL(orgId, row.data.app_id, row.data.pipeline_id, row.data.id)}
          className={'txt-md title-link'}>
          {formatDate(
            row.data.created_at,
            DATE_FORMATS_TYPES.HOUR_MINUTE_DATE_MONTH_YEAR,
            undefined
          )}
        </CustomLink>
      ),
    },
    {
      label: statusColumnLabel,
      prop: 'status',
      alignIconAndText: true,
      fixedWidth: 110,
      template: (row: WalTableRow<PipelineRun>) => (
        <StatusWrapper className={'txt-translucent'}>
          {getStatus(getIsWaitingForApproval(row.data) ? 'waiting' : row.data.status, true)}
        </StatusWrapper>
      ),
    },
  ];
};

export const PipelineRunsList = () => {
  const { orgId, appId, pipelineId } = useParams<keyof MatchParams>();

  // i18n
  const { t } = useTranslation();
  const pipelineTranslations = t('PIPELINE_RUNS');
  const translations = t('NAVIGATION');
  const uiTranslations = t('UI');

  // State
  const [runToCancel, setRunToCancel] = useState('');
  const [statusFilterValues, setStatusFilterValues] = useState<string[]>([]);
  const [createBeforeDate, setCreateBeforeDate] = useState<string>();
  const [createAfterDate, setCreateAfterDate] = useState<string>();
  const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
  const [reviewProps, setReviewProps] = useState<{
    runId: string;
    appId: string;
    pipelineId: string;
  }>({
    runId: '',
    appId: '',
    pipelineId: '',
  });

  const [openTriggerPipelineForm, setOpenTriggerPipelineForm] = useState(false);
  const [pipelineRunToTrigger, setPipelineRunToTrigger] = useState<PipelineRun>();

  // React Query
  const queryClient = useQueryClient();

  const {
    data: pipelineRuns = [],
    isLoading: isLoadingPipelineRuns,
    isSuccess: hasFetchedPipelineRuns,
    isError,
    isFetchingNextPage: isFetchingNextPipelineRunsPage,
    fetchNextPage: fetchNextPipelineRunsPage,
    hasNextPage: hasNextPipelineRunsPage = false,
  } = usePipelineRunsQuery({
    statuses: statusFilterValues,
    createBeforeDate,
    createAfterDate,
  });

  const {
    mutate: cancelPipeline,
    isPending: isCancelling,
    reset: resetMutationState,
  } = useCancelPipelineRunsMutation(() => {
    // invalidate query so that it refetches with the new status after cancellation
    queryClient.invalidateQueries({
      queryKey: pipelineRunsQueryKeys.pipelineRuns(orgId, appId, pipelineId, {
        statuses: statusFilterValues,
      }),
    });
    // `runToCancel` holds the id of the run currently being cancelled. After a run is cancelled, reset `runToCancel`
    setRunToCancel('');
  });

  const handleCancelPipelineRun = (
    pipelineIdOfRun: string,
    runId: string,
    applicationId: string
  ) => {
    setRunToCancel(runId);
    cancelPipeline({ pipelineId: pipelineIdOfRun, runId, appId: applicationId });
  };

  useEffect(() => {
    if (hasFetchedPipelineRuns) {
      // to ensure that `runCancelled` is reset to false after a cancellation is successful
      resetMutationState();
    }
  }, [hasFetchedPipelineRuns, resetMutationState]);

  const handleClickOnTrigger = (pipelineRun: PipelineRun) => {
    setOpenTriggerPipelineForm(true);
    setPipelineRunToTrigger(pipelineRun);
  };

  const handleReviewPipelineRun = (
    runId: string,
    applicationId: string,
    pipelineIdOfRun: string
  ) => {
    setReviewProps({ runId, appId: applicationId, pipelineId: pipelineIdOfRun });
    setShowReviewModal(true);
  };

  const handlePrefetchPipelineRunApprovalRequests = async (
    applicationId: string,
    runId: string
  ) => {
    await queryClient.prefetchQuery(
      getPipelineRunsApprovalRequestsQuery(orgId, applicationId, {
        run: runId,
        status: 'waiting',
      })
    );
  };

  const columns: WalTableColumn[] = [
    ...getCommonPipelineRunsColumns(
      pipelineTranslations.TABLE.RUN,
      pipelineTranslations.TABLE.STATUS,
      orgId
    ),
    {
      label: pipelineTranslations.TABLE.STARTED,
      prop: 'started',
      fixedWidth: 110,
      ellipsisTooltip: {
        maxWidth: 110,
        maxCharacters: 30,
        text: (row) =>
          row.data.executing_at && formatDistanceToNowStrict(new Date(row.data.executing_at)),
      },
      template: (row) => (
        <p className={'py-md txt-md'}>
          {row.data.executing_at ? formatDistanceToNowStrict(new Date(row.data.executing_at)) : '-'}{' '}
          {row.data.executing_at && pipelineTranslations.TABLE.AGO}
        </p>
      ),
    },
    {
      label: pipelineTranslations.TABLE.TOTAL_RUN_TIME,
      fixedWidth: 100,
      prop: 'time',
      ellipsisTooltip: {
        maxWidth: 150,
        maxCharacters: 30,
        text: (row) => getDuration(row.data.completed_at, row.data.executing_at),
      },
      template: (row) => (
        <p className={'py-md txt-md'}>
          {getDuration(row.data.completed_at, row.data.executing_at)}
        </p>
      ),
    },
    {
      label: '',
      prop: '',
      template: (row: WalTableRow<PipelineRun>) => (
        <div className={'flex-row'}>
          {getIsWaitingForApproval(row.data) && (
            <WalButton
              onMouseEnter={() =>
                handlePrefetchPipelineRunApprovalRequests(row.data.app_id, row.data.id)
              }
              className={'mr-md'}
              variant={'primary'}
              size={'small'}
              onClick={() =>
                handleReviewPipelineRun(row.data.id, row.data.app_id, row.data.pipeline_id)
              }>
              {uiTranslations.REVIEW}
            </WalButton>
          )}
          {row.data.status === 'executing' ? (
            <WalButton
              variant={'secondary'}
              size={'small'}
              onClick={() =>
                handleCancelPipelineRun(row.data.pipeline_id, row.data.id, row.data.app_id)
              }
              loading={isCancelling && runToCancel === row.data.id}
              disabled={isCancelling || isLoadingPipelineRuns}>
              {isCancelling && runToCancel === row.data.id
                ? pipelineTranslations.CANCELLING
                : uiTranslations.CANCEL}
            </WalButton>
          ) : (
            <WalButton
              variant={'secondary'}
              size={'small'}
              onClick={() => handleClickOnTrigger(row.data)}>
              {pipelineTranslations.RERUN}
            </WalButton>
          )}
        </div>
      ),
    },
  ];

  const filterByOptions: FilterByOptions = {
    main: {
      options: [
        {
          label: pipelineTranslations.TABLE.STATUS,
          id: 'status',
          value: 'status',
          comboSelectOptions: Object.entries(statuses).map(([key, value]) => ({
            id: key,
            label: value,
            searchString: value,
            value,
          })),
        },
        {
          label: pipelineTranslations.TABLE.DATE_RANGE,
          id: 'date',
          value: 'date',
          isDateRange: true,
        },
      ],
    },
  };

  const handleFilterChange = (conditions?: FilterConditions) => {
    setStatusFilterValues(
      conditions?.main?.find((condition) => condition.filterBy === 'status')?.values || []
    );
    setCreateAfterDate(
      conditions?.main?.find((condition) => condition.filterBy === 'date')?.dateRange?.from
    );
    setCreateBeforeDate(
      conditions?.main?.find((condition) => condition.filterBy === 'date')?.dateRange?.to
    );
  };

  return (
    <Container>
      <MultiFilterTable
        infiniteScroll={{
          hasNextPage: hasNextPipelineRunsPage,
          isLoadingNextPage: isFetchingNextPipelineRunsPage,
          hasError: isError,
          fetchNextPage: fetchNextPipelineRunsPage,
        }}
        isLoading={isLoadingPipelineRuns}
        defaultConditions={{ main: [] }}
        filterByOptions={filterByOptions}
        onFiltersChange={handleFilterChange}
        initialRowsCount={0}
        hideFilterMessage
        caption={translations.PIPELINES}
        columns={columns}
        disableScrolling
        rows={pipelineRuns.map((pipelineRun) => {
          return { data: pipelineRun };
        })}
      />

      {openTriggerPipelineForm && pipelineRunToTrigger && (
        <TriggerPipelineRunModal
          openState={[openTriggerPipelineForm, setOpenTriggerPipelineForm]}
          pipelineId={pipelineRunToTrigger.pipeline_id}
          pipelineRun={pipelineRunToTrigger}
        />
      )}

      {showReviewModal && (
        <ReviewsApproval {...reviewProps} openState={[showReviewModal, setShowReviewModal]} />
      )}
    </Container>
  );
};
