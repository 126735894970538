import { DeploymentStatus } from '@src/models/deployment-object';

export const getDeploymentStatus = (
  deploymentStatus: DeploymentStatus,
  hasRunningPods?: boolean,
  activeDeployment?: boolean
): string => {
  let status = '';
  switch (deploymentStatus) {
    case 'never deployed':
      status = 'Never deployed';
      break;
    case 'failed':
      status = 'Failed';
      break;
    case 'timeout':
      status = 'Timed out';
      break;
    case 'succeeded':
      status = activeDeployment ? (hasRunningPods ? 'Running' : 'Stopped') : 'Successful';
      break;
    case 'in progress':
      status = 'Deploying';
      break;
    case 'cluster_changed':
      status = 'Cluster changed';
      break;
    default:
      break;
  }
  return status;
};
