import { TabGroupRouting } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import { useFeature } from '@src/hooks/useFeature';
import { useRBAC } from '@src/hooks/useRBAC';

import AppMembers from './components/AppMembers/AppMembers';
import DeleteApplication from './components/DeleteApplication/DeleteApplication';
import Environments from './components/Environments/Environments';
import PipelinesList from './components/Pipelines/components/PipelinesList/PipelinesList';
import SharedAppValues from './components/SharedAppValues/SharedAppValues';
import Webhooks from './components/Webhooks/Webhooks';

const ViewApp = () => {
  // i18n
  const { t } = useTranslation('viewApplication');
  const tabsTranslations = t('TABS');

  const canDeleteApplication = useRBAC('deleteApplication');

  const [isPipelineRunsEnabled] = useFeature('deployments-pipelines');

  return (
    <>
      <PageHeader showPageContext />
      <TabGroupRouting
        items={[
          { label: tabsTranslations.ENVIRONMENTS, path: 'envs', element: <Environments /> },
          ...(isPipelineRunsEnabled
            ? [
                {
                  label: tabsTranslations.PIPELINES,
                  path: 'pipelines',
                  element: <PipelinesList />,
                },
              ]
            : []),
          {
            label: tabsTranslations.VALUES_AND_SECRETS,
            path: 'values-and-secrets',
            element: <SharedAppValues />,
          },
          { label: tabsTranslations.WEBHOOKS, path: 'webhooks', element: <Webhooks /> },
          {
            label: tabsTranslations.PEOPLE,
            path: 'people',
            element: (
              <div>
                <AppMembers />
              </div>
            ),
          },
          {
            label: tabsTranslations.DELETE,
            path: 'delete',
            hide: !canDeleteApplication,
            element: <DeleteApplication />,
          },
        ]}
      />
    </>
  );
};

export default ViewApp;
