import { useQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { orderBy } from 'lodash';
import { useParams } from 'react-router-dom';

import { Application } from '@src/models/application';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { applicationsQueryKeys } from '../applicationsQueryKeys';

const sortAppsByCreateDate = (apps: Application[]) =>
  orderBy(apps, (a) => parseISO(a.created_at), 'desc');

const useApplicationsQuery = () => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  return useQuery({
    queryKey: applicationsQueryKeys.list(orgId),
    queryFn: () => makeRequest<Application[]>('GET', `/orgs/${orgId}/apps`),
    enabled: Boolean(orgId),
    select: (response) => sortAppsByCreateDate(response.data),
  });
};

export default useApplicationsQuery;
