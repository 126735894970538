import { UserPreferences } from '@src/hooks/zustand/useUserPreferencesStore';

import {
  LS_INVITE_TOKEN,
  LS_LAST_VISITED_APP,
  LS_NAVIGATION_MINIMIZED,
  LS_SELECTED_ORGANIZATION,
  LS_USER_PREFERENCES,
} from './variables';

/**
 * Returns the selected organization.
 */
export const getSelectedOrganization = (): string | null => {
  return localStorage.getItem(LS_SELECTED_ORGANIZATION);
};

export const getUserPreferencesFromLS = (): UserPreferences | null => {
  const preferences = localStorage.getItem(LS_USER_PREFERENCES);
  return preferences && JSON.parse(preferences);
};

export const setUserPreferencereInLS = (userPreferences: UserPreferences): void => {
  localStorage.setItem(LS_USER_PREFERENCES, JSON.stringify(userPreferences));
};

/**
 * Sets the selected organization.
 */
export const setSelectedOrganization = (organizationUuid: string): void => {
  localStorage.setItem(LS_SELECTED_ORGANIZATION, organizationUuid);
};

/**
 * Returns InviteToken.
 */
export const getInviteToken = (): string | null => {
  return localStorage.getItem(LS_INVITE_TOKEN);
};

/**
 * Gets lastVisitedApp.
 */
export const getLastVisitedApp = (): string | null => {
  return localStorage.getItem(LS_LAST_VISITED_APP);
};

/**
 * Sets lastVisitedApp.
 */
export const setLastVisitedApp = (appId: string): void => {
  localStorage.setItem(LS_LAST_VISITED_APP, appId);
};

/**
 * Removes lastVisitedApp.
 */
export const removeLastVisitedApp = (): void => {
  localStorage.removeItem(LS_LAST_VISITED_APP);
};

/**
 * Sets InviteToken.
 */
export const setInviteToken = (inviteToken: string): void => {
  localStorage.setItem(LS_INVITE_TOKEN, inviteToken);
};

/**
 * Removes InviteToken.
 */
export const removeInviteToken = (): void => {
  localStorage.removeItem(LS_INVITE_TOKEN);
};

/**
 * Returns if the navigation menu is minimized.
 */
export const getNavigationMenuMinimized = (): boolean => {
  return Boolean(localStorage.getItem(LS_NAVIGATION_MINIMIZED) === 'true');
};

/**
 * Sets if the navigation menu is minimized.
 */
export const setNavigationMenuMinimized = (minimized: boolean): void => {
  localStorage.setItem(LS_NAVIGATION_MINIMIZED, minimized ? 'true' : 'false');
};
