import { useInfiniteQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getPipelineRunsFilterString } from '@src/containers/Orgs/PipelineRuns/utils';
import { PipelineRun } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelineRunsQueryKeys } from './pipelineRunsQueryKeys';

interface usePipelineRunsQueryProps {
  appId?: string;
  envId?: string;
  statuses?: string[];
  createBeforeDate?: string;
  createAfterDate?: string;
}

const usePipelineRunsByOrgQuery = ({
  appId,
  envId,
  statuses,
  createBeforeDate,
  createAfterDate,
}: usePipelineRunsQueryProps) => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useInfiniteQuery({
    queryKey: pipelineRunsQueryKeys.pipelineRunsByOrg(orgId, {
      statuses,
      createBeforeDate,
      createAfterDate,
      appId,
      envId,
    }),
    queryFn: ({ pageParam }) => {
      return makeRequest<PipelineRun[]>(
        'GET',
        `/orgs/${orgId}/pipeline-runs${getPipelineRunsFilterString(
          statuses,
          createBeforeDate,
          createAfterDate,
          pageParam,
          appId,
          envId
        )}`
      );
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage.headers.link?.match(/page=([^&]+)/)?.[1] || null;
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(orgId) && Boolean(appId) && Boolean(envId),
  });

  return { ...queryResult, data: data?.pages.flatMap((page) => page.data) };
};

export default usePipelineRunsByOrgQuery;
