import { ExpandableCard } from '@humanitec/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EnvironmentWorkloads from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/EnvironmentWorkloads/EnvironmentWorkloads';
import SharedResources from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/SharedResources/SharedResources';
import DeploymentCardEmptyState from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/Deploys/components/components/DeploymentCardEmptyState';
import DeploymentCard from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/Deploys/components/DeploymentCard';
import useDeploymentsListQuery from '@src/hooks/react-query/environments/queries/useDeploymentsListQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';

const EnvStatus = () => {
  // React Query
  const { data: environment } = useEnvironmentQuery();
  const { data: deployments } = useDeploymentsListQuery();
  const { t } = useTranslation('viewEnvironment');
  const envStatusTranslations = t('STATUS');

  const lastPastDeploymentId = useMemo(
    () => deployments?.filter((deploy) => deploy.id !== environment?.last_deploy?.id)[0]?.id,
    [environment?.last_deploy?.id, deployments]
  );

  const deployment = useMemo(
    () => deployments?.find((deploy) => deploy.id === environment?.last_deploy?.id),
    [environment?.last_deploy?.id, deployments]
  );

  return (
    <>
      {deployment ? (
        <>
          <DeploymentCard
            cardStyle={'transparent'}
            deploy={deployment}
            isActiveDeployment
            lastPastDeploymentId={lastPastDeploymentId}
          />
          <ExpandableCard
            id={'workloads'}
            expandedByDefault
            headerContent={envStatusTranslations.WORKLOADS}
            cardStyle={'transparent'}
            className={'mb-md'}
            content={<EnvironmentWorkloads customDeployment={deployment} />}
          />
          <ExpandableCard
            id={'shared-resources'}
            expandedByDefault
            headerContent={envStatusTranslations.SHARED_RESOURCES}
            cardStyle={'transparent'}
            content={<SharedResources inEnvStatusTab />}
          />
        </>
      ) : (
        <DeploymentCardEmptyState />
      )}
    </>
  );
};

export default EnvStatus;
