import { em } from 'polished';
import styled from 'styled-components';

import HumanitecSVG from '@src/components/shared/svg/HumanitecSVG';
import { units } from '@src/styles/variables';

const LogoWrapper = styled.div`
  display: flex;
  font-size: ${units.fontSize.base};
`;

const HumanitecImage = styled(HumanitecSVG)`
  width: ${em(176)};
  height: ${em(32)};
  cursor: pointer;
`;

interface HumanitecPlatformLogoProps {
  onClick?: () => any;
  className?: string;
}

const HumanitecPlatformLogo = ({ onClick, className }: HumanitecPlatformLogoProps) => {
  return (
    <LogoWrapper className={className}>
      <HumanitecImage onClick={onClick} />
    </LogoWrapper>
  );
};

export default HumanitecPlatformLogo;
