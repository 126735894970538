import { useMatch } from 'react-router-dom';

const useGetRouteMatchObject = () => {
  const profileSettingsMatch = useMatch('/profile-settings');
  const appsListMatch = useMatch('/orgs/:orgId/apps');
  const compareDeploymentsMatch = useMatch(
    '/orgs/:orgId/apps/:appId/envs/:envId/compare-deployments/:leftDeploymentId/:rightDeploymentId'
  );
  const workloadMatch = useMatch(
    '/orgs/:orgId/apps/:appId/envs/:envId/:deployOrDeltaSegment/:deployOrDeltaId/workloads/:moduleId/*'
  );
  const appDetailsMatch = useMatch(
    '/orgs/:orgId/apps/:appId/envs/:envId/:deployOrDeltaSegment/:deployOrDeltaId/:workloadOrSharedResourcesSegment'
  );
  const serviceUsersMatch = useMatch('/orgs/:orgId/service-users');

  // Org settings
  const resourcesMatch = useMatch('/orgs/:orgId/resources');
  const resourceDetailsMatch = useMatch('/orgs/:orgId/resources/:defId/*');
  const registriesMatch = useMatch('/orgs/:orgId/registries');
  const environmentTypesMatch = useMatch('/orgs/:orgId/environment-types');
  const accountsMatch = useMatch('/orgs/:orgId/accounts');
  const imagesMatch = useMatch('/orgs/:orgId/images');
  const apiTokensMatch = useMatch('/orgs/:orgId/api-tokens');
  const orgMembersMatch = useMatch('/orgs/:orgId/org-members');
  const pipelineRunsMatch = useMatch('/orgs/:orgId/pipeline-runs');
  const pipelineDetailsMatch = useMatch('/orgs/:orgId/apps/:appId/pipelines/:pipelineId/*');
  const pipelineVersionMatch = useMatch(
    '/orgs/:orgId/apps/:appId/pipelines/:pipelineId/versions/:pipelineVersionId/*'
  );
  const pipelinesMatch = useMatch('/orgs/:orgId/apps/:appId/pipelines');
  const pipelineRundetailsMatch = useMatch(
    '/orgs/:orgId/apps/:appId/pipelines/:pipelineId/pipeline-runs/:pipelineRunId'
  );

  const userDetailsMatch = useMatch('/orgs/:orgId/org-members/:userId/*');

  return {
    pipelineDetailsMatch,
    profileSettingsMatch,
    appsListMatch,
    appDetailsMatch,
    compareDeploymentsMatch,
    workloadMatch,
    resourcesMatch,
    resourceDetailsMatch,
    registriesMatch,
    environmentTypesMatch,
    accountsMatch,
    imagesMatch,
    apiTokensMatch,
    orgMembersMatch,
    serviceUsersMatch,
    pipelineRunsMatch,
    pipelineRundetailsMatch,
    pipelinesMatch,
    pipelineVersionMatch,
    userDetailsMatch,
  };
};
export default useGetRouteMatchObject;
