import { SearchInput, WalTable } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import useUserRolesQuery from '@src/hooks/react-query/user/useUserRolesQuery';
import useFilterApplications from '@src/hooks/useFilterApplications';
import { MatchParams } from '@src/models/routing';
import { generateAppURL } from '@src/utilities/navigation';
import { capitalize } from '@src/utilities/string-utility';

import AdministratorWarning from '../../../components/AdministratorWarning';

const OrgMemberApplicationRoles = () => {
  // Component state
  const [filterValue, setFilterValue] = useState<string>();
  // React Query
  const { data: userRoles } = useUserRolesQuery();

  // i18n
  const { t } = useTranslation('orgMembers');
  const translations = t('APPLICATIONS_TAB');

  // React Router
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const applications = useFilterApplications(filterValue);

  return (
    <>
      <AdministratorWarning type={'application'} />
      <SearchInput
        name={'filter-applications'}
        onChange={setFilterValue}
        placeholder={translations.FIND_APPLICATION}
      />
      <WalTable
        caption={t('APPLICATION_ROLES')}
        columns={[
          {
            prop: 'application',
            label: t('APPLICATIONS', { returnObjects: false }),
            template: (row) => {
              return (
                <Link className={'txt-base'} to={generateAppURL(orgId, row.data.id)}>
                  {row.data.name}
                </Link>
              );
            },
          },
          {
            prop: 'role',
            label: translations.APP_PERMISSIONS,
            justifyContent: 'flex-end',
            template: (row) => (
              <div>
                {capitalize(userRoles?.[`/orgs/${orgId}/apps/${row.data.id}`] ?? '') || 'None'}
              </div>
            ),
          },
        ]}
        rows={applications?.map((app) => ({ data: app })) ?? []}
      />
    </>
  );
};

export default OrgMemberApplicationRoles;
