import { CopyText, Icon, WalButton, WalLabel } from '@humanitec/ui-components';
import { styled } from '@storybook/theming';
import { useTranslation } from 'react-i18next';

import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { ArtefactVersion } from '@src/models/artefact';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units.margin.md};
`;

const WarningWrapper = styled.div`
  margin-bottom: ${units.margin.md};
`;
const VersionDetails = ({
  version,
  isOverriden,
  onArchiveButtonClick,
  hideArchiveButton = false,
}: {
  version: ArtefactVersion;
  isOverriden: boolean;
  onArchiveButtonClick: () => void;
  hideArchiveButton?: boolean;
}) => {
  // i18n
  const { t } = useTranslation();
  const imageTranslations = t('VIEW_MODULE').IMAGE;
  const { orgRole } = useGetUserRoles();

  const canArchive = () => {
    // This restricts archiving permissions based on RBAC detailed at https://www.notion.so/humanitec/Frontend-Engineering-f8a268f74f7e4a48a3f5f8eea4d94040#6ef33f52f696404593bb7d54c1a2b5cc
    return orgRole === 'administrator' || orgRole === 'manager' ? true : false;
  };

  return (
    <>
      {(isOverriden || version.archived) && (
        <WarningWrapper className={'flex-centered'}>
          <Icon name={'warning'} marginRight={'sm'} />
          {version.archived
            ? imageTranslations.IMAGE_WAS_ARCHIVED
            : imageTranslations.IMAGE_VERSION_UNAVAILABLE}
        </WarningWrapper>
      )}
      {version?.commit && (
        <Field>
          <WalLabel>{imageTranslations.COMMIT_ID}</WalLabel>
          <CopyText text={version?.commit} showText textClassName={'txt-sm'} />
        </Field>
      )}
      {version?.version && (
        <Field>
          <WalLabel>{imageTranslations.IMAGE_TAG}</WalLabel>
          <span className={'txt-sm'}>{version.version}</span>
        </Field>
      )}
      {version?.ref && (
        <Field>
          <WalLabel>{imageTranslations.REFERENCE}</WalLabel>
          <CopyText text={version?.ref} showText textClassName={'txt-sm'} />
        </Field>
      )}
      {version.digest && (
        <Field>
          <WalLabel>{imageTranslations.DIGEST}</WalLabel>
          <CopyText text={version.digest} showText textClassName={'txt-sm'} />
        </Field>
      )}
      <Field>
        <WalLabel>{imageTranslations.ADDED_AT}</WalLabel>
        <span className={'txt-sm'}>
          {formatDate(version.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}
        </span>
      </Field>
      {canArchive() && !hideArchiveButton && (
        <WalButton variant={'secondary'} onClick={onArchiveButtonClick}>
          {' '}
          {version.archived ? imageTranslations.UNARCHIVE : imageTranslations.ARCHIVE}
        </WalButton>
      )}
    </>
  );
};

export default VersionDetails;
