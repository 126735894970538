import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  findJSONSchemaFields,
  validateJSONSchemaErrors,
} from '@src/components/shared/DynamicForm/utils/dynamic-form-utils';
import { DynamicFormSchema } from '@src/types/dynamic-form';

/**
 * This hook will check which inputs should be validated as JSON schema, and set the errors on the form. Returns a boolean if there is more than one error.
 * Temporary solution intil this is resolved - https://github.com/react-hook-form/react-hook-form/issues/8422. We'll implement a resolver which will validate AJV + in-built validations when this issue is fixed.
 *
 * @param prefix
 * @param schema
 * @returns
 */
export const useGetSchemaErrors = (prefix: string, schema?: DynamicFormSchema) => {
  const [customErrors, setCustomErrors] = useState<{
    [x: string]: Record<string, string> | undefined;
  }>();

  const {
    formState: { isSubmitted, isSubmitting },
    setError,
    control,
  } = useFormContext();

  const schemasWithJSONValidation = useMemo(
    () => findJSONSchemaFields({ schema, prefix }) || [],
    [schema, prefix]
  );

  const watchedPaths = useMemo(
    () => schemasWithJSONValidation.map((sch) => sch.path),
    [schemasWithJSONValidation]
  );

  const watchedValues = useWatch({ name: watchedPaths, control });

  /**
   * Validates the value and sets it in a state object.
   */
  const validateField = useCallback(
    (path: string, value: string) => {
      const schemaForField = schemasWithJSONValidation?.find((s) => s.path === path);

      if (schemaForField?.schema) {
        const errs = validateJSONSchemaErrors(
          schemaForField.schema,
          value,
          Boolean(schemaForField.required)
        );
        setCustomErrors((prevState) => {
          if (errs && !isEqual(prevState?.[path], errs)) {
            return { ...prevState, [path]: errs };
          } else if (prevState?.[path]) {
            delete prevState?.[path];
            return prevState;
          }
        });
      }
    },
    [schemasWithJSONValidation]
  );

  /**
   * Iterates over all the watched paths & runs validation.
   */
  useEffect(() => {
    if (schema) {
      watchedPaths.forEach((path, i) => {
        validateField(path, watchedValues[i]);
      });
    }
  }, [watchedValues, schema, validateField, watchedPaths]);

  /**
   * Iterates over the error state object & uses `setError` on react-hook-form.
   */
  useEffect(() => {
    if (Object.keys(customErrors || {}).length) {
      Object.entries(customErrors || {}).forEach(([path, errs]) => {
        if (isSubmitted || isSubmitting) {
          const [[type, message]] = Object.entries(errs || {});
          setError(path, { type, message, types: errs }, { shouldFocus: true });
        }
      });
    }
  }, [setError, customErrors, isSubmitted, isSubmitting]);

  return Boolean(Object.keys(customErrors || {}).length);
};
