import { Dispatch, SetStateAction, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import useApplicationEnvironmentsQuery from '@src/hooks/react-query/environments/queries/useApplicationEnvironmentsQuery';
import { Application } from '@src/models/application';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

const SubTitleText = styled.p`
  margin-bottom: ${units.margin.md};
`;

interface ConfirmDeleteAppModalProps {
  deleteConfirmedCallback: () => void;
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  application: Application;
}

const ConfirmDeleteAppModal = ({
  openState,
  deleteConfirmedCallback,
  application,
}: ConfirmDeleteAppModalProps) => {
  // i18n
  const { t } = useTranslation();
  const settingsTranslations = t('APP_SETTINGS');
  const titleTranslation = t('APP_SETTINGS.DELETE_APP_TITLE', {
    replace: {
      appId: application?.name,
      date: formatDate(application?.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR),
    },
  });

  // React Query
  const { data: applicationEnvironments = [] } = useApplicationEnvironmentsQuery();

  const activeDeploymentsCount = useMemo(
    () =>
      applicationEnvironments.filter(
        (environment) => environment.last_deploy?.status === 'succeeded'
      ).length,
    [applicationEnvironments]
  );

  return (
    <ConfirmDeleteModal
      state={openState}
      deleteConfirmedCallback={deleteConfirmedCallback}
      deleteButtonText={settingsTranslations.DELETE_APP_BUTTON}
      title={titleTranslation}
      customContentComponent={
        <>
          <SubTitleText>
            {activeDeploymentsCount === 1
              ? settingsTranslations.DELETE_APP_SUBTITLE_PREFIX_SINGULAR
              : settingsTranslations.DELETE_APP_SUBTITLE_PREFIX_PLURAL}
            <Trans
              defaults={settingsTranslations.DELETE_APP_SUBTITLE}
              values={{
                activeDeploymentsCount,
                deploymentText:
                  activeDeploymentsCount === 1
                    ? settingsTranslations.DELETE_APP_MODAL_ACTIVE_DEPLOYMENT
                    : settingsTranslations.DELETE_APP_MODAL_ACTIVE_DEPLOYMENTS,
                environmentsCount: application?.envs.length,
                environmentText:
                  application?.envs.length === 1
                    ? settingsTranslations.DELETE_APP_MODAL_ENVIRONMENT
                    : settingsTranslations.DELETE_APP_MODAL_ENVIRONMENTS,
                appName: application?.name,
              }}
            />
          </SubTitleText>
          <SubTitleText>
            <Trans
              defaults={settingsTranslations.DELETE_APP_SUBTITLE2}
              values={{ appName: application?.name }}
            />
          </SubTitleText>
        </>
      }
      inputConfirmation={{
        correctValue: application?.name || '',
        objectName: settingsTranslations.DELETE_APP_MODAL_APPLICATION,
      }}
    />
  );
};

export default ConfirmDeleteAppModal;
