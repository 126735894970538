import { Text } from '@humanitec/ui-components';
import { Edge, Node, Position } from 'reactflow';
import styled from 'styled-components';

import i18n from '@src/i18n/i18n';
import { PipelineYamlSchema } from '@src/models/pipeline';
import { DynamicFormSchema } from '@src/types/dynamic-form';

const translations = i18n.t('PIPELINE_RUNS');

const JobId = styled(Text)`
  text-transform: capitalize;
  display: block;
`;

export const convertToNodesAndEdges = (
  yamlData: PipelineYamlSchema
): { nodes: Node[]; edges: Edge[] } => {
  const nodes = [];
  const edges = [];

  const jobs = yamlData?.jobs || {};
  for (const jobName in jobs) {
    if (Object.prototype.hasOwnProperty.call(jobs, jobName)) {
      const job = jobs[jobName];
      const needs = job.needs || [];

      nodes.push({
        id: jobName,
        data: {
          label: (
            <>
              <JobId size={'base'}>{jobName}</JobId>
              <Text color={'textTranslucent'} size={'sm'}>
                {job.steps?.length || 0} {translations.STEPS}
              </Text>
            </>
          ),
        },
        position: { x: 0, y: 0 },
        connectable: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      });

      for (const dependency of needs) {
        edges.push({ id: `${dependency}-${jobName}`, source: dependency, target: jobName });
      }
    }
  }

  return { nodes, edges };
};

/**
 * Converts a Pipeline JSON schema to a Dynamic Form JSON schema.
 *
 * @param {any} object - The Pipeline JSON schema object.
 * @returns {DynamicFormSchema | undefined} - The converted Dynamic Form JSON schema.
 */
export const convertPipelineJSONSchemaToDynamicFormJsonSchema = (
  object: any
): DynamicFormSchema | undefined => {
  return !object
    ? undefined
    : Object.entries(object).reduce(
        (acc: any, [key, value]: any) => {
          if (typeof value === 'object') {
            if (!value.type) {
              if (!acc.properties[key]) {
                acc.properties[key] = {};
              }
              if (!Array.isArray(value)) {
                acc.properties[key] = {
                  ...acc.properties[key],
                  ...convertPipelineJSONSchemaToDynamicFormJsonSchema(value),
                };
              }
              acc.properties[key].type = 'object';
            } else {
              acc.properties[key] = value;
              acc.properties[key].title = key;
              if (value.required) {
                if (!acc.required) {
                  acc.required = [];
                }
                acc.required.push(key);
              }
            }
          }

          return acc;
        },
        { properties: {} }
      );
};
