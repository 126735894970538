import useResourceDefinitionsQuery from './react-query/resources/queries/useResourceDefinitionsQuery';
import { useFeature } from './useFeature';

export const useShouldShowWelcomeBox = () => {
  // Optimizely
  const [isWelcomeBoxEnabled] = useFeature('welcome-screen');

  // React Query
  const { data: resourceDefinitions, isSuccess: resourceDefinitionsLoaded } =
    useResourceDefinitionsQuery();

  return (
    resourceDefinitionsLoaded &&
    resourceDefinitions?.every((def) => def.is_default) &&
    isWelcomeBoxEnabled
  );
};
