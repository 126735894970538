import { WalButton, WalCard } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const GetStartedButton = styled(WalButton)`
  align-self: flex-start;
`;

interface WelcomeBoxProps {
  className?: string;
}

const WelcomeBox = ({ className }: WelcomeBoxProps) => {
  // i18n
  const { t } = useTranslation('viewApplicationList');
  const translations = t('WELCOME_BOX');

  return (
    <WalCard cardStyle={'transparent'} className={className}>
      <span className={'txt-lg mb-lg'}>{translations.WELCOME_TO_HUMANITEC}</span>
      <p className={'mb-md'}>{translations.NEXT_STEPS}</p>
      <p className={'mb-lg'}>{translations.TAKES_45_MINS}</p>
      <GetStartedButton
        link={{
          href: 'https://developer.humanitec.com/training/master-your-internal-developer-platform/setup-ref-arch-in-your-cloud/',
        }}
        target={'_blank'}>
        {translations.GET_STARTED}
      </GetStartedButton>
    </WalCard>
  );
};

export default WelcomeBox;
