import { Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { WalButton } from '../../base/Button/Button';

export interface AddButtonFormProps {
  /* the text on the button */
  buttonText: string;
  /* the form that should be displayed on the left side of the component in edit mode */
  formContent: ReactNode;
  /* Hides add mode button */
  readonly?: boolean;
  showFormContentState?: [boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>];
}

const AddButtonForm = ({
  buttonText,
  formContent,
  readonly = false,
  showFormContentState,
}: AddButtonFormProps) => {
  const showFormContentStateFromComponent = useState<boolean | undefined>(false);
  const [showFormContent, setShowFormContent] =
    showFormContentState || showFormContentStateFromComponent;

  if (readonly) return <div />;

  return !showFormContent ? (
    <WalButton
      variant={'secondary'}
      className={'full-width-button'}
      iconLeft={'plus'}
      onClick={() => setShowFormContent(true)}>
      {buttonText}
    </WalButton>
  ) : (
    <>{formContent}</>
  );
};

export default AddButtonForm;
