import { useFeature } from '../useFeature';
import useGetRouteMatchObject from './useGetRouteMatchObject';

/**
 * Hook that returns a string depending on what page you're on. This is used to define what level we are on for breadcrumbs.
 */
const useGetCurrentPage = () => {
  const {
    profileSettingsMatch,
    appsListMatch,
    appDetailsMatch,
    compareDeploymentsMatch,
    workloadMatch,
    resourcesMatch,
    resourceDetailsMatch,
    registriesMatch,
    environmentTypesMatch,
    accountsMatch,
    imagesMatch,
    apiTokensMatch,
    orgMembersMatch,
    serviceUsersMatch,
    pipelineRunsMatch,
    pipelineDetailsMatch,
    pipelineRundetailsMatch,
    pipelineVersionMatch,
    userDetailsMatch,
  } = useGetRouteMatchObject();
  const [isNewServiceUsersEnabled] = useFeature('service_users');

  return profileSettingsMatch
    ? 'profilesettingspage'
    : compareDeploymentsMatch
      ? 'comparedeploymentspage'
      : appsListMatch
        ? 'appslistpage'
        : appDetailsMatch
          ? 'appdetailspage'
          : workloadMatch
            ? 'workloadpage'
            : resourcesMatch
              ? 'resourcespage'
              : resourceDetailsMatch
                ? 'resourcedetailspage'
                : registriesMatch
                  ? 'registriespage'
                  : pipelineVersionMatch
                    ? 'pipelineversionpage'
                    : pipelineDetailsMatch
                      ? 'pipelinedetailspage'
                      : environmentTypesMatch
                        ? 'environmenttypespage'
                        : accountsMatch
                          ? 'accountspage'
                          : pipelineRundetailsMatch
                            ? 'pipelinerundetailspage'
                            : pipelineRunsMatch
                              ? 'pipelinerunspage'
                              : imagesMatch
                                ? 'imagespage'
                                : !isNewServiceUsersEnabled && apiTokensMatch
                                  ? 'apitokenspage'
                                  : orgMembersMatch
                                    ? 'orgmembersmatch'
                                    : isNewServiceUsersEnabled && serviceUsersMatch
                                      ? 'serviceusersmatch'
                                      : userDetailsMatch
                                        ? 'userdetailspage'
                                        : undefined;
};

export default useGetCurrentPage;
