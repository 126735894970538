import { Route, Routes } from 'react-router-dom';

import { useFeature } from '@src/hooks/useFeature';

import OrgMemberDetails from './containers/OrgMemberDetails/OrgMemberDetails';
import OrgMembersList from './containers/OrgMembersList/OrgMembersList';

const OrgMembers = () => {
  // Optimizely
  const [isReworkOrgMembersReadonlyEnabled] = useFeature('rework-org-members-readonly');
  return (
    <Routes>
      <Route path={'/'} element={<OrgMembersList />} />
      {isReworkOrgMembersReadonlyEnabled && (
        <Route path={':userId/*'} element={<OrgMemberDetails />} />
      )}
    </Routes>
  );
};

export default OrgMembers;
