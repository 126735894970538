import { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import { containerStyle } from '@src/styles/mixins';

const TRANSITION_DELAY = '300ms';

export const Content = styled.div<{ disableOverflow?: boolean }>`
  display: flex;
  flex-direction: column;
  ${containerStyle(true)};
  flex: 1;

  ${({ disableOverflow }) =>
    !disableOverflow &&
    css`
      overflow: auto;
    `}
  &.settings-enter {
    opacity: 0;
  }
  &.settings-enter-active {
    opacity: 1;
    transition: all ease-in ${TRANSITION_DELAY};
  }

  &.settings-exit {
    display: none;
  }
  &.settings-exit-active {
    opacity: 0;
    transition: all ease-in ${TRANSITION_DELAY};
  }
`;

const SectionsSettingWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Content tabIndex={-1}>
      <PageHeader />
      {children}
    </Content>
  );
};

export default SectionsSettingWrapper;
