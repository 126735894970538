import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

const RadioInput = styled.input`
  display: none;
`;

const RadioStyle = styled.div<{ selected: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(15)};
  width: ${rem(15)};
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.main : theme.color.baseOutline};
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) => theme.color.baseOutline};
      opacity: 0.48;
    `}
`;

const RadioInner = styled.div`
  height: ${rem(7)};
  width: ${rem(7)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.white};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  margin-left: ${units.margin.sm};
  font-size: ${units.fontSize.sm};
  line-height: ${rem(18)};
  flex: 1;
`;

interface RadioProps {
  name: string;
  value: string;
  id?: string;
  defaultChecked?: boolean;
  onClick?: (value: string) => void; // function to be called when radio button input or label(if provided) is clicked.
  label?: string;
  className?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  tabIndex?: number;
}

export const Radio = ({
  name,
  value,
  id,
  defaultChecked = false,
  onClick,
  label,
  className,
  disabled,
  hideLabel,
  tabIndex,
}: RadioProps) => {
  const { register, watch, setValue } = useFormContext();
  const [selected, setSelected] = useState(false);

  const formValue = watch(name);

  const onRadioClick = () => {
    setValue(name, value);
    if (onClick) {
      onClick(value);
    }
  };

  useEffect(() => {
    setSelected(formValue === value);
  }, [formValue, value]);

  useEffect(() => {
    setSelected(defaultChecked);
  }, [defaultChecked]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === KeyBindings.ENTER) {
      onRadioClick();
    }
  };

  return (
    <Wrapper className={className}>
      <RadioInput
        id={id || `${name}-${value}`}
        value={value}
        defaultChecked={defaultChecked}
        {...register(name)}
        type={'radio'}
      />
      <RadioStyle
        data-testid={label}
        disabled={disabled}
        selected={selected}
        onClick={onRadioClick}
        onKeyDown={handleKeyDown}
        tabIndex={tabIndex || 0}>
        {selected && <RadioInner />}
      </RadioStyle>
      {(label || value) && !hideLabel && (
        <Label htmlFor={id || `${name}-${value}`} onClick={onRadioClick}>
          {label || value}
        </Label>
      )}
    </Wrapper>
  );
};
