import { MultistepModal, WalButton, WalCard } from '@humanitec/ui-components';
import { addDays, endOfDay } from 'date-fns';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useApiTokensByUserCreateMutations from '@src/hooks/react-query/api-tokens/mutations/useApiTokensByUserCreateMutations';
import { useRolesStore } from '@src/hooks/zustand/useRolesStore';
import { OrgRoles, Role } from '@src/models/role';
import { useWalhallForm } from '@src/utilities/form';

import AddNewApiTokenForm from './AddNewApiTokenForm';
import ApiTokenTable from './ApiTokenTable/ApiTokenTable';
import StoreTokenForm from './StoreTokenForm';

const SectionCard = styled(WalCard)`
  background-color: ${({ theme }) => theme.color.baseOutline};
`;

const AddTokenButton = styled(WalButton)`
  width: max-content;
`;

interface ApiTokenSectionProps {
  user: Role<OrgRoles>;
  readOnly?: boolean;
}

type Payload = {
  description: string;
  id: string;
  type: string;
  expires_at?: Date;
};

const ApiTokenSection = ({ user, readOnly }: ApiTokenSectionProps) => {
  // Form
  const formMethods = useWalhallForm();
  const { reset, watch, setError, trigger } = formMethods;
  const formValues = watch();
  // i18n
  const { t } = useTranslation();
  const apiTokensTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS.API_TOKENS;
  const uiTranslations = t('UI');
  // State
  const [openmodal, setOpenModal] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [enableDoneButton, setEnableDoneButton] = useState<boolean>(false);
  const [createdToken, setCreatedToken] = useState<string | undefined>(undefined);
  // Zustand
  const { pushIdToLastCreatedApiTokensIds } = useRolesStore();

  // React Query
  const {
    mutate: createApiToken,
    isPending: isCreating,
    variables,
  } = useApiTokensByUserCreateMutations(
    user.id,
    // successCallback
    (token) => {
      setCreatedToken(token);
      setCurrentStep(currentStep + 1);
      // Push id to zustand store variable to display as newest item on ApiTokenTable.
      if (variables?.id) pushIdToLastCreatedApiTokensIds(variables?.id);
    },
    // errorCallback
    (errorMessage) => {
      // show backend error message on the id field in the form.
      if (!!errorMessage) setError('id', { type: 'manual', message: errorMessage });
    }
  );

  const handleCreateButtonClick = async () => {
    if (await trigger()) {
      const payload: Payload = {
        description: formValues.description,
        id: formValues.id.trim(),
        type: 'static',
      };

      if (formValues.expires_at !== 'no-expiration' && formValues.expires_at !== 'custom') {
        // only expires_at options with numeric values
        // Add numeric value in days to today and return 23:59:59:999pm (end of day) at the calculated day.
        payload.expires_at = endOfDay(addDays(new Date(), Number(formValues.expires_at)));
      } else if (formValues.expires_at === 'custom') {
        // Return 23:59:59:999pm (end of day) of the selected day from the DatePicker.
        payload.expires_at = endOfDay(new Date(formValues['expiry-date']));
      }
      createApiToken(payload);
    }
  };

  const handleDoneButtonClick = () => {
    setOpenModal(false);
    reset();
    setCurrentStep(0);
    setEnableDoneButton(false);
  };

  const renderAddTokenModal = () => {
    return (
      <FormProvider {...formMethods}>
        <form>
          <MultistepModal
            showClose={currentStep === 0} // only show cancel icon on first step
            disableClickOutside={currentStep > 0} // only allow cancelling by clicking outside the modal for the first step
            steps={[
              {
                title: apiTokensTranslations.ADD_API_TOKEN,
                actions: [
                  {
                    label: uiTranslations.CLOSE,
                    callback: reset,
                  },
                  {
                    label: apiTokensTranslations.CREATE_NEW_API_TOKEN,
                    variant: 'primary',
                    disableDefaultAction: true,
                    loading: isCreating,
                    disabled: isCreating || Boolean(formValues.id?.trim()) === false,
                    callback: handleCreateButtonClick,
                  },
                ],
                content: <AddNewApiTokenForm user={user} />,
              },
              {
                title: apiTokensTranslations.STORE_TOKEN,
                actions: [
                  {
                    label: uiTranslations.DONE,
                    type: 'submit',
                    disabled: !enableDoneButton,
                    callback: () => handleDoneButtonClick(),
                  },
                ],
                content: (
                  <StoreTokenForm
                    enableDoneButtonState={[enableDoneButton, setEnableDoneButton]}
                    createdToken={createdToken}
                  />
                ),
              },
            ]}
            openState={[openmodal, setOpenModal]}
            currentStepState={[currentStep, setCurrentStep]}
          />
        </form>
      </FormProvider>
    );
  };

  return (
    <SectionCard className={''} cardStyle={'transparent'} padding={'small'}>
      <div className={'mt-md mb-md txt-translucent'}>{apiTokensTranslations.API_TOKENS} </div>
      {!readOnly && (
        <AddTokenButton
          variant={'secondary'}
          iconLeft={{ name: 'plus' }}
          innerWrapperNotFullWidth
          solidColor={false}
          onClick={() => setOpenModal(true)}>
          {apiTokensTranslations.ADD_NEW_API_TOKEN}
        </AddTokenButton>
      )}
      {renderAddTokenModal()}
      <ApiTokenTable userId={user.id} readOnly={readOnly} />
    </SectionCard>
  );
};

export default ApiTokenSection;
