import { TabGroupRouting } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useUserDetailQuery from '@src/hooks/react-query/user/useUserDetailQuery';
import { AllRoles } from '@src/models/role';
import { containerStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { capitalize } from '@src/utilities/string-utility';

import OrgMemberApplicationRoles from './components/OrgMemberApplicationRoles';
import OrgMemberEnvTypeRoles from './components/OrgMemberEnvTypeRoles';
import OrgMemberRemove from './components/OrgMemberRemove';

const Container = styled.div`
  ${containerStyle()};
  display: flex;
  flex-direction: column;
  margin-top: ${units.margin.xl};
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 50%;
`;

const InfoGroup = ({ label, value }: { label: string; value: string | AllRoles | undefined }) => (
  <div className={'flex flex-column mb-md'}>
    <span className={'txt-translucent txt-sm pb-xs'}>{label}</span>
    <span className={'pb-md txt-md'}>{value}</span>
  </div>
);

const OrgMemberDetails = () => {
  const { t } = useTranslation('orgMembers');

  const { data: userDetail } = useUserDetailQuery();

  return (
    <Container>
      <span className={'txt-translucent txt-sm pb-xs'}>{t('USER')}</span>
      <span className={'txt-xl mb-xl'}>{userDetail?.name || '...'}</span>
      <InfoGroup label={t('EMAIL_ADDRESS')} value={userDetail?.email} />
      <DetailsGrid>
        <InfoGroup
          label={t('STATUS')}
          value={userDetail?.type === 'user' ? t('ACTIVE') : t('INVITATION_PENDING')}
        />
        <InfoGroup
          label={t('JOINED')}
          value={formatDate(userDetail?.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR)}
        />
        <InfoGroup label={t('ORGANIZATION_ROLE')} value={capitalize(userDetail?.role ?? '')} />
      </DetailsGrid>
      <TabGroupRouting
        items={[
          {
            label: t('APPLICATIONS'),
            path: 'applications',
            element: <OrgMemberApplicationRoles />,
          },
          {
            label: t('ENVIRONMENT_TYPES'),
            path: 'environment-types',
            element: <OrgMemberEnvTypeRoles />,
          },
          {
            label: t('REMOVE_USER'),
            path: 'remove',
            element: <OrgMemberRemove />,
            hide: true,
          },
        ]}
      />
    </Container>
  );
};

export default OrgMemberDetails;
