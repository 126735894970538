import { MenuItem } from '@humanitec/ui-components';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import { isCollectionFeature, WorkloadFeatureDefinition } from '@src/models/workload-profile-v2';
import { BreadcrumbItem } from '@src/types/breadcrumbs';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import {
  generateAppURL,
  generateDeploymentURL,
  generateDraftURL,
  generatePipelinesURL,
  generateProfileSettingsURL,
  generateResourceDefinitionUrl,
  generateResourcesUrl,
  generateSettingsURL,
} from '@src/utilities/navigation';

import useApplicationQuery from './react-query/applications/queries/useApplicationQuery';
import useApplicationsQuery from './react-query/applications/queries/useApplicationsListQuery';
import useDeploymentDeltaListQuery from './react-query/deployment-delta/queries/useDeploymentDeltaListQuery';
import useDeploymentDeltaQuery from './react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import useDeploymentQuery from './react-query/environments/queries/useDeploymentQuery';
import useDeploymentsListQuery from './react-query/environments/queries/useDeploymentsListQuery';
import useEnvironmentQuery from './react-query/environments/queries/useEnvironmentQuery';
import usePipelineRunDetailsQuery from './react-query/pipeline-runs/queries/usePipelineRunDetailsQuery';
import usePipelineDetailsQuery from './react-query/pipelines/queries/usePipelineDetailsQuery';
import useUserDetailQuery from './react-query/user/useUserDetailQuery';
import useWorkloadProfileLatestQuery from './react-query/workload-profiles/queries/useWorkloadProfileLatestQuery';
import useGetCurrentPage from './react-router/useGetCurrentPage';
import useGetRouteMatchObject from './react-router/useGetRouteMatchObject';
import useParamsFromParent from './react-router/useParamsFromParent';
import { useGetDeploymentSetWithDeltaUpdates } from './useGetDeploymentSetWithDeltaUpdates';
import { useGetNavigationPermissions } from './useGetNavigationPermissions';

const DraftListItem = styled.div`
  span {
    color: ${({ theme }) => theme.color.textTranslucent};
  }
`;

const DeploymentComment = styled.span`
  overflow-wrap: anywhere;
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

/**
 * useBreadcrumbs hook to abstract breadcrumb data generation from the component that renders it.
 *
 * The breadcrumbs component needs to be rendered at the root of the app.
 * As of now, we don't have access to the routing data at this level. e.g. :appId is not accessible unless you're trying to access it under a child route of the URL `/orgs/:orgId/apps/:appId`.
 * This means we have to manually define the routes in this hook & the hooks that are used in it.
 *
 * A better solution is to use useMatches - https://reactrouter.com/en/main/hooks/use-matches#breadcrumbs
 * But we need to migrate to `createBrowserRouter` to use this `useMatches`. We should do this after the navigation refactoring is finished.
 * Ticket for `createBrowserRouter` - https://humanitec.atlassian.net/browse/WAL-5572
 */
const useBreadcrumbs = () => {
  // i18n
  const { t } = useTranslation();
  const translations = t('NAVIGATION');
  const breadcrumbTranslations = t('BREADCRUMBS');
  const uiTranslations = t('UI');

  // Get all the params from a custom hook (we usually use useParams, but since we're using this hook at the root of the app, we need to have a custom hook to define the params)
  const {
    orgId,
    appId,
    envId,
    defId,
    moduleId,
    deployOrDeltaSegment,
    deployOrDeltaId,
    pipelineId,
    pipelineRunId,
    pipelineVersionId,
    userId,
  } = useParamsFromParent();
  const [search] = useSearchParams();
  const currentPage = useGetCurrentPage();
  const { workloadMatch } = useGetRouteMatchObject();

  // Hooks
  const deploymentSetWithDeltaUpdates = useGetDeploymentSetWithDeltaUpdates();

  // React Query
  const { data: resourceDefinitions } = useResourceDefinitionsQuery();
  const { data: environmentDeltas } = useDeploymentDeltaListQuery({
    orgId,
    appId,
    envId,
  });
  const { data: delta } = useDeploymentDeltaQuery({
    orgId,
    appId,
    deltaId: deployOrDeltaSegment === 'draft' ? deployOrDeltaId : undefined,
  });
  const { data: environmentDeployments = [] } = useDeploymentsListQuery({ orgId, appId, envId });
  const { data: deploy } = useDeploymentQuery(
    deployOrDeltaSegment === 'deploys'
      ? { orgId, appId, envId, deploymentId: deployOrDeltaId }
      : undefined
  );
  const { data: environment } = useEnvironmentQuery({
    orgId,
    appId,
    envId,
  });
  const { data: pipelineRun } = usePipelineRunDetailsQuery({
    id: pipelineRunId || search.get('run') || undefined,
  });
  const { data: pipeline } = usePipelineDetailsQuery({ id: pipelineId, appId });

  const workload = deploymentSetWithDeltaUpdates?.modules[moduleId];

  const { data: workloadProfile } = useWorkloadProfileLatestQuery(workload?.profile);

  const resourceDefinition = resourceDefinitions?.find((resDef) => resDef.id === defId);

  const { data: applications } = useApplicationsQuery();
  const { data: app } = useApplicationQuery({ appId });
  const { data: userDetail } = useUserDetailQuery({ userId });

  /**
   * Get all the navbar items.
   */
  const navigationItems = useGetNavigationPermissions(orgId);

  const crumbs = useMemo(() => {
    /**
     * Define the initial breadcrumbs array.
     *
     * 'name' & 'label' are required. Adding `items` will render a dropdown for that crumb item
     */
    const breadcrumbArray: BreadcrumbItem[] = [];

    // Filter out the application item, we don't want to display that on the org settings pages
    const orgSettingsItems: MenuItem<string>[] = navigationItems
      .filter((item) => item.to !== `/orgs/${orgId}/apps`)
      .map(({ label, to }) => ({
        label,
        link: { to },
        value: to,
      }));

    let orgSettingsName = '';

    const pipelinesCrumbs = () => {
      if (appId) {
        breadcrumbArray.push({
          name: app?.name || '',
          label: translations.APP,
          labelAsTitle: true,
          menuAriaLabel: 'Applications',
          to: generatePipelinesURL(orgId, appId),
        });
      }
      if (pipelineId && !pipelineVersionId) {
        breadcrumbArray.push({
          name: pipeline?.name || '',
          label: translations.PIPELINES,
          labelAsTitle: true,
          menuAriaLabel: 'Pipelines',
          to: generatePipelinesURL(orgId, appId, pipelineId),
        });
      }

      if (pipelineRun) {
        breadcrumbArray.push({
          name: format(new Date(pipelineRun.created_at), 'HH:mm, dd MMM yyyy'),
          label: translations.PIPELINE_RUNS,
          labelAsTitle: true,
          menuAriaLabel: 'Pipeline Runs',
          to: generatePipelinesURL(orgId, appId, pipelineId, pipelineRun.id),
        });
      }

      if (pipelineVersionId) {
        breadcrumbArray.push({
          name: `${pipeline?.name} @ ${pipelineVersionId}` || '',
          label: translations.PIPELINES,
          labelAsTitle: true,
          menuAriaLabel: 'Pipelines',
          to: generatePipelinesURL(orgId, appId, pipelineId),
        });
      }
    };

    /**
     * First level crumbs.
     *
     * Org settings, application list
     */
    if (currentPage === 'profilesettingspage') {
      breadcrumbArray.push({ name: translations.ACCOUNT, to: generateProfileSettingsURL() });
    } else if (currentPage === 'appslistpage') {
      breadcrumbArray.push({
        name: '',
        label: translations.APP,
        items: applications?.map((a) => ({
          link: {
            to: generateAppURL(orgId, a.id),
          },
          label: a.name,
          value: a.id,
        })),
        labelAsTitle: true,
        menuAriaLabel: 'Application',
      });
    } else if (currentPage === 'resourcespage') {
      orgSettingsName = translations.RESOURCE_DEFINITIONS;
    } else if (currentPage === 'registriespage') {
      orgSettingsName = translations.REGISTRIES;
    } else if (currentPage === 'environmenttypespage') {
      orgSettingsName = translations.ENVIRONMENT_TYPES;
    } else if (currentPage === 'accountspage') {
      orgSettingsName = translations.CLOUD_ACCOUNTS;
    } else if (currentPage === 'imagespage') {
      orgSettingsName = translations.CONTAINER_IMAGES;
    } else if (currentPage === 'apitokenspage') {
      orgSettingsName = translations.API_TOKENS;
    } else if (currentPage === 'orgmembersmatch') {
      orgSettingsName = translations.ORG_MEMBERS;
    } else if (currentPage === 'serviceusersmatch') {
      orgSettingsName = translations.SERVICE_USERS;
    } else if (currentPage === 'pipelinerunspage') {
      pipelinesCrumbs();
    } else if (currentPage === 'pipelinerundetailspage') {
      pipelinesCrumbs();
    } else if (defId && resourceDefinition) {
      breadcrumbArray.push({
        label: translations.SETTINGS,
        name: translations.RESOURCE_DEFINITIONS,
        to: generateResourcesUrl(orgId),
        items: orgSettingsItems,
      });
    } else if (currentPage === 'pipelineversionpage') {
      pipelinesCrumbs();
    } else if (currentPage === 'pipelinedetailspage') {
      pipelinesCrumbs();
    } else if (appId && app) {
      breadcrumbArray.push({
        label: translations.APP,
        name: app.name,
        to: generateAppURL(orgId, appId),
        items: applications?.map((a) => ({
          link: { to: generateAppURL(orgId, a.id) },
          label: a.name,
          value: a.id,
        })),
        menuAriaLabel: breadcrumbTranslations.SELECT_APPLICATION,
      });
    } else if (currentPage === 'userdetailspage' && userDetail) {
      const items: BreadcrumbItem[] = [
        {
          name: translations.ORG_MEMBERS,
          label: breadcrumbTranslations.SETTINGS,
          to: generateSettingsURL(orgId, 'org-members'),
        },
        {
          label: 'User',
          name: userDetail?.name,
        },
      ];
      breadcrumbArray.push(...items);
    }

    // If orgSettingsName exists, that mean we're on an org settings page, and not a child page e.g. view resource definition
    if (orgSettingsName) {
      breadcrumbArray.push({
        name: orgSettingsName,
        label: breadcrumbTranslations.SETTINGS,
        items: orgSettingsItems,
      });
    }

    /**
     * Second level crumbs.
     *
     * Env, resource details
     */
    if (environment) {
      breadcrumbArray.push({
        label: translations.ENV,
        name: environment.name,
        to: generateAppURL(orgId, appId, envId),
        items: app?.envs.map((e) => ({
          link: { to: generateAppURL(orgId, appId, e.id) },
          label: e.name,
          value: e.id,
        })),
        menuAriaLabel: 'Select environment',
      });
    }
    if (currentPage === 'resourcedetailspage' && resourceDefinition) {
      breadcrumbArray.push({
        name: t('BREADCRUMBS.OBJECT_DETAILS', { replace: { objectName: resourceDefinition?.id } }),
        to: generateResourceDefinitionUrl(orgId, resourceDefinition?.id),
      });
    }

    /**
     * Third level crumbs.
     *
     * Draft, Deploy
     */
    if (currentPage === 'comparedeploymentspage') {
      breadcrumbArray.push({
        name: breadcrumbTranslations.DIFF_DEPLOYMENTS,
        to: generateAppURL(orgId, appId, envId),
      });
    } else if (delta && deployOrDeltaSegment === 'draft') {
      breadcrumbArray.push({
        label: uiTranslations.DRAFT,
        name: delta?.metadata?.name || uiTranslations.UNTITLED_DRAFT,
        to: generateDraftURL(orgId, appId, envId, delta.id, 'workloads'),
        items: environmentDeltas?.map((d) => ({
          link: { to: generateDraftURL(orgId, appId, envId, d.id, 'workloads') },
          label: d?.metadata?.name || uiTranslations.UNTITLED_DRAFT,
          value: d.id,
          component: (
            <DraftListItem>
              <div>{d?.metadata?.name || uiTranslations.UNTITLED_DRAFT}</div>
              <span>
                {t('BREADCRUMBS.LAST_EDITED', {
                  replace: {
                    time: formatDate(
                      d?.metadata?.last_modified_at,
                      DATE_FORMATS_TYPES.DATE_MONTH_HOUR_MINUTE,
                      true
                    ),
                  },
                })}
              </span>
            </DraftListItem>
          ),
        })),
        menuAriaLabel: breadcrumbTranslations.SELECT_DRAFT,
      });
    } else if (deploy && deployOrDeltaSegment === 'deploys') {
      breadcrumbArray.push({
        label: uiTranslations.DEPLOY,
        name: deploy.id,
        to: generateDeploymentURL(orgId, appId, envId, deploy.id, 'workloads'),
        items: environmentDeployments.slice(0, 20).map((environmentDeployment) => ({
          link: {
            to: generateDeploymentURL(orgId, appId, envId, environmentDeployment.id, 'workloads'),
          },
          label: environmentDeployment.comment || uiTranslations.NO_COMMENT,
          value: environmentDeployment.id,
          component: (
            <ItemWrapper>
              <div>{environmentDeployment.id}</div>
              <DeploymentComment>
                {environmentDeployment.comment || uiTranslations.NO_COMMENT}
              </DeploymentComment>
            </ItemWrapper>
          ),
        })),
      });
    }

    /**
     * Fourth level crumbs - Workloads
     */
    const workloadsURL = `/orgs/${orgId}/apps/${appId}/envs/${envId}/${deployOrDeltaSegment}/${deployOrDeltaId}/workloads`;
    // Workload doesn't have a readable name, so we can use the id from URL
    if (moduleId) {
      breadcrumbArray.push({
        label: uiTranslations.WORKLOAD,
        name: moduleId,
        to: `${workloadsURL}/${moduleId}`,
        items: Object.keys(deploymentSetWithDeltaUpdates?.modules || {}).map((workloadId) => ({
          link: {
            to: `${workloadsURL}/${workloadId}`,
          },
          label: workloadId,
          value: workloadId,
        })),
        menuAriaLabel: breadcrumbTranslations.SELECT_WORKLOAD,
      });
    }

    return breadcrumbArray;
  }, [
    app,
    appId,
    applications,
    breadcrumbTranslations.DIFF_DEPLOYMENTS,
    breadcrumbTranslations.SELECT_APPLICATION,
    breadcrumbTranslations.SELECT_DRAFT,
    breadcrumbTranslations.SELECT_WORKLOAD,
    breadcrumbTranslations.SETTINGS,
    currentPage,
    defId,
    delta,
    deploy,
    deployOrDeltaId,
    deployOrDeltaSegment,
    deploymentSetWithDeltaUpdates?.modules,
    envId,
    environment,
    environmentDeltas,
    environmentDeployments,
    moduleId,
    navigationItems,
    orgId,
    pipeline?.name,
    pipelineId,
    pipelineRun,
    pipelineVersionId,
    resourceDefinition,
    t,
    translations.ACCOUNT,
    translations.API_TOKENS,
    translations.APP,
    translations.CLOUD_ACCOUNTS,
    translations.CONTAINER_IMAGES,
    translations.ENV,
    translations.ENVIRONMENT_TYPES,
    translations.ORG_MEMBERS,
    translations.PIPELINES,
    translations.PIPELINE_RUNS,
    translations.REGISTRIES,
    translations.RESOURCE_DEFINITIONS,
    translations.SERVICE_USERS,
    translations.SETTINGS,
    uiTranslations.DEPLOY,
    uiTranslations.DRAFT,
    uiTranslations.NO_COMMENT,
    uiTranslations.UNTITLED_DRAFT,
    uiTranslations.WORKLOAD,
    userDetail,
  ]);

  /**
   * Generate breadcrumbs based on workload profile structure & available data.
   */
  const workloadProfileCrumbs = useMemo(() => {
    const breadcrumbArray: BreadcrumbItem[] = [];
    if (deployOrDeltaId && moduleId) {
      const segments = workloadMatch?.params['*']?.split('/');

      let awaitingCollectionId = false;

      let currentFeatures: Record<string, WorkloadFeatureDefinition> | undefined =
        workloadProfile?.spec_definition?.properties;

      let currentCrumbLabel = '';
      let currentCrumbName = '';

      segments?.forEach((segment, index) => {
        const currentFeatureSegment = currentFeatures?.[segment];

        if (!awaitingCollectionId && isCollectionFeature(currentFeatureSegment)) {
          // is collection. on next iteration, look for the collection id
          currentCrumbLabel = currentFeatureSegment.title || segment;
          awaitingCollectionId = true;
          currentFeatures = currentFeatureSegment.properties;
        } else if (awaitingCollectionId && !isCollectionFeature(currentFeatureSegment)) {
          currentCrumbName = segment;
          awaitingCollectionId = false;
        }

        const currentSegments = segments.slice(0, index + 1);

        if (currentCrumbLabel && currentCrumbName) {
          breadcrumbArray.push({
            label: currentCrumbLabel,
            name: currentCrumbName,
            to: `${workloadMatch?.pathnameBase}/${currentSegments.join('/')}`,
          });
          currentCrumbName = '';
          currentCrumbLabel = '';
        }
      });
    }
    return breadcrumbArray;
  }, [
    deployOrDeltaId,
    moduleId,
    workloadMatch?.params,
    workloadMatch?.pathnameBase,
    workloadProfile?.spec_definition?.properties,
  ]);

  return [...crumbs, ...workloadProfileCrumbs];
};

export default useBreadcrumbs;
