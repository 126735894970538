import { rem } from 'polished';
import { ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { CardStyles, getCardPadding, WalCard } from '../Card/Card';
import Icon from '../Icon/Icon';

const HeaderWrapper = styled.div<{ isExpanded?: boolean; $disabled?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $disabled }) => ($disabled ? `1fr` : `${rem(20)} 1fr`)};
  grid-column-gap: ${units.margin.md};
  align-items: center;
  ${getCardPadding()};
  ${({ $disabled }) =>
    !$disabled &&
    css`
      cursor: pointer;
    `}
`;

const ExpandableCardElement = styled(WalCard)`
  padding: 0;
`;

const Content = styled.div`
  ${getCardPadding()};
`;

export interface ExpandableCardProps {
  id: string;
  /* The content that will be shown on the header of the card*/
  headerContent: ReactNode;
  /* The content that will be viewed when the content is expanded */
  content: ReactNode;
  cardStyle?: CardStyles;
  /** disable expanding the accordion item */
  disableExpansion?: boolean;
  /** wether the accordion item should be expanded by default */
  expandedByDefault?: boolean;
  /**
   * expandedByDefault will usually only set the expended state on the first render.
   * In some cases, you want to update the expanded state after the first render
   * e.g. when the component is rendered, but API data comes in later
   */
  allowExpandedUpdateAfterFirstRender?: boolean;
  className?: string;
  title?: string;
  error?: boolean;
  dataTestId?: string;
}

const ExpandableCard = ({
  id,
  expandedByDefault = false,
  cardStyle,
  disableExpansion,
  headerContent,
  content,
  className,
  allowExpandedUpdateAfterFirstRender,
  title,
  error,
  dataTestId,
}: ExpandableCardProps) => {
  const [expanded, setExpanded] = useState(expandedByDefault);

  useEffect(() => {
    if (allowExpandedUpdateAfterFirstRender) {
      setExpanded(expandedByDefault);
    }
  }, [allowExpandedUpdateAfterFirstRender, expandedByDefault]);

  const expandItem = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <ExpandableCardElement
      className={className}
      cardStyle={cardStyle}
      ariaLabel={title}
      error={error}
      dataTestId={dataTestId}
      role={'region'}>
      <HeaderWrapper
        isExpanded={expanded}
        onClick={() => !disableExpansion && expandItem()}
        $disabled={disableExpansion}
        aria-controls={id}
        aria-expanded={expanded}>
        {!disableExpansion && <Icon name={expanded ? 'arrow-down' : 'arrow-right'} />}
        {headerContent}
      </HeaderWrapper>
      {expanded && !disableExpansion && (
        <Content aria-hidden={!expanded} id={id}>
          {content}
        </Content>
      )}
    </ExpandableCardElement>
  );
};

export default ExpandableCard;
