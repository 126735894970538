import { TabGroup, TabGroupRouting, Text, Tooltip, WalButton } from '@humanitec/ui-components';
import yaml from 'js-yaml';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import { PipelineMatchingCriteria } from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelineDetails/PipelineMatchingCriteria/PipelineMatchingCriteria';
import PipelineRevertModal from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelineVersions/components/PipelineRevertModal/PipelineRevertModal';
import PipelineVersions from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelineVersions/PipelineVersions';
import { PipelineRunsList } from '@src/containers/Orgs/PipelineRuns/components/PipelineRunsList/PipelineRunsList';
import { useWalhallContext } from '@src/context/walhallContext';
import usePipelineRunCreateMutation from '@src/hooks/react-query/pipeline-runs/mutation/usePipelineRunCreateMutation';
import usePipelineDetailsQuery from '@src/hooks/react-query/pipelines/queries/usePipelineDetailsQuery';
import usePipelineSchemaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineSchemaQuery';
import usePipelinesVersions from '@src/hooks/react-query/pipelines/queries/usePipelineVersionsQuery';
import { useFeature } from '@src/hooks/useFeature';
import { PipelineYamlSchema } from '@src/models/pipeline';
import { CodePlaceholder } from '@src/styles/global-styles';
import { containerStyle } from '@src/styles/mixins';
import { generatePipelinesURL } from '@src/utilities/navigation';

import TriggerPipelineRunModal from '../../../../../../../../../../../components/shared/TriggerPipelineRunModal/TriggerPipelineRunModal';
import { DeletePipeline } from '../DeletePipeline/DeletePipeline';
import { PipelinePreview } from '../PipelinePreview/PipelinePreview';
import { PipelinesYaml } from '../PipelinesYaml/PipelinesYaml';

const Wrapper = styled.div`
  flex-direction: column;
  ${containerStyle()}
`;

interface PipelineDetailsProps {
  isCreation?: boolean;
  isView?: boolean;
}

const PipelineDetails = ({ isCreation, isView }: PipelineDetailsProps) => {
  const { pipelineId, pipelineVersionId, appId } = useParams();
  const location = useLocation();
  const [isPipelinePreviewEnabled] = useFeature('pipeline-preview');
  const navigate = useNavigate();

  // state
  const [openTriggerPipelineForm, setOpenTriggerPipelineForm] = useState(false);
  const [revertModalOpen, setRevertModalOpen] = useState(false);

  // i18n
  const { t } = useTranslation('viewApplication');
  const translations = t('PIPELINES');
  const tabTranslations = t('TABS');

  // react-query
  const { data: pipeline } = usePipelineDetailsQuery({
    id: pipelineId,
    versionId: pipelineVersionId,
    appId,
  });
  const { data: pipelineVersions = [] } = usePipelinesVersions();
  const { data: latestPipeline } = usePipelineDetailsQuery({
    id: pipelineVersionId ? pipelineId : undefined,
    appId,
  });
  const { data: schema = '' } = usePipelineSchemaQuery();
  const { shouldConfirmOnNavigateState } = useWalhallContext();

  const schemaObject = yaml.load(schema) as PipelineYamlSchema;
  const pipelineCall = schemaObject?.on?.pipeline_call;
  const hasDeploymentRequest = schemaObject?.on?.deployment_request;
  const { mutate: createPipelineRun } = usePipelineRunCreateMutation(pipelineId || '');
  const isLatest = isView && pipeline?.version === latestPipeline?.version;
  const selectedPipelineVersion = pipelineVersions.find((p) => p.id === pipeline?.version);

  const getItems = () => [
    {
      label: translations.RUNS,
      path: 'runs',
      element: <PipelineRunsList />,
    },
    {
      label: translations.DEFINITION,
      path: 'definition',
      element: <PipelinesYaml />,
      confirmOnLeave: shouldConfirmOnNavigateState[0],
    },
    {
      label: translations.MATCHING_CRITERIA_TITLE,
      path: 'matching-criteria',
      element: <PipelineMatchingCriteria />,
      disabled: !hasDeploymentRequest,
      hoverText: translations.MATCHING_CRITERIA_HOVER_TEXT,
    },
    {
      hide: !isPipelinePreviewEnabled,
      label: tabTranslations.PREVIEW,
      path: 'preview',
      element: <PipelinePreview />,
    },

    {
      label: tabTranslations.VERSIONS,
      path: 'versions',
      element: <PipelineVersions />,
    },
    {
      label: tabTranslations.DELETE,
      path: 'delete',
      element: <DeletePipeline />,
    },
  ];

  useEffect(() => {
    if (isLatest && pipeline) {
      navigate(`${generatePipelinesURL(pipeline?.org_id, pipeline?.app_id, pipeline?.id)}/runs`, {
        replace: true,
      });
    }
  }, [isLatest, navigate, pipeline]);

  return (
    <Wrapper>
      <PageHeader
        showPageContext={!isCreation}
        customHeading={
          isCreation ? (
            ' '
          ) : (
            <span>
              {pipeline?.name}{' '}
              {isView && pipeline ? <Text size={'base'}> @ {pipeline.version}</Text> : ''}
            </span>
          )
        }
        statusMessage={isCreation ? translations.CREATE_NEW_PIPELINE : undefined}
        rightContent={
          !isCreation &&
          (location.pathname.match(/\/(preview|definition)/) || isView) && (
            <Tooltip
              offset={15}
              width={240}
              text={
                <Trans defaults={translations.CANNOT_TRIGGER_PIPELINE}>
                  <CodePlaceholder />
                </Trans>
              }
              disableTooltip={typeof pipelineCall !== undefined}
              triggerComponent={
                <WalButton
                  onClick={() => {
                    if (isView) {
                      setRevertModalOpen(true);
                      return;
                    }
                    if (isEmpty(pipelineCall?.inputs)) {
                      createPipelineRun({ inputs: { inputs: {} } });
                    } else {
                      setOpenTriggerPipelineForm(true);
                    }
                  }}
                  disabled={typeof pipelineCall === undefined}>
                  {isView ? translations.RESTORE : translations.RUN}
                </WalButton>
              }
            />
          )
        }
      />

      {isCreation || isView ? (
        <TabGroup
          options={[
            {
              label: isCreation ? translations.YAML : translations.VIEW,
              value: 'view',
              element: <PipelinesYaml isCreation={isCreation} isView={isView} />,
            },
            ...(isView
              ? [
                  {
                    label: tabTranslations.VERSIONS,
                    value: 'versions',
                    element: <PipelineVersions />,
                  },
                ]
              : []),
          ]}
        />
      ) : (
        <TabGroupRouting items={getItems()} />
      )}
      {openTriggerPipelineForm && pipelineId && (
        <TriggerPipelineRunModal
          openState={[openTriggerPipelineForm, setOpenTriggerPipelineForm]}
          pipelineId={pipelineId}
        />
      )}
      {revertModalOpen && selectedPipelineVersion && latestPipeline && (
        <PipelineRevertModal
          openState={[revertModalOpen, setRevertModalOpen]}
          selectedPipelineVersion={selectedPipelineVersion}
          pipeline={latestPipeline}
        />
      )}
    </Wrapper>
  );
};
export default PipelineDetails;
