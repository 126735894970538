import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useWalhallContext } from '@src/context/walhallContext';
import { BreadcrumbItem } from '@src/types/breadcrumbs';

import BreadcrumbSection from './BreadcrumbSection';

const BreadcrumbOrderedList = styled.ol`
  display: flex;
  margin: 0;
  padding: 0;
`;

interface NewBreadcrumbsProps {
  crumbs: BreadcrumbItem[];
  className?: string;
}

const NewBreadcrumbs = ({ crumbs, className }: NewBreadcrumbsProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const { navigateDialogState, nextRouteState, shouldConfirmOnNavigateState } = useWalhallContext();
  const [, setShowNavigateDialog] = navigateDialogState;
  const [shouldConfirmOnNavigate] = shouldConfirmOnNavigateState;
  const [, setNextRoute] = nextRouteState;

  return (
    <nav className={className} aria-label={uiTranslations.BREADCRUMBS}>
      <BreadcrumbOrderedList>
        {crumbs.map((crumb, index) => (
          <BreadcrumbSection
            {...crumb}
            key={`${crumb.name}-${crumb.to}`}
            isLastItem={index === crumbs.length - 1}
            onClick={(event) => {
              if (shouldConfirmOnNavigate) {
                event.preventDefault();
                setShowNavigateDialog(true);
                setNextRoute(crumb.to as string);
              }
            }}
          />
        ))}
      </BreadcrumbOrderedList>
    </nav>
  );
};

export default NewBreadcrumbs;
