import { Icon, Spinner } from '@humanitec/ui-components';
import { rem } from 'polished';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

import { Log } from './ContainerLog';

const ContainerLogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 50vh;
`;

const LogEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ theme }) => theme.color.baseDarker};
  border-radius: ${rem(4)};
  padding: ${units.padding.lg} ${units.padding.xl};
  overflow: auto;
  font-family: 'Source Code Pro', sans-serif;
`;

const LogEntry = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${units.fontSize.sm};
`;

const LogEntryOverview = styled.div`
  display: grid;
  grid-template-columns: ${rem(150)} 1fr;
  align-items: center;
  margin-bottom: ${units.margin.md};
`;

const LogEntryMessage = styled.div`
  min-width: 0;
  overflow-wrap: break-word;
`;

const LogEntryTime = styled.div`
  min-width: 0;
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const ArrowIcon = styled(Icon)`
  justify-self: center;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

interface DisplayLogsProps {
  logs: Log[];
  loadingBefore: boolean;
  loadingAfter: boolean;
  moreLogsBefore: boolean;
  moreLogsAfter: boolean;
  getLogsAfter: () => void;
  getLogsBefore: () => void;
}

/** If the last log is this much away from the bottom of container window, we should load more */
const BUFFER_FROM_LAST_LOG = 200;

const DisplayLogs = ({
  logs,
  loadingBefore,
  loadingAfter,
  moreLogsAfter,
  moreLogsBefore,
  getLogsAfter,
  getLogsBefore,
}: DisplayLogsProps) => {
  // Context
  const { onRunningDeployment } = useDeploymentOrDeltaContext();

  // Component state
  const [stickToBottom, setStickToBottom] = useState(onRunningDeployment);
  const logWrapperRef = useRef<any>();
  const logRef = useRef<any>(null);

  const onWheel = (event: any) => {
    const logTopPos = logRef.current?.getBoundingClientRect().top;
    const wrapperBottomPos = event.currentTarget?.getBoundingClientRect().bottom;
    const wrapperTopPos = event.currentTarget?.getBoundingClientRect().top;

    const scrollDirection = event.nativeEvent.wheelDelta > 0 ? 'up' : 'down';

    const lastLogIsCloseToWrapperBottom = wrapperBottomPos - logTopPos + BUFFER_FROM_LAST_LOG > 0;
    const lastLogIsInView = wrapperBottomPos - logTopPos > 0;

    if (scrollDirection === 'up') {
      setStickToBottom(false);
    } else if (scrollDirection === 'down' && onRunningDeployment && lastLogIsInView) {
      setStickToBottom(true);
    }
    // Don't get logs after if we are on the currently running deployment.
    if (!onRunningDeployment && lastLogIsCloseToWrapperBottom && scrollDirection === 'down') {
      getLogsAfter();
    } else if (
      event.currentTarget.firstChild?.getBoundingClientRect().bottom - wrapperTopPos > 0 &&
      scrollDirection === 'up'
    ) {
      getLogsBefore();
    }
  };

  useEffect(() => {
    if (stickToBottom && onRunningDeployment) {
      logWrapperRef.current.scrollTop = logWrapperRef.current.scrollHeight;
    }
  }, [logs.length, stickToBottom, onRunningDeployment]);

  return (
    <ContainerLogWrapper>
      <LogEntryWrapper className={'qa-log-entry-wrapper'} onWheel={onWheel} ref={logWrapperRef}>
        <SpinnerWrapper>
          {moreLogsBefore && !loadingBefore && logs.length > 0 && (
            <ArrowIcon name={'arrow-up'} pointer onClick={getLogsBefore} />
          )}
          {loadingBefore && <Spinner size={'small'} />}
        </SpinnerWrapper>
        {logs.map((entry, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LogEntry key={index} ref={logRef}>
            <LogEntryOverview>
              <LogEntryTime>{formatDate(entry.timestamp, DATE_FORMATS_TYPES.LOGS)}</LogEntryTime>
              <LogEntryMessage>{entry.payload}</LogEntryMessage>
            </LogEntryOverview>
          </LogEntry>
        ))}
        <SpinnerWrapper>
          {loadingAfter && <Spinner size={'small'} />}
          {moreLogsAfter && !loadingAfter && logs.length > 0 && (
            <ArrowIcon name={'arrow-down'} pointer onClick={getLogsAfter} />
          )}
        </SpinnerWrapper>
      </LogEntryWrapper>
    </ContainerLogWrapper>
  );
};

export default DisplayLogs;
