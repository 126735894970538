import { Icon, Tag, WalButton, WalCard, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useWorkloadProfilesListQuery from '@src/hooks/react-query/workload-profiles/queries/useWorkloadProfilesListQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { usePrevious } from '@src/hooks/usePrevious';
import { useDeploymentDeltasStore } from '@src/hooks/zustand/useDeploymentDeltasStore';
import { isWorkloadProfile, ManagedProfile, WorkloadProfile } from '@src/models/workload-profile';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import WorkloadDropdown from '../../WorkloadDropdown/WorkloadDropdown';
import WorkloadNameInput from '../../WorkloadNameInput';

const ButtonsWrapper = styled.div`
  margin-top: ${units.margin.lg};
  button {
    margin-right: ${units.margin.sm};
  }
`;

const BasedOnWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${units.fontSize.sm};
`;

const WorkloadType = styled(Tag)`
  margin-left: ${units.margin.xs};
`;

const ProfileDropdown = styled(WorkloadDropdown)`
  justify-self: flex-end;
`;

const InfoCard = styled(WalCard)`
  justify-content: space-between;
  flex-direction: row;
`;

interface AddWorkloadModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
}

export const AddWorkloadModal = ({ openState }: AddWorkloadModalProps) => {
  // React Query
  const { data: workloadProfiles } = useWorkloadProfilesListQuery();

  // Zustand
  const { currentDeltaAction } = useDeploymentDeltasStore();

  // Component state
  const [open, setOpen] = openState;
  const [workloadProfileToAdd, setWorkloadProfileToAdd] = useState<
    WorkloadProfile | ManagedProfile
  >();
  const submitting = currentDeltaAction === 'add-workload';
  const prevDeltaCurrentAction = usePrevious(currentDeltaAction);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const workloadTranslations = t('VIEW_WORKLOADS');

  // Form
  const formMethods = useWalhallForm<{ workloadName: string }>({
    shouldUnregister: true,
    mode: 'onChange',
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const { addWorkload } = useDeltaUtils();

  useEffect(() => {
    setWorkloadProfileToAdd(
      (prevState) => prevState || workloadProfiles?.find((wp) => wp.id === 'default-module')
    );
  }, [workloadProfiles, open]);

  useEffect(() => {
    if (
      prevDeltaCurrentAction === 'add-workload' &&
      currentDeltaAction === 'add-workload-success'
    ) {
      setOpen(false);
    }
  }, [currentDeltaAction, prevDeltaCurrentAction, setOpen]);

  const submitForm = ({ workloadName }: { workloadName: string }) => {
    if (!workloadProfileToAdd) return;

    addWorkload(workloadName, {
      profile: `${
        isWorkloadProfile(workloadProfileToAdd) ? workloadProfileToAdd.org_id : 'humanitec'
      }/${workloadProfileToAdd.id}`,
      spec: {},
    });
  };

  return (
    <WalModal
      openState={openState}
      title={workloadTranslations.CREATE_WORKLOAD}
      disableOverflow
      backgroundColor={'base'}
      customSize={580}
      content={
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(submitForm)}>
            <WorkloadNameInput />
            <InfoCard>
              <BasedOnWrapper>
                <Icon name={'info'} size={14} marginRight={'sm'} />
                {workloadTranslations.BASED_ON_PROFILE}
                <WorkloadType text={workloadProfileToAdd?.id ?? ''} />
              </BasedOnWrapper>
              <ProfileDropdown
                onClick={(profile) => setWorkloadProfileToAdd(profile)}
                currentSelectedProfile={workloadProfileToAdd}
              />
            </InfoCard>
            <ButtonsWrapper>
              <WalButton
                type={'submit'}
                disabled={submitting || !isValid || !workloadProfileToAdd}
                loading={submitting}>
                {workloadTranslations.CREATE_WORKLOAD}
              </WalButton>
              <WalButton variant={'secondary'} onClick={() => setOpen(false)}>
                {uiTranslations.CANCEL}
              </WalButton>
            </ButtonsWrapper>
          </form>
        </FormProvider>
      }
    />
  );
};
