import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { AppRoles, Role } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { generateRolesURL, rolesQueryKeys } from '../rolesQueryKeys';

const useAppRolesQuery = (): QueryResponse<Role<AppRoles>[]> => {
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: rolesQueryKeys.appRoles(orgId, appId),
    queryFn: () =>
      makeRequest<Role<AppRoles>[]>(
        'GET',
        generateRolesURL(orgId || '', {
          type: 'apps',
          id: appId,
        })
      ),
  });

  return { ...queryResult, data: data?.data };
};

export default useAppRolesQuery;
