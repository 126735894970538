import {
  FormTable,
  InfoPopup,
  WalButton,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { css } from 'styled-components/macro';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import SnippetCopy from '@src/containers/Orgs/ApiTokens/components/SnippetCopy';
import SectionsSettingWrapper from '@src/containers/Orgs/components/styles';
import useApiTokensCreateMutations from '@src/hooks/react-query/api-tokens/mutations/useApiTokensCreateMutations';
import useApiTokensDeleteMutations from '@src/hooks/react-query/api-tokens/mutations/useApiTokensDeleteMutations';
import useApiTokensQuery from '@src/hooks/react-query/api-tokens/queries/useApiTokensQuery';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { ApiToken } from '@src/models/api-token';
import { OrgRoles } from '@src/models/role';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { useWalhallForm } from '@src/utilities/form';

const formGridTemplate = css`
  grid-template-columns: 1fr auto;
`;

const RevokeButton = styled(WalButton)`
  margin-right: ${units.margin.md};
`;

const ActionButtonsWrapper = styled.div`
  justify-self: end;
  justify-content: space-between;
`;

const ApiTokenDescription = styled.h4`
  display: flex;
  align-items: center;
`;

const ApiTokenWrapper = styled(SectionsSettingWrapper)`
  margin-top: 0;
`;

const ApiTokens = () => {
  // i18n
  const { t } = useTranslation();
  const settingsTranslations = t('ACCOUNT_SETTINGS').API_TOKENS;
  const uiTranslations = t('UI');
  const navigationTranslations = t('NAVIGATION');

  // Form
  const methods = useWalhallForm();
  const { setError, reset } = methods;

  // Component state
  const [showTokens, setShowTokens] = useState<any>({});
  const [tokenToBeDeletedId, setTokenToBeDeletedId] = useState<string | null>(null);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);

  // Context
  const { orgRole } = useGetUserRoles();

  // React Query
  const { data: apiTokens } = useApiTokensQuery();
  const { mutate: createApiToken } = useApiTokensCreateMutations(
    // successCallback
    () => {
      reset();
    },
    // errorCallback
    (tokenError) => {
      if (tokenError === 409) {
        setError('token_id', { type: 'manual', message: settingsTranslations.TOKEN_EXISTS });
      }
    }
  );
  const {
    mutate: deleteApiToken,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useApiTokensDeleteMutations();

  useEffect(() => {
    if (isDeletedSuccessfully) {
      setOpenConfirmDeleteModal(false);
      resetDeleteMutation();
    }
  }, [isDeletedSuccessfully, resetDeleteMutation]);

  const generateToken = (form: any) => {
    createApiToken(form.token_id);
  };

  const showToken = (token: string) => {
    const newShowTokens = { ...showTokens };
    newShowTokens[token] = true;
    setShowTokens(newShowTokens);
  };

  const hideToken = (token: string) => {
    const newShowTokens = { ...showTokens };
    newShowTokens[token] = false;
    setShowTokens(newShowTokens);
  };

  const revokeToken = () => {
    if (tokenToBeDeletedId) {
      deleteApiToken({ tokenId: tokenToBeDeletedId });
      setTokenToBeDeletedId(null);
    }
  };

  const columns: WalTableColumn<ApiToken>[] = [
    {
      prop: 'id',
      label: settingsTranslations.TOKEN_ID,
    },
    {
      prop: 'token',
      label: settingsTranslations.TOKEN,
      template: (row) =>
        showTokens[row.data.id] ? (
          <SnippetCopy customValue={row.data.token} maxWidth={250} />
        ) : (
          <span>{'••••••••'}</span>
        ),
    },
    {
      prop: 'edit_date',
      label: settingsTranslations.CREATED,
      template: (row) =>
        formatDate(row.data.create_date, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE),
    },
    {
      prop: 'created_by',
      label: settingsTranslations.GENERATED_BY,
      template: (row) => <span>{row.data.created_by?.name}</span>,
    },
    {
      prop: 'actions',
      template: (row) => (
        <ActionButtonsWrapper>
          {!showTokens[row.data.id] && (
            <WalButton variant={'secondary'} size={'small'} onClick={() => showToken(row.data.id)}>
              {uiTranslations.SHOW}
            </WalButton>
          )}
          {showTokens[row.data.id] && orgRole === 'administrator' && (
            <RevokeButton
              variant={'danger'}
              size={'small'}
              onClick={() => {
                setTokenToBeDeletedId(row.data.id);
                setOpenConfirmDeleteModal(true);
              }}>
              {uiTranslations.REVOKE}
            </RevokeButton>
          )}
          {showTokens[row.data.id] && (
            <WalButton variant={'secondary'} size={'small'} onClick={() => hideToken(row.data.id)}>
              {uiTranslations.HIDE}
            </WalButton>
          )}
        </ActionButtonsWrapper>
      ),
    },
  ];

  return (
    <ApiTokenWrapper>
      <ApiTokenDescription>
        {settingsTranslations.SET_UP_INTEGRATIONS}
        <InfoPopup
          moreInformationLink={'https://api-docs.humanitec.com'}
          position={'bottom'}
          text={settingsTranslations.SET_UP_INTEGRATIONS_INFO_TEXT}
          moreInformationText={settingsTranslations.SET_UP_INTEGRATIONS_MORE_INFORMATION_TEXT}
        />
      </ApiTokenDescription>
      <FormProvider {...methods}>
        <FormTable
          table={{
            caption: navigationTranslations.API_TOKENS,
            columns,
            rows:
              apiTokens?.map(
                (token): WalTableRow => ({
                  data: token,
                })
              ) ?? [],
          }}
          formGridTemplate={formGridTemplate}
          hideForm={
            orgRole ? !(['administrator', 'manager'] as OrgRoles[]).includes(orgRole) : true
          }
          form={{
            onSubmit: generateToken,
            submitButton: { children: settingsTranslations.GENERATE_TOKEN },
            fields: [
              {
                type: 'input',
                props: {
                  name: 'token_id',
                  label: settingsTranslations.TOKEN_ID,
                  required: true,
                  standardValidation: [
                    { type: 'id' },
                    { type: 'existingId', ids: apiTokens?.map((at) => at.id) ?? [] },
                  ],
                },
              },
            ],
          }}
          formMaxWidth={750}
        />
      </FormProvider>
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          deleteConfirmedCallback={revokeToken}
          deleteButtonText={uiTranslations.REVOKE}
          title={settingsTranslations.REVOKE_TOKEN}
          confirmMessage={settingsTranslations.REVOKE_TOKEN_CONFIRMATION}
          state={[openConfirmDeleteModal, setOpenConfirmDeleteModal]}
        />
      )}
    </ApiTokenWrapper>
  );
};

export default ApiTokens;
