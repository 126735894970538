import { WalButton } from '@humanitec/ui-components';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DynamicForm from '@src/components/shared/DynamicForm/DynamicForm';
import { mapDynamicFormValuesToSchemaDefaults } from '@src/components/shared/DynamicForm/utils/dynamic-form-utils';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { units } from '@src/styles/variables';
import { DynamicFormSchema } from '@src/types/dynamic-form';
import { useWalhallForm } from '@src/utilities/form';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-content: center;
  justify-content: space-between;
`;

const EditButton = styled(WalButton)`
  align-self: flex-start;
  justify-self: flex-end;
`;

const Form = styled.form`
  display: flex;
  flex: 1;
  margin-bottom: ${units.margin.md};
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  bottom: 0;
  flex: 1;
  padding: ${units.padding.lg} 0;
`;

const CreateButton = styled(WalButton)`
  margin-right: ${units.margin.sm};
`;

interface SchemaFeatureProps {
  schema: DynamicFormSchema;
  deltaPath: string;
}

const SchemaFeature = ({ schema, deltaPath }: SchemaFeatureProps) => {
  // State
  const [editMode, setEditMode] = useState<boolean>(false);
  const formMethods = useWalhallForm();
  const { handleSubmit } = formMethods;

  const splitPath = deltaPath.split('/');
  const lastPath = splitPath[splitPath.length - 1];

  const { updateWorkload, data } = useDeltaUtils<Record<string, any>>(deltaPath);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const onSubmit = handleSubmit((formValues) => {
    updateWorkload([{ value: formValues[lastPath], op: 'replace' }]);
    setEditMode(false);
  });

  return (
    <Wrapper>
      <FormProvider {...formMethods}>
        <Form onSubmit={onSubmit}>
          <DynamicForm
            formSchema={mapDynamicFormValuesToSchemaDefaults(data, schema)}
            prefix={lastPath}
            viewMode={!editMode}
          />
          {editMode && (
            <ButtonWrapper>
              <CreateButton type={'submit'}>{uiTranslations.SAVE}</CreateButton>
              <WalButton variant={'secondary'} onClick={() => setEditMode(false)}>
                {uiTranslations.CANCEL}
              </WalButton>
            </ButtonWrapper>
          )}
        </Form>
      </FormProvider>
      {!editMode && (
        <EditButton variant={'secondary'} iconLeft={'edit'} onClick={() => setEditMode(true)}>
          {uiTranslations.EDIT}
        </EditButton>
      )}
    </Wrapper>
  );
};

export default SchemaFeature;
