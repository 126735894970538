import dagre from 'dagre';
import { ConnectionLineType, Edge, Node, Position } from 'reactflow';

const dagreGraph = new dagre.graphlib.Graph();

dagreGraph.setDefaultEdgeLabel(() => ({}));

/**
 * Get automatic layouted elements using the dagre layout algorithm.
 *
 * @param {Node[]} nodes - An array of nodes.
 * @param {Edge[]} edges - An array of edges.
 * @param {string} [direction='LR'] - The direction of the layout: 'TB', 'BT', 'RL' or 'LR'. Defaults to 'LR'.
 * @returns {Object} - An object containing the layouted nodes and edges.
 */
export const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = 'LR') => {
  const nodeWidth = 172;
  const nodeHeight = 36;

  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
    edge.type = ConnectionLineType.SmoothStep;
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = Position.Left;
    node.sourcePosition = Position.Right;

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};
