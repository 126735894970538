import { useMatch } from 'react-router-dom';

/**
 * Use this hook if you want to get params from an element which is not nested under a url which contains that param.
 * In all other cases, you should use react-router useParams hook.
 * This hook will be redundant once this task is done - https://humanitec.atlassian.net/browse/WAL-5572
 */
const useParamsFromParent = () => {
  const orgMatch = useMatch('/orgs/:orgId/*');
  const appMatch = useMatch('/orgs/:orgId/apps/:appId/*');
  const envMatch = useMatch('/orgs/:orgId/apps/:appId/envs/:envId/*');
  const deployOrDeltaMatch = useMatch(
    '/orgs/:orgId/apps/:appId/envs/:envId/:deployOrDeltaSegment/:deployOrDeltaId/*'
  );
  const workloadOrSharedMatch = useMatch(
    '/orgs/:orgId/apps/:appId/envs/:envId/:deployOrDeltaSegment/:deployOrDeltaId/:workloadOrShared'
  );
  const workloadMatch = useMatch(
    '/orgs/:orgId/apps/:appId/envs/:envId/:deployOrDeltaSegment/:deployOrDeltaId/workloads/:moduleId/*'
  );

  const pipelineMatch = useMatch('/orgs/:orgId/apps/:appId/pipelines/:pipelineId/*');
  const pipelineVersionMatch = useMatch(
    '/orgs/:orgId/apps/:appId/pipelines/:pipelineId/versions/:pipelineVersionId/*'
  );

  const resourceDefinitionMatch = useMatch('/orgs/:orgId/resources/:defId/*');
  const pipelineRunMatch = useMatch(
    '/orgs/:orgId/apps/:appId/pipelines/:pipelineId/pipeline-runs/:pipelineRunId'
  );

  const userDetailsMatch = useMatch('/orgs/:orgId/org-members/:userId/*');

  // These matches are defined from lowest (containers) to highest (orgs) based on our routing structure
  const deepestMatch =
    userDetailsMatch ||
    workloadMatch ||
    workloadOrSharedMatch ||
    deployOrDeltaMatch ||
    envMatch ||
    pipelineRunMatch ||
    pipelineVersionMatch ||
    pipelineMatch ||
    appMatch ||
    resourceDefinitionMatch ||
    orgMatch;
  const params = deepestMatch?.params || {};

  return params as {
    orgId: string;
    appId: string;
    envId: string;
    defId: string;
    deployOrDeltaSegment: 'deploys' | 'draft';
    deployOrDeltaId: string;
    workloadOrShared: 'workloads' | 'shared';
    moduleId: string;
    pipelineRunId: string;
    pipelineId: string;
    pipelineVersionId: string;
    userId: string;
  };
};

export default useParamsFromParent;
