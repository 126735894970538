import { DropdownItem, WalDropdownMenu, WalInput, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useEnvironmentCreateMutation from '@src/hooks/react-query/environments/mutations/useEnvironmentCreateMutation';
import { DeploymentObject } from '@src/models/deployment-object';
import { Environment } from '@src/models/environment';
import { EnvironmentType } from '@src/models/environment-type';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import { generateSettingsURL } from '@src/utilities/navigation';

const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${units.margin.sm};
  margin-bottom: ${units.margin.md};
  align-items: flex-end;
`;

const NameInput = styled(WalInput)<{ fromDeployment?: DeploymentObject }>`
  grid-column: 1 / 3;
  ${({ fromDeployment }) =>
    fromDeployment &&
    css`
      grid-column: 1 / 2;
      order: 1;
    `}
`;

const EnvTypeSelect = styled.div<{ fromDeployment?: DeploymentObject }>`
  ${({ fromDeployment }) =>
    fromDeployment &&
    css`
      order: 2;
    `}
`;

interface AddEnvironmentModalProps {
  state: [boolean, Dispatch<SetStateAction<boolean>>];
  fromDeployment?: DeploymentObject;
  environments: Environment[];
}

export const CreateEnvironmentModal = ({
  state,
  fromDeployment,
  environments,
}: AddEnvironmentModalProps) => {
  // Form
  const methods = useWalhallForm();
  const { setValue, setError, clearErrors, formState } = methods;
  const { isDirty } = formState;

  // Component state
  const [modalOpen, setModalOpen] = state;
  const [cloneFromEnv, setCloneFromEnv] = useState<Environment>();
  const [selectedEnvType, setSelectedEnvType] = useState<string>();

  // React query
  const { data: envTypes = [] } = useEnvironmentTypesQuery();

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const viewApplicationTranslations = t('VIEW_APPLICATION');

  // Redux hooks
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const location = useLocation();

  // React Query
  const {
    mutate: createEnvironment,
    isPending: isEnvironmentCreating,
    isSuccess: isEnvironmentCreated,
    error: createEnvironmentError,
  } = useEnvironmentCreateMutation();

  const environmentOptions = useMemo(
    () =>
      environments
        .filter((env) => env.last_deploy)
        .map((environment) => ({
          label: environment.name,
          value: environment,
          id: environment.id,
        })),
    [environments]
  );

  const envTypeOptions: DropdownItem<EnvironmentType>[] = useMemo(
    () => envTypes.map((envType) => ({ label: envType.id, value: envType, id: envType.id })),
    [envTypes]
  );

  useEffect(() => {
    if (modalOpen) {
      setValue('environmentName', ''); // set env name to empty string everytime the modal is open
      clearErrors('environmentName');
    }
  }, [modalOpen, setValue, clearErrors]);

  useEffect(() => {
    if (
      environmentOptions.length &&
      !environmentOptions.find((envOption) => envOption.id === cloneFromEnv?.id)
    ) {
      setCloneFromEnv(environmentOptions[0].value);
    }
  }, [environmentOptions, cloneFromEnv]);

  useEffect(() => {
    if (environmentOptions?.length && !selectedEnvType) {
      setSelectedEnvType(environmentOptions[0].value.type);
    }
  }, [environmentOptions, selectedEnvType]);

  useEffect(() => {
    if (cloneFromEnv) {
      setSelectedEnvType(cloneFromEnv.type);
    }
  }, [cloneFromEnv]);

  useEffect(() => {
    if (isEnvironmentCreated) {
      setModalOpen(false);
    }
  }, [isEnvironmentCreated, setModalOpen]);

  useEffect(() => {
    if (createEnvironmentError?.response?.status === 409) {
      setError('environmentName', { type: 'manual', message: 'Environment already exists' });
    }
  }, [createEnvironmentError, setError]);

  const createEnv = (formValue: any) => {
    if (fromDeployment && fromDeployment.env_id && selectedEnvType) {
      createEnvironment({
        orgId,
        appId,
        envName: formValue.environmentName,
        envType: selectedEnvType,
        deploymentId: fromDeployment.id,
      });
    } else if (cloneFromEnv && selectedEnvType) {
      const envSource = environments.find((env) => env.id === cloneFromEnv.id);
      createEnvironment({
        orgId,
        appId,
        envName: formValue.environmentName,
        envType: selectedEnvType,
        deploymentId: envSource?.last_deploy?.id,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <WalModal
        handleFormSubmit={createEnv}
        title={viewApplicationTranslations.ADD_ENVIRONMENT_MODAL_TITLE}
        content={
          <>
            <FieldContainer>
              {!fromDeployment && cloneFromEnv && (
                <WalDropdownMenu
                  defaultValue={cloneFromEnv.id}
                  items={environmentOptions}
                  buttonVariant={'input'}
                  maxHeight={250}
                  fullWidth
                  label={viewApplicationTranslations.ENVIRONMENT_SOURCE}
                  onItemClick={(id, item) => setCloneFromEnv(item.value)}
                  menuSize={'parent'}
                />
              )}
              {cloneFromEnv && selectedEnvType && (
                <EnvTypeSelect fromDeployment={fromDeployment}>
                  <WalDropdownMenu
                    defaultValue={selectedEnvType}
                    items={envTypeOptions}
                    buttonVariant={'input'}
                    dataTestId={'environment-type-dropdown'}
                    fullWidth
                    maxWidth={250}
                    maxHeight={250}
                    label={viewApplicationTranslations.ENVIRONMENT_TYPE}
                    onItemClick={(id, value) => setSelectedEnvType(value.id)}
                    menuSize={'parent'}
                    fixedBottomRow={{
                      component: <span>{viewApplicationTranslations.MANAGE_TYPES}</span>,
                      link: {
                        to: generateSettingsURL(orgId, 'environment-types'),
                        state: {
                          previousPage: location.pathname,
                        },
                      },
                    }}
                  />
                </EnvTypeSelect>
              )}
              <NameInput
                name={'environmentName'}
                label={viewApplicationTranslations.ENVIRONMENT_ID}
                required
                maxLength={20}
                fromDeployment={fromDeployment}
                standardValidation={[
                  { type: 'name' },
                  { type: 'existingId', ids: environments.map((e) => e.id) },
                ]}
              />
            </FieldContainer>
          </>
        }
        actions={{
          main: {
            props: {
              type: 'submit',
              loading: isEnvironmentCreating,
            },
            text: uiTranslations.CREATE,
          },
          cancel: {},
        }}
        openState={[modalOpen, setModalOpen]}
        disableClickOutside={isDirty}
        disableOverflow
      />
    </FormProvider>
  );
};
