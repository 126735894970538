import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { WalModal } from '@humanitec/ui-components';
import yamlWorker from '@humanitec/ui-components/src/monaco/monaco-yaml-worker?worker'; //  https://github.com/remcohaszing/monaco-yaml#why-doesnt-it-work-with-vite
// Make monaco editor add to build, so it won't load from CDN everytime
import { loader } from '@monaco-editor/react';
import {
  createInstance,
  OptimizelyProvider,
  ReactSDKClient,
  setLogLevel,
} from '@optimizely/react-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import * as monaco from 'monaco-editor';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';

import AdBlockerWarningModal from '@src/components/AdBlockerWarningModal';
import { useUserPreferencesStore } from '@src/hooks/zustand/useUserPreferencesStore';
import { GlobalStyle } from '@src/styles/global-styles';
import {
  getInviteToken,
  getUserPreferencesFromLS,
  removeInviteToken,
} from '@src/utilities/local-storage';

import FormPlayground from './components/shared/DynamicForm/FormPlayground/FormPlayground';
import LoadingBar from './components/shared/LoadingBar';
import PrivateRoute from './components/shared/PrivateRoute';
import WorkloadProfilePlayground from './components/shared/ViewWorkloadProfile/WorkloadProfilePlayground';
import Auth from './containers/Auth/Auth';
import Orgs from './containers/Orgs/Orgs';
import ProfileSettings from './containers/ProfileSettings/ProfileSettings';
import VerifyEmail from './containers/VerifyEmail';
import AuthWrapper from './context/AuthWrapper';
import { useWalhallContext, WalhallContext } from './context/walhallContext';
import { windowEnv } from './environment';
import useAcceptInviteMutation from './hooks/react-query/user/useAcceptInviteMutation';
import useGetCurrentUserQuery from './hooks/react-query/user/useGetCurrentUserQuery';
import useLoadInviteQuery from './hooks/react-query/user/useLoadInviteQuery';
import { useDatadogReplay } from './hooks/useDatadogReplay';
import { useFeature } from './hooks/useFeature';
import { useCurrentUserStore } from './hooks/zustand/useCurrentUserStore';
import RedirectRoot from './RedirectRoot';
import { darkTheme, lightTheme } from './theme/theme';
import { addMetaTag } from './utilities/gapi-utils';
import {
  generateFrontChatNodes,
  identifyFrontChat,
  initFrontChat,
  shutdownFrontChat,
} from './utilities/tracking/frontchat';
import { hubspotLogin } from './utilities/tracking/hubspot/hubspot';

loader.config({ monaco });

// Add monaco worker info to window object. At this point, this is the only ugly way we know how to do this
// Monaco uses web workers for syntax validation. By adding webpack plugin in craco.config.js, we are already loading default web workers.
// But since we are using custom Yaml validation library , it needs to be loaded separately. But there is no way to load it using the webpack plugin,
// so we need to manually load the workers again here. We should see if there is a better way to do these
// @ts-ignore
window.MonacoEnvironment = {
  getWorker: (_: any, label: string) => {
    if (label === 'json') {
      return new jsonWorker();
    }
    if (label === 'typescript' || label === 'javascript') {
      return new tsWorker();
    }
    if (label === 'yaml') {
      // @ts-ignore
      return new yamlWorker();
    }
    return new editorWorker();
  },
};

// eslint-disable-next-line no-var
declare var gapi: any;

if (windowEnv.ENVIRONMENT_NAME !== 'local') {
  datadogRum.init({
    applicationId: 'eee00462-9d63-4565-946b-168f95001c0a',
    clientToken: windowEnv.DATADOG_KEY,
    site: 'datadoghq.eu',
    env: windowEnv.ENVIRONMENT_NAME,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    version: windowEnv.VERSION,
    defaultPrivacyLevel: 'mask',
    startSessionReplayRecordingManually: true,
  });

  datadogLogs.init({
    clientToken: windowEnv.DATADOG_KEY,
    site: 'datadoghq.eu',
    env: windowEnv.ENVIRONMENT_NAME,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

// Set optimizely log level to null
setLogLevel('null');

const AppMain = () => {
  const inviteToken = getInviteToken() || undefined;

  // Optimizely
  const [isFrontchatEnabled] = useFeature('frontchat');

  // Component state
  const [hubspotSent, setHubspotSent] = useState(false);
  const [frontChatScriptLoaded, setFrontChatScriptLoaded] = useState(false);
  const [frontChatInitialized, setFrontChatInitialized] = useState(false);
  const [frontChatIdentified, setFrontChatIdentified] = useState(false);

  const { pendingRequests } = useWalhallContext();
  const { data: user } = useGetCurrentUserQuery();
  const { data: invite, isError: loadInviteError } = useLoadInviteQuery(inviteToken);
  const { authType } = useCurrentUserStore();
  const { mutate: acceptInvite } = useAcceptInviteMutation();

  useDatadogReplay();
  /**
   * If the user is already logged in and the invitation exists, automatically accept it.
   */
  useEffect(() => {
    if (user && invite && invite.status !== 'done' && inviteToken) {
      acceptInvite({ invite });
    }
  }, [acceptInvite, invite, inviteToken, user]);

  useEffect(() => {
    if (loadInviteError) {
      removeInviteToken();
    }
  }, [loadInviteError]);

  const showLoadingBar = useMemo(() => pendingRequests !== 0, [pendingRequests]);

  useEffect(() => {
    if (user && authType === 'register' && !hubspotSent) {
      try {
        setHubspotSent(true);
        hubspotLogin(user, windowEnv.ENVIRONMENT_NAME);
      } catch (err) {
        datadogRum.addError(err);
      }
    }
  }, [authType, hubspotSent, user]);

  useEffect(() => {
    // Generate script tag & init frontchat.
    if (isFrontchatEnabled && !frontChatScriptLoaded) {
      generateFrontChatNodes(() => {
        setFrontChatScriptLoaded(true);
        setFrontChatInitialized(true);
      });
    } else if (!isFrontchatEnabled && frontChatInitialized) {
      /**
       * This case handles switching form an org which has frontchat enabled to one with it disabled.
       * In this case we need to remove frontchat.
       */
      shutdownFrontChat();
    } else if (isFrontchatEnabled && frontChatScriptLoaded) {
      /**
       * This handles the case where the frontchat script is already loaded.
       * Here we only need to call the init function again.
       */
      initFrontChat();
    }
  }, [frontChatInitialized, isFrontchatEnabled, frontChatScriptLoaded]);

  /**
   * Identify frontchat user.
   */
  useEffect(() => {
    if (isFrontchatEnabled && frontChatInitialized && user && !frontChatIdentified) {
      setFrontChatIdentified(true);
      // Need to use a setTimeout, otherwise we'll get an error that frontchat is not initialized yet.
      // Similar to https://community.front.com/developer-q-a-37/problems-showing-the-chat-js-widget-116
      setTimeout(() => {
        identifyFrontChat(user);
      }, 1000);
    }
  }, [frontChatInitialized, user, frontChatIdentified, isFrontchatEnabled]);

  return (
    <div className={'flex'}>
      {showLoadingBar && <LoadingBar />}
      <Routes>
        <Route
          path={'/orgs/:orgId/*'}
          element={
            <PrivateRoute>
              <Orgs />
            </PrivateRoute>
          }
        />
        <Route
          path={'/profile-settings'}
          element={
            <PrivateRoute>
              <ProfileSettings />
            </PrivateRoute>
          }
        />
        <Route path={'/verify-email/:confirmationEmailHash'} element={<VerifyEmail />} />
        <Route path={'/'} element={<RedirectRoot />} />
        <Route path={'auth/*'} element={<Auth />} />
        <Route path={'/form-playground'} element={<FormPlayground />} />
        <Route path={'/workload-profile-playground'} element={<WorkloadProfilePlayground />} />
        <Route path={'*'} element={<RedirectRoot />} />
      </Routes>
    </div>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    },
  },
});

const App = () => {
  // State
  const [adBlockerWarningModalOpen, setAdBlockerWarningOpen] = useState(false);
  const [optimizelyInstance, setOptimizelyInstance] = useState<ReactSDKClient>();

  // Routing State
  const [shouldConfirmOnNavigate, setShouldConfirmOnNavigate] = useState(false);
  const [showNavigateDialog, setShowNavigateDialog] = useState(false);
  const [nextRoute, setNextRoute] = useState<null | string>(null);

  const navigate = useNavigate();
  const { optimizelyUser } = useCurrentUserStore();
  const [pendingRequests, setPendingRequests] = useState<number>(0);
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // Zustand
  const { setTheme, theme, syncLocalStorageToUserPreferences } = useUserPreferencesStore();

  const resetRoutingState = (isCancel?: boolean) => {
    setShowNavigateDialog(false);
    setNextRoute(null);
    if (!isCancel) {
      setShouldConfirmOnNavigate(false);
    }
  };

  useEffect(() => {
    // request interceptor
    axios.interceptors.request.use(
      (request) => {
        setPendingRequests((prev) => prev + 1);
        return request;
      },
      (error) => {
        setPendingRequests((prev) => prev - 1);
        return Promise.reject(error);
      }
    );
    // response interceptor
    axios.interceptors.response.use(
      (response) => {
        setPendingRequests((prev) => prev - 1);
        return response;
      },
      (error) => {
        if (error?.response?.status === 401 && !window.location.pathname.includes('/auth')) {
          window.location.href = `${window.location.origin}/auth/login`;
        }
        setPendingRequests((prev) => prev - 1);
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    const showWarningBeforeLeavePage = (event: BeforeUnloadEvent) => {
      // some browser do not allow custom messages so the browser default message will be displayed in these cases
      event.returnValue = uiTranslations.CONFIRM_LEAVE_PAGE; // for firefox;
      return uiTranslations.CONFIRM_LEAVE_PAGE;
    };
    if (shouldConfirmOnNavigate) {
      window.addEventListener('beforeunload', showWarningBeforeLeavePage);
    }

    return () => {
      window.removeEventListener('beforeunload', showWarningBeforeLeavePage);
    };
  }, [shouldConfirmOnNavigate, uiTranslations.CONFIRM_LEAVE_PAGE]);

  useEffect(() => {
    setOptimizelyInstance(
      createInstance({
        sdkKey: windowEnv.OPTIMIZELY_SDK_KEY,
        eventBatchSize: 10,
        errorHandler: {
          handleError: () => {
            setAdBlockerWarningOpen(true);
          },
        },
        datafileOptions: {
          // Use the env var if it's defined(this is usually only for on-premise installations), otherwise use the default URL.
          urlTemplate:
            windowEnv.OPTIMIZELY_URL_TEMPLATE || 'https://oply.humanitec.io/datafiles/%s.json',
        },
      })
    );
  }, []);

  useEffect(() => {
    addMetaTag();
  }, []);

  useEffect(() => {
    const userPrefParsed = getUserPreferencesFromLS();
    if (userPrefParsed) {
      const prefs = { ...userPrefParsed };
      if (prefs) {
        datadogRum.addAction('migrate user preferences localstorage', {
          old: userPrefParsed,
          new: prefs,
        });
        syncLocalStorageToUserPreferences(prefs);
      }
    }
  }, [syncLocalStorageToUserPreferences]);

  useEffect(() => {
    const toggleMediaTheme = () => {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    };
    const themePreference = getUserPreferencesFromLS()?.theme;

    if (!themePreference) {
      toggleMediaTheme();
    } else if (themePreference && (themePreference === 'dark' || themePreference === 'light')) {
      setTheme(themePreference);
    }
    window.matchMedia('(prefers-color-scheme: dark)').addListener(() => {
      toggleMediaTheme();
    });
  }, [setTheme]);

  useEffect(() => {
    if (typeof gapi !== 'undefined') {
      gapi.load('auth2', () => {
        gapi.auth2.init({});
      });
    }
  }, []);

  /**
   * React query client object. We are using the default in-memory caching for now.
   */

  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <GlobalStyle />
      {theme && (
        <WalhallContext.Provider
          value={{
            shouldConfirmOnNavigateState: [shouldConfirmOnNavigate, setShouldConfirmOnNavigate],
            navigateDialogState: [showNavigateDialog, setShowNavigateDialog],
            nextRouteState: [nextRoute, setNextRoute],
            theme,
            pendingRequests,
          }}>
          <WalModal
            disableClickOutside
            openState={[showNavigateDialog, setShowNavigateDialog]}
            content={uiTranslations.CONFIRM_LEAVE_PAGE}
            actions={{
              cancel: {
                text: uiTranslations.CANCEL,
                props: {
                  onClick: () => {
                    resetRoutingState(true);
                  },
                },
              },
              main: {
                text: uiTranslations.LEAVE,
                props: {
                  onClick: () => {
                    if (nextRoute) {
                      navigate(nextRoute);
                      resetRoutingState();
                    }
                  },
                },
              },
            }}
          />
          {optimizelyInstance && (
            <OptimizelyProvider optimizely={optimizelyInstance} user={optimizelyUser}>
              <QueryClientProvider client={queryClient}>
                <AuthWrapper setAdBlockerWarningOpen={setAdBlockerWarningOpen}>
                  <AppMain />
                </AuthWrapper>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </OptimizelyProvider>
          )}
        </WalhallContext.Provider>
      )}
      <AdBlockerWarningModal openState={[adBlockerWarningModalOpen, setAdBlockerWarningOpen]} />
    </ThemeProvider>
  );
};

export default App;
