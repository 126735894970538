import styled from 'styled-components/macro';

import useBreadcrumbs from '@src/hooks/useBreadcrumbs';
import { containerStyle } from '@src/styles/mixins';
import { NEW_APP_HEADER_HEIGHT, units } from '@src/styles/variables';

import NewBreadcrumbs from '../NewBreadcrumbs/NewBreadcrumbs';
import FreeTrialBanner from './components/FreeTrialBanner';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.baseDarker};
  position: sticky;
  top: 0;
  z-index: 99;
  height: ${NEW_APP_HEADER_HEIGHT};
`;

const Breadcrumbs = styled(NewBreadcrumbs)`
  ${containerStyle(true)};
  padding-top: ${units.padding.xl};
  padding-bottom: ${units.padding.xl};
`;

const AppHeader = () => {
  const crumbs = useBreadcrumbs();

  return (
    <>
      <FreeTrialBanner />
      <Wrapper>
        <Breadcrumbs crumbs={crumbs} />
      </Wrapper>
    </>
  );
};

export default AppHeader;
