import { rem } from 'polished';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import Icon from '../base/Icon/Icon';
import { WalInput, WalInputProps } from '../base/Input/Input';

const SearchWrapper = styled.div<{ width?: number }>`
  position: relative;
  display: flex;
  align-items: center;
  margin: ${units.margin.md} 0;
  width: ${({ width }) => rem(width || 300)};
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  right: ${rem(10)};
  top: ${rem(8)};
`;

const SearchInputField = styled(WalInput)`
  flex: 1;
`;

interface SearchInputProps extends WalInputProps {
  width?: number;
}

const SearchInput = ({ className, width, ...rest }: SearchInputProps) => {
  const methods = useWalhallForm();

  return (
    <SearchWrapper className={className} width={width}>
      <FormProvider {...methods}>
        <SearchInputField {...rest} hideLabel />
        <SearchIcon name={'search'} />
      </FormProvider>
    </SearchWrapper>
  );
};

export default SearchInput;
