import { WalInput, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { removeUntouchedPropsFromPayload } from '@src/containers/Orgs/Resources/components/ResourceDefinitionForm/resource-form.utils';
import { useResourceAccountsCreateMutation } from '@src/hooks/react-query/resource-accounts/mutations/useResourceAccountsCreateMutation';
import { useResourceAccountsUpdateMutation } from '@src/hooks/react-query/resource-accounts/mutations/useResourceAccountsUpdateMutation';
import { ResourceAccount, ResourceAccountType } from '@src/models/resource-account';
import { useWalhallForm } from '@src/utilities/form';

import { AivenAndCloudflareFields } from './components/AivenAndClouflareFields';
import AWSFields from './components/AWSFields';
import AzureFields from './components/AzureFields';
import GCPFields from './components/GCPFields';

interface AddAccountModalProps {
  /** The open state of the modal */
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  /** The ResourceAccount we are editing. If this is passed, the modal will perform an UPDATE, instead of a POST */
  editingAccountState?: [
    ResourceAccount | undefined,
    Dispatch<SetStateAction<ResourceAccount | undefined>>,
  ];
  creating?: boolean;
  accountType: ResourceAccountType;
  /** Optional className */
  className?: string;
}

const parseCredential = (credential: any): Record<string, unknown> | null => {
  try {
    return JSON.parse(credential);
  } catch (e) {
    return null;
  }
};

/**
 * Build up the credentials. It will differ depending on what ResourceAccount.
 * - AWS is split into two fields and combined.
 * - GCP will just parse the provided JSON.
 *
 * @param formValue The value from the form.
 */
const buildCredential = (
  formValue: any,
  accountType: ResourceAccountType
): Record<string, unknown> | null => {
  if (accountType === 'gcp' && formValue.token) {
    return parseCredential(formValue.token);
  } else if (accountType === 'azure' && formValue.token) {
    return parseCredential(formValue.token);
  } else if (accountType === 'aws') {
    const { accessKeyId, secretAccessKey } = formValue;
    const credential: {
      aws_access_key_id?: string;
      aws_secret_access_key?: string;
    } = {};
    if (accessKeyId) {
      credential.aws_access_key_id = accessKeyId;
    }
    if (secretAccessKey) {
      credential.aws_secret_access_key = secretAccessKey;
    }
    return Object.keys(credential).length ? credential : null;
  } else if ((accountType === 'aiven' || accountType === 'cloudflare') && formValue.token) {
    return {
      token: formValue.token,
    };
  }
  return null;
};

const AddAccountModal = ({
  openState,
  creating,
  editingAccountState,
  accountType,
  className,
}: AddAccountModalProps) => {
  // Form
  const methods = useWalhallForm();
  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isDirty, dirtyFields },
  } = methods;

  // Component state
  /** Whether the modal is adding or creating ResourceAccount */
  const [editingOrCreating, setEditingOrCreating] = useState<'editing' | 'creating'>('creating');
  const [editingAccount, setEditingAccount] = editingAccountState || [];
  const [open, setOpen] = openState;

  // i18n
  const { t } = useTranslation();
  const formTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS.ACCOUNT_MODAL;
  const uiTranslations = t('UI');

  // React Query
  const {
    mutate: createResourceAccount,
    error,
    isSuccess: isCreated,
  } = useResourceAccountsCreateMutation();
  const { mutate: updateResourceAccount, isSuccess: isUpdated } =
    useResourceAccountsUpdateMutation();

  useEffect(() => {
    if (error?.status === 409) {
      setError('name', { type: 'manual', message: formTranslations.NAME_EXISTS });
    }
  }, [error, setError, formTranslations]);

  useEffect(() => {
    if (isCreated || isUpdated) {
      setOpen(false);
    }
  }, [isCreated, setOpen, isUpdated]);

  /**
   * Clear form on close.
   */
  useEffect(() => {
    if (!open) {
      setValue('name', null);
      setValue('token', null);
      setValue('secretAccessKey', null);
      setValue('accessKeyId', null);
      if (setEditingAccount) {
        setEditingAccount(undefined);
      }
      clearErrors();
    }
  }, [open, setEditingAccount, setValue, clearErrors]);

  useEffect(() => {
    setEditingOrCreating(editingAccount ? 'editing' : 'creating');
  }, [editingAccount]);

  /**
   * If in editing mode, set the name to the ResourceAccount
   */
  useEffect(() => {
    if (editingAccount && !getValues().name) {
      setValue('name', editingAccount.name);
    }
  }, [editingAccount, getValues, setValue]);

  /**
   * Dispatch actions for Create or Update
   *
   * @param name The name from the form.
   * @param credential The token from the form.
   */
  const handleResourceAccountCreateOrUpdate = (
    name: string,
    credential: Record<string, unknown> | null
  ) => {
    if (editingAccount && editingOrCreating === 'editing') {
      updateResourceAccount({ resourceAccountId: editingAccount.id, name, credential });
    } else if (editingOrCreating === 'creating') {
      createResourceAccount({ credential, name, resourceAccountType: accountType });
    }
  };

  /**
   * On Authorize button click.
   *
   * @param formValue The submitted form.
   */
  const handleAuthorizeClick = async (formValue: any) => {
    handleResourceAccountCreateOrUpdate(
      formValue.name,
      buildCredential(removeUntouchedPropsFromPayload(formValue, dirtyFields), accountType)
    );
  };

  const resourceAccountFields = () => {
    switch (accountType) {
      case 'gcp':
        return <GCPFields isNew={!editingAccount} />;
      case 'aws':
        return <AWSFields />;
      case 'aiven':
      case 'cloudflare':
        return <AivenAndCloudflareFields />;
      case 'azure':
        return <AzureFields isNew={!editingAccount} />;
      default:
        return <></>;
    }
  };

  const getTitle = () => {
    if (accountType === 'gcp' || editingAccount?.type === 'gcp') {
      return formTranslations.GCP.TITLE;
    } else if (accountType === 'aws' || editingAccount?.type === 'aws') {
      return formTranslations.AWS.TITLE;
    } else if (accountType === 'aiven' || editingAccount?.type === 'aiven') {
      return formTranslations.AIVEN.TITLE;
    } else if (accountType === 'azure' || editingAccount?.type === 'azure') {
      return formTranslations.AZURE.TITLE;
    } else if (accountType === 'cloudflare' || editingAccount?.type === 'cloudflare') {
      return formTranslations.CLOUDFLARE.TITLE;
    }
  };

  return (
    <WalModal
      openState={openState}
      size={'large'}
      title={getTitle()}
      className={className}
      disableClickOutside={isDirty}
      content={
        <FormProvider {...methods}>
          <WalInput
            name={'name'}
            label={formTranslations.NAME_LABEL}
            labelExtensionText={formTranslations.NAME_PLACEHOLDER}
            required
            maxLength={20}
            dataTestId={'account'}
            standardValidation={[{ type: 'name' }]}
          />
          {resourceAccountFields()}
        </FormProvider>
      }
      actions={{
        main: {
          props: {
            className: 'qa-gcp-authorize',
            loading: creating,
            disabled: !isDirty,
            onClick: handleSubmit(handleAuthorizeClick),
          },
          text: uiTranslations.AUTHORIZE,
        },
        cancel: {
          props: {
            className: 'qa-gcp-cancel',
          },
          hideButton: !creating,
        },
      }}
    />
  );
};

export default AddAccountModal;
