import { Placement } from '@floating-ui/react';
import { rem } from 'polished';
import React, { Dispatch, MouseEvent, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { WalButton } from '../../base/Button/Button';
import { CardStyles } from '../../base/Card/Card';
import { Spinner } from '../../base/Spinner/Spinner';
import { Text } from '../../base/Text/Text';
import TableRow from './components/TableRow';

interface TableWrapperProps {
  disableScrolling?: boolean;
}

const TableWrapper = styled.table<TableWrapperProps>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 ${units.margin.xs};
  border-radius: 4px;
  ${({ disableScrolling }) =>
    !disableScrolling &&
    css`
      overflow: auto;
    `}
`;

type TableStyleProps = 'regular' | 'expandable' | 'clickable';

interface TableHeaderProps {
  padding?: 'small' | 'medium';
  tableStyle?: TableStyleProps;
}

const TableHeader = styled.thead<TableHeaderProps>`
  ${({ tableStyle }) =>
    tableStyle === 'expandable' &&
    css`
      margin-left: 35px;
    `}
  ${({ padding }) => css`
    padding: ${units.padding.sm}
      calc(${`${padding === 'medium' ? units.padding.xl : units.padding.md} + ${rem(1)}`});
  `}
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
  top: 0;
  z-index: 1;
`;

interface TableHeaderItemProps {
  $justifyContent?: 'flex-start' | 'center' | 'flex-end';
  $last?: boolean;
}
const TableHeaderItem = styled.th<TableHeaderItemProps>`
  min-width: ${rem(50)};
  text-align: left;
  padding: ${units.padding.sm} ${units.padding.md};
  ${({ $justifyContent }) =>
    $justifyContent &&
    css`
      text-align: ${$justifyContent === 'flex-start'
        ? 'left'
        : $justifyContent === 'flex-end'
          ? 'right'
          : 'center'};
    `}

  ${({ $last }) =>
    $last &&
    css`
      justify-content: flex-end;
    `}
`;

const TableBody = styled.tbody<{ disableScrolling?: boolean }>`
  width: 100%;
  ${({ disableScrolling }) =>
    !disableScrolling &&
    css`
      overflow: auto;
    `}
`;

const InfiniteScrollWrapper = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export interface WalTableColumn<T = any, TProps = string> {
  /* data object property name */
  prop: TProps;
  /* label of the column */
  label?: string;
  /* custom template for styling of column content*/
  template?: (rowData: WalTableRow<T>, index: number) => any;
  /** custom template for when the row is expanded */
  expandedStateTemplate?: (rowData: WalTableRow<T>, index: number) => any;
  /* css justify-content property */
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  /* if the content should take the full available space in the cell */
  fullWidth?: boolean;
  /* enables ellipsis and tooltip to see the complete text on hover for this column */
  ellipsisTooltip?: {
    /* max width to trigger the ellipsis */
    maxWidth?: number;
    /* max characters to enable the tooltip */
    maxCharacters?: number;
    /* text to be shown in the tooltip */
    text: (rowData: WalTableRow<T>) => string | undefined;
    showGenericText?: boolean;
    position?: Placement;
    hideTooltipOnfocus?: boolean;
    /* shows the ellipsis in the beginning of the text instead of the end */
    invertEllipsis?: boolean;
  };
  alignIconAndText?: boolean;
  hide?: boolean;
  tabIndex?: number;
  /* the text of the info popup if the cell has one */
  infoPopupText?: (rowData: WalTableRow<T>) => string | undefined;
  /** fixed width for the column */
  fixedWidth?: number | string;
}

export interface WalTableRow<T = any> {
  /* adds blue dot to the front of the row to signify recently added data */
  isNew?: boolean;
  /* object whose field values should match the name of the columns fields.*/
  data: T;
  /* content inside expandable element*/
  expandableContent?: ReactNode;
  /* triggered when clicking on the row. */
  onRowClick?: (e: MouseEvent, row: WalTableRow) => void;
  /* content that should take the full row width */
  columnOverrideTemplate?: ReactNode;
  /* toggles between showing the columnOverrideTemplate content or the regaular template content  */
  overrideColumns?: boolean | undefined;
  /* if the content of the row should be align to the top */
  verticalAlignTop?: boolean;
}

export interface WalTableProps {
  /* rows passed */
  rows: WalTableRow[];
  /* columns passed */
  columns: WalTableColumn[];
  /* caption property. This will be passed as aria-label. Functionally, it's the same as caption - https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/table_role */
  caption: string;
  tablePadding?: 'small' | 'medium';
  /* disable scrollbars on the table */
  disableScrolling?: boolean;
  /* css class name  */
  className?: string;
  /* the index of the currently expanded row */
  expandedRowState?: [number | undefined, Dispatch<SetStateAction<number | undefined>>];
  /* determines some features and style of the table. e.g 'expandable' tabStyle have arrows on the left */
  tableStyle?: TableStyleProps;
  tableRowStyle?: CardStyles;
  infiniteScroll?: {
    isLoadingNextPage: boolean;
    hasNextPage: boolean;
    fetchNextPage: () => void;
    hasError: boolean;
  };
}

export const WalTable = ({
  columns,
  rows,
  tablePadding = 'small',
  className,
  disableScrolling,
  expandedRowState,
  caption,
  tableStyle = 'regular',
  tableRowStyle,
  infiniteScroll,
}: WalTableProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const [atBottom, setAtBottom] = useState(false);

  useEffect(() => {
    if (atBottom && infiniteScroll?.hasNextPage && !infiniteScroll.isLoadingNextPage) {
      infiniteScroll.fetchNextPage();
      setAtBottom(false);
    }
  }, [atBottom, infiniteScroll]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight) {
        setAtBottom(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const expandedRowIndexState = useState<number | undefined>();
  const [expandedRowIndex, setExpandedRowIndex] = expandedRowState || expandedRowIndexState;

  const filteredColumns = columns.filter((column) => !column.hide);

  if (rows.length <= 0) return <></>;

  return (
    <>
      <TableWrapper aria-label={caption} className={className} disableScrolling={disableScrolling}>
        <TableHeader padding={tablePadding} tableStyle={tableStyle} data-testid={'thead'}>
          <tr>
            {tableStyle === 'expandable' && <th />}
            {filteredColumns.map((column, colIndex) => (
              <TableHeaderItem
                key={column.prop}
                $justifyContent={column.justifyContent}
                $last={colIndex === filteredColumns.length - 1}>
                {column.label}
              </TableHeaderItem>
            ))}
          </tr>
        </TableHeader>
        <TableBody disableScrolling={disableScrolling} data-testid={'tbody'}>
          {rows
            .filter((row) => row.isNew)
            .reverse()
            .concat(rows.filter((row) => !row.isNew))
            .map((row, index) => (
              <TableRow
                row={row}
                // eslint-disable-next-line react/no-array-index-key
                key={row.data.id || index}
                tableRowStyle={tableRowStyle}
                index={index}
                overrideColumns={Boolean(row.overrideColumns)}
                isExpandableTable={tableStyle === 'expandable'}
                isClickable={tableStyle === 'clickable'}
                onRowClick={row?.onRowClick}
                expandedRowIndexState={[expandedRowIndex, setExpandedRowIndex]}
                tablePadding={tablePadding}
                filteredColumns={filteredColumns}
                verticalAlignTop={row.verticalAlignTop}
              />
            ))}
        </TableBody>
      </TableWrapper>
      {infiniteScroll && (
        <InfiniteScrollWrapper data-testid={'table-infinite-scroll-wrapper'}>
          {infiniteScroll.hasError ? (
            <WalButton
              variant={'borderless-red'}
              onClick={() => infiniteScroll.fetchNextPage()}
              size={'large'}>
              {uiTranslations.SOMETHING_WRONG_TAP_RETRY}
            </WalButton>
          ) : infiniteScroll.isLoadingNextPage ? (
            <Spinner size={'medium'} />
          ) : (
            !infiniteScroll.hasNextPage &&
            Boolean(rows.length) && (
              <Text color={'textTranslucent'}>{uiTranslations.NO_ITEMS_TO_LOAD}</Text>
            )
          )}
        </InfiniteScrollWrapper>
      )}
    </>
  );
};
export default WalTable;
