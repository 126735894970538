import { rem } from 'polished';
import { Dispatch, KeyboardEvent, MouseEvent, SetStateAction } from 'react';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

import {
  CARD_BORDER_RADIUS,
  CardStyles,
  getCardPadding,
  getCardStyle,
} from '../../../base/Card/Card';
import Icon from '../../../base/Icon/Icon';
import { WalTableColumn, WalTableProps, WalTableRow } from '../Table';
import TableColumnCell from './TableColumnCell';

interface TableRowElementProps {
  $expanded?: boolean;
  isExpandableTable: boolean;
  shouldOverrideColumns: boolean;
  $cardStyle?: CardStyles;
  $highlightOnHover?: boolean;
}

const TableRowElement = styled.tr<TableRowElementProps>`
  width: 100%;
  column-gap: ${units.padding.sm};
  font-size: ${units.fontSize.sm};
  ${({ $expanded }) =>
    $expanded
      ? css`
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          margin-bottom: ${units.margin.xxs};
          td:first-child {
            border-bottom-left-radius: 0 !important;
          }
          td:last-child {
            border-bottom-right-radius: 0 !important;
          }
        `
      : css`
          margin-bottom: ${units.margin.xs};
        `}
  ${({ $highlightOnHover }) =>
    $highlightOnHover &&
    css`
      &:hover td {
        border-color: ${({ theme }) => theme.color.main};
      }
      &:hover {
        cursor: pointer;
      }
    `}
`;

export interface TableCellProps {
  tableRowStyle?: CardStyles;
  tablePadding?: 'small' | 'medium';
  verticalAlignTop?: boolean;
}

export const TableCell = styled.td<TableCellProps>`
  border: solid 1px transparent;
  border-style: solid none;
  transition: border-color 200ms ease;
  ${({ verticalAlignTop }) =>
    verticalAlignTop &&
    css`
      vertical-align: top;
    `};
  ${({ tablePadding }) => getCardPadding(tablePadding || 'small')}
  ${({ tableRowStyle }) => getCardStyle(tableRowStyle)}
    &:first-child {
    border-left-style: solid;
    border-top-left-radius: ${CARD_BORDER_RADIUS};
    border-bottom-left-radius: ${CARD_BORDER_RADIUS};
  }
  &:last-child {
    border-right-style: solid;
    border-bottom-right-radius: ${CARD_BORDER_RADIUS};
    border-top-right-radius: ${CARD_BORDER_RADIUS};
  }
`;

const ArrowCell = styled(TableCell)`
  width: ${rem(40)};
`;

const ExpandedContent = styled.tr`
  margin-bottom: ${units.margin.xs};
`;

const ExpandedContentCell = styled(TableCell)`
  background-color: ${({ theme }) => theme.color.baseBrightest} !important ;
  padding: ${units.padding.xl} !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
`;

type TableRowProps = Pick<WalTableProps, 'tablePadding'> & {
  row: WalTableRow;
  index: number;
  overrideColumns: boolean;
  isExpandableTable: boolean;
  filteredColumns: WalTableColumn[];
  isClickable?: boolean;
  expandedRowIndexState: [number | undefined, Dispatch<SetStateAction<number | undefined>>];
  onRowClick?: (e: MouseEvent, row: WalTableRow) => void;
  tablePadding?: 'small' | 'medium';
  tableRowStyle?: CardStyles;
  verticalAlignTop?: boolean;
};

const TableRow = ({
  row,
  index,
  overrideColumns,
  isClickable,
  onRowClick,
  expandedRowIndexState,
  isExpandableTable,
  filteredColumns,
  tablePadding,
  tableRowStyle,
  verticalAlignTop,
}: TableRowProps) => {
  const expandableRowContentId = `content_${index + 1}`;
  const [expandedRowIndex, setExpandedRowIndex] = expandedRowIndexState;

  const toggleRow = () => {
    if (row.expandableContent) {
      if (expandedRowIndex === index) {
        setExpandedRowIndex(undefined);
      } else {
        setExpandedRowIndex(index);
      }
    }
  };

  const handleRowClick = (event: MouseEvent) => {
    onRowClick?.(event, row);
    toggleRow();
  };

  const handleRowKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KeyBindings.ENTER || e.keyCode === KeyBindings.SPACE) {
      toggleRow();
    }
  };
  return (
    <>
      <TableRowElement
        tabIndex={isExpandableTable ? 0 : undefined}
        onClick={handleRowClick}
        onKeyDown={handleRowKeyDown}
        $expanded={Boolean(expandedRowIndex === index && row.expandableContent)}
        $highlightOnHover={
          (isExpandableTable && row.expandableContent) || isClickable ? true : false
        }
        isExpandableTable={isExpandableTable}
        shouldOverrideColumns={overrideColumns}
        aria-controls={isExpandableTable ? expandableRowContentId : undefined}>
        {!overrideColumns ? (
          <>
            {isExpandableTable && (
              <ArrowCell tablePadding={tablePadding} tableRowStyle={tableRowStyle}>
                {expandedRowIndex === index ? (
                  <Icon name={'arrow-down'} marginLeft={'sm'} size={12} />
                ) : (
                  <Icon
                    name={'arrow-right'}
                    marginLeft={'sm'}
                    size={12}
                    overrideColor={row.expandableContent ? undefined : 'text-translucent'}
                    disabled={!row.expandableContent}
                  />
                )}
              </ArrowCell>
            )}
            {filteredColumns.map((cell, colIndex) => (
              <TableColumnCell
                column={cell}
                key={cell.prop}
                $rowIndex={index}
                $colIndex={colIndex}
                row={row}
                filteredColumns={filteredColumns}
                tablePadding={tablePadding}
                tableRowStyle={tableRowStyle}
                verticalAlignTop={verticalAlignTop}
              />
            ))}
            {isClickable && (
              <ArrowCell tablePadding={tablePadding} tableRowStyle={tableRowStyle}>
                <Icon
                  name={'arrow-right'}
                  size={12}
                  marginLeft={'sm'}
                  disabled={Boolean(onRowClick) === false}
                />
              </ArrowCell>
            )}
          </>
        ) : (
          <td colSpan={filteredColumns.length + 1}>{row.columnOverrideTemplate}</td>
        )}
      </TableRowElement>

      {isExpandableTable && !overrideColumns && expandedRowIndex === index && (
        <ExpandedContent id={expandableRowContentId} data-testid={expandableRowContentId}>
          <ExpandedContentCell
            tablePadding={tablePadding}
            tableRowStyle={tableRowStyle}
            colSpan={filteredColumns.length + 1}>
            {row.expandableContent}
          </ExpandedContentCell>
        </ExpandedContent>
      )}
    </>
  );
};

export default TableRow;
