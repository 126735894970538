import Ajv from 'ajv';
import yaml from 'js-yaml';
import { cloneDeep, get, isArray, isEmpty, isObject, set, transform } from 'lodash';

import i18n from '@src/i18n/i18n';
import { DynamicFormSchema, RowSchema, SectionSchema } from '@src/types/dynamic-form';

import { isJSONSchemaField, isRowSchema, isSectionSchema } from './dynamic-form-type-guards';
import { FieldProps, RowProps, SectionProps, SortProps } from './dynamic-form-types';

interface IteratePropsProps extends FieldProps {
  /**
   * You can optionally specify a callback.
   *
   * This will be executed once the deepest element of the node is reached (i.e. we have reached a field definition of the schema).
   *
   * This is useful if you need to do some actions for each field. You'll need to return the props object at the end.
   *
   * If you don't specify this, the props object will be returned automatically.
   */
  callback?: (props: FieldProps) => IteratePropsProps;
  /* whether additional props feature flag is enabled */
  isAdditionalPropsEnabled?: boolean;
}

interface FindSchemaFieldsProps {
  schema?: DynamicFormSchema;
  prefix?: string;
}

/**
 * Recursive function which iterates over all properties and displays inputs.
 * Iterates over all props and accepts a callback function, which will be called only if this prop does not have any children.
 */
export const iterateProps = ({
  schema,
  path,
  lastKey = '',
  required = undefined,
  secret = false,
  isAdditionalPropsEnabled,
  callback,
}: IteratePropsProps): (FieldProps | undefined)[] | FieldProps => {
  let lastKeyWithReplacement = lastKey;

  if (lastKey && lastKeyWithReplacement && lastKeyWithReplacement?.indexOf('.') !== -1) {
    // Replace the dot with a delimiter. Because react hook form interprets the dot as a new object.
    lastKeyWithReplacement = lastKeyWithReplacement?.replace('.', DOT_NOTATION_DELIMITER);
    path = path.replace(lastKey, lastKeyWithReplacement);
  }

  const propObject = { schema, path, lastKey, required, secret };

  return schema.properties &&
    !(schema.type === 'object' && schema.uiHints?.inputType === 'textarea')
    ? Object.keys(schema.properties).flatMap((key) => {
        const property = schema?.properties && schema?.properties[key];

        if (property) {
          // if the property has additionalProperties then it should return the property. The property should be shown as textarea
          if (isAdditionalPropsEnabled && property.hasOwnProperty('additionalProperties')) {
            const obj = {
              schema: property,
              path: `${path}.${key}`,
              lastKey: key,
              required: schema?.required?.includes(key),
              // if `secrets` key is encountered,let `secret` to `true` in subsequent recursive iterations. If `secret` is already `true` one of the parent might have been `secrets`
              secret: secret || lastKey === 'secrets',
              callback,
            };
            return callback?.(obj) ?? obj;
          }
          if (
            // iterate props if there are no additional properties
            Boolean(property?.additionalProperties) === false ||
            (!isAdditionalPropsEnabled && property.hasOwnProperty('additionalProperties')) ||
            // iterate props if there is a nested `properties` object
            (property.hasOwnProperty('properties') && !isEmpty(property.properties))
          ) {
            return iterateProps({
              schema: property,
              path: `${path}.${key}`,
              lastKey: key,
              required: schema?.required?.includes(key),
              // if `secrets` key is encountered,let `secret` to `true` in subsequent recursive iterations. If `secret` is already `true` one of the parent might have been `secrets`
              secret: secret || lastKey === 'secrets',
              callback,
            });
          }
        }
        return undefined;
      })
    : callback?.(propObject) ?? propObject;
};

export const findJSONSchemaFields = ({
  schema,
  prefix,
}: FindSchemaFieldsProps): FieldProps[] | undefined => {
  if (!schema || !prefix) return undefined;

  const props = iterateProps({
    schema,
    path: prefix,
    required: undefined,
    secret: false,
    lastKey: '',
  });

  const arrayify = isArray(props) ? props : [props];

  const schemasWithJSONValidation = arrayify.filter((field) =>
    isJSONSchemaField(field?.schema)
  ) as FieldProps[];

  return schemasWithJSONValidation;
};

export const isPatternProperties = (property: DynamicFormSchema) =>
  property.type === 'object' &&
  Object.keys(property.patternProperties || {}).length &&
  property.additionalProperties === false;

export const DOT_NOTATION_DELIMITER = 'dotReplace';

/**
 * Recursively replace key which contains the delimiter. Use in the component which is submitting the form.
 *
 * @param theObject The Object to iterate over.
 */
export const replaceKeyDelimiter = (
  theObject: Record<string, unknown>
): Record<string, unknown> => {
  return transform<Record<string, unknown>, any>(theObject, (result, value, key) => {
    let hasDelimiter = false;
    if (key.indexOf(DOT_NOTATION_DELIMITER) !== -1) {
      hasDelimiter = true;
    }
    const currentKey = hasDelimiter ? key.replace(RegExp(DOT_NOTATION_DELIMITER, 'g'), '.') : key;

    result[currentKey] =
      isObject(value) && !Array.isArray(value)
        ? replaceKeyDelimiter(value as Record<string, unknown>)
        : value;
  });
};

/**
 * Sort order ascending.
 */
const sortDynamicFormFieldsOrder = <T extends unknown>(arr: SortProps<T>[]): T[] =>
  arr
    // Sort the index
    .sort((a, b) => {
      const aIndex = a?.order;
      const bIndex = b?.order;

      if (aIndex && bIndex === undefined) return -1;
      if (aIndex === undefined && bIndex) return 1;
      if (aIndex === undefined) return 0;
      if (bIndex === undefined) return 0;
      return aIndex - bIndex;
    })
    .map((i) => i.object);

/**
 * Sorts the schema into rows, sections, and individual fields based on the order defined in the uiGroups and uiHints.
 *
 * @param schema
 * @param fields
 */
export const sortDynamicFormFieldsAndSections = (
  schema: DynamicFormSchema,
  fields: FieldProps[]
): (FieldProps | RowProps | SectionProps)[] => {
  // First get the 'row' groups.
  const rows: RowProps[] = Object.entries(schema.uiGroups || {})
    .filter(([, val]) => isRowSchema(val))
    .map(([rowName, val]) => ({
      order: val.order,
      rowName,
      description: (val as RowSchema).description,
      section: (val as RowSchema).section,
      fields: sortDynamicFormFieldsOrder<FieldProps>(
        fields
          .filter((field) => field.schema.uiHints?.group === rowName)
          .map((i) => ({ order: i.order, object: i }))
      ),
    }));

  // Filter out elements that have been assigned to a row.
  const elementsWithoutRows: FieldProps[] = fields.filter(
    (s) => !rows.flatMap((g) => g.fields.map((f) => f.path)).includes(s.path)
  );

  // Get sections from the remaining entries.
  const sections: SectionProps[] = Object.entries(schema.uiGroups || {})
    .filter(([, val]) => isSectionSchema(val))
    .map(([sectionName, val]) => ({
      order: val.order,
      title: (val as SectionSchema).title,
      sectionName,
      fields: elementsWithoutRows.filter((field) => field.schema.uiHints?.group === sectionName),
    }));

  // Add the rows to the sections.
  const addRowsToSections = sections.map((sec) => ({
    ...sec,
    fields: sortDynamicFormFieldsOrder<FieldProps | RowProps>(
      [...sec.fields, ...rows.filter((g) => g.section === sec.sectionName)].map((i) => ({
        order: i.order,
        object: i,
      }))
    ),
  }));

  // Remove the rows from rows array if it has been assigned to a group
  const removeRowsWhichHaveSections = rows.filter((g) =>
    g.section ? !sections.map((s) => s.sectionName).includes(g.section) : true
  );

  // Remove any sections from the remaining fields.
  const elementsWithoutSectionOrRow = elementsWithoutRows.filter(
    (field) =>
      !sections.map((section) => section.sectionName).includes(field.schema.uiHints?.group ?? '')
  );

  const combined = [
    ...elementsWithoutSectionOrRow,
    ...addRowsToSections,
    ...removeRowsWhichHaveSections,
  ];

  // Sort the index
  return sortDynamicFormFieldsOrder<FieldProps | RowProps | SectionProps>(
    combined.map((c) => ({ order: c.order, object: c }))
  );
};

/**
 * Validates a value against JSON schema object.
 *
 * @param value The value to validate.
 * @param schema JSON schema to validate against.
 * @returns Object with error messages, or undefined if no errors exist.
 */
export const validateJSONSchemaErrors = (
  schema: DynamicFormSchema,
  value?: string,
  required?: boolean
): Record<string, string> | undefined => {
  let parsedAsJSON;
  if (value) {
    try {
      parsedAsJSON = yaml.load(value);
    } catch {
      return {
        invalidJSON: i18n.t('UTIL_FUNCTIONS.PLEASE_PROVIDE_VALID_JSON_OR_YAML'),
      };
    }
  }

  const ajv = new Ajv({
    strict: false,
    allErrors: true,
  });

  // if field is optional AND value is empty, it is valid
  const valid = !required && !value ? true : ajv.validate(schema, parsedAsJSON);
  if (!valid && ajv.errors?.length) {
    return ajv.errors.reduce((prev, { instancePath, message }, i) => {
      const completeMessage = generateAJVMessage(instancePath, message);
      return completeMessage
        ? {
            ...prev,
            [`ajv${i}`]: completeMessage,
          }
        : prev;
    }, {});
  }
};

/**
 * Function to generate AJV message & handle undefined errors.
 *
 * @param instancePath
 * @param message
 * @returns
 */
export const generateAJVMessage = (instancePath: string, message?: string) =>
  `${instancePath ? `${instancePath} ` : ''}${message ? message : ''}`;

/**
 * maps resource values to schema defaults
 *
 * @param {ResourceDefinition} resource
 * @param {DynamicFormSchema} schema
 */
export const mapDynamicFormValuesToSchemaDefaults = (
  data: any,
  schema: DynamicFormSchema
): DynamicFormSchema => {
  // If there are no "properties", no need to iterate. The data provided should be the default.
  if (!schema.properties) {
    return { ...schema, default: data };
  }

  const newSchema = cloneDeep(schema);
  const allPaths = getCompletePath(schema);

  for (const path of allPaths) {
    const formVal = get(
      data,
      path.filter((p) => p !== 'properties')
    );

    const isSecret = path.includes('secrets');
    if (formVal) {
      set(newSchema, [...path, 'default'], formVal);
    } else if (isSecret) {
      delete newSchema.properties?.secrets.required; // make secrets not required
    }
  }

  return newSchema;
};

/**
 * Iterates over schema and generates acomplete path until it finds the last node in the tree.
 */
export const getCompletePath = (
  schema: DynamicFormSchema,
  path: string[] = [],
  allPaths: string[][] = []
) => {
  path.push('properties');
  Object.entries(schema.properties || {}).forEach(([key, value]) => {
    let newPath = [...path];
    newPath.push(key);
    if (value.properties) {
      getCompletePath(value, newPath, allPaths);
    } else {
      allPaths.push(newPath);
      newPath = [];
    }
  });

  return allPaths;
};
