export const pipelineRunsQueryKeys = {
  // e.g. ['orgs', 'my-org', 'my-app', 'my-pipeline', 'runs', '?status=succeeded', ]
  all: (
    orgId?: string | undefined,
    appId?: string | undefined,
    pipelineId?: string | undefined
  ) => ['orgs', orgId, appId, pipelineId, 'runs'],
  pipelineRunsByOrg: (
    orgId?: string | undefined,
    filters?: {
      statuses?: string[] | undefined;
      createBeforeDate?: string | undefined;
      createAfterDate?: string | undefined;
      appId?: string | undefined;
      envId?: string | undefined;
    }
  ) => ['orgs', orgId, 'pipeline-runs', filters],
  pipelineRuns: (
    orgId: string | undefined,
    appId: string | undefined,
    pipelineId: string | undefined,
    filters?: {
      statuses?: string[] | undefined;
      createBeforeDate?: string | undefined;
      createAfterDate?: string | undefined;
      appId?: string | undefined;
      envId?: string | undefined;
    }
  ) => {
    const baseQuery: (
      | string
      | undefined
      | {
          statuses?: string[] | undefined;
          createBeforeDate?: string | undefined;
          createAfterDate?: string | undefined;
        }
    )[] = [...pipelineRunsQueryKeys.all(orgId, appId, pipelineId)];

    if (filters) {
      baseQuery.push(filters);
    }

    return baseQuery;
  },
  pipelineRunApprovals: (
    orgId: string | undefined,
    appId: string | undefined,
    filterQuery?: { run?: string }
  ) => [...pipelineRunsQueryKeys.all(orgId), 'apps', appId, 'approvals', filterQuery],
  pipelineRunDetails: (
    orgId: string | undefined,
    appId: string,
    pipelineId: string,
    id?: string
  ) => [...pipelineRunsQueryKeys.all(orgId, appId, pipelineId), id],
  pipelineRunJobs: (orgId: string | undefined, appId: string, pipelineId: string, id?: string) => [
    ...pipelineRunsQueryKeys.pipelineRunDetails(orgId, appId, pipelineId, id),
    'jobs',
  ],
  pipelineRunJobDetails: (
    orgId: string | undefined,
    appId: string,
    pipelineId: string,
    pipelineRunId: string,
    id?: string
  ) => [
    ...pipelineRunsQueryKeys.pipelineRunJobs(orgId, appId, pipelineId, pipelineRunId),
    'job-details',
    id,
  ],
  pipelineRunJobStepDetails: (
    orgId: string | undefined,
    appId: string,
    pipelineId: string,
    pipelineRunId: string,
    jobId: string,
    index?: number
  ) => [
    ...pipelineRunsQueryKeys.pipelineRunJobDetails(orgId, appId, pipelineId, pipelineRunId, jobId),
    'step-details',
    index,
  ],
};
