import { WalButton } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DeleteEnvironmentModal from '@src/components/shared/DeleteEnvironmentModal';

const SubHeading = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const DeleteButton = styled(WalButton)`
  align-self: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeleteEnvironment = () => {
  // i18n
  const { t } = useTranslation('viewEnvironment');
  const deleteTranslations = t('DELETE');

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);

  return (
    <Wrapper>
      <span className={'txt-lg mb-sm'}>{deleteTranslations.HEADING}</span>
      <SubHeading className={'txt-sm mb-md'}>{deleteTranslations.SUBHEADING}</SubHeading>
      <DeleteButton
        iconLeft={'delete'}
        variant={'danger'}
        onClick={() => setOpenConfirmDeleteModal(true)}>
        {deleteTranslations.DELETE}
      </DeleteButton>
      {openConfirmDeleteModal && (
        <DeleteEnvironmentModal openState={[openConfirmDeleteModal, setOpenConfirmDeleteModal]} />
      )}
    </Wrapper>
  );
};

export default DeleteEnvironment;
