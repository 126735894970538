import { EmptyStateCard, SearchInput, WalButton, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import SelectDeploymentCard from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/CompareDeployments/components/SelectDeploymentCard';
import useDeploymentsListQuery from '@src/hooks/react-query/environments/queries/useDeploymentsListQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import { DeploymentObject } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import { generateCompareDeploymentsURL } from '@src/utilities/navigation';

interface SelectDeploymentModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  /** the comparison side of the deployment to be selected */
  selectingSide: 'left' | 'right';
  rightDeployment?: DeploymentObject;
  leftDeployment?: DeploymentObject;
}

const SelectDeploymentModal = ({
  openState,
  selectingSide,
  rightDeployment,
  leftDeployment,
}: SelectDeploymentModalProps) => {
  // Component state
  const [filterValue, setFilterValue] = useState('');

  // i18n
  const { t } = useTranslation();
  const compareDeploymentsTranslations = t('COMPARE_DEPLOYMENTS');

  // React Query
  const { data: environmentDeployments = [] } = useDeploymentsListQuery();
  const { data: environment } = useEnvironmentQuery();

  // Router
  const navigate = useNavigate();
  const { leftDeploymentId, rightDeploymentId, orgId, appId, envId } = useParams<
    keyof MatchParams
  >() as MatchParams;

  const [, setOpen] = openState;
  const [numberOfShownDeployments, setNumberOfShownDeployments] = useState(10);

  const filteredDeployments = useMemo(() => {
    return environmentDeployments
      .filter((deploy) => deploy.id !== environment?.last_deploy?.id)
      .filter((deploy) =>
        selectingSide === 'left'
          ? deploy.id !== rightDeployment?.id
          : deploy.id !== leftDeployment?.id
      )
      .filter((deployment) => deployment.comment?.includes(filterValue));
  }, [
    environment?.last_deploy?.id,
    environmentDeployments,
    filterValue,
    leftDeployment?.id,
    rightDeployment?.id,
    selectingSide,
  ]);

  const activeDeployment = environmentDeployments.find(
    (deploy) => deploy.id === environment?.last_deploy?.id
  );

  const selectDeployment = (deploymentId: string) => {
    navigate(
      generateCompareDeploymentsURL(
        orgId,
        appId,
        envId,
        selectingSide === 'left' ? deploymentId : leftDeploymentId,
        selectingSide === 'right' ? deploymentId : rightDeploymentId
      )
    );
    setOpen(false);
  };

  return (
    <WalModal
      openState={openState}
      showClose
      disableClickOutside
      title={
        selectingSide === 'left'
          ? compareDeploymentsTranslations.SELECT_BASE_DEPLOYMENT
          : compareDeploymentsTranslations.SELECT_TARGET_DEPLOYMENT
      }
      content={
        <>
          <SearchInput
            name={'deployments-filter'}
            placeholder={compareDeploymentsTranslations.FILTER_DEPLOYMENTS}
            hideLabel
            onChange={(value) => setFilterValue(value)}
          />
          {activeDeployment &&
            activeDeployment.comment?.includes(filterValue) &&
            (selectingSide === 'left'
              ? activeDeployment.id !== rightDeployment?.id
              : activeDeployment.id !== leftDeployment?.id) && (
              <SelectDeploymentCard
                title={compareDeploymentsTranslations.ACTIVE}
                deploy={activeDeployment}
                buttonText={compareDeploymentsTranslations.SELECT}
                buttonVariant={'primary'}
                buttonCallback={() => selectDeployment(activeDeployment.id)}
                isSelected={
                  selectingSide === 'left'
                    ? activeDeployment.id === leftDeployment?.id
                    : activeDeployment.id === rightDeployment?.id
                }
                isTransparent
              />
            )}
          {filteredDeployments.slice(0, numberOfShownDeployments).map((deploy, index) => (
            <SelectDeploymentCard
              key={deploy.id}
              title={index === 0 ? compareDeploymentsTranslations.DEPLOYED : undefined}
              deploy={deploy}
              buttonText={compareDeploymentsTranslations.SELECT}
              buttonVariant={'primary'}
              buttonCallback={() => selectDeployment(deploy.id)}
              isSelected={
                selectingSide === 'left'
                  ? deploy.id === leftDeployment?.id
                  : deploy.id === rightDeployment?.id
              }
              isTransparent
            />
          ))}
          {environmentDeployments.length === 0 && (
            <EmptyStateCard>
              {compareDeploymentsTranslations.NOTHING_HAS_BEEN_DEPLOYED}
            </EmptyStateCard>
          )}
          {filteredDeployments.length > 10 &&
            numberOfShownDeployments < filteredDeployments.length && (
              <WalButton
                variant={'secondary'}
                onClick={() => setNumberOfShownDeployments((prevState) => prevState + 10)}>
                {compareDeploymentsTranslations.SHOW_MORE_DEPLOYMENTS}
              </WalButton>
            )}
        </>
      }
    />
  );
};

export default SelectDeploymentModal;
