import { WalButton } from '@humanitec/ui-components';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import ResourceDefinitionForm from '@src/containers/Orgs/Resources/components/ResourceDefinitionForm/ResourceDefinitionForm';
import useResourceDefinitionByIdQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionByIdQuery';
import useResourceDriversQuery from '@src/hooks/react-query/resources/queries/useResourceDriversQuery';
import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import { ResourceForm } from '@src/models/resources';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-size: ${units.fontSize.base};
  margin-bottom: ${units.margin.lg};
`;

const ResourceDefinitionConfiguration = () => {
  // State
  const [editMode, setEditMode] = useState<boolean>(false);

  // React query
  const { data: resourceDrivers } = useResourceDriversQuery();
  const { data: resourceTypes } = useResourceTypesQuery();
  const { data: resourceDefinition } = useResourceDefinitionByIdQuery();

  // Form
  const formMethods = useWalhallForm<ResourceForm>();

  // I18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  const resourceType = resourceTypes?.find((rt) => rt.type === resourceDefinition?.type);

  return (
    <Wrapper>
      <FormProvider {...formMethods}>
        <LeftContent>
          <Text>{resourcesTranslations.CHANGES_TO_THIS_DEFINITION}</Text>
          {resourceType && (
            <ResourceDefinitionForm
              key={editMode ? 'edit-mode-form' : 'view-mode-form'}
              selectedDriver={resourceDrivers?.find(
                (driver) => `${driver.org_id}/${driver.id}` === resourceDefinition?.driver_type
              )}
              resourceType={resourceType}
              resource={resourceDefinition}
              onCanceButtonClicked={() => setEditMode(false)}
              onResourceDefinitionCreatedOrUpdated={() => setEditMode(false)}
              viewMode={!editMode}
            />
          )}
        </LeftContent>
      </FormProvider>
      {!editMode && (
        <div>
          <WalButton variant={'secondary'} iconLeft={'edit'} onClick={() => setEditMode(true)}>
            {resourcesTranslations.EDIT_CONFIGURATION}
          </WalButton>
        </div>
      )}
    </Wrapper>
  );
};

export default ResourceDefinitionConfiguration;
