import { AddMode, ComboSelect } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import UserDropdownItem from '@src/components/shared/UserDropdownItem';
import MembersRoleDropdown from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/AppMembers/components/MemberRolesDropdown';
import useAppRolesQuery from '@src/hooks/react-query/roles/queries/useAppRolesQuery';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import { AppRoles, OrgRoles, Role } from '@src/models/role';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 80% 1fr;
  grid-column-gap: ${units.margin.sm};
  width: 100%;
`;

interface AppMembersAddModeProps {
  onAdd: (formValues: { role: AppRoles }) => void;
  usersToAddState: [Role<OrgRoles>[], Dispatch<SetStateAction<Role<OrgRoles>[]>>];
  resetInputState: [boolean, Dispatch<SetStateAction<boolean>>];
}

interface FormData {
  users: string;
  role: AppRoles;
}

const AppMembersAddMode = ({ usersToAddState, resetInputState, onAdd }: AppMembersAddModeProps) => {
  const [usersToAdd, setUsersToAdd] = usersToAddState;

  // React Query
  const { data: users = [] } = useOrgRolesQuery();
  const { data: appRoles = [] } = useAppRolesQuery();

  // i18n
  const { t } = useTranslation();
  const translations = t('APP_SETTINGS').APP_MEMBERS;
  // Form
  const formMethods = useWalhallForm<FormData>({
    defaultValues: {
      users: '',
      role: 'viewer',
    },
  });
  const { setError, clearErrors } = formMethods;

  useEffect(() => {
    if (usersToAdd.length) {
      clearErrors('users');
    }
  }, [usersToAdd, clearErrors]);

  const items = users
    .filter((u) => !appRoles.map((r) => r.id).includes(u.id))
    .map((user) => ({
      label: user.name || user.email,
      component: <UserDropdownItem user={user} />,
      value: user,
      id: user.id,
      searchString: `${user.name} ${user.email}`,
    }));

  const handleAdd = (formValues: FormData) => {
    if (!Boolean(usersToAdd.length)) {
      setError('users', { message: translations.MUST_SELECT_ONE_USER });
    } else {
      onAdd(formValues);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <AddMode
        buttonText={translations.ADD_MEMBERS}
        shouldNotResetOnConfirm
        formContent={
          <FormWrapper>
            <ComboSelect
              items={items}
              name={'users'}
              label={translations.TYPE_TO_FILTER}
              defaultValues={[]}
              resetSelectedItemsState={resetInputState}
              onChange={(res: string[]) => {
                setUsersToAdd(users.filter((u) => res.includes(u.id)));
              }}
            />
            <MembersRoleDropdown defaultValue={'viewer'} label={'Role'} fullWidth />
          </FormWrapper>
        }
        onAdd={handleAdd}
      />
    </FormProvider>
  );
};

export default AppMembersAddMode;
