import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { AppRoles, EnvTypeRoles, OrgRoles } from '@src/models/role';
import { MatchParams } from '@src/models/routing';

import useApplicationEnvironmentsQuery from './react-query/environments/queries/useApplicationEnvironmentsQuery';
import useEnvironmentQuery from './react-query/environments/queries/useEnvironmentQuery';
import useGetCurrentUserQuery from './react-query/user/useGetCurrentUserQuery';
import { useGetUserRoles } from './useGetUserRoles';

export type RBACPermissionTypes = keyof ReturnType<typeof usePermissions>;

interface CustomParams {
  appId?: string;
  envId?: string;
}

/**
 * Internal hook. This is separated from the `useRBAC` hook so we can automatically infer the return type in the useRBAC hook.
 * Guidelines:
 * ```
 * - Be explicit about permission names. Even if the permitted roles are the same for two different features, define it as a separate key/name.
 *   This will improve readability & make it easier to understand what part of the UI it's referring to.
 * - Be explicit about what roles have permssions i.e. positive checks vs negative checks. Negative checks could lead to a new role type being granted permission once it's added.
 *   e.g. (appRole === 'owner' || appRole === 'developer') vs appRole !== 'viewer'
 * - orgType administator has permission to do everything by default. There's no need to include it in the permissions defined here.
 * ```
 */
const usePermissions = (orgRole: OrgRoles, customParams?: CustomParams) => {
  // Router hooks
  const { orgId, appId: routerAppId } = useParams<keyof MatchParams>() as MatchParams;

  const appId = customParams?.appId || routerAppId;

  // React Query
  const { data: user } = useGetCurrentUserQuery();
  const { data: applicationEnvironments } = useApplicationEnvironmentsQuery({ orgId, appId });
  const { data: environment } = useEnvironmentQuery({ orgId, appId, envId: customParams?.envId });

  const deployerRoleForAllEnvTypes = useMemo(() => {
    if (!applicationEnvironments?.length || !user) return false;

    const appEnvTypes = applicationEnvironments?.map((env) => env.type);

    const envTypeRolesForApplication: Record<string, EnvTypeRoles> = appEnvTypes.reduce(
      (prevState, envType) => {
        const role = user.roles[`/orgs/${orgId}/env-types/${envType}`] as EnvTypeRoles;

        return role
          ? {
              ...prevState,
              [envType]: role,
            }
          : prevState;
      },
      {}
    );

    if (!Object.keys(envTypeRolesForApplication).length) {
      return false;
    }

    /**
     * An Owner will not be able to delete an App unless they have the Deployer Role for all the Environment Types used in the App.
     */
    return appEnvTypes?.every((envTypeId) => envTypeRolesForApplication[envTypeId] === 'deployer');
  }, [applicationEnvironments, user, orgId]);

  // Selectors
  const appRole = user?.roles?.[`/orgs/${orgId}/apps/${appId}`];

  const envTypeRole = environment
    ? user?.roles?.[`/orgs/${orgId}/env-types/${environment.type}`]
    : undefined;

  const editApplication =
    appRole && (['owner', 'developer'] as AppRoles[]).includes(appRole as AppRoles);

  return {
    createEnvironment: appRole === 'owner' || appRole === 'developer',
    deleteEnvironment:
      (appRole === 'owner' || appRole === 'developer') && envTypeRole === 'deployer',
    accessDraftURL: appRole === 'owner' || appRole === 'developer',
    pauseEnvironment:
      (appRole === 'owner' || appRole === 'developer') && envTypeRole === 'deployer',
    revertValueVersion: appRole === 'owner' || appRole === 'developer',
    deleteApplication: appRole === 'owner' && deployerRoleForAllEnvTypes,
    createApplication: orgRole === 'manager',
    updateWebhook: appRole === 'owner',
    editApplication,
    deployEnvironment: editApplication && envTypeRole === 'deployer',
  };
};

/**
 * @param action Name of the action you want to get the permissions for.
 * @example
 * When naming your return variable when using the hook, prefix with `can`.
 * Permission `pauseEnvironment` will be named `canPauseEnvironment`.
 * ```
 *  const canPauseEnvironment = useRBAC('pauseEnvironment');
 * ```
 * @returns boolean
 */
export const useRBAC = (action?: RBACPermissionTypes, customParams?: CustomParams) => {
  // Selectors
  const { orgRole } = useGetUserRoles();

  const permissions = usePermissions(orgRole, customParams);

  // Administrator can access everything
  return (action && permissions[action]) || orgRole === 'administrator';
};
