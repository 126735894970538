export const resourcesQueryKeys = {
  // e.g. ['orgs', 'my-org', 'resources']
  all: (orgId: string | undefined) => ['orgs', orgId, 'resources'],
  // e.g. ['orgs', 'my-org', 'resources', 'drivers', 'list']
  listDrivers: (orgId: string | undefined) => [...resourcesQueryKeys.all(orgId), 'drivers', 'list'],
  // e.g. ['orgs', 'my-org', 'resources', 'types', 'list']
  listTypes: (orgId: string | undefined) => [...resourcesQueryKeys.all(orgId), 'types', 'list'],
  // e.g. ['orgs', 'my-org', 'resources', 'defs']
  allDefinitions: (orgId: string | undefined) => [...resourcesQueryKeys.all(orgId), 'defs'],
  // e.g. ['orgs', 'my-org', 'resources', 'defs', 'list']
  listDefinitions: (orgId: string | undefined) => [
    ...resourcesQueryKeys.allDefinitions(orgId),
    'list',
  ],
  // e.g. ['orgs', 'my-org', 'resources', 'defs', 'detail', 'my-def']
  definitionDetail: (orgId: string | undefined, defId: string | undefined) => [
    ...resourcesQueryKeys.allDefinitions(orgId),
    'detail',
    defId,
  ],
  // e.g. ['orgs', 'my-org', 'resources', 'defs', 'detail', 'my-def', 'active-resources']
  activeResources: (orgId: string | undefined, defId: string | undefined) => [
    ...resourcesQueryKeys.definitionDetail(orgId, defId),
    'active-resources',
    'list',
  ],
};
