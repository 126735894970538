import { rem } from 'polished';
import { css } from 'styled-components';

import { InputElementProps, inputHeight } from '../Input';

/**
 * Mixin for the textarea size
 */
export const textareaSizeStyle = (
  minHeight: string,
  fontSize: string,
  paddingVertical: string,
  paddingHorizontal: string
) => css`
  min-height: ${minHeight};
  font-size: ${fontSize};
  padding: ${paddingVertical} ${paddingHorizontal};
  box-sizing: border-box;
  &::placeholder {
    font-size: ${fontSize};
  }
`;

/**
 * Mixin for the input size
 */
export const inputSizeStyle = (
  height: string,
  fontSize: string,
  paddingVertical: string,
  paddingHorizontal: string
) => css`
  height: ${height};
  min-height: ${height};
  font-size: ${fontSize};
  padding: ${paddingVertical} ${paddingHorizontal};
  &::placeholder {
    font-size: ${fontSize};
  }
`;

const disabledStyles = css`
  color: ${({ theme }) => theme.color.textTranslucent};
  background: ${({ theme }) => theme.color.baseOutline};
  border: ${rem(1)} solid ${({ theme }) => theme.color.baseOutline} !important;
  outline: none;
`;

/**
 * Styling for the input component
 */
export const inputCss = ({
  inputSize = 'medium',
  rows,
  invalid,
  resizable,
  noHover,
  noBorderRadius,
  transparent,
  fontFamily,
  iconRight,
  hideOutline,
  readOnly,
  allowArrowScrolling,
}: InputElementProps) => css`
  margin: 0;
  border: ${rem(1)} solid ${({ theme }) => theme.color.baseOutline};
  border-radius: ${rem(4)};
  line-height: 1.5;
  transition: border linear 0.2s;
  background-color: ${({ theme }) => theme.color.baseLayer};
  color: ${({ theme }) => theme.color.text};
  box-shadow:
    inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 1px 0 rgba(255, 255, 255, 0.05);

  &:disabled {
    ${disabledStyles};
  }

  ${() => readOnly && disabledStyles};

  ${() =>
    allowArrowScrolling &&
    css`
      pointer-events: auto !important;
    `}

  &::placeholder {
    color: ${({ theme }) => theme.color.textTranslucent};
  }

  &:hover,
  &:focus {
    ${() =>
      !noHover &&
      !invalid &&
      css`
        border-color: ${({ theme }) => theme.color.mainDarker};
      `}
  }

  ${invalid &&
  css`
    border-color: ${({ theme }) => theme.color.alert};
  `};
  ${!resizable &&
  css`
    resize: none;
  `}

  ${() => {
    const sizeStyle = rows ? textareaSizeStyle : inputSizeStyle;
    switch (inputSize) {
      case 'large':
        return sizeStyle(inputHeight.large, rem(14), rem(4), rem(10));
      case 'small':
        return sizeStyle(inputHeight.small, rem(12), rem(4), rem(4));
      case 'medium':
      default:
        return sizeStyle(inputHeight.medium, rem(12), rem(4), rem(8));
    }
  }} 
  /* In case of icon to right of icon, add padding to right(1.7rem) else .5rem(which is the default)*/
  padding-right: ${iconRight ? rem(26) : rem(8)};

  ${() =>
    noBorderRadius === 'all'
      ? css`
          border-radius: 0;
        `
      : noBorderRadius === 'right'
        ? css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          `
        : noBorderRadius === 'left' &&
          css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          `}

  ${() =>
    transparent &&
    css`
      background-color: transparent;
      border: none;
      box-shadow: none;
    `}
    ${fontFamily === 'source-code' &&
  css`
    font-family: 'Source Code Pro', sans-serif;
  `}
  ${() =>
    hideOutline &&
    css`
      outline: none;
    `}
`;
