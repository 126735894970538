export const userQueryKeys = {
  userByUserId: (id: string) => ['users', id],
  currentUser: () => ['current-user'],
  invite: (inviteId: string | undefined) => ['invite', inviteId],
  user: (orgId: string | undefined, userId: string | undefined) => ['orgs', orgId, 'users', userId],
  userRoles: (orgId: string | undefined, userId: string | undefined) => [
    'orgs',
    orgId,
    'users',
    userId,
    'roles',
  ],
};
