import { ReactNode } from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

import Icon from '..//Icon/Icon';

const Wrapper = styled.div<{ $mode: Mode; disableDefaultMargin?: boolean }>`
  display: flex;
  align-items: flex-start;
  border-left: 2px solid
    ${({ theme, $mode }) =>
      $mode === 'warning'
        ? theme.color.yellow
        : $mode === 'alert'
          ? theme.color.alert
          : theme.color.main};
  padding-left: ${units.padding.sm};
  font-size: ${units.fontSize.sm};

  ${({ disableDefaultMargin }) =>
    !disableDefaultMargin &&
    css`
      margin-bottom: ${units.margin.lg};
    `}
`;

const WarningContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export type Mode = 'alert' | 'warning' | 'info';

export interface WarningSectionProps {
  children: ReactNode;
  /** the mode of the error: warning or alert **/
  mode?: Mode;
  /** remove default bottom margin from the component **/
  disableDefaultMargin?: boolean;
  className?: string;
  /** if the icon should be shown **/
  showIcon?: boolean;
}

const WarningSection = ({
  children,
  mode = 'warning',
  disableDefaultMargin,
  className,
  showIcon,
}: WarningSectionProps) => (
  <Wrapper className={className} $mode={mode} disableDefaultMargin={disableDefaultMargin}>
    {showIcon && (
      <Icon
        name={mode === 'info' ? 'info' : 'warning'}
        overrideColor={mode === 'alert' ? 'alert' : mode === 'warning' ? 'yellow' : 'main'}
        marginRight={'md'}
      />
    )}
    <WarningContentWrapper>{children}</WarningContentWrapper>
  </Wrapper>
);

export default WarningSection;
