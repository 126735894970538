import { Text, WalCard as Card } from '@humanitec/ui-components';
import i18next from 'i18next';
import { rem } from 'polished';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import usePipelineRunJobsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunJobsQuery';
import { PipelineRunJob } from '@src/models/pipeline';

import { getIsWaitingForApproval, getStatusIcons } from '../../../../utils';
import { JobDetails } from '../JobDetails/JobDetails';

const translations = i18next.t('PIPELINE_RUNS');

const CustomCard = styled(Card)<{ isSelected: boolean }>`
  background-color: ${({ theme, isSelected }) => isSelected && theme.color.mainDarker};
  border: ${({ theme, isSelected }) => isSelected && `1px solid ${theme.color.mainDarker}`};
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  align-items: start;
`;

const JobsList = styled.div`
  flex: 1;
`;

const Details = styled.div<{ darkenBg: boolean }>`
  flex: 4;
  border-radius: ${rem(4)};
  border: 1px solid ${({ theme }) => theme.color.baseBrighter};
  ${({ darkenBg, theme }) => darkenBg && `background-color:${theme.color.baseBrighter}`}
`;

const CustomText = styled(Text)<{ isSelected?: boolean }>`
  text-transform: capitalize;
  color: ${({ theme, isSelected }) => (isSelected ? theme.color.white : theme.color.text)};
`;

export const PipelineJobs = () => {
  const { pipelineRunId } = useParams();
  const { hash } = useLocation();

  const jobHash = hash.slice(1);

  const [selectedJob, setSelectedJob] = useState(jobHash || '');

  const { data: jobs = [] as PipelineRunJob[] } = usePipelineRunJobsQuery({ id: pipelineRunId });

  useEffect(() => {
    if (jobs.length && !jobHash) {
      setSelectedJob(jobs[jobs.length - 1].id);
    }
  }, [jobs, jobHash]);

  return (
    <div className={'flex-column mb-md pt-xl mt-lg full-height'}>
      <Text size={'sm'} color={'textTranslucent'}>
        {translations.JOBS.TITLE}
      </Text>
      <Wrapper className={'flex-centered gap-md full-height'}>
        <JobsList className={'flex-column mt-sm'}>
          {jobs.map((job) => (
            <CustomCard
              isSelected={selectedJob === job.id}
              onClick={() => {
                setSelectedJob(job.id);
                window.location.hash = job.id;
              }}
              role={'button'}
              key={job.id}
              highlightOnHover
              className={'flex-centered'}>
              {
                getStatusIcons(18, selectedJob === job.id)[
                  getIsWaitingForApproval(job) ? 'waiting' : job.status
                ]
              }{' '}
              <CustomText size={'lg'} isSelected={selectedJob === job.id} maxWidthEllipsis={140}>
                {job.id}
              </CustomText>
            </CustomCard>
          ))}
        </JobsList>
        <Details className={'p-xl mt-sm full-height flex-column'} darkenBg={Boolean(selectedJob)}>
          {selectedJob ? (
            <JobDetails jobId={selectedJob} />
          ) : (
            <div className={'flex-column align-center'}>
              <Text size={'sm'} color={'textTranslucent'}>
                {translations.JOBS.NO_JOB_SELECTED}
              </Text>
              <Text size={'sm'} color={'textTranslucent'} className={'mt-sm'}>
                {translations.JOBS.SELECT_JOB}
              </Text>
            </div>
          )}
        </Details>
      </Wrapper>
    </div>
  );
};
