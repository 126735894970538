import {
  FilterTable,
  Icon,
  Spinner,
  Text,
  WalLabel,
  WalMenu,
  WarningSection,
} from '@humanitec/ui-components';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { Dot } from '@src/components/shared/Dot';
import useEnvironmentRuntimeQuery from '@src/hooks/react-query/environments/queries/useEnvironmentRuntimeQuery';
import { useDebouncedValue } from '@src/hooks/useDebouncedValue';
import { useFeature } from '@src/hooks/useFeature';
import { ContainerErrorState, ReplicaPodRuntime } from '@src/models/environment';
import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';
import { getContainerErrorsInReplicaSet } from '@src/utilities/runtime-utils';

const ReplicaStatusesWrapper = styled.div<{ newVersion: boolean }>`
  display: flex;
  align-items: center;

  ${({ newVersion }) =>
    newVersion &&
    css`
      & > div:not(:last-child) {
        margin-right: ${units.margin.sm};
      }
      margin-top: ${units.margin.md};
    `}
`;

const ReplicaStatus = styled.div<{
  first?: boolean;
  last?: boolean;
  active?: boolean;
  newVersion?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
  padding: ${units.padding.sm} ${units.padding.md};

  ${({ newVersion, first, last, active }) =>
    newVersion
      ? css`
          border-radius: ${rem(4)};
          background: ${({ theme }) => theme.color.baseBrightest};
        `
      : css`
          border: ${rem(1)} solid ${({ theme }) => theme.color.baseOutline};
          cursor: pointer;
          height: ${rem(38)};
          &:hover {
            border-color: ${({ theme }) => theme.color.main};
            background-color: ${({ theme }) => theme.color.mainTransparent};
          }
          ${first &&
          css`
            border-top-left-radius: ${rem(5)};
            border-bottom-left-radius: ${rem(5)};
          `}
          ${last &&
          css`
            border-top-right-radius: ${rem(5)};
            border-bottom-right-radius: ${rem(5)};
          `}
          ${active &&
          css`
            border-color: ${({ theme }) => theme.color.main};
            background-color: ${({ theme }) => theme.color.mainTransparent};
          `}
        `};
`;

const DotWrapper = styled.div<{ newVersion?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${rem(6)};

  ${({ newVersion }) =>
    newVersion ? `margin-right: ${units.margin.xs}` : `margin-top: ${units.margin.xs}`};
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
  word-break: break-all;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${units.fontSize.sm};
  font-family: 'Source Code Pro', sans-serif;
  .hidden {
    visibility: hidden;
  }
`;

const PodBoxes = ({ phase, count }: { phase: ReplicaPodRuntime['phase']; count: number }) => (
  <ReplicaStatus aria-label={phase} tabIndex={0} key={phase} newVersion>
    <DotWrapper newVersion>
      {phase === 'Pending' && <Spinner diameter={10} />}
      {phase === 'Failed' && <Dot color={'red'} />}
      {phase === 'Unknown' && <Dot color={'yellow'} />}
      {phase === 'Running' &&
        (count > 0 ? (
          <Dot color={'green'} />
        ) : (
          <Icon overrideColor={'yellow'} name={'warning'} size={12} />
        ))}
    </DotWrapper>
    <Text className={'ml-sm'}>
      {count} {phase.toLowerCase()}
    </Text>
  </ReplicaStatus>
);

interface ReplicaStatusesProps {
  /** number of displayed replicas, further replicas will be hidden under dropdown menu */
  maxDisplayableReplicas: number;
}

const ReplicaStatuses = ({ maxDisplayableReplicas }: ReplicaStatusesProps) => {
  // i18n
  const { t } = useTranslation();
  const viewModuleTranslations = t('VIEW_MODULE');

  const [isReplicaNewDesignEnabled] = useFeature('replica_section_redesign');

  // Component state
  const [selectedReplicaStatus, setSelectedReplicaStatus] = useState<number>(0);
  const [errors, setErrors] = useState<ContainerErrorState[]>([]);
  const [removingReplicas, setRemovingReplicas] = useState(0);
  const [replicaRuntimes, setReplicaRuntimes] = useState<ReplicaPodRuntime[]>([]);
  const [replicasCount, setReplicaCount] = useState(0);

  // React Query
  const { data: environmentRuntimeData } = useEnvironmentRuntimeQuery();
  const { workloadController } = environmentRuntimeData || {};

  const debouncedWorkloadController = useDebouncedValue(workloadController, 300);

  useEffect(() => {
    setReplicaRuntimes(debouncedWorkloadController?.pods ?? []);
    setReplicaCount(debouncedWorkloadController?.replicas ?? 0);
  }, [debouncedWorkloadController]);

  useEffect(() => {
    const errorsArr: ContainerErrorState[] = [];
    if (selectedReplicaStatus !== undefined && replicaRuntimes) {
      getContainerErrorsInReplicaSet(replicaRuntimes[selectedReplicaStatus], errorsArr);
      setErrors(errorsArr);
    }
  }, [selectedReplicaStatus, replicaRuntimes]);

  useEffect(() => {
    setRemovingReplicas(replicaRuntimes.length - replicasCount);
  }, [replicasCount, replicaRuntimes.length]);

  const containerStatusError = !!debouncedWorkloadController?.pods.find(
    (pod) => pod.containerStatuses.length === 0
  );

  const runningPods = replicaRuntimes.filter(
    (pod) => pod.phase === 'Running' && pod.status === 'Success'
  );
  const unknownPods = replicaRuntimes.filter((pod) => pod.phase === 'Unknown');
  const failedPods = replicaRuntimes.filter(
    (pod) =>
      pod.phase === 'Failed' ||
      (pod.phase === 'Running' && ['Failure', 'Warning'].includes(pod.status))
  );

  const pendingPods = replicaRuntimes.filter((pod) => pod.phase === 'Pending');

  return (
    <>
      {!isReplicaNewDesignEnabled && <WalLabel>{viewModuleTranslations.STATUS}</WalLabel>}
      <ReplicaStatusesWrapper newVersion={isReplicaNewDesignEnabled}>
        {!isReplicaNewDesignEnabled ? (
          <>
            {replicaRuntimes?.map(
              (replicaRuntime, index) =>
                index < maxDisplayableReplicas && (
                  <ReplicaStatus
                    aria-label={`Replica ${index + 1} status`}
                    onClick={() => {
                      setSelectedReplicaStatus(index);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === KeyBindings.ENTER) {
                        setSelectedReplicaStatus(index);
                      }
                    }}
                    tabIndex={0}
                    active={index === selectedReplicaStatus}
                    key={replicaRuntime.podName}
                    first={index === 0}
                    last={index === replicaRuntimes.length - 1}>
                    {index + 1}
                    <DotWrapper>
                      {removingReplicas && index - removingReplicas >= 0 ? (
                        <Spinner diameter={10} />
                      ) : replicaRuntime.status === 'Failure' ||
                        replicaRuntime.status === 'Warning' ? (
                        <Dot color={'red'} />
                      ) : (
                        replicaRuntime.status === 'Success' && <Dot color={'base'} />
                      )}
                    </DotWrapper>
                  </ReplicaStatus>
                )
            )}
            {replicaRuntimes?.length >= maxDisplayableReplicas && (
              <WalMenu
                items={replicaRuntimes
                  .slice(14, replicaRuntimes?.length)
                  .map((replicaRuntime, index) => ({
                    value: index + maxDisplayableReplicas,
                    label: String(index + maxDisplayableReplicas + 1),
                  }))}
                overflowScrollable={false}
                onItemClick={(item) => {
                  setSelectedReplicaStatus(item.value);
                }}
                toggle={<ReplicaStatus last>•••</ReplicaStatus>}
              />
            )}
          </>
        ) : (
          <>
            {Boolean(runningPods.length) && (
              <PodBoxes count={runningPods.length} phase={'Running'} />
            )}
            {Boolean(unknownPods.length) && (
              <PodBoxes count={unknownPods.length} phase={'Unknown'} />
            )}
            {Boolean(failedPods.length) && <PodBoxes count={failedPods.length} phase={'Failed'} />}
            {Boolean(pendingPods.length) && (
              <PodBoxes count={pendingPods.length} phase={'Pending'} />
            )}
          </>
        )}
      </ReplicaStatusesWrapper>
      {isReplicaNewDesignEnabled ? (
        <BottomWrapper>
          {Boolean(errors.length) && (
            <WarningSection mode={'alert'}>
              <Trans
                defaults={viewModuleTranslations.ERROR_IN_X_PODS}
                values={{ count: errors.length }}
              />
              <FilterTable
                tableRowStyle={'base'}
                className={'mt-md'}
                tableStyle={'expandable'}
                rows={errors?.map((error) => ({
                  data: error,
                  expandableContent: (
                    <div className={'flex-column'}>
                      <Text size={'sm'} color={'textTranslucent'}>
                        {viewModuleTranslations.ERROR_CONTENT.CONTAINER}
                      </Text>
                      {error.container}
                      <Text size={'sm'} color={'textTranslucent'} className={'mt-lg'}>
                        {viewModuleTranslations.ERROR_CONTENT.ERROR}
                      </Text>
                      {error.message}
                      <Text size={'sm'} color={'textTranslucent'} className={'mt-lg'}>
                        {viewModuleTranslations.ERROR_CONTENT.DEBUG_INFORMATION}
                      </Text>
                      {viewModuleTranslations.ERROR_CONTENT.REASON}: {error.reason}
                    </div>
                  ),
                }))}
                columns={[
                  {
                    label: viewModuleTranslations.POD_NAME,
                    prop: 'pod_name',
                    template: (row) => <p>{row.data.pod || '-'}</p>,
                  },
                  {
                    label: viewModuleTranslations.CONTAINER_AFFECTED,
                    prop: 'container',
                    justifyContent: 'flex-start',
                    template: (row) => <p>{row.data.container || '-'}</p>,
                  },
                ]}
                caption={viewModuleTranslations.ERRORS}
                alignGroupedTableCellByWidthOf={'200px'}
              />
            </WarningSection>
          )}
          {containerStatusError ? (
            <div>{viewModuleTranslations.NO_CONTAINER_STATUS_AVALAIBLE}</div>
          ) : (
            <div aria-hidden={'true'} className={'hidden'}>
              -
            </div>
          )}
          {(debouncedWorkloadController?.status === 'Succeeded' ||
            debouncedWorkloadController?.status === 'Running') &&
            (!errors || errors?.length === 0) && (
              <div>{viewModuleTranslations.ALL_CONTAINERS_RUN_SUCCESSFULLY}</div>
            )}
        </BottomWrapper>
      ) : (
        <BottomWrapper>
          {errors?.map((error, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <div>
                {error.container} - {error.reason}
              </div>
              <ErrorMessage>{error.message}</ErrorMessage>
            </React.Fragment>
          ))}

          {containerStatusError ? (
            <div>{viewModuleTranslations.NO_CONTAINER_STATUS_AVALAIBLE}</div>
          ) : (
            <div aria-hidden={'true'} className={'hidden'}>
              -
            </div>
          )}
          {(debouncedWorkloadController?.status === 'Succeeded' ||
            debouncedWorkloadController?.status === 'Running') &&
            (!errors || errors?.length === 0) && (
              <div>{viewModuleTranslations.ALL_CONTAINERS_RUN_SUCCESSFULLY}</div>
            )}
        </BottomWrapper>
      )}
    </>
  );
};

export default ReplicaStatuses;
