import { useParams } from 'react-router-dom';

import ViewWorkloadProfile from '@src/components/shared/ViewWorkloadProfile/ViewWorkloadProfile';
import useWorkloadProfileLatestQuery from '@src/hooks/react-query/workload-profiles/queries/useWorkloadProfileLatestQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { MatchParams } from '@src/models/routing';

import PageHeader from '../PageHeader/PageHeader';
import { WorkloadStatus } from './components/WorkloadsStatus/WorkloadStatus';

const RenderViewWorkload = () => {
  // Router hooks
  const { deploymentId, moduleId } = useParams<keyof MatchParams>() as MatchParams;

  const { workloadProfileId } = useDeltaUtils();

  // React Query
  const { data: workloadProfile } = useWorkloadProfileLatestQuery(workloadProfileId);

  return workloadProfile?.spec_definition ? (
    <>
      <PageHeader
        statusMessage={<WorkloadStatus deploymentId={deploymentId} workloadId={moduleId} />}
        showPageContext
      />
      <ViewWorkloadProfile schema={workloadProfile.spec_definition} />
    </>
  ) : (
    <></>
  );
};

export default RenderViewWorkload;
